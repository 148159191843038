const INITIAL_STATE = {
  state: 'get',
  plan: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SALES_PLAN_STATE_CHANGED':
      return {
        ...state,
        state: action.payload,
      };
    case 'SALES_PLAN_CHANGED':
      return {
        ...state,
        plan: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};
