import React from 'react';
import * as ReactRedux from 'react-redux';

import type { IMarketplaceUser } from 'types/entities';

import type { IResourceListInterface } from 'types/services';
import { api } from 'services/api';
import {
  setCustomerState,
  setSnackbarMessage,
  setSnackbarOpen,
  setSnackbarType,
} from 'store/actions';

import { useAuthContext } from '../useAuthContext.hook';
import type { IMarketplaceUserReducerState } from 'types/redux/reducers/marketplace-user-reducer.types';
import {
  setListMarketplaceUsers,
  setMarketplaceUser,
  setMarketplaceUserPagination,
  setMarketplaceUserState,
} from 'store/actions/marketplace-user-actions';

interface IMarketplaceUserServiceGetAll {
  limit?: number;
  offset?: number;
}

interface UseMarketplaceUserService {
  create: {
    name: IMarketplaceUser['name'];
    email: IMarketplaceUser['email'];
    roles: IMarketplaceUser['roles'];
    documentNumber: IMarketplaceUser['documentNumber'];
    phoneNumber: IMarketplaceUser['phoneNumber'];
    internalMetadata: IMarketplaceUser['internalMetadata'];
    metadata: IMarketplaceUser['metadata'];
  };
  sendEmail: {
    id: IMarketplaceUser['name'];
  };
  edit: {
    id: IMarketplaceUser['id'];
    name: IMarketplaceUser['name'];
    phoneNumber: IMarketplaceUser['phoneNumber'];
    roles: IMarketplaceUser['roles'];
    internalMetadata: IMarketplaceUser['internalMetadata'];
    metadata: IMarketplaceUser['metadata'];
  };
  delete: {
    id: IMarketplaceUser['id'];
  };
}

function useMarketplaceUserService() {
  const dispatch = ReactRedux.useDispatch();

  const { user } = useAuthContext();

  const { marketplace_id } = user;

  const getAll = React.useCallback(
    async (props?: IMarketplaceUserServiceGetAll): Promise<void> => {
      try {
        const response = await api.get<
          IResourceListInterface<IMarketplaceUser>
        >(`v2/marketplaces/${marketplace_id}/users`, {
          auth: {
            username: process.env.REACT_APP_BYPASS_API_KEY!,
            password: '',
          },
          params: {
            limit: props?.limit,
            offset: props?.offset,
          },
        });

        const {
          page,
          offset,
          limit,
          has_more: hasMore,
          items: marketplaceUsers,
          total,
        } = response.data;

        const pagination: IMarketplaceUserReducerState['pagination'] = {
          hasMore,
          limit,
          offset,
          page,
          total,
        };

        dispatch([
          setListMarketplaceUsers(marketplaceUsers),
          setMarketplaceUserState('success'),
          setMarketplaceUserPagination(pagination),
        ]);
      } catch (error) {
        dispatch([
          setSnackbarMessage(
            'Não foi possível fazer a listagem dos usuários, tente novamente mais tarde!'
          ),
          setSnackbarType('error'),
          setSnackbarOpen(),
        ]);
        console.error('Não foi possível buscar a lista de usuários.');
        throw error;
      }
    },
    [dispatch, marketplace_id]
  );

  const create = React.useCallback(
    async (params: UseMarketplaceUserService['create']) => {
      try {
        const {
          name,
          email,
          roles,
          documentNumber,
          phoneNumber,
          internalMetadata,
          metadata,
        } = params;

        const response = await api.post<IMarketplaceUser>(
          `v2/marketplaces/${marketplace_id}/users`,
          {
            name,
            email,
            roles,
            documentNumber,
            phoneNumber,
            internalMetadata,
            metadata,
          },
          {
            auth: {
              username: process.env.REACT_APP_BYPASS_API_KEY!,
              password: '',
            },
          }
        );

        dispatch([
          setMarketplaceUserState('get'),
          setMarketplaceUser(null),
          setSnackbarMessage('Usuário criado com sucesso!'),
          setSnackbarType('success'),
          setSnackbarOpen(),
        ]);

        return response.data;
      } catch (error) {
        dispatch([
          setSnackbarMessage(
            'Não foi possível criar este usuário, tente novamente mais tarde!'
          ),
          setSnackbarType('error'),
          setSnackbarOpen(),
        ]);
        throw error;
      }
    },
    [dispatch, marketplace_id]
  );

  const sendEmail = React.useCallback(
    async (params: UseMarketplaceUserService['sendEmail']) => {
      try {
        const { id } = params;

        await api.post(
          `v2/marketplaces/${marketplace_id}/users/${id}/invite`,
          {},
          {
            auth: {
              username: process.env.REACT_APP_BYPASS_API_KEY!,
              password: '',
            },
          }
        );

        dispatch([
          setSnackbarMessage('E-mail enviado com sucesso!'),
          setSnackbarType('success'),
          setSnackbarOpen(),
        ]);
      } catch (error) {
        dispatch([
          setSnackbarMessage(
            'Não foi possível enviar este e-mail, tente novamente mais tarde!'
          ),
          setSnackbarType('error'),
          setSnackbarOpen(),
        ]);
      }
    },
    [dispatch, marketplace_id]
  );

  const edit = React.useCallback(
    async (params: UseMarketplaceUserService['edit']) => {
      try {
        const { id, name, phoneNumber, internalMetadata, metadata, roles } =
          params;

        await api.put(
          `v2/marketplaces/${marketplace_id}/users/${id}`,
          {
            name,
            phoneNumber,
            roles,
            internalMetadata,
            metadata,
          },
          {
            auth: {
              username: process.env.REACT_APP_BYPASS_API_KEY!,
              password: '',
            },
          }
        );

        dispatch([
          setMarketplaceUserState('get'),
          setMarketplaceUser(null),
          setSnackbarMessage('Usuário editado com sucesso!'),
          setSnackbarType('success'),
          setSnackbarOpen(),
        ]);
      } catch (error) {
        dispatch([
          setSnackbarMessage(
            'Não foi possível editar este usuário, tente novamente mais tarde!'
          ),
          setSnackbarType('error'),
          setSnackbarOpen(),
        ]);
      }
    },
    [dispatch, marketplace_id]
  );

  const report = React.useCallback(async () => {
    try {
      const response = await api.get(
        `v2/marketplaces/${marketplace_id}/users/report`,
        {
          auth: {
            username: process.env.REACT_APP_BYPASS_API_KEY!,
            password: '',
          },
          responseType: 'blob',
        }
      );

      const blob = response.data;
      const linkElement = document.createElement('a');
      linkElement.href = URL.createObjectURL(blob);
      linkElement.download = 'MarketplaceUsers.csv';
      linkElement.click();
      setTimeout(() => URL.revokeObjectURL(linkElement.href), 0);
    } catch (error) {
      dispatch([
        setSnackbarMessage(
          'Não foi possível gerar o relatório de usuários, tente novamente mais tarde!'
        ),
        setSnackbarType('error'),
        setSnackbarOpen(),
        setCustomerState('failed'),
      ]);
      console.error('Não foi possível gerar o relatório de usuários.');
      throw error;
    }
  }, [dispatch, marketplace_id]);

  const deleteMarketplaceUser = React.useCallback(
    async (params: UseMarketplaceUserService['delete']) => {
      try {
        await api.delete(
          `v2/marketplaces/${marketplace_id}/users/${params.id}`,
          {
            auth: {
              username: process.env.REACT_APP_BYPASS_API_KEY!,
              password: '',
            },
          }
        );

        dispatch([
          setMarketplaceUserState('get'),
          setMarketplaceUser(null),
          setSnackbarMessage('Usuário deletado com sucesso!'),
          setSnackbarType('success'),
          setSnackbarOpen(),
        ]);
      } catch (error) {
        dispatch([
          setSnackbarMessage(
            'Não foi possível deletar este usuário, tente novamente mais tarde!'
          ),
          setSnackbarType('error'),
          setSnackbarOpen(),
        ]);
      }
    },
    [dispatch, marketplace_id]
  );

  return React.useMemo(
    () => ({
      create,
      deleteMarketplaceUser,
      edit,
      getAll,
      report,
      sendEmail,
    }),
    [create, deleteMarketplaceUser, edit, getAll, report, sendEmail]
  );
}

export default useMarketplaceUserService;
