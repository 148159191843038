import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';

import {
  Link as RouterLink,
  useHistory,
  useParams,
  withRouter,
} from 'react-router-dom';

import validate from 'validate.js';

import {
  setSnackbarMessage,
  setSnackbarOpen,
  setSnackbarType,
} from 'store/actions/SnackbarActions';

import { makeStyles } from '@material-ui/styles';

import {
  Button,
  Grid,
  IconButton,
  Link,
  TextField,
  Typography,
} from '@material-ui/core';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { api } from 'services/api';

const schema = {
  email: {
    presence: {
      allowEmpty: false,
      message: '^E-mail é necessário',
    },
    email: {
      message: '^Não é um e-mail valido!',
    },
    length: {
      maximum: 64,
    },
  },
  password: {
    presence: {
      allowEmpty: false,
      message: '^Senha é necessária',
    },
    length: {
      maximum: 128,
    },
  },
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%',
  },
  grid: {
    height: '100%',
  },
  quoteContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  quote: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url(/images/auth.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '600px',
  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 900,
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white,
    fontWeight: 500,
  },
  bio: {
    color: theme.palette.white,
  },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  logoImage: {
    marginLeft: theme.spacing(4),
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    },
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  title: {
    marginTop: theme.spacing(3),
  },
  socialButtons: {
    marginTop: theme.spacing(3),
  },
  socialIcon: {
    marginRight: theme.spacing(1),
  },
  sugestion: {
    marginTop: theme.spacing(2),
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  signInButton: {
    margin: theme.spacing(2, 0),
  },
}));

interface IFormState {
  isValid: boolean;
  values: {
    email: string;
    password: string;
  };
  errors: {
    email?: string[];
    password?: string[];
  };
  touched: {
    email?: boolean;
    password?: boolean;
  };
}

const MarketplaceUserSignInPage = () => {
  const history = useHistory();
  const classes = useStyles();
  const { marketplaceId } = useParams<{ marketplaceId: string }>();

  const dispatch = useDispatch();

  const [formState, setFormState] = useState<IFormState>({
    isValid: false,
    values: {
      email: '',
      password: '',
    },
    touched: {},
    errors: {},
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: !errors,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleBack = () => {
    history.goBack();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleSignIn = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const { email, password } = formState.values;

    try {
      const response = await api.post(
        `/v2/marketplaces/${marketplaceId}/users/authenticate`,
        {
          email,
          password,
        }
      );

      const userLogged = response.data;

      if (!userLogged) {
        dispatch([
          setSnackbarMessage(
            'Não foi possível fazer o login, tente novamente mais tarde !'
          ),
          setSnackbarType('error'),
          setSnackbarOpen(),
        ]);
      }

      localStorage.setItem('token', userLogged.token);

      history.push('/');
    } catch (error) {
      console.error('Não foi possível efetuar o login');

      dispatch([
        setSnackbarMessage(
          'Não foi possível fazer o login, tente novamente mais tarde !'
        ),
        setSnackbarType('error'),
        setSnackbarOpen(),
      ]);
    }
  };

  const hasError = (field: keyof IFormState['values']) =>
    !!(formState.touched[field] && formState.errors[field]);

  return (
    <div className={classes.root}>
      <Grid className={classes.grid} container>
        <Grid className={classes.quoteContainer} item lg={6}>
          <div className={classes.quote}>
            <div className={classes.quoteInner}>
              <Typography className={classes.quoteText} variant="h1">
                Receba pagamentos online por boleto ou cartão de crédito
              </Typography>
              <div>
                <Typography className={classes.name} variant="body1">
                  Sem burocracia, sem taxas extras e pra todo mundo.
                </Typography>
              </div>
            </div>
          </div>
        </Grid>
        <Grid className={classes.content} item lg={6} xs={12}>
          <div className={classes.content}>
            <div className={classes.contentHeader}>
              <IconButton onClick={handleBack}>
                <ArrowBackIcon />
              </IconButton>
            </div>
            <div className={classes.contentBody}>
              <form className={classes.form} onSubmit={handleSignIn}>
                <Typography className={classes.title} variant="h2">
                  Entrar
                </Typography>
                {/* <Typography color="textSecondary" gutterBottom>
                  Entre com de mídias sociais
                </Typography> */}
                {/* <Grid className={classes.socialButtons} container spacing={2}>
                  <Grid item>
                    <Button
                      color="primary"
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      <FacebookIcon className={classes.socialIcon} />
                      Login com Facebook
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button size="large" variant="contained">
                      <GoogleIcon className={classes.socialIcon} />
                      Login com Google
                    </Button>
                  </Grid>
                </Grid> */}
                {/* <Typography
                  align="center"
                  className={classes.sugestion}
                  color="textSecondary"
                  variant="body1"
                >
                  ou entre com seu e-mail
                </Typography> */}
                <TextField
                  className={classes.textField}
                  error={hasError('email')}
                  fullWidth
                  helperText={
                    hasError('email') ? formState.errors.email?.[0] : null
                  }
                  label="E-mail"
                  name="email"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.email || ''}
                  variant="outlined"
                />
                <TextField
                  className={classes.textField}
                  error={hasError('password')}
                  fullWidth
                  helperText={
                    hasError('password') ? formState.errors.password?.[0] : null
                  }
                  label="Senha"
                  name="password"
                  onChange={handleChange}
                  type="password"
                  value={formState.values.password || ''}
                  variant="outlined"
                />
                <Button
                  className={classes.signInButton}
                  color="primary"
                  disabled={!formState.isValid}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Entrar agora
                </Button>
                <Typography color="textSecondary" variant="body1">
                  Não tem conta ainda?{' '}
                  <Link component={RouterLink} to="/sign-up" variant="h6">
                    Cadastre-se
                  </Link>
                </Typography>
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export const MarketplaceUserSignIn = withRouter(MarketplaceUserSignInPage);
