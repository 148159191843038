import React from 'react';
import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';
import clsx from 'clsx';

import useStyles from './SearchInput.styles';

type SearchInputProps = Mui.InputProps;

const SearchInputComponent = (props: SearchInputProps) => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <Mui.Paper className={clsx(classes.root, className)}>
      <MuiIcons.Search className={classes.icon} />
      <Mui.Input
        {...rest}
        className={classes.input}
        classes={{ disabled: classes.disabled }}
        disableUnderline
      />
    </Mui.Paper>
  );
};

export default SearchInputComponent;
