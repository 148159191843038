import React, { useState } from 'react';

import { useDispatch } from 'react-redux';

import { Link as RouterLink } from 'react-router-dom';

import clsx from 'clsx';

import { setNotificationsOpen } from 'store/actions';

import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';

import { AppBar, Toolbar, Badge, Hidden, IconButton } from '@material-ui/core';

import MenuIcon from '@material-ui/icons/Menu';

import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none',
    backgroundColor: theme.palette.background.dark,
  },
  flexGrow: {
    flexGrow: 1,
  },
  containerLogo: {
    display: 'flex',
    alignItems: 'center',
    width: '200px',
  },
  logo: {
    marginRight: '1rem',
    maxWidth: '100px',
  },
  textLogo: {
    color: '#fff',
  },
  signOutButton: {
    marginLeft: theme.spacing(1),
  },
}));

const Topbar = props => {
  const { className, onSidebarOpen, ...rest } = props;

  const classes = useStyles();

  const dispatch = useDispatch();

  const [notifications] = useState([]);

  const handleNotificationsOpen = () => dispatch(setNotificationsOpen());

  return (
    <AppBar {...rest} className={clsx(classes.root, className)}>
      <Toolbar>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onSidebarOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
        <RouterLink className={classes.containerLogo} to="/">
          <img
            alt="Logo"
            className={classes.logo}
            src="/images/logos/logo.svg"
          />
        </RouterLink>
        <div className={classes.flexGrow} />
        <IconButton color="inherit" onClick={handleNotificationsOpen}>
          <Badge
            badgeContent={notifications.length}
            color="primary"
            overlap="circular"
            variant="dot"
          >
            <NotificationsIcon />
          </Badge>
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,
};

export default Topbar;
