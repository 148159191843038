import React from 'react';

import * as CheckoutHooks from 'hooks/useCheckout';
import * as InternalComponents from './components';

function CheckoutsList() {
  const checkout = CheckoutHooks.useCheckout();
  const { state, listCheckouts, pagination } = checkout.state;

  React.useEffect(() => {
    if (state === 'get') {
      checkout.service.getAll({
        offset: pagination?.offset,
        limit: pagination?.limit,
      });
    }
  }, [checkout.service, pagination?.limit, pagination?.offset, state]);

  if (state === 'get') {
    return <InternalComponents.CheckoutListLoading />;
  } else if (listCheckouts.length > 0) {
    return <InternalComponents.CheckoutListTable />;
  } else if (state === 'failed') {
    return <InternalComponents.CheckoutFailedState />;
  } else {
    return <InternalComponents.CheckoutEmptyState />;
  }
}

export default CheckoutsList;
