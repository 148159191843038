import React from 'react';
import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';

import * as SaleHooks from 'hooks/useSale';

import useStyles from './SaleFailedState.styles';

function SaleFailedState() {
  const classes = useStyles();
  const sale = SaleHooks.useSale();

  return (
    <Mui.Grid
      alignItems="center"
      className={classes.containerProgress}
      container
      direction="column"
      justify="center"
      spacing={2}
    >
      <Mui.Typography align="center" variant="body2">
        Não foi possível se conectar ao servidor. Tente novamente...
      </Mui.Typography>
      <Mui.IconButton onClick={sale.ui.refresh}>
        <MuiIcons.Refresh />
      </Mui.IconButton>
    </Mui.Grid>
  );
}

export default SaleFailedState;
