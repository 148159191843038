import React from 'react';

import { useDispatch } from 'react-redux';

import { setBankAccountState, setBankAccount } from 'store/actions';

import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Typography,
  IconButton,
} from '@material-ui/core';

import { BankAccountToolbar, BankAccountList } from './components';

import { makeStyles } from '@material-ui/styles';

import { Refresh as RefreshIcon } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(3),
    backgroundColor: theme.palette.background.dark2,
    padding: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1),
      margin: theme.spacing(1),
    },
  },
  cardContent: {
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1),
    },
  },
}));

const BankAccount = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleRefresh = () => {
    dispatch([setBankAccount({}), setBankAccountState('get')]);
  };

  return (
    <Card className={classes.root}>
      <CardHeader
        action={
          <IconButton onClick={handleRefresh}>
            <RefreshIcon />
          </IconButton>
        }
        subheader={
          <Typography color="textSecondary" variant="h5">
            Cadastre recebedores e escolha onde deseja receber suas
            transferências automáticas
          </Typography>
        }
        title={<Typography variant="h3">Contas Bancárias</Typography>}
      />
      <Divider />
      <CardContent className={classes.cardContent}>
        <BankAccountToolbar />
        <BankAccountList />
      </CardContent>
    </Card>
  );
};

export default BankAccount;
