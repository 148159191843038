import type { IOfferReducerAction, IOfferReducerState } from 'types/redux';

const INITIAL_STATE: IOfferReducerState = {
  state: 'get',
  offer: null,
  listOffers: [],
  pagination: null,
};

export default (
  state = INITIAL_STATE,
  action: IOfferReducerAction<unknown>
) => {
  switch (action.type) {
    case 'OFFER_STATE_CHANGED':
      return {
        ...state,
        state: action.payload,
      };
    case 'OFFER_CHANGED':
      return {
        ...state,
        offer: action.payload,
      };
    case 'LIST_OFFERS_CHANGED':
      return {
        ...state,
        listOffers: action.payload,
      };
    case 'OFFER_PAGINATION_CHANGED':
      return {
        ...state,
        pagination: action.payload,
      };
    default:
      return state;
  }
};
