import React, { forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import { logout } from '../../../../../../auth';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { List, ListItem, Button } from '@material-ui/core';
import { PowerSettingsNew } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  root: {},
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.text.primary,
    width: '100%',
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
  },
  buttonLabel: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    color: theme.palette.text.primary,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
  },
  iconDisabled: {
    color: theme.palette.grey['700'],
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.primary.main,
    },
  },
}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div
    ref={ref}
    style={{
      flexGrow: 1,
    }}
  >
    <RouterLink {...props} />
  </div>
));

const SidebarNav = props => {
  const { pages, className, history, onClose, ...rest } = props;

  const classes = useStyles();

  const handleLogout = () => logout(history);

  return (
    <List {...rest} className={clsx(classes.root, className)}>
      {pages.map(page => (
        <ListItem className={classes.item} disableGutters key={page.title}>
          <Button
            activeClassName={classes.active}
            className={classes.button}
            classes={{
              label: classes.buttonLabel,
            }}
            component={CustomRouterLink}
            disabled={page.disabled}
            onClick={onClose}
            to={page.href}
          >
            <div className={clsx(classes.icon, classes.iconDisabled)}>
              {page.icon}
            </div>
            {page.title}
            {page.adornmentLeft}
          </Button>
        </ListItem>
      ))}
      <ListItem className={classes.item} disableGutters>
        <Button className={classes.button} onClick={handleLogout}>
          <div className={classes.icon}>
            <PowerSettingsNew />
          </div>
          Sair
        </Button>
      </ListItem>
    </List>
  );
};

SidebarNav.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.array.isRequired,
};

export default SidebarNav;
