import type {
  IMarketplaceUserReducerAction,
  IMarketplaceUserReducerState,
} from 'types/redux/reducers/marketplace-user-reducer.types';

const INITIAL_STATE: IMarketplaceUserReducerState = {
  state: 'get',
  marketplaceUser: null,
  listMarketplaceUsers: [],
  pagination: null,
};

export default (
  state = INITIAL_STATE,
  action: IMarketplaceUserReducerAction<unknown>
) => {
  switch (action.type) {
    case 'MARKETPLACE_USER_STATE_CHANGED':
      return {
        ...state,
        state: action.payload,
      };
    case 'MARKETPLACE_USER_CHANGED':
      return {
        ...state,
        marketplaceUser: action.payload,
      };
    case 'LIST_MARKETPLACE_USERS_CHANGED':
      return {
        ...state,
        listMarketplaceUsers: action.payload,
      };
    case 'MARKETPLACE_USER_PAGINATION_CHANGED':
      return {
        ...state,
        pagination: action.payload,
      };
    default:
      return state;
  }
};
