export function setDashboardState(state) {
  return {
    type: 'DASHBOARD_STATE_CHANGED',
    payload: state,
  };
}

export function setTotalSales(number_sales) {
  return {
    type: 'TOTAL_SALES_CHANGED',
    payload: number_sales,
  };
}

export function setTotalCustomers(number_customers) {
  return {
    type: 'TOTAL_CUSTOMERS_CHANGED',
    payload: number_customers,
  };
}

export function setCustomerDifferenceValue(difference_value) {
  return {
    type: 'CUSTOMER_DIFFERENCE_VALUE_CHANGED',
    payload: difference_value,
  };
}

export function setExpectedBilling(money) {
  return {
    type: 'EXPECTED_BILLING_CHANGED',
    payload: money,
  };
}

export function setCurrentBalance(money) {
  return {
    type: 'CURRENT_BALANCE_CHANGED',
    payload: money,
  };
}

export function setSales(sales_list) {
  return {
    type: 'SALES_CHANGED',
    payload: sales_list,
  };
}

export function setSalesDifferenceValue(difference_value) {
  return {
    type: 'SALES_DIFFERENCE_VALUE_CHANGED',
    payload: difference_value,
  };
}

export function setSalesSucceeded(number_sales_succeeded) {
  return {
    type: 'SALES_SUCCEEDED_CHANGED',
    payload: number_sales_succeeded,
  };
}

export function setSalesPending(number_sales_pending) {
  return {
    type: 'SALES_PENDING_CHANGED',
    payload: number_sales_pending,
  };
}

export function setSalesFailed(number_sales_failed) {
  return {
    type: 'SALES_FAILED_CHANGED',
    payload: number_sales_failed,
  };
}

export function setSalesRefunded(number_sales_refunded) {
  return {
    type: 'SALES_REFUNDED_CHANGED',
    payload: number_sales_refunded,
  };
}

export function setDashboardFilter(filter) {
  return {
    type: 'DASHBOARD_FILTER_CHANGED',
    payload: filter,
  };
}
