import React from 'react';

import * as PlanHooks from 'hooks/usePlan';
import * as InternalComponents from './components';

function PlansList() {
  const plan = PlanHooks.usePlan();
  const { state, listPlans, pagination } = plan.state;

  React.useEffect(() => {
    if (state === 'get') {
      plan.service.getAll({
        offset: pagination?.offset,
        limit: pagination?.limit,
      });
    }
  }, [plan.service, pagination?.limit, pagination?.offset, state]);

  if (state === 'get') {
    return <InternalComponents.PlanListLoading />;
  } else if (listPlans.length > 0) {
    return <InternalComponents.PlanListTable />;
  } else if (state === 'failed') {
    return <InternalComponents.PlanFailedState />;
  } else {
    return <InternalComponents.PlanEmptyState />;
  }
}

export default PlansList;
