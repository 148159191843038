export function chargeNextStep() {
  return {
    type: 'CHARGE_NEXT_STEP',
  };
}

export function chargePreviousStep() {
  return {
    type: 'CHARGE_PREVIOUS_STEP',
  };
}

export function setChargeCustomers(customers) {
  return {
    type: 'CHARGE_CUSTOMERS_CHANGED',
    payload: customers,
  };
}

export function setChargeBillet(activedOrDesactived) {
  return {
    type: 'CHARGE_BILLET_CHANGED',
    payload: activedOrDesactived,
  };
}

export function setChargeCreditCard(activedOrDesactived) {
  return {
    type: 'CHARGE_CREDIT_CARD_CHANGED',
    payload: activedOrDesactived,
  };
}

export function setChargeAmount(amount) {
  return {
    type: 'CHARGE_AMOUNT_CHANGED',
    payload: amount,
  };
}

export function setChargeInstallments(number) {
  return {
    type: 'CHARGE_INSTALLMENTS_CHANGED',
    payload: number,
  };
}

export function setChargeExpirationDate(date) {
  return {
    type: 'CHARGE_EXPIRATION_DATE_CHANGED',
    payload: date,
  };
}

export function setChargeExpirationOptions(activedOrDesactived) {
  return {
    type: 'CHARGE_EXPIRATION_OPTIONS_CHANGED',
    payload: activedOrDesactived,
  };
}

export function setChargePaymentLimitDate(numberDays) {
  return {
    type: 'CHARGE_PAYMENT_LIMIT_DATE_CHANGED',
    payload: numberDays,
  };
}

export function setChargeLateFeePercentage(percentageDecimal) {
  return {
    type: 'CHARGE_LATE_FEE_PERCENTAGE_CHANGED',
    payload: percentageDecimal,
  };
}

export function setChargeInterestPercentage(percentageDecimal) {
  return {
    type: 'CHARGE_INTEREST_PERCENTAGE_CHANGED',
    payload: percentageDecimal,
  };
}

export function setChargeDiscountOptions(activedOrDesactived) {
  return {
    type: 'CHARGE_DISCOUNT_OPTIONS_CHANGED',
    payload: activedOrDesactived,
  };
}

export function setChargeDiscountLimitDate(numberDays) {
  return {
    type: 'CHARGE_DISCOUNT_LIMIT_DATE_CHANGED',
    payload: numberDays,
  };
}

export function setChargeDiscountAmount(amount) {
  return {
    type: 'CHARGE_DISCOUNT_AMOUNT_CHANGED',
    payload: amount,
  };
}

export function setChargeDisabledButtons(enableOrDisable) {
  return {
    type: 'CHARGE_DISABLED_BUTTONS_CHANGED',
    payload: enableOrDisable,
  };
}

export function setChargeState(state) {
  return {
    type: 'CHARGE_STATE_CHANGED',
    payload: state,
  };
}

export function setChargeList(chargeList) {
  return {
    type: 'CHARGE_LIST_CHANGED',
    payload: chargeList,
  };
}

export function resetCharge() {
  return {
    type: 'CHARGE_STATE_RESETED',
  };
}

export function setChargeSelected(charge) {
  return {
    type: 'CHARGE_SELECTED_CHANGED',
    payload: charge,
  };
}
