import React from 'react';
import * as ReactRedux from 'react-redux';
import { validate } from 'validate.js';

import { useTaxInvoiceConfigService } from 'hooks/use-tax-invoice-config';
import * as SubscriptionHooks from 'hooks/useSubscription';
import type { Moment } from 'moment';
import moment from 'moment';
import {
  setDialogClose,
  setDialogType,
  setSubscription,
  setSnackbarMessage,
  setSnackbarOpen,
  setSnackbarType,
} from 'store/actions';
import type { ISubscription } from 'types/entities';

import type { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import type { RootState } from 'types/redux';

interface IFormState {
  disabled: boolean;
  isValid: boolean;
  values: {
    plan: ISubscription['plan'];
    card: ISubscription['card'];
    description: ISubscription['description'];
    payment_method: ISubscription['payment_method'];
    due_date: Moment | null;
    due_since_date: Moment | null;
    expiration_date: Moment | null;
    amount: string | number;
    currency: ISubscription['currency'];
    status: ISubscription['status'];
    suspended_at: Moment | null;
    metadata: ISubscription['metadata'];
  };
  errors: {
    plan?: string;
    card?: string;
    description?: string;
    payment_method?: string;
    due_date?: string;
    due_since_date?: string;
    expiration_date?: string;
    amount?: string;
    currency?: string;
    status?: string;
    suspended_at?: string;
    metadata?: string;
  };
  touched: {
    plan?: boolean;
    card?: boolean;
    description?: boolean;
    payment_method?: boolean;
    due_date?: boolean;
    due_since_date?: boolean;
    expiration_date?: boolean;
    amount?: boolean;
    currency?: boolean;
    status?: boolean;
    suspended_at?: boolean;
    metadata?: boolean;
  };
}

const schema = {
  name: {
    presence: {
      allowEmpty: false,
      message: '^Nome é obrigatório',
    },
  },
  image: {
    presence: {
      allowEmpty: false,
      message: '^Imagem é obrigatória',
    },
  },
  description: {
    presence: {
      allowEmpty: false,
      message: '^Descrição é obrigatória',
    },
  },
};

export function useSubscriptionEditDialog() {
  const dispatch = ReactRedux.useDispatch();

  const { state, service } = SubscriptionHooks.useSubscription();

  const { subscription, state: subscriptionState } = state;

  const formStateInitialValue = React.useMemo(
    () => ({
      disabled: false,
      isValid: false,
      values: {
        plan: subscription?.plan || '',
        card: subscription?.card || '',
        description: subscription?.description || '',
        payment_method: subscription?.payment_method || 'credit',
        due_date: subscription?.due_date
          ? moment(subscription?.due_date)
          : null,
        due_since_date: subscription?.due_since_date
          ? moment(subscription.due_since_date)
          : null,
        expiration_date: subscription?.expiration_date
          ? moment(subscription.expiration_date)
          : null,
        amount: subscription?.amount ? subscription?.amount / 100 : '',
        currency: subscription?.currency || '',
        status: subscription?.status || 'active',
        suspended_at: subscription?.suspended_at
          ? moment(subscription.suspended_at)
          : null,
        metadata: subscription?.metadata || null,
      },
      errors: {},
      touched: {
        payment_method: true,
        due_date: true,
        due_since_date: true,
        expiration_date: true,
      },
    }),
    [subscription]
  );

  const [formState, setFormState] = React.useState<IFormState>(
    formStateInitialValue
  );

  const handleDialogClose = React.useCallback(() => {
    dispatch([setSubscription(null), setDialogClose(), setDialogType(null)]);
  }, [dispatch]);

  const handleChangeForm = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.persist();

      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          [event.target.name]:
            event.target.type === 'checkbox'
              ? event.target.checked
              : event.target.value,
        },
        touched: {
          ...formState.touched,
          [event.target.name]: true,
        },
      }));
    },
    []
  );

  const handleImageChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const files = event.target.files;

      if (files?.length === 0) {
        return;
      }

      const file = files![0];

      setFormState(prevState => ({
        ...prevState,
        values: {
          ...prevState.values,
          image: URL.createObjectURL(file),
          imageFile: file,
        },
      }));
    },
    []
  );

  const handleMetadataChange = React.useCallback(
    (metadata: Record<string, string | number> | null) => {
      setFormState(prevState => ({
        ...prevState,
        values: {
          ...prevState.values,
          metadata,
        },
      }));
    },
    []
  );

  const hasError = React.useCallback(
    (field: keyof IFormState['values']) =>
      !!(formState.touched[field] && formState.errors[field]),
    [formState.errors, formState.touched]
  );

  function handleInputFocus(inputName: keyof IFormState['values']) {
    const inputElement = document.querySelector(
      `#${inputName}`
    ) as HTMLInputElement;

    inputElement.focus();
  }

  const handleDateChange = React.useCallback(
    (
        field: keyof Pick<
          IFormState['values'],
          'due_date' | 'due_since_date' | 'expiration_date' | 'suspended_at'
        >
      ) =>
      (date: MaterialUiPickersDate) => {
        setFormState(prevState => ({
          ...prevState,
          values: {
            ...prevState.values,
            [field]: date,
          },
        }));
      },
    []
  );

  const handleSubmit = React.useCallback(async () => {
    try {
      setFormState(prevState => ({
        ...prevState,
        error: {},
      }));

      const {
        amount,
        card,
        currency,
        description,
        due_date,
        due_since_date,
        expiration_date,
        metadata,
        payment_method,
        plan,
        status,
        suspended_at,
      } = formState.values;

      if (subscriptionState === 'edit') {
        await service.edit({
          id: subscription!.id,
          due_date: due_date?.toISOString(),
        });
      }

      dispatch([setDialogClose(), setDialogType(null)]);
    } catch (error) {
      console.log(error);
      dispatch([
        setSnackbarMessage(
          'Não foi possível criar este produto, tente novamente mais tarde!'
        ),
        setSnackbarType('error'),
        setSnackbarOpen(),
      ]);
    }
  }, [dispatch, formState.values, service, subscription, subscriptionState]);

  React.useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: !errors,
      errors: errors || {},
    }));
  }, [formState.values]);

  return React.useMemo(
    () => ({
      formState,
      subscription,
      subscriptionState,

      handleDateChange,
      handleChangeForm,
      handleDialogClose,
      handleImageChange,
      handleMetadataChange,
      handleSubmit,
      hasError,
      setFormState,
    }),
    [
      formState,
      subscription,
      subscriptionState,

      handleDateChange,
      handleChangeForm,
      handleDialogClose,
      handleImageChange,
      handleMetadataChange,
      handleSubmit,
      hasError,
      setFormState,
    ]
  );
}
