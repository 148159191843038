import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';
import clsx from 'clsx';
import moment from 'moment';
import React from 'react';
import * as ReactRedux from 'react-redux';

import * as MarketplaceHooks from 'hooks/use-marketplace-user';
import type { IMarketplaceUser } from 'types/entities';

import { useAuthContext } from 'hooks/useAuthContext.hook';

import useStyles from './marketplace-user-list-table.styles';
import { MarketplaceUserUtils } from 'helpers/marketplace-user-utils';
import { getDisplayPhoneNumber } from 'helpers';
import {
  setMarketplaceUserPagination,
  setMarketplaceUserState,
} from 'store/actions/marketplace-user-actions';

function MarketplaceUserListTable() {
  const theme = Mui.useTheme();
  const dispatch = ReactRedux.useDispatch();
  const marketplaceUser = MarketplaceHooks.useMarketplaceUser();
  const { marketplaceUser: marketplaceUserAuthenticated } = useAuthContext();
  const { pagination, listMarketplaceUsers } = marketplaceUser.state;

  const classes = useStyles();

  function handlePageChange(_: unknown, page: number) {
    if (!pagination) {
      return;
    }

    let newOffset: number;
    if (page + 1 > pagination.page) {
      newOffset = pagination.offset + pagination.limit;
    } else {
      newOffset = pagination.offset - pagination.limit;
    }

    dispatch([
      setMarketplaceUserPagination({ ...pagination, offset: newOffset }),
      setMarketplaceUserState('get'),
    ]);
  }

  function handleRowsPerPageChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (!pagination) {
      return;
    }

    const limit = Number(event.target.value);

    dispatch([
      setMarketplaceUserPagination({ ...pagination, limit, offset: 0 }),
      setMarketplaceUserState('get'),
    ]);
  }

  const listMarketplaceUsersFiltered = React.useMemo(() => {
    return listMarketplaceUsers.filter(
      (marketplaceUserData: IMarketplaceUser) =>
        marketplaceUserAuthenticated?.id !== marketplaceUserData.id
    );
  }, [listMarketplaceUsers, marketplaceUserAuthenticated?.id]);

  return (
    <Mui.Grid className={classes.container} component={Mui.List} container>
      <Mui.Grid
        className={classes.paginationContainer}
        container
        justifyContent="flex-end"
      >
        <Mui.TablePagination
          SelectProps={{
            classes: {
              icon: classes.icon,
            },
            MenuProps: {
              classes: {
                paper: classes.menuList,
              },
            },
          }}
          backIconButtonText="Voltar página"
          component="div"
          count={pagination?.total || 0}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to === -1 ? count : to} de ${
              count !== -1 ? count : `mais que ${to}`
            }`
          }
          labelRowsPerPage=""
          nextIconButtonText="Próxima página"
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          page={(pagination?.page || 1) - 1}
          rowsPerPage={pagination?.limit || 30}
          rowsPerPageOptions={[30, 50, 100]}
        />
      </Mui.Grid>

      <Mui.ListItem disableGutters>
        <Mui.Grid className={classes.tableCell} item md={2} sm={3} xs={8}>
          <Mui.Typography noWrap variant="h5">
            Nome
          </Mui.Typography>
        </Mui.Grid>
        <Mui.Grid
          className={clsx(classes.tableCell, classes.tableTablet)}
          item
          md={2}
          sm={3}
        >
          <Mui.Typography noWrap variant="h5">
            E-mail
          </Mui.Typography>
        </Mui.Grid>
        <Mui.Grid
          className={clsx(classes.tableCell, classes.tableTablet)}
          item
          md={2}
          sm={3}
        >
          <Mui.Typography noWrap variant="h5">
            Cargo
          </Mui.Typography>
        </Mui.Grid>
        <Mui.Grid
          className={clsx(classes.tableCell, classes.tableTablet)}
          item
          md={2}
          sm={3}
        >
          <Mui.Typography noWrap variant="h5">
            Telefone
          </Mui.Typography>
        </Mui.Grid>
        <Mui.Grid
          className={clsx(classes.tableCell, classes.tableDesktop)}
          item
          md={2}
        >
          <Mui.Typography noWrap variant="h5">
            Criado em
          </Mui.Typography>
        </Mui.Grid>
      </Mui.ListItem>

      <Mui.Grid component={Mui.Divider} item xs={12} />

      {listMarketplaceUsersFiltered.map(
        (marketplaceUserData: IMarketplaceUser) => {
          return (
            <React.Fragment key={marketplaceUserData.id}>
              <Mui.ListItem className={classes.listItem} disableGutters>
                <Mui.Grid
                  className={classes.tableCell}
                  item
                  md={2}
                  sm={3}
                  xs={8}
                >
                  <Mui.ListItemText
                    disableTypography
                    primary={
                      <Mui.Typography noWrap>
                        {marketplaceUserData.name}
                      </Mui.Typography>
                    }
                  />
                </Mui.Grid>
                <Mui.Grid
                  className={clsx(classes.tableCell, classes.tableTablet)}
                  item
                  md={2}
                  sm={3}
                >
                  <Mui.Typography noWrap variant="body1">
                    {marketplaceUserData.email}
                  </Mui.Typography>
                </Mui.Grid>
                <Mui.Grid
                  className={clsx(classes.tableCell, classes.tableDesktop)}
                  item
                  md={2}
                >
                  <Mui.Typography
                    noWrap
                    title={MarketplaceUserUtils.toRolesLabel(
                      marketplaceUserData.roles
                    ).join(', ')}
                    variant="body1"
                  >
                    {MarketplaceUserUtils.toRolesLabel(
                      marketplaceUserData.roles
                    ).join(', ')}
                  </Mui.Typography>
                </Mui.Grid>
                <Mui.Grid
                  className={clsx(classes.tableCell, classes.tableDesktop)}
                  item
                  md={2}
                >
                  <Mui.Typography noWrap variant="body1">
                    {getDisplayPhoneNumber(marketplaceUserData.phoneNumber)}
                  </Mui.Typography>
                </Mui.Grid>
                <Mui.Grid
                  className={clsx(classes.tableCell, classes.tableDesktop)}
                  item
                  md={2}
                >
                  <Mui.Typography noWrap variant="body1">
                    {marketplaceUserData.createdAt
                      ? moment(marketplaceUserData.createdAt).format(
                          'DD/MM/YYYY'
                        )
                      : 'Sem data definida'}
                  </Mui.Typography>
                </Mui.Grid>
                <Mui.Grid
                  className={clsx(classes.tableCell, classes.actionsContainer)}
                  item
                  md={2}
                  sm={3}
                  xs={4}
                >
                  <Mui.IconButton
                    onClick={() =>
                      marketplaceUser.ui.openToView(marketplaceUserData)
                    }
                    size={
                      window.innerWidth > theme.breakpoints.values.sm
                        ? 'medium'
                        : 'small'
                    }
                  >
                    <MuiIcons.Visibility className={classes.iconButton} />
                  </Mui.IconButton>
                  <Mui.IconButton
                    onClick={() =>
                      marketplaceUser.ui.openToEdit(marketplaceUserData)
                    }
                    size={
                      window.innerWidth > theme.breakpoints.values.sm
                        ? 'medium'
                        : 'small'
                    }
                  >
                    <MuiIcons.Edit className={classes.iconButton} />
                  </Mui.IconButton>

                  <Mui.IconButton
                    onClick={() =>
                      marketplaceUser.ui.openToDelete(marketplaceUserData)
                    }
                    size={
                      window.innerWidth > theme.breakpoints.values.sm
                        ? 'medium'
                        : 'small'
                    }
                  >
                    <MuiIcons.Delete className={classes.iconButton} />
                  </Mui.IconButton>
                </Mui.Grid>
              </Mui.ListItem>

              <Mui.Grid component={Mui.Divider} item xs={12} />
            </React.Fragment>
          );
        }
      )}

      <Mui.Grid
        className={classes.paginationContainer}
        container
        justifyContent="flex-end"
      >
        <Mui.TablePagination
          SelectProps={{
            classes: {
              icon: classes.icon,
            },
            MenuProps: {
              classes: {
                paper: classes.menuList,
              },
            },
          }}
          backIconButtonText="Voltar página"
          component="div"
          count={pagination?.total || 0}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to === -1 ? count : to} de ${
              count !== -1 ? count : `mais que ${to}`
            }`
          }
          labelRowsPerPage=""
          nextIconButtonText="Próxima página"
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          page={(pagination?.page || 1) - 1}
          rowsPerPage={pagination?.limit || 30}
          rowsPerPageOptions={[30, 50, 100]}
        />
      </Mui.Grid>
    </Mui.Grid>
  );
}

export default MarketplaceUserListTable;
