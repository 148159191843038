const INITIAL_STATE = {
  state: 'get',
  future_transfers_list: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'FUTURE_TRANSFERS_STATE_CHANGED':
      return {
        ...state,
        state: action.payload,
      };
    case 'FUTURE_TRANSFERS_LIST_CHANGED':
      return {
        ...state,
        future_transfers_list: action.payload,
      };
    default:
      return state;
  }
};
