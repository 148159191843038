import React from 'react';

import * as TaxInvoiceHooks from 'hooks/use-tax-invoice';
import * as InternalComponents from './components';

function TaxInvoicesList() {
  const taxInvoice = TaxInvoiceHooks.useTaxInvoice();
  const { state, listTaxInvoices, pagination } = taxInvoice.state;

  React.useEffect(() => {
    if (state === 'get') {
      taxInvoice.service.getAll({
        offset: pagination?.offset,
        limit: pagination?.limit,
      });
    }
  }, [taxInvoice.service, pagination?.limit, pagination?.offset, state]);

  if (state === 'get') {
    return <InternalComponents.OfferListLoading />;
  } else if (listTaxInvoices.length > 0) {
    return <InternalComponents.OfferListTable />;
  } else if (state === 'failed') {
    return <InternalComponents.OfferFailedState />;
  } else {
    return <InternalComponents.OfferEmptyState />;
  }
}

export default TaxInvoicesList;
