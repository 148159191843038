import type {
  IChargeReducerAction,
  IChargeReducerActionPayload,
  IChargeReducerState,
} from 'types/redux/reducers/charge-reducer.types';

export function setChargeState(
  state: IChargeReducerState['state']
): IChargeReducerAction<IChargeReducerActionPayload['CHARGE_STATE_CHANGED']> {
  return {
    type: 'CHARGE_STATE_CHANGED',
    payload: state,
  };
}

export function setCharge(
  charge: IChargeReducerState['charge']
): IChargeReducerAction<IChargeReducerActionPayload['CHARGE_CHANGED']> {
  return {
    type: 'CHARGE_CHANGED',
    payload: charge,
  };
}

export function setListCharges(
  charges: IChargeReducerState['listCharges']
): IChargeReducerAction<IChargeReducerActionPayload['LIST_CHARGES_CHANGED']> {
  return {
    type: 'LIST_CHARGES_CHANGED',
    payload: charges,
  };
}

export function setChargePagination(
  pagination: IChargeReducerState['pagination']
) {
  return {
    type: 'CHARGE_PAGINATION_CHANGED',
    payload: pagination,
  } as IChargeReducerAction<
    IChargeReducerActionPayload['CHARGE_PAGINATION_CHANGED']
  >;
}
