import type { IPaymentTypesConfig } from 'types/entities/checkout';

export class BoletoUtils {
  public static discountTypeLabelMap: Record<
    IPaymentTypesConfig['boleto']['discountType'],
    string
  > = {
    fixed: 'Fixo',
    percentage: 'Porcentagem',
  };

  public static getDiscountTypeLabel(
    discountType: IPaymentTypesConfig['boleto']['discountType']
  ): string {
    return this.discountTypeLabelMap[discountType] || '';
  }

  public static lateFeeModeMap: Record<
    IPaymentTypesConfig['boleto']['lateFee']['mode'],
    string
  > = {
    fixed: 'Fixo',
    percentage: 'Porcentagem',
  };

  public static getLateFeeModeMap(
    mode: IPaymentTypesConfig['boleto']['lateFee']['mode']
  ): string {
    return this.lateFeeModeMap[mode] || '';
  }

  public static interestModeMap: Record<
    IPaymentTypesConfig['boleto']['interest']['mode'],
    string
  > = {
    'daily-amount': 'Diário (R$)',
    'daily-percentage': 'Diário (%)',
    'monthly-percentage': 'Mensal (%)',
  };

  public static getInterestModeMap(
    mode: IPaymentTypesConfig['boleto']['interest']['mode']
  ): string {
    return this.interestModeMap[mode] || '';
  }

  public static getDiscountsModeMap(
    mode: IPaymentTypesConfig['boleto']['lateFee']['mode']
  ): string {
    return this.lateFeeModeMap[mode] || '';
  }
}
