import React, { useMemo, useState } from 'react';
import { getInitials } from 'helpers';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  // CardActions,
  CardContent,
  Avatar,
  Typography,
  // Divider,
  // Button,
  LinearProgress,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark2,
  },
  details: {
    display: 'flex',
  },
  avatar: {
    marginLeft: 'auto',
    height: 110,
    width: 110,
    flexShrink: 0,
    flexGrow: 0,
  },
  progress: {
    marginTop: theme.spacing(2),
  },
  uploadButton: {
    marginRight: theme.spacing(2),
  },
}));

const AccountProfile = props => {
  const { user, className, ...rest } = props;

  const classes = useStyles();

  const [progress_profile, setProgressProfile] = useState(0);

  useMemo(() => {
    if (user) {
      const user_data_filter = [
        'name',
        'phone_number',
        'state',
        'city',
        'neighborhood',
        'street',
        'house_number',
        'postal_code',
      ];
      const user_data_list = Object.entries(user);

      const amount_information_filled = user_data_list.reduce(
        (counter, user_data) => {
          const [key, value] = user_data;

          if (
            user_data_filter.includes(key) &&
            value !== '' &&
            value !== null
          ) {
            counter++;
          }

          return counter;
        },
        0
      );

      setProgressProfile(
        (amount_information_filled / user_data_filter.length) * 100
      );
    }
  }, [user]);

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent>
        <div className={classes.details}>
          <div>
            <Typography gutterBottom variant="h4">
              {user.name}
            </Typography>
            <Typography
              className={classes.locationText}
              color="textSecondary"
              variant="body1"
            >
              {user.city}, {user.state_initials}
            </Typography>
          </div>
          <Avatar className={classes.avatar} src={user.avatar}>
            {getInitials(user.name)}
          </Avatar>
        </div>
        <div className={classes.progress}>
          <Typography variant="body1">
            Perfil completo: {progress_profile}%
          </Typography>
          <LinearProgress value={progress_profile} variant="determinate" />
        </div>
      </CardContent>
      {/* <Divider />
      <CardActions>
        <Button
          className={classes.uploadButton}
          color="primary"
          variant="text"
        >
          Subir foto
        </Button>
        <Button variant="text">Remover foto</Button>
      </CardActions> */}
    </Card>
  );
};

AccountProfile.propTypes = {
  className: PropTypes.string,
};

export default AccountProfile;
