const INITIAL_STATE = {
  open: false,
  notifications: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'NOTIFICATIONS_OPEN_CHANGED':
      return {
        ...state,
        open: true,
      };
    case 'NOTIFICATIONS_CLOSE_CHANGED':
      return {
        ...state,
        open: false,
      };
    case 'NOTIFICATIONS_CHANGED':
      return {
        ...state,
        notifications: action.payload,
      };
    default:
      return state;
  }
};
