import React from 'react';
import * as ReactRedux from 'react-redux';

import type { ICoupon } from 'types/entities';
import type { ICouponReducerState } from 'types/redux';
import type { IResourceListInterface } from 'types/services';
import { api } from 'services/api';
import {
  setCoupon,
  setCouponPagination,
  setCouponState,
  setCustomerState,
  setListCoupons,
  setSnackbarMessage,
  setSnackbarOpen,
  setSnackbarType,
} from 'store/actions';

import { useAuthContext } from '../useAuthContext.hook';

interface ICouponServiceGetAll {
  limit?: number;
  offset?: number;
}

interface UseCourseService {
  create: {
    dateEnd: string | null;
    dateStart: string | null;
    duration?: number;
    frequency?: ICoupon['frequency'];
    maxUse: number | null;
    metadata: Record<string, string | number> | null;
    redeemCode: string;
    type: ICoupon['type'];
    value: number;
    valueType: ICoupon['valueType'];
  };
  edit: {
    dateEnd: string | null;
    dateStart: string | null;
    duration?: number;
    frequency?: ICoupon['frequency'];
    id: string;
    maxUse: number | null;
    metadata: Record<string, string | number> | null;
    redeemCode?: string;
    value: number;
    valueType: ICoupon['valueType'];
  };
}

function useCouponService() {
  const dispatch = ReactRedux.useDispatch();

  const { user } = useAuthContext();

  const { marketplace_id: marketplaceId, id } = user;

  const getAll = React.useCallback(
    async (props?: ICouponServiceGetAll): Promise<void> => {
      try {
        const response = await api.get<IResourceListInterface<ICoupon>>(
          `/marketplaces/${marketplaceId}/coupons`,
          {
            auth: {
              username: process.env.REACT_APP_BYPASS_API_KEY!,
              password: '',
            },
            params: {
              limit: props?.limit,
              offset: props?.offset,
            },
          }
        );

        const {
          page,
          offset,
          limit,
          has_more: hasMore,
          items: coupons,
          total,
        } = response.data;

        const pagination: ICouponReducerState['pagination'] = {
          hasMore,
          limit,
          offset,
          page,
          total,
        };

        dispatch([
          setListCoupons(coupons),
          setCouponState('success'),
          setCouponPagination(pagination),
        ]);
      } catch (error) {
        dispatch([
          setSnackbarMessage(
            'Não foi possível fazer a listagem dos cupons, tente novamente mais tarde!'
          ),
          setSnackbarType('error'),
          setSnackbarOpen(),
        ]);
        console.error('Não foi possível buscar a lista de cupons');
        throw error;
      }
    },
    [dispatch, marketplaceId]
  );

  const create = React.useCallback(
    async (params: UseCourseService['create']) => {
      try {
        const {
          dateEnd,
          dateStart,
          duration,
          frequency,
          maxUse,
          metadata,
          redeemCode,
          type,
          value,
          valueType,
        } = params;

        await api.post(
          `/marketplaces/${marketplaceId}/coupons`,
          {
            sellerId: id,
            type,
            value,
            valueType,
            redeemCode,
            maxUse,
            dateStart,
            dateEnd,
            frequency,
            duration,
            metadata,
          },
          {
            auth: {
              username: process.env.REACT_APP_BYPASS_API_KEY!,
              password: '',
            },
          }
        );

        dispatch([
          setCouponState('get'),
          setCoupon(null),
          setSnackbarMessage('Cupom criado com sucesso!'),
          setSnackbarType('success'),
          setSnackbarOpen(),
        ]);
      } catch (error) {
        dispatch([
          setSnackbarMessage(
            'Não foi possível criar este cupom, tente novamente mais tarde!'
          ),
          setSnackbarType('error'),
          setSnackbarOpen(),
        ]);
      }
    },
    [dispatch, id, marketplaceId]
  );

  const edit = React.useCallback(
    async (params: UseCourseService['edit']) => {
      try {
        const {
          dateEnd,
          dateStart,
          duration,
          frequency,
          id,
          maxUse,
          metadata,
          redeemCode,
          value,
          valueType,
        } = params;

        await api.put(
          `/marketplaces/${marketplaceId}/coupons/${id}`,
          {
            dateEnd,
            dateStart,
            duration,
            frequency,
            maxUse,
            metadata,
            redeemCode,
            value,
            valueType,
          },
          {
            auth: {
              username: process.env.REACT_APP_BYPASS_API_KEY!,
              password: '',
            },
          }
        );

        dispatch([
          setCouponState('get'),
          setCoupon(null),
          setSnackbarMessage('Cupom editado com sucesso!'),
          setSnackbarType('success'),
          setSnackbarOpen(),
        ]);
      } catch (error) {
        dispatch([
          setSnackbarMessage(
            'Não foi possível editar este cupom, tente novamente mais tarde!'
          ),
          setSnackbarType('error'),
          setSnackbarOpen(),
        ]);
      }
    },
    [dispatch, marketplaceId]
  );

  const report = React.useCallback(async () => {
    try {
      const response = await api.get(
        `/marketplaces/${marketplaceId}/coupons/report`,
        {
          auth: {
            username: process.env.REACT_APP_BYPASS_API_KEY!,
            password: '',
          },
          responseType: 'blob',
        }
      );

      const blob = response.data;
      const linkElement = document.createElement('a');
      linkElement.href = URL.createObjectURL(blob);
      linkElement.download = 'coupons.csv';
      linkElement.click();
      setTimeout(() => URL.revokeObjectURL(linkElement.href), 0);
    } catch (error) {
      dispatch([
        setSnackbarMessage(
          'Não foi possível gerar o relatório de cupons, tente novamente mais tarde!'
        ),
        setSnackbarType('error'),
        setSnackbarOpen(),
        setCustomerState('failed'),
      ]);
      console.error('Não foi possível gerar o relatório de cupons');
      throw error;
    }
  }, [dispatch, marketplaceId]);

  return React.useMemo(
    () => ({
      create,
      edit,
      getAll,
      report,
    }),
    [create, edit, getAll, report]
  );
}

export default useCouponService;
