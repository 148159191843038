import * as Mui from '@material-ui/core';
import * as MuiColors from '@material-ui/core/colors';

const useStyles = Mui.makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(2),
    backgroundColor: theme.palette.background.dark2,
    borderRadius: 4,
  },
  icon: {
    color: theme.palette.white,
  },
  menuList: {
    backgroundColor: theme.palette.background.dark2,
  },
  tableCell: {
    display: 'flex',
    alignItems: 'center',
    padding: '0px 7px',
  },
  containerCheckbox: {
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'flex-end',
    },
  },
  checkbox: {
    padding: 0,
    margin: 'auto',
  },
  justifyContentCenter: {
    justifyContent: 'center',
  },
  tableDesktop: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  tableTablet: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  listItem: {
    '&:hover': {
      backgroundColor: theme.palette.complementary.grey,
    },
  },
  new: {
    color: theme.palette.info.dark,
  },
  succeeded: {
    color: MuiColors.green[600],
  },
  pending: {
    color: MuiColors.amber[700],
  },
  pending_zoop: {
    color: MuiColors.amber[700],
  },
  canceled: {
    color: theme.palette.error.dark,
  },
  failed: {
    color: theme.palette.error.dark,
  },
  charged_back: {
    color: theme.palette.error.dark,
  },
  refunded: {
    color: theme.palette.error.dark,
  },
  dispute: {
    color: theme.palette.info.dark,
  },
  pre_authorized: {
    color: MuiColors.amber[700],
  },
  reversed: {
    color: theme.palette.error.dark,
  },
  displayAmountOnCustomer: {
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  iconButton: {
    fontSize: 18,
  },
  paymentMethodContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  cardBrandIconContainer: {
    display: 'flex',
    alignItems: 'center',

    '& > img': {
      marginRight: theme.spacing(1),
      objectFit: 'contain',
      minWidth: 50,
      maxWidth: 50,
      minHeight: 25,
      maxHeight: 25,
    },

    '& > svg': {
      marginRight: theme.spacing(1),
    },
  },
  pixIcon: {
    objectFit: 'contain',
    minWidth: 50,
    width: 50,
    maxWidth: 50,
    minHeight: 25,
    height: 25,
    maxHeight: 25,
    marginRight: theme.spacing(1),
  },
}));

export default useStyles;
