import React from 'react';
import * as ReactRedux from 'react-redux';

import type { RootState } from 'types/redux/redux.types';

import useOfferService from './useOffer.service';
import useOfferUI from './useOffer.ui';

function useOffer() {
  const service = useOfferService();
  const ui = useOfferUI();
  const state = ReactRedux.useSelector((state: RootState) => state.offerState);

  return React.useMemo(
    () => ({
      service,
      ui,
      state,
    }),
    [service, state, ui]
  );
}

export default useOffer;
