import * as Mui from '@material-ui/core';

const useStyles = Mui.makeStyles(theme => ({
  containerProgress: {
    marginTop: theme.spacing(2),
    minHeight: 200,
  },
}));

export default useStyles;
