import moment from 'moment-timezone';

export function getSession() {
  try {
    const token = localStorage.getItem('token');

    if (token) {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace('-', '+').replace('_', '/');
      const session = JSON.parse(window.atob(base64));

      const sessionExpDate = moment
        .unix(session.exp)
        .format('YYYY-MM-DD HH:mm:ss');

      if (!moment().isBefore(sessionExpDate)) {
        localStorage.removeItem('token');
        localStorage.removeItem('@recebafacil-dashboard/user');
        localStorage.removeItem('@recebafacil-dashboard/marketplaceUser');
        throw new Error('Authenticate expired');
      }

      return session.id;
    } else {
      throw new Error('User not authorized');
    }
  } catch (error) {
    console.log(error);
    localStorage.removeItem('token');
    localStorage.removeItem('@recebafacil-dashboard/user');
    localStorage.removeItem('@recebafacil-dashboard/marketplaceUser');

    return false;
  }
}

export function logout(history) {
  try {
    const marketplaceUserStorage = localStorage.getItem(
      '@recebafacil-dashboard/marketplaceUser'
    );

    localStorage.removeItem('token');
    localStorage.removeItem('@recebafacil-dashboard/user');
    localStorage.removeItem('@recebafacil-dashboard/marketplaceUser');

    if (marketplaceUserStorage) {
      const marketplaceUser = JSON.parse(marketplaceUserStorage);

      history.push(`/marketplaces/${marketplaceUser.marketplaceId}/sign-in`);
      return;
    }

    history.push('/');
  } catch (error) {
    console.error(error);
  }
}
