import React from 'react';
import * as ReactRedux from 'react-redux';

import { api } from 'services/api';
import {
  setDialogClose,
  setDialogType,
  setSnackbarMessage,
  setSnackbarOpen,
  setSnackbarType,
} from 'store/actions';
import type { ITaxInvoiceConfig } from 'types/entities/tax-invoice-config';
import { useAuthContext } from '../useAuthContext.hook';
import {
  setTaxInvoiceConfig,
  setTaxInvoiceConfigState,
} from 'store/actions/tax-invoice-config-actions';

interface IUseTaxInvoiceConfigService {
  create: {
    provider: ITaxInvoiceConfig['provider'];
    active: ITaxInvoiceConfig['active'];
    internalMetadata?: ITaxInvoiceConfig['internalMetadata'];
    metadata?: ITaxInvoiceConfig['metadata'];
  };
  edit: {
    id: string;
    provider: ITaxInvoiceConfig['provider'];
    active: ITaxInvoiceConfig['active'];
    internalMetadata?: ITaxInvoiceConfig['internalMetadata'];
    metadata?: ITaxInvoiceConfig['metadata'];
  };
}

function useTaxInvoiceConfigService() {
  const dispatch = ReactRedux.useDispatch();

  const { user } = useAuthContext();

  const { marketplace_id, id: sellerId } = user;

  const getBySellerId = React.useCallback(async () => {
    try {
      const response = await api.get<ITaxInvoiceConfig>(
        `/v2/marketplaces/${marketplace_id}/sellers/${sellerId}/tax-invoice-configs/default`,
        {
          auth: {
            username: process.env.REACT_APP_BYPASS_API_KEY!,
            password: '',
          },
        }
      );

      dispatch([
        setTaxInvoiceConfigState('config'),
        setTaxInvoiceConfig(response.data),
      ]);

      return response.data;
    } catch (error) {}
  }, [dispatch, sellerId, marketplace_id]);

  const create = React.useCallback(
    async (params: IUseTaxInvoiceConfigService['create']) => {
      try {
        const { active, internalMetadata, metadata, provider } = params;

        await api.post(
          `/v2/marketplaces/${marketplace_id}/sellers/${sellerId}/tax-invoice-configs`,
          {
            active,
            internalMetadata,
            metadata,
            provider,
          },
          {
            auth: {
              username: process.env.REACT_APP_BYPASS_API_KEY!,
              password: '',
            },
          }
        );

        dispatch([
          setTaxInvoiceConfigState('get'),
          setTaxInvoiceConfig(null),
          setSnackbarMessage('Configuração da nota fiscal criada com sucesso!'),
          setSnackbarType('success'),
          setSnackbarOpen(),
          setDialogClose(),
          setDialogType(null),
        ]);
      } catch (error) {
        dispatch([
          setSnackbarMessage(
            'Não foi possível criar esta configuração da nota fiscal, tente novamente mais tarde!'
          ),
          setSnackbarType('error'),
          setSnackbarOpen(),
        ]);
      }
    },
    [dispatch, sellerId, marketplace_id]
  );

  const edit = React.useCallback(
    async (params: IUseTaxInvoiceConfigService['edit']) => {
      try {
        const { active, internalMetadata, metadata, provider, id } = params;

        await api.put(
          `/v2/marketplaces/${marketplace_id}/sellers/${sellerId}/tax-invoice-configs/${id}`,
          {
            active,
            internalMetadata,
            metadata,
            provider,
          },
          {
            auth: {
              username: process.env.REACT_APP_BYPASS_API_KEY!,
              password: '',
            },
          }
        );

        dispatch([
          setTaxInvoiceConfigState('get'),
          setTaxInvoiceConfig(null),
          setSnackbarMessage(
            'Configuração da nota fiscal editada com sucesso!'
          ),
          setSnackbarType('success'),
          setSnackbarOpen(),
          setDialogClose(),
          setDialogType(null),
        ]);
      } catch (error) {
        dispatch([
          setSnackbarMessage(
            'Não foi possível editar a configuração desta nota fiscal, tente novamente mais tarde!'
          ),
          setSnackbarType('error'),
          setSnackbarOpen(),
        ]);
      }
    },
    [dispatch, sellerId, marketplace_id]
  );

  return React.useMemo(
    () => ({
      getBySellerId,
      create,
      edit,
    }),
    [getBySellerId, create, edit]
  );
}

export default useTaxInvoiceConfigService;
