import type {
  ICouponReducerAction,
  ICouponReducerActionPayload,
  ICouponReducerState,
} from 'types/redux';

export function setCouponState(
  state: ICouponReducerState['state']
): ICouponReducerAction<ICouponReducerActionPayload['COUPON_STATE_CHANGED']> {
  return {
    type: 'COUPON_STATE_CHANGED',
    payload: state,
  };
}

export function setCoupon(
  coupon: ICouponReducerState['coupon']
): ICouponReducerAction<ICouponReducerActionPayload['COUPON_CHANGED']> {
  return {
    type: 'COUPON_CHANGED',
    payload: coupon,
  };
}

export function setListCoupons(
  coupons: ICouponReducerState['listCoupons']
): ICouponReducerAction<ICouponReducerActionPayload['LIST_COUPONS_CHANGED']> {
  return {
    type: 'LIST_COUPONS_CHANGED',
    payload: coupons,
  };
}

export function setCouponPagination(
  pagination: ICouponReducerState['pagination']
) {
  return {
    type: 'COUPON_PAGINATION_CHANGED',
    payload: pagination,
  } as ICouponReducerAction<
    ICouponReducerActionPayload['COUPON_PAGINATION_CHANGED']
  >;
}
