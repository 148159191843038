import React from 'react';

import PropTypes from 'prop-types';

import clsx from 'clsx';

import { makeStyles } from '@material-ui/styles';

import { Typography, Link } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
  },
}));

const Footer = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Typography variant="body1">
        &copy;{' '}
        <Link component="a" href="https://recebafacil.com/" target="_blank">
          Fpay
        </Link>
        . 2019
      </Typography>
      <Typography variant="caption">
        Receba pagamentos online por boleto ou cartão de crédito. Sem
        burocracia, sem taxas extras e pra todo mundo.
      </Typography>
    </div>
  );
};

Footer.propTypes = {
  className: PropTypes.string,
};

export default Footer;
