import React from 'react';
import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';

import * as InternalComponent from './components';

import * as PlanHooks from 'hooks/usePlan';

import useStyles from './Plans.styles';

function Plans() {
  const classes = useStyles();
  const plan = PlanHooks.usePlan();

  return (
    <Mui.Card className={classes.root}>
      <Mui.CardHeader
        action={
          <Mui.IconButton onClick={plan.ui.refresh}>
            <MuiIcons.Refresh />
          </Mui.IconButton>
        }
        subheader={
          <Mui.Typography color="textSecondary" variant="h5">
            Gerencie seus planos
          </Mui.Typography>
        }
        title={<Mui.Typography variant="h3">Planos</Mui.Typography>}
      />
      <Mui.Divider />
      <Mui.CardContent className={classes.cardContent}>
        <InternalComponent.PlansToolbar />
        <InternalComponent.PlansList />
      </Mui.CardContent>
    </Mui.Card>
  );
}

export default Plans;
