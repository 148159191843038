import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';

import {
  AccountDetails,
  AccountFeeDetails,
  AccountProfile,
} from './components';
import { useAuthContext } from 'hooks/useAuthContext.hook';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
    },
  },
}));

const Account = () => {
  const classes = useStyles();

  const { user, marketplaceUser, handleUserChange } = useAuthContext();

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item lg={4} md={6} xl={4} xs={12}>
          <AccountProfile onChange={handleUserChange} user={user} />
        </Grid>
        <Grid item lg={8} md={6} xl={8} xs={12}>
          <AccountDetails onChange={handleUserChange} user={user} />
        </Grid>
        {(!marketplaceUser ||
          marketplaceUser.roles.includes('owner') ||
          marketplaceUser?.roles.includes('financial')) && (
          <Grid item xs={12}>
            <AccountFeeDetails />
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default Account;
