import React from 'react';
import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';
import { v4 as uuid } from 'uuid';

import useStyles from './Metadata.styles';
import { RegexUtils } from 'helpers';

interface IMetadata {
  id: string;
  key: string;
  value: string;
}

interface IFormState {
  values: IMetadata[];
  errors: Record<string, string>;
  touched: Record<string, boolean>;
}

interface IMetadataProps {
  metadata?: object | null | undefined;
  onChange?(metadata: Record<string, string | number> | null): void;
}

export function Metadata(props: IMetadataProps) {
  const { metadata, onChange } = props;

  const classes = useStyles();

  const formStateInitialValue = React.useMemo<IFormState>(() => {
    return {
      values: Object.entries(metadata || {}).map(([key, value]) => ({
        id: uuid(),
        key,
        value: value.toString(),
      })),
      errors: {},
      touched: {},
    };
  }, [metadata]);

  const [formState, setFormState] = React.useState<IFormState>(
    formStateInitialValue
  );

  function handleFormChange(key: keyof IMetadata, index: number) {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      const metadataArray = formState.values.slice();
      const metadata = metadataArray[index];
      let value = event.target.value;

      if (key === 'key') {
        value = value.replace(RegexUtils.NanAlphanumeric, '');
      }

      metadataArray[index] = {
        ...metadata,
        [key]: value,
      };

      setFormState(prevState => ({
        ...prevState,
        values: metadataArray,
        touched: {
          [metadata.id]: true,
        },
      }));
    };
  }

  function handleAddMetadata() {
    const newValues = formState.values.slice();

    newValues.push({ id: uuid(), key: '', value: '' });

    setFormState(prevState => ({
      ...prevState,
      values: newValues,
    }));
  }

  function handleRemoveMetadata(index: number) {
    const newValues = formState.values.slice();

    newValues.splice(index, 1);

    setFormState(prevState => ({
      ...prevState,
      values: newValues,
    }));
  }

  function hasError(id: string) {
    return formState.touched[id] && !!formState.errors[id];
  }

  React.useEffect(() => {
    const errors = formState.values
      .filter(metadata => metadata.key === '')
      .reduce(
        (initialValue, metadata) => ({
          ...initialValue,
          [metadata.id]: 'Campo não pode estar vazio!',
        }),
        {}
      );

    setFormState(prevState => ({
      ...prevState,
      errors: {
        ...errors,
      },
    }));
  }, [formState.values]);

  React.useEffect(() => {
    const validMetadata = formState.values
      .filter(metadata => metadata.key !== '')
      .reduce(
        (initialValue, metadata) => ({
          ...initialValue,
          [metadata.key]:
            RegexUtils.NaN.test(metadata.value) || metadata.value === ''
              ? metadata.value.trim()
              : Number(metadata.value),
        }),
        {}
      );

    const metadata =
      Object.keys(validMetadata).length > 0 ? validMetadata : null;

    onChange && onChange(metadata);
  }, [formState.values, onChange]);

  return (
    <Mui.Grid container spacing={2}>
      {formState.values.length === 0 && (
        <Mui.Grid container item>
          <Mui.Button
            color="primary"
            onClick={handleAddMetadata}
            variant="contained"
          >
            Adicionar Metadados
          </Mui.Button>
        </Mui.Grid>
      )}
      {formState.values.map(({ id, key, value }, index) => (
        <Mui.Grid
          className={classes.metadataContainer}
          container
          item
          key={id}
          spacing={2}
          wrap="nowrap"
        >
          <Mui.Grid className={classes.textFieldContainer} item>
            <Mui.TextField
              error={hasError(id)}
              fullWidth
              helperText={hasError(id) ? formState.errors[id] : ''}
              id={`key-${id}`}
              label="Chave"
              name={`key-${id}`}
              onChange={handleFormChange('key', index)}
              type="text"
              value={key}
              variant="outlined"
            />
          </Mui.Grid>
          <Mui.Grid className={classes.textFieldContainer} item>
            <Mui.TextField
              fullWidth
              id={`value-${id}`}
              label="Valor"
              name={`value-${id}`}
              onChange={handleFormChange('value', index)}
              type="text"
              value={value}
              variant="outlined"
            />
          </Mui.Grid>
          <div className={classes.actionsContainer}>
            <Mui.Grid item>
              <Mui.IconButton onClick={handleAddMetadata}>
                <MuiIcons.Add />
              </Mui.IconButton>
            </Mui.Grid>
            <Mui.Grid item>
              <Mui.IconButton onClick={() => handleRemoveMetadata(index)}>
                <MuiIcons.Close />
              </Mui.IconButton>
            </Mui.Grid>
          </div>
        </Mui.Grid>
      ))}
    </Mui.Grid>
  );
}
