import type {
  EMarketplaceUserRole,
  IMarketplaceUser,
} from 'types/entities/marketplace-user';

export class MarketplaceUserUtils {
  public static rolesLabelMap: Record<EMarketplaceUserRole, string> = {
    owner: 'Proprietário',
    marketing: 'Marketing',
    developer: 'Desenvolvedor',
    financial: 'Financeiro',
    seller: 'Vendedor',
    attendants: 'Atendente',
  };

  public static toRolesLabel(roles: IMarketplaceUser['roles']): string[] {
    return roles.map(role => MarketplaceUserUtils.rolesLabelMap[role]);
  }
}
