import type {
  ICustomerReducerAction,
  ICustomerReducerActionPayload,
  ICustomerReducerState,
} from 'types/redux';

const INITIAL_STATE: ICustomerReducerState = {
  customer: null,
  listCustomers: [],
  listCustomersFiltered: [],
  listCustomersSelected: [],
  listType: 'get',
  pagination: null,
  state: 'get',
  filter: {
    dateEnd: '',
    dateStart: '',
    search: '',
  },
};

export default (
  state = INITIAL_STATE,
  action: ICustomerReducerAction<unknown>
) => {
  switch (action.type) {
    case 'CUSTOMER_STATE_CHANGED':
      return {
        ...state,
        state: action.payload,
      };
    case 'CUSTOMER_CHANGED':
      return {
        ...state,
        customer: action.payload,
      };
    case 'LIST_CUSTOMERS_CHANGED':
      return {
        ...state,
        listCustomers: action.payload,
      };
    case 'LIST_CUSTOMERS_FILTERED_CHANGED':
      return {
        ...state,
        listCustomersFiltered: action.payload,
      };
    case 'LIST_CUSTOMERS_SELECTED_CHANGED':
      return {
        ...state,
        listCustomersSelected: action.payload,
      };
    case 'CUSTOMER_PAGINATION_CHANGED':
      return {
        ...state,
        pagination: action.payload,
      };
    case 'LIST_TYPE_CHANGED':
      return {
        ...state,
        listType: action.payload,
      };
    case 'CUSTOMER_FILTER_CHANGED':
      return {
        ...state,
        filter: {
          ...state.filter,
          ...(action.payload as ICustomerReducerActionPayload['CUSTOMER_FILTER_CHANGED']),
        },
      };
    default:
      return state;
  }
};
