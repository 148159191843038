import type {
  ISubscriptionReducerAction,
  ISubscriptionReducerState,
} from 'types/redux';

const INITIAL_STATE: ISubscriptionReducerState = {
  state: 'get',
  subscription: null,
  listSubscriptions: [],
  pagination: null,
};

export default (
  state = INITIAL_STATE,
  action: ISubscriptionReducerAction<unknown>
) => {
  switch (action.type) {
    case 'SUBSCRIPTION_STATE_CHANGED':
      return {
        ...state,
        state: action.payload,
      };
    case 'SUBSCRIPTION_CHANGED':
      return {
        ...state,
        subscription: action.payload,
      };
    case 'LIST_SUBSCRIPTIONS_CHANGED':
      return {
        ...state,
        listSubscriptions: action.payload,
      };
    case 'SUBSCRIPTION_PAGINATION_CHANGED':
      return {
        ...state,
        pagination: action.payload,
      };
    default:
      return state;
  }
};
