import React from 'react';
import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';

import { InputCurrency } from 'components';
import { Metadata } from 'components/utils/Metadata';
import { getDisplayMoney, PlanUtils, StringUtils } from 'helpers';

import useStyles from './PlanEditDialog.styles';
import { usePlanEditDialog } from './PlanEditDialog.hook';

const PlanEditDialog = () => {
  const classes = useStyles();

  const {
    formState,
    plan,
    planState,
    handleDialogClose,
    handleFormChange,
    handleMetadataChange,
    handleSubmit,
    hasError,
  } = usePlanEditDialog();

  return (
    <React.Fragment>
      <Mui.AppBar className={classes.appBar}>
        <Mui.Toolbar>
          <Mui.IconButton
            aria-label="Fechar"
            color="inherit"
            edge="start"
            onClick={handleDialogClose}
          >
            <MuiIcons.Close />
          </Mui.IconButton>
          <Mui.Typography className={classes.title} variant="h5">
            {planState === 'create' ? 'Criar novo plano' : 'Editar plano'}
          </Mui.Typography>
          <Mui.Button
            color="inherit"
            disabled={!formState.isValid || formState.disabled}
            onClick={handleSubmit}
          >
            {planState === 'create' ? 'Criar' : 'Salvar'}
          </Mui.Button>
        </Mui.Toolbar>
      </Mui.AppBar>
      <Mui.Grid className={classes.container} container spacing={2}>
        <Mui.Grid item xs={12}>
          <Mui.Typography variant="h4">Ações</Mui.Typography>
        </Mui.Grid>
        <div className={classes.actionsContainer}>
          <Mui.Tooltip title="Em breve">
            <div className={classes.actionButton}>
              <Mui.Button
                color="secondary"
                disabled
                fullWidth
                variant="contained"
              >
                Desativar
              </Mui.Button>
            </div>
          </Mui.Tooltip>
          <Mui.Tooltip title="Em breve">
            <div className={classes.actionButton}>
              <Mui.Button
                color="secondary"
                disabled
                fullWidth
                variant="contained"
              >
                Reativar
              </Mui.Button>
            </div>
          </Mui.Tooltip>
        </div>
        <Mui.Grid item xs={12}>
          <Mui.Divider />
        </Mui.Grid>
        <Mui.Grid item xs={12}>
          <Mui.Typography variant="h4">Dados do plano</Mui.Typography>
        </Mui.Grid>
        <Mui.Grid item md={4} sm={6} xs={12}>
          <Mui.TextField
            disabled={formState.disabled}
            error={hasError('name')}
            fullWidth
            helperText={hasError('name') ? formState.errors.name : ''}
            id="name"
            label="Nome do plano"
            name="name"
            onChange={handleFormChange}
            value={formState.values.name || ''}
            variant="outlined"
          />
        </Mui.Grid>
        <Mui.Grid item md={4} sm={6} xs={12}>
          <Mui.TextField
            InputLabelProps={{
              classes: {
                disabled: classes.selectDisabled,
              },
            }}
            SelectProps={{
              classes: {
                icon: classes.selectIcon,
              },
              MenuProps: {
                classes: {
                  paper: classes.selectMenuList,
                },
              },
            }}
            disabled={formState.disabled}
            error={hasError('frequency')}
            fullWidth
            helperText={hasError('frequency') ? formState.errors.frequency : ''}
            label="Frequência da cobrança"
            name="frequency"
            onChange={handleFormChange}
            select
            value={formState.values.frequency}
            variant="outlined"
          >
            {Object.entries(PlanUtils.statusLabelMap).map(([value, label]) => {
              return (
                <Mui.MenuItem
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  ListItemClasses={{
                    root: classes.selectMenuItem,
                    selected: classes.selectItemSelected,
                  }}
                  button
                  key={value}
                  selected={value === formState.values.frequency}
                  value={value}
                >
                  {StringUtils.capitalize(label)}
                </Mui.MenuItem>
              );
            })}
          </Mui.TextField>
        </Mui.Grid>
        <Mui.Grid item md={4} sm={6} xs={12}>
          <InputCurrency
            disabled={formState.disabled}
            error={hasError('amount')}
            fullWidth
            helperText={hasError('amount') ? formState.errors.amount : ''}
            id="amount"
            label="Valor da cobrança"
            name="amount"
            onChange={handleFormChange}
            type="tel"
            value={formState.values.amount}
            variant="outlined"
          />
        </Mui.Grid>

        <Mui.Grid item md={4} sm={6} xs={12}>
          <Mui.TextField
            disabled={formState.disabled}
            error={hasError('interval')}
            fullWidth
            helperText={hasError('interval') ? formState.errors.interval : ''}
            id="interval"
            label="Intervalo de cobrança"
            name="interval"
            onChange={handleFormChange}
            type="tel"
            value={formState.values.interval}
            variant="outlined"
          />
        </Mui.Grid>

        <Mui.Grid item md={4} sm={6} xs={12}>
          <Mui.TextField
            disabled={formState.disabled}
            error={hasError('duration')}
            fullWidth
            helperText={hasError('duration') ? formState.errors.duration : ''}
            id="duration"
            label="Duração do plano"
            name="duration"
            onChange={handleFormChange}
            type="tel"
            value={formState.values.duration}
            variant="outlined"
          />
        </Mui.Grid>

        <Mui.Grid item md={4} sm={6} xs={12}>
          <Mui.TextField
            disabled={formState.disabled}
            error={hasError('description')}
            fullWidth
            helperText={
              hasError('description') ? formState.errors.description : ''
            }
            id="description"
            label="Descrição"
            maxRows="4"
            multiline
            name="description"
            onChange={handleFormChange}
            value={formState.values.description}
            variant="outlined"
          />
        </Mui.Grid>

        <Mui.Grid item xs={12}>
          <Mui.FormHelperText className={classes.formHelperText}>
            {`O cliente será cobrado com a frequência ${PlanUtils.getFrequencyLabel(
              formState.values.frequency
            )} de R$ ${getDisplayMoney(formState.values.amount)} a cada ${
              formState.values.interval
            } ${Number(formState.values.interval) > 1 ? 'meses' : 'mês'} por ${
              formState.values.duration
            } ${Number(formState.values.duration) > 1 ? 'meses' : 'mês'}.`}
          </Mui.FormHelperText>
        </Mui.Grid>

        <Mui.Grid item xs={12}>
          <Mui.Divider />
        </Mui.Grid>
        <Mui.Grid item xs={12}>
          <Mui.Typography variant="h4">Metadados</Mui.Typography>
        </Mui.Grid>
        <Mui.Grid item lg={8} md={10} xs={12}>
          <Metadata metadata={plan?.metadata} onChange={handleMetadataChange} />
        </Mui.Grid>
      </Mui.Grid>
    </React.Fragment>
  );
};

export default PlanEditDialog;
