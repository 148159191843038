import React from 'react';
import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';
import * as MuiPickers from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';

import { CouponUtils } from 'helpers';
import { InputCurrency } from 'components';
import { Metadata } from 'components/utils/Metadata';

import useStyles from './CouponEditDialog.styles';
import { useCouponEditDialog } from './CouponEditDialog.hook';

function CouponEditDialog() {
  const classes = useStyles();

  const {
    coupon,
    couponState,
    formState,
    handleDateChange,
    handleDialogClose,
    handleDisableDateEnd,
    handleFormChange,
    handleGenerateRedeemCode,
    handleMetadataChange,
    handleSubmit,
    hasError,
  } = useCouponEditDialog();

  return (
    <React.Fragment>
      <Mui.AppBar className={classes.appBar}>
        <Mui.Toolbar>
          <Mui.IconButton
            aria-label="Fechar"
            color="inherit"
            edge="start"
            onClick={handleDialogClose}
          >
            <MuiIcons.Close />
          </Mui.IconButton>
          <Mui.Typography className={classes.appBarTitle} variant="h5">
            {couponState === 'create' ? 'Criar novo cupom' : 'Editar cupom'}
          </Mui.Typography>
          <Mui.Button
            color="inherit"
            disabled={!formState.isValid || formState.disabled}
            onClick={handleSubmit}
          >
            {couponState === 'create' ? 'Criar' : 'Salvar'}
          </Mui.Button>
        </Mui.Toolbar>
      </Mui.AppBar>
      <Mui.Grid className={classes.container} container spacing={2}>
        {couponState === 'edit' && (
          <>
            <Mui.Grid item xs={12}>
              <Mui.Typography variant="h4">Ações</Mui.Typography>
            </Mui.Grid>
            <div className={classes.actionsContainer}>
              <Mui.Tooltip title="Em breve">
                <div className={classes.actionButton}>
                  <Mui.Button
                    color="secondary"
                    disabled
                    fullWidth
                    variant="contained"
                  >
                    Deletar
                  </Mui.Button>
                </div>
              </Mui.Tooltip>
            </div>
            <Mui.Grid item xs={12}>
              <Mui.Divider />
            </Mui.Grid>
          </>
        )}
        <Mui.Grid item xs={12}>
          <Mui.Typography variant="h4">Dados do cupom</Mui.Typography>
        </Mui.Grid>
        <Mui.Grid item md={4} sm={6} xs={12}>
          <Mui.TextField
            InputProps={{
              endAdornment: (
                <Mui.InputAdornment position="end">
                  <Mui.Button onClick={handleGenerateRedeemCode}>
                    Gerar
                  </Mui.Button>
                </Mui.InputAdornment>
              ),
            }}
            error={hasError('redeemCode')}
            fullWidth
            helperText={hasError('redeemCode') && formState.errors.redeemCode}
            id="redeemCode"
            label="Código de resgate"
            name="redeemCode"
            onChange={handleFormChange}
            value={formState.values.redeemCode}
            variant="outlined"
          />
        </Mui.Grid>
        <Mui.Grid item md={4} sm={6} xs={12}>
          <Mui.TextField
            InputLabelProps={{
              classes: {
                disabled: classes.selectDisabled,
              },
            }}
            SelectProps={{
              classes: {
                icon: classes.selectIcon,
                disabled: classes.selectDisabled,
              },
              MenuProps: {
                classes: {
                  paper: classes.selectMenuList,
                },
              },
            }}
            disabled={couponState === 'edit'}
            error={hasError('type')}
            fullWidth
            helperText={hasError('type') && formState.errors.type}
            label="Tipo de cupom"
            name="type"
            onChange={handleFormChange}
            select
            value={formState.values.type}
            variant="outlined"
          >
            {Object.entries(CouponUtils.typeLabelMap).map(([value, label]) => {
              return (
                <Mui.MenuItem
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  ListItemClasses={{
                    root: classes.selectMenuItem,
                    selected: classes.selectItemSelected,
                  }}
                  button
                  key={value}
                  selected={value === formState.values.frequency}
                  value={value}
                >
                  {label}
                </Mui.MenuItem>
              );
            })}
          </Mui.TextField>
        </Mui.Grid>
        <Mui.Grid item md={4} sm={6} xs={12}>
          <Mui.TextField
            InputLabelProps={{
              classes: {
                disabled: classes.selectDisabled,
              },
            }}
            SelectProps={{
              classes: {
                icon: classes.selectIcon,
                disabled: classes.selectDisabled,
              },
              MenuProps: {
                classes: {
                  paper: classes.selectMenuList,
                },
              },
            }}
            error={hasError('valueType')}
            fullWidth
            helperText={hasError('valueType') && formState.errors.valueType}
            label="Tipo do valor"
            name="valueType"
            onChange={handleFormChange}
            select
            value={formState.values.valueType}
            variant="outlined"
          >
            {Object.entries(CouponUtils.valueTypeLabelMap).map(
              ([value, label]) => {
                return (
                  <Mui.MenuItem
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    ListItemClasses={{
                      root: classes.selectMenuItem,
                      selected: classes.selectItemSelected,
                    }}
                    button
                    key={value}
                    selected={value === formState.values.frequency}
                    value={value}
                  >
                    {label}
                  </Mui.MenuItem>
                );
              }
            )}
          </Mui.TextField>
        </Mui.Grid>
        <Mui.Grid item md={4} sm={6} xs={12}>
          {formState.values.valueType === 'fixed' ? (
            <InputCurrency
              error={hasError('value')}
              fullWidth
              helperText={hasError('value') && formState.errors.value}
              id="value"
              label="Valor do cupom"
              name="value"
              onChange={handleFormChange}
              type="tel"
              value={formState.values.value}
              variant="outlined"
            />
          ) : (
            <Mui.TextField
              error={hasError('value')}
              fullWidth
              helperText={hasError('value') && formState.errors.value}
              id="value"
              label="Valor do cupom"
              name="value"
              onChange={handleFormChange}
              type="tel"
              value={formState.values.value}
              variant="outlined"
            />
          )}
        </Mui.Grid>
        <Mui.Grid item md={4} sm={6} xs={12}>
          <Mui.TextField
            error={hasError('maxUse')}
            fullWidth
            helperText={
              (hasError('maxUse') && formState.errors.maxUse) ||
              'Deixe o valor 0 para uso ilimitado.'
            }
            id="maxUse"
            label="Máximo de uso"
            name="maxUse"
            onChange={handleFormChange}
            type="tel"
            value={formState.values.maxUse}
            variant="outlined"
          />
        </Mui.Grid>
        <Mui.Grid item md={4} sm={6} xs={12}>
          <MuiPickers.MuiPickersUtilsProvider
            libInstance={moment}
            locale="pt-br"
            utils={MomentUtils}
          >
            <MuiPickers.DatePicker
              DialogProps={{
                PaperProps: {
                  classes: {
                    root: classes.rootDialog,
                  },
                },
              }}
              cancelLabel="CANCELAR"
              clearLabel="LIMPAR"
              clearable
              format="DD/MM/YYYY"
              fullWidth
              id="dateStart"
              inputVariant="outlined"
              label="Data de início"
              leftArrowButtonProps={{
                classes: {
                  root: classes.buttonPicker,
                },
              }}
              name="dateStart"
              onChange={handleDateChange('dateStart')}
              rightArrowButtonProps={{
                classes: {
                  root: classes.buttonPicker,
                },
              }}
              value={formState.values.dateStart}
            />
          </MuiPickers.MuiPickersUtilsProvider>
        </Mui.Grid>
        <Mui.Grid item md={4} sm={6} xs={12}>
          <MuiPickers.MuiPickersUtilsProvider
            libInstance={moment}
            locale="pt-br"
            utils={MomentUtils}
          >
            <MuiPickers.DatePicker
              DialogProps={{
                PaperProps: {
                  classes: {
                    root: classes.rootDialog,
                  },
                },
              }}
              cancelLabel="CANCELAR"
              clearLabel="LIMPAR"
              clearable
              format="DD/MM/YYYY"
              fullWidth
              id="dateEnd"
              inputVariant="outlined"
              label="Data de término"
              leftArrowButtonProps={{
                classes: {
                  root: classes.buttonPicker,
                },
              }}
              minDate={formState.values.dateStart || undefined}
              minDateMessage={`A data mínima é ${formState.values.dateStart?.format(
                'DD/MM/YYYY'
              )}`}
              name="dateEnd"
              onChange={handleDateChange('dateEnd')}
              rightArrowButtonProps={{
                classes: {
                  root: classes.buttonPicker,
                },
              }}
              shouldDisableDate={handleDisableDateEnd}
              value={formState.values.dateEnd}
            />
          </MuiPickers.MuiPickersUtilsProvider>
        </Mui.Grid>
        {formState.values.type === 'subscription' && (
          <>
            <Mui.Grid item md={4} sm={6} xs={12}>
              <Mui.TextField
                InputLabelProps={{
                  classes: {
                    disabled: classes.selectDisabled,
                  },
                }}
                SelectProps={{
                  classes: {
                    icon: classes.selectIcon,
                    disabled: classes.selectDisabled,
                  },
                  MenuProps: {
                    classes: {
                      paper: classes.selectMenuList,
                    },
                  },
                }}
                error={hasError('frequency')}
                fullWidth
                helperText={hasError('frequency') && formState.errors.frequency}
                label="Frequência do cupom"
                name="frequency"
                onChange={handleFormChange}
                select
                value={formState.values.frequency}
                variant="outlined"
              >
                {Object.entries(CouponUtils.frequencyAdjectiveLabelMap).map(
                  ([value, label]) => {
                    return (
                      <Mui.MenuItem
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        ListItemClasses={{
                          root: classes.selectMenuItem,
                          selected: classes.selectItemSelected,
                        }}
                        button
                        key={value}
                        selected={value === formState.values.frequency}
                        value={value}
                      >
                        {label}
                      </Mui.MenuItem>
                    );
                  }
                )}
              </Mui.TextField>
            </Mui.Grid>
            <Mui.Grid item md={4} sm={6} xs={12}>
              <Mui.TextField
                error={hasError('duration')}
                fullWidth
                helperText={
                  hasError('duration')
                    ? formState.errors.duration
                    : `Você selecionou ${
                        formState.values.duration
                      } ${CouponUtils.getFrequencyLabel(
                        formState.values.frequency!,
                        Number(formState.values.duration) > 1
                          ? 'plural_of_noun'
                          : 'noun'
                      )}`
                }
                id="duration"
                label="Duração do cupom"
                name="duration"
                onChange={handleFormChange}
                type="tel"
                value={formState.values.duration}
                variant="outlined"
              />
            </Mui.Grid>
          </>
        )}

        <Mui.Grid item xs={12}>
          <Mui.Divider />
        </Mui.Grid>
        <Mui.Grid item xs={12}>
          <Mui.Typography variant="h4">Metadados</Mui.Typography>
        </Mui.Grid>
        <Mui.Grid item lg={8} md={10} xs={12}>
          <Metadata
            metadata={coupon?.metadata}
            onChange={handleMetadataChange}
          />
        </Mui.Grid>
      </Mui.Grid>
    </React.Fragment>
  );
}

export default CouponEditDialog;
