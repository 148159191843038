import * as Mui from '@material-ui/core';

const useStyles = Mui.makeStyles(theme => ({
  container: {
    minHeight: 120,
    justifyContent: 'center',
    marginTop: theme.spacing(2),
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  text: {
    padding: theme.spacing(2),
  },
  button: {
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      maxWidth: '90%',
      marginLeft: 0,
    },
  },
}));

export default useStyles;
