import React from 'react';

import type { IMarketplaceUser, ISeller } from 'types/entities';
import { api } from 'services/api';
import { getSession, logout } from 'auth';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { JwtUtils } from 'helpers/jwt-utils';
import type { IJwtAuthentication } from 'types/authentication/jwt-authentication';

interface IAuthContextProps {
  user: ISeller;
  marketplaceUser: IMarketplaceUser | null;
  handleUserChange(user: IAuthContextProps['user']): void;
}

export const AuthContext = React.createContext({} as IAuthContextProps);

interface IAuthProviderProps {
  children?: React.ReactNode | React.ReactNode[];
  history: unknown;
}

export function AuthProvider(props: IAuthProviderProps) {
  const { children, history } = props;

  const storage = useLocalStorage();

  const [user, setUser] = React.useState<IAuthContextProps['user'] | null>(
    null
  );
  const [marketplaceUser, setMarketplaceUser] = React.useState<
    IAuthContextProps['marketplaceUser'] | null
  >(null);

  const handleUserChange = React.useCallback(
    (user: IAuthContextProps['user']) => {
      setUser(user);
    },
    []
  );

  React.useEffect(() => {
    if (user) {
      return;
    }

    const userStorage = storage.get('user');
    const seller_id = getSession();

    if (userStorage) {
      setUser(userStorage as ISeller);
    }

    const token = localStorage.getItem('token');

    api
      .get(`/seller/${seller_id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(response => {
        const userResponse = response.data;

        storage.set('user', userResponse);
        setUser(userResponse);
      })
      .catch(error => {
        console.error(error);
        logout(history);
      });
  }, [user, storage, history]);

  React.useEffect(() => {
    if (!user || marketplaceUser) {
      return;
    }

    const marketplaceUserStorage = storage.get('marketplaceUser');

    if (marketplaceUserStorage) {
      setMarketplaceUser(marketplaceUserStorage as IMarketplaceUser);
    }

    const token = localStorage.getItem('token');
    const { marketplaceUserId } = JwtUtils.decode<IJwtAuthentication>(
      token as string
    );

    if (marketplaceUserId) {
      api
        .get(
          `/v2/marketplaces/${user.marketplace_id}/users/${marketplaceUserId}`,
          {
            auth: {
              username: process.env.REACT_APP_BYPASS_API_KEY!,
              password: '',
            },
          }
        )
        .then(response => {
          const marketplaceUserResponse = response.data;

          storage.set('marketplaceUser', marketplaceUserResponse);
          setMarketplaceUser(marketplaceUserResponse);
        })
        .catch(error => {
          console.error(error);
          logout(history);
        });
    }
  }, [history, marketplaceUser, storage, user]);

  if (!user) {
    return null;
  }

  return (
    <AuthContext.Provider value={{ user, marketplaceUser, handleUserChange }}>
      {children}
    </AuthContext.Provider>
  );
}
