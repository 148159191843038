import React from 'react';

import * as SaleHooks from 'hooks/useSale';

import * as InternalComponents from './components';

const SalesList = () => {
  const sale = SaleHooks.useSale();
  const { state, saleList, pagination, listType, filter } = sale.state;

  React.useEffect(() => {
    if (state === 'get') {
      sale.service.search({
        amountFrom: filter.amountFrom,
        amountTo: filter.amountTo,
        dateEnd: filter.dateEnd,
        dateStart: filter.dateStart,
        limit: pagination?.limit,
        offset: pagination?.offset,
        paymentTypes: filter.paymentTypes,
        search: filter.search,
        status: filter.status,
      });
    }
  }, [filter, pagination?.limit, pagination?.offset, sale.service, state]);

  if (state === 'get' || state === 'search') {
    return <InternalComponents.SaleListLoading />;
  } else if (saleList.length > 0) {
    return <InternalComponents.SaleListTable />;
  } else if (state === 'failed') {
    return <InternalComponents.SaleFailedState />;
  } else {
    if (listType === 'search') {
      return <InternalComponents.SaleEmptySearchState />;
    }

    return <InternalComponents.SaleEmptyState />;
  }
};

export default SalesList;
