import React from 'react';
import * as Mui from '@material-ui/core';

import * as ProductHooks from 'hooks/useProduct';

import useStyles from './CouponEmptyState.styles';
import { useAuthContext } from 'hooks/useAuthContext.hook';

function CouponEmptyState() {
  const classes = useStyles();
  const { marketplaceUser } = useAuthContext();
  const product = ProductHooks.useProdcut();

  return (
    <Mui.Grid className={classes.container} container>
      <Mui.Typography className={classes.text} variant="h5">
        Você ainda não possui produtos!
      </Mui.Typography>
      {(!marketplaceUser ||
        marketplaceUser?.roles.includes('owner') ||
        marketplaceUser?.roles.includes('seller') ||
        marketplaceUser?.roles.includes('marketing') ||
        marketplaceUser?.roles.includes('attendants')) && (
        <Mui.Button
          className={classes.button}
          color="primary"
          disableFocusRipple
          disableRipple
          onClick={product.ui.openToCreate}
          variant="text"
        >
          Crie um agora
        </Mui.Button>
      )}
    </Mui.Grid>
  );
}

export default CouponEmptyState;
