import React from 'react';
import * as ReactRedux from 'react-redux';
import validate from 'validate.js';

import * as TaxInvoiceHooks from 'hooks/use-tax-invoice';
import { setDialogClose, setDialogType, setOffer } from 'store/actions';
import type { IProduct } from 'types/entities';
import type { ITaxInvoice } from 'types/entities/tax-invoice';

interface IFormState {
  disabled: boolean;
  isValid: boolean;
  values: {
    marketplaceId: ITaxInvoice['marketplaceId'];
    sellerId: ITaxInvoice['sellerId'];
    transactionId: ITaxInvoice['transactionId'];
    customerId: ITaxInvoice['customerId'];
    providerId: ITaxInvoice['providerId'];
    provider: ITaxInvoice['provider'] | null;
    amount: ITaxInvoice['amount'];
    status: ITaxInvoice['status'];
    description: ITaxInvoice['description'];
    internalMetadata: ITaxInvoice['internalMetadata'];
    metadata: ITaxInvoice['metadata'];
  };
  errors: {
    marketplaceId?: string;
    sellerId?: string;
    transactionId?: string;
    customerId?: string;
    providerId?: string;
    provider?: string;
    amount?: string;
    status?: string;
    description?: string;
    internalMetadata?: string;
    metadata?: string;
  };
  touched: {
    marketplaceId?: boolean;
    sellerId?: boolean;
    transactionId?: boolean;
    customerId?: boolean;
    providerId?: boolean;
    provider?: boolean;
    amount?: boolean;
    status?: boolean;
    description?: boolean;
    internalMetadata?: boolean;
    metadata?: boolean;
  };
}

const schema = {
  name: {
    presence: {
      allowEmpty: false,
      message: '^Nome está vazio',
    },
  },
};

export function useTaxInvoiceEditDialog() {
  const dispatch = ReactRedux.useDispatch();

  const { state } = TaxInvoiceHooks.useTaxInvoice();

  const { taxInvoice, state: taxInvoiceState } = state;

  const [formState, setFormState] = React.useState<IFormState>({
    disabled: false,
    isValid: false,
    values: {
      marketplaceId: '',
      sellerId: '',
      transactionId: '',
      customerId: '',
      providerId: '',
      provider: null,
      amount: 0,
      status: 'pending',
      description: '',
      internalMetadata: null,
      metadata: null,
    },
    errors: {},
    touched: {},
  });

  const handleFormChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.persist();

      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          [event.target.name]: event.target.value,
        },
        touched: {
          ...formState.touched,
          [event.target.name]: true,
        },
      }));
    },
    []
  );

  const handleProductChange = React.useCallback(
    (event: React.ChangeEvent<object>, product: IProduct | null) => {
      event.persist();

      setFormState(prevState => ({
        ...prevState,
        values: {
          ...prevState.values,
          product,
        },
      }));
    },
    []
  );

  const handleMetadataChange = React.useCallback(
    (metadata: Record<string, string | number> | null) => {
      setFormState(prevState => ({
        ...prevState,
        values: {
          ...prevState.values,
          metadata,
        },
      }));
    },
    []
  );

  const hasError = React.useCallback(
    (field: keyof IFormState['values']) => {
      return !!formState.touched[field] && !!formState.errors[field];
    },
    [formState.errors, formState.touched]
  );

  // const handleInputFocus = React.useCallback(
  //   (inputName: keyof IFormState['values']) => {
  //     const inputElement = document.querySelector(
  //       `#${inputName}`
  //     ) as HTMLInputElement;

  //     inputElement.focus();
  //   },
  //   []
  // );

  const handleDialogClose = React.useCallback(() => {
    dispatch([setOffer(null), setDialogClose(), setDialogType(null)]);
  }, [dispatch]);

  React.useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: !errors,
      errors: errors || {},
    }));
  }, [formState.values]);

  return React.useMemo(
    () => ({
      formState,
      taxInvoice,
      taxInvoiceState,
      handleDialogClose,
      handleFormChange,
      handleMetadataChange,
      handleProductChange,
      hasError,
    }),
    [
      formState,
      taxInvoice,
      taxInvoiceState,
      handleDialogClose,
      handleFormChange,
      handleMetadataChange,
      handleProductChange,
      hasError,
    ]
  );
}
