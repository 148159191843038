import React from 'react';
import * as ReactRedux from 'react-redux';
import validate from 'validate.js';

import * as OfferHooks from 'hooks/useOffer';
import type { IOffer, IProduct } from 'types/entities';
import type { IResourceListInterface } from 'types/services';
import { NumberUtils } from 'helpers';
import { api } from 'services/api';
import { setDialogClose, setDialogType, setOffer } from 'store/actions';
import { useAuthContext } from 'hooks/useAuthContext.hook';
import type { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import type { Moment } from 'moment-timezone';
import moment from 'moment-timezone';

interface IFormState {
  disabled: boolean;
  isValid: boolean;
  values: {
    product: IProduct | null;
    name: string;
    description: string | null;
    status: IOffer['status'];
    amount: number;
    dateStart: Moment | null;
    dateEnd: Moment | null;
    automaticallyRecoverSales: boolean;
    metadata: Record<string, string | number> | null;
  };
  errors: {
    product?: string;
    name?: string;
    description?: string;
    status?: string;
    amount?: string;
    dateStart?: string;
    dateEnd?: string;
    automaticallyRecoverSales?: string;
    metadata?: string;
  };
  touched: {
    product?: boolean;
    name?: boolean;
    description?: boolean;
    status?: boolean;
    amount?: boolean;
    dateStart?: boolean;
    dateEnd?: boolean;
    automaticallyRecoverSales?: boolean;
    metadata?: boolean;
  };
}

const schema = {
  name: {
    presence: {
      allowEmpty: false,
      message: '^Nome está vazio',
    },
  },
};

export function useOfferEditDialog() {
  const dispatch = ReactRedux.useDispatch();

  const { user } = useAuthContext();

  const { state, service } = OfferHooks.useOffer();

  const { marketplace_id: marketplaceId } = user;
  const { offer, state: offerState } = state;

  const [formState, setFormState] = React.useState<IFormState>({
    disabled: false,
    isValid: false,
    values: {
      product: null,
      name: offer?.name || '',
      description: offer?.description || '',
      status: offer?.status || 'active',
      amount: (offer?.amount || 0) / 100,
      dateStart: offer?.dateStart ? moment(offer.dateStart) : null,
      dateEnd: offer?.dateEnd ? moment(offer.dateEnd) : null,
      automaticallyRecoverSales: false,
      metadata: null,
    },
    errors: {},
    touched: {
      amount: true,
      product: true,
    },
  });
  const [products, setProducts] = React.useState<IProduct[]>([]);

  const handleFormChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.persist();

      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          [event.target.name]: event.target.value,
        },
        touched: {
          ...formState.touched,
          [event.target.name]: true,
        },
      }));
    },
    []
  );

  const handleProductChange = React.useCallback(
    (event: React.ChangeEvent<object>, product: IProduct | null) => {
      event.persist();

      setFormState(prevState => ({
        ...prevState,
        values: {
          ...prevState.values,
          product,
        },
      }));
    },
    []
  );

  const handleDateChange = React.useCallback(
    (field: keyof Pick<IFormState['values'], 'dateStart' | 'dateEnd'>) =>
      (date: MaterialUiPickersDate) => {
        setFormState(prevState => ({
          ...prevState,
          values: {
            ...prevState.values,
            [field]: date,
          },
        }));
      },
    []
  );

  const handleDisableDateEnd = React.useCallback(
    (momentDate: MaterialUiPickersDate) => {
      const dateStart = moment(formState.values.dateStart);

      return !!momentDate?.isBefore(dateStart.add(-1, 'days'));
    },
    [formState.values.dateStart]
  );

  const handleMetadataChange = React.useCallback(
    (metadata: Record<string, string | number> | null) => {
      setFormState(prevState => ({
        ...prevState,
        values: {
          ...prevState.values,
          metadata,
        },
      }));
    },
    []
  );

  const hasError = React.useCallback(
    (field: keyof IFormState['values']) => {
      return !!formState.touched[field] && !!formState.errors[field];
    },
    [formState.errors, formState.touched]
  );

  const handleInputFocus = React.useCallback(
    (inputName: keyof IFormState['values']) => {
      const inputElement = document.querySelector(
        `#${inputName}`
      ) as HTMLInputElement;

      inputElement.focus();
    },
    []
  );

  const handleSubmit = React.useCallback(async () => {
    setFormState(prevState => ({
      ...prevState,
      error: {},
    }));

    const {
      name,
      dateEnd,
      dateStart,
      description,
      product,
      status,
      automaticallyRecoverSales,
      metadata,
    } = formState.values;
    let { amount } = formState.values;

    amount = NumberUtils.toPrecision(amount * 100, 0);

    if (name === '') {
      setFormState(formState => ({
        ...formState,
        errors: {
          ...formState.errors,
          name: 'O nome está vazio!',
        },
      }));

      handleInputFocus('name');
      return;
    }

    if (amount === 0) {
      setFormState(formState => ({
        ...formState,
        errors: {
          ...formState.errors,
          amount: 'Valor deve ser maior que R$ 0,00',
        },
      }));

      handleInputFocus('amount');
      return;
    }

    if (!product) {
      setFormState(prevState => ({
        ...prevState,
        errors: {
          ...prevState.errors,
          product: 'Selecione um produto',
        },
      }));

      handleInputFocus('product');
      return;
    }

    if (offerState === 'create') {
      await service.create({
        productId: product.id,
        name,
        amount,
        description,
        status,
        dateStart: dateStart
          ? dateStart.startOf('day').utc(true).toISOString()
          : null,
        dateEnd: dateEnd ? dateEnd.endOf('day').utc(true).toISOString() : null,
        automaticallyRecoverSales,
        metadata,
      });
    }

    if (offerState === 'edit') {
      await service.edit({
        id: offer!.id,
        name,
        amount,
        description,
        status,
        dateStart: dateStart
          ? dateStart.startOf('day').utc(true).toISOString()
          : null,
        dateEnd: dateEnd ? dateEnd.endOf('day').utc(true).toISOString() : null,
        automaticallyRecoverSales,
        metadata,
      });
    }
  }, [formState.values, handleInputFocus, offer, offerState, service]);

  const handleDialogClose = React.useCallback(() => {
    dispatch([setOffer(null), setDialogClose(), setDialogType(null)]);
  }, [dispatch]);

  React.useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: !errors,
      errors: errors || {},
    }));
  }, [formState.values]);

  React.useEffect(() => {
    if (offerState === 'create') {
      api
        .get<IResourceListInterface<IProduct>>(
          `v2/marketplaces/${marketplaceId}/products`,
          {
            auth: {
              username: process.env.REACT_APP_BYPASS_API_KEY!,
              password: '',
            },
            params: {
              limit: 1000,
              offset: 0,
            },
          }
        )
        .then(response => {
          const result = response.data;

          setProducts(result.items);
        })
        .catch(error => {
          console.error(error);
        });
    }

    if (offerState === 'edit' && offer) {
      api
        .get<IProduct>(
          `v2/marketplaces/${marketplaceId}/products/${offer.productId}`,
          {
            auth: {
              username: process.env.REACT_APP_BYPASS_API_KEY!,
              password: '',
            },
          }
        )
        .then(response => {
          const product = response.data;

          setProducts([product]);
          setFormState(prevState => ({
            ...prevState,
            values: {
              ...prevState.values,
              product,
            },
          }));
        })
        .catch(error => {
          console.error(error);
        });
    }
  }, [offer, offerState, marketplaceId]);

  return React.useMemo(
    () => ({
      formState,
      offer,
      offerState,
      products,
      handleDateChange,
      handleDialogClose,
      handleDisableDateEnd,
      handleFormChange,
      handleMetadataChange,
      handleProductChange,
      handleSubmit,
      hasError,
    }),
    [
      formState,
      offer,
      offerState,
      products,
      handleDateChange,
      handleDialogClose,
      handleDisableDateEnd,
      handleFormChange,
      handleMetadataChange,
      handleProductChange,
      handleSubmit,
      hasError,
    ]
  );
}
