import type {
  ITaxInvoiceConfigReducerAction,
  ITaxInvoiceConfigReducerActionPayload,
  ITaxInvoiceConfigReducerState,
} from 'types/redux/reducers/tax-invoice-config-reducer.types';

export function setTaxInvoiceConfigState(
  state: ITaxInvoiceConfigReducerState['state']
) {
  return {
    type: 'TAX_INVOICE_CONFIG_STATE_CHANGED',
    payload: state,
  } as ITaxInvoiceConfigReducerAction<
    ITaxInvoiceConfigReducerActionPayload['TAX_INVOICE_CONFIG_STATE_CHANGED']
  >;
}

export function setTaxInvoiceConfig(
  taxInvoice: ITaxInvoiceConfigReducerState['taxInvoiceConfig']
) {
  return {
    type: 'TAX_INVOICE_CONFIG_CHANGED',
    payload: taxInvoice,
  } as ITaxInvoiceConfigReducerAction<
    ITaxInvoiceConfigReducerActionPayload['TAX_INVOICE_CONFIG_CHANGED']
  >;
}
