import React, { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment-timezone';

import {
  setChargeSelected,
  setDialogClose,
  setDialogType,
  setSnackbarMessage,
  setSnackbarOpen,
  setSnackbarType,
} from 'store/actions';

import {
  getDisplayMoney,
  getDisplayPhoneNumber,
  getDisplayEmail,
} from 'helpers';

import {
  AppBar,
  Divider,
  Grid,
  Toolbar,
  IconButton,
  Typography,
  InputBase,
  Button,
  Hidden,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/styles';

import {
  Close as CloseIcon,
  FileCopy as FileCopyIcon,
} from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    color: '#fff',
  },
  container: {
    padding: theme.spacing(3),
    width: '100%',
  },
  containerLink: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      flexWrap: 'nowrap',
      alignItems: 'center',
    },
  },
  inputLink: {
    width: '100%',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      width: 560,
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
    },
  },
  buttonCopyLink: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
    },
  },
}));

const ChargeViewDialog = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [button_link_text, setButtonLinkText] = useState('Copiar');

  const { chargeSelected } = useSelector(state => state.chargeState);

  const handleDialogClose = () => {
    dispatch(setChargeSelected({}));
    dispatch(setDialogClose());
    dispatch(setDialogType(null));
  };

  const handleSelectAllText = event => {
    try {
      event.target.select();
      event.target.setSelectionRange(0, 99999);
    } catch (error) {
      console.error('Não foi possível copiar o texto');
    }
  };

  const handleCopyToClipBoard = () => {
    const input_copy = document.querySelector('#checkout-link');
    input_copy.select();
    input_copy.setSelectionRange(0, 99999);
    document.execCommand('copy');
    setButtonLinkText('Copiado !');
    dispatch([
      setSnackbarType('info'),
      setSnackbarMessage('Copiado para área de transferência'),
      setSnackbarOpen(),
    ]);
    setTimeout(() => {
      setButtonLinkText('Copiar');
    }, 3000);
  };

  const getDisplayInstallmentOrCash = () => {
    if (chargeSelected.installments !== 1) {
      return `${chargeSelected.installments}x de ${getDisplayMoney(
        chargeSelected.amount / 100
      )}`;
    } else {
      return `${getDisplayMoney(chargeSelected.amount / 100)} à vista`;
    }
  };

  const getDisplayPaymentTypesAccepted = () => {
    let payment_types = chargeSelected.payment_types_accepted.split(',');

    payment_types = payment_types.map(payment_type =>
      payment_type === 'billet' ? 'Boleto' : 'Cartão de Crédito'
    );

    return payment_types.join(', ');
  };

  const getDisplayChargeStatus = () => {
    switch (chargeSelected.status) {
      case 'new':
        return 'Criada';
      case 'failed':
        return 'Falhou';
      case 'succeeded':
        return 'Paga';
      case 'pending':
      case 'pending_zoop':
        return 'Pendente';
      case 'canceled':
        return 'Cancelada';
      case 'dispute':
        return 'Disputada';
      case 'refunded':
      case 'charged_back':
        return 'Reembolsado';
      default:
        return '';
    }
  };

  const getDisplayChargeEmailStatus = () => {
    switch (chargeSelected.email_status) {
      case 'send':
        return 'Enviada para o cliente';
      case 'visualizada':
        return 'Visualizada pelo cliente';
      case 'open':
        return 'Aberta pelo cliente';
      default:
        return 'Não enviada';
    }
  };

  const getDisplayPaymentType = () => {
    if (chargeSelected.payment_type === 'billet') {
      return 'Boleto';
    } else {
      return 'Cartão de Crédito';
    }
  };

  return (
    <React.Fragment>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Typography className={classes.title} variant="h5">
            {`Cobrança ${chargeSelected.archived ? '(Arquivada)' : ''}`}
          </Typography>
          <IconButton
            aria-label="Fechar"
            color="inherit"
            edge="end"
            onClick={handleDialogClose}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Grid className={classes.container} container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4">Dados pessoais</Typography>
        </Grid>
        <Grid item md={3} sm={4} xs={12}>
          <Typography variant="body1">
            <strong>De: </strong> {chargeSelected.seller_name}
          </Typography>
        </Grid>
        <Grid item md={3} sm={4} xs={12}>
          <Typography variant="body1">
            <strong>Para: </strong> {chargeSelected.buyer_name}
          </Typography>
        </Grid>
        <Grid item md={3} sm={4} xs={12}>
          <Typography variant="body1">
            <strong>E-mail: </strong>{' '}
            {getDisplayEmail(chargeSelected.buyer_email)}
          </Typography>
        </Grid>
        <Grid item md={3} sm={4} xs={12}>
          <Typography variant="body1">
            <strong>Número de contato: </strong>{' '}
            {getDisplayPhoneNumber(chargeSelected.buyer_phone_number)}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4">Dados da cobrança</Typography>
        </Grid>
        <Grid item md={3} sm={4} xs={12}>
          <Typography variant="body1">
            <strong>Valor: </strong> {getDisplayInstallmentOrCash()}
          </Typography>
        </Grid>
        <Grid item md={3} sm={4} xs={12}>
          <Typography variant="body1">
            <strong>Valor Total: </strong>{' '}
            {getDisplayMoney(chargeSelected.original_amount / 100)}
          </Typography>
        </Grid>
        {chargeSelected.installments !== 1 && (
          <React.Fragment>
            <Grid item md={3} sm={4} xs={12}>
              <Typography variant="body1">
                <strong>Parcela: </strong> {chargeSelected.installment}
              </Typography>
            </Grid>
            <Grid item md={3} sm={4} xs={12}>
              <Typography variant="body1">
                <strong>Total de parcelas: </strong>{' '}
                {chargeSelected.installments}
              </Typography>
            </Grid>
          </React.Fragment>
        )}
        <Grid item md={3} sm={4} xs={12}>
          <Typography variant="body1">
            <strong>Data de vencimento: </strong>{' '}
            {moment(chargeSelected.expiration_date).format('DD/MM/YYYY')}
          </Typography>
        </Grid>
        <Grid item md={3} sm={4} xs={12}>
          <Typography variant="body1">
            <strong>Tipos de pagamentos aceitos: </strong>{' '}
            {getDisplayPaymentTypesAccepted()}
          </Typography>
        </Grid>
        <Grid item md={3} sm={4} xs={12}>
          <Typography variant="body1">
            <strong>Status da cobrança: </strong> {getDisplayChargeStatus()}
          </Typography>
        </Grid>
        <Grid item md={3} sm={4} xs={12}>
          <Typography variant="body1">
            <strong>Status do e-mail da cobrança: </strong>{' '}
            {getDisplayChargeEmailStatus()}
          </Typography>
        </Grid>
        <Grid item md={3} sm={4} xs={12}>
          <Typography variant="body1">
            <strong>Emissão da cobrança: </strong>{' '}
            {moment(chargeSelected.created_at).format('DD/MM/YYYY')}
          </Typography>
        </Grid>
        <Grid item md={3} sm={4} xs={12}>
          <Typography variant="body1">
            <strong>Última atualização da cobrança: </strong>{' '}
            {moment(chargeSelected.updated_at).format('DD/MM/YYYY')}
          </Typography>
        </Grid>
        <Hidden smUp>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Hidden>
        <Grid item xs={12}>
          <div className={classes.containerLink}>
            <Typography variant="body1">
              <strong>Link de pagamento: </strong>
            </Typography>
            <InputBase
              className={classes.inputLink}
              id="checkout-link"
              onClick={event => handleSelectAllText(event)}
              // eslint-disable-next-line no-undef
              value={`${process.env.REACT_APP_BASE_URL_FRONTEND}/checkout/${chargeSelected.checkout}`}
            />
            <Button
              className={classes.buttonCopyLink}
              color="primary"
              onClick={() => handleCopyToClipBoard()}
              startIcon={<FileCopyIcon />}
              variant="contained"
            >
              {button_link_text}
            </Button>
          </div>
        </Grid>
        {chargeSelected.payment_limit_date && (
          <React.Fragment>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4">Opções de vencimento</Typography>
            </Grid>
            <Grid item md={3} sm={4} xs={12}>
              <Typography variant="body1">
                <strong>Prazo máximo após a data de vencimento: </strong>{' '}
                {moment(chargeSelected.payment_limit_date).format('DD/MM/YYYY')}
              </Typography>
            </Grid>
            <Grid item md={3} sm={4} xs={12}>
              <Typography variant="body1">
                <strong>Multa pelo atraso do pagamento: </strong>{' '}
                {getDisplayMoney(
                  (chargeSelected.amount / 100) *
                    (chargeSelected.late_fee_percentage / 100)
                )}
              </Typography>
            </Grid>
            <Grid item md={3} sm={4} xs={12}>
              <Typography variant="body1">
                <strong>Juros pelo atrado do pagamento: </strong>{' '}
                {`${chargeSelected.interest_percentage.toFixed(2)}% (por mês)`}
              </Typography>
            </Grid>
          </React.Fragment>
        )}
        {chargeSelected.discount_limit_date && (
          <React.Fragment>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4">Opções de desconto</Typography>
            </Grid>
            <Grid item md={3} sm={4} xs={12}>
              <Typography variant="body1">
                <strong>Prazo máximo do desconto: </strong>{' '}
                {moment(chargeSelected.discount_limit_date).format(
                  'DD/MM/YYYY'
                )}
              </Typography>
            </Grid>
            <Grid item md={3} sm={4} xs={12}>
              <Typography variant="body1">
                <strong>Valor do desconto: </strong>{' '}
                {getDisplayMoney(chargeSelected.discount_amount / 100)}
              </Typography>
            </Grid>
          </React.Fragment>
        )}
        {chargeSelected.payment_date && (
          <React.Fragment>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4">Cobrança Paga</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2">
                Mostra como o cliente pagou, só aparece quando a cobrança for
                paga.
              </Typography>
            </Grid>
            <Grid item md={3} sm={4} xs={12}>
              <Typography variant="body1">
                <strong>Método de pagamento: </strong> {getDisplayPaymentType()}
              </Typography>
            </Grid>
            <Grid item md={3} sm={4} xs={12}>
              <Typography variant="body1">
                <strong>Dia do processamento: </strong>{' '}
                {moment(chargeSelected.payment_date).format('DD/MM/YYYY')}
              </Typography>
            </Grid>
          </React.Fragment>
        )}
      </Grid>
    </React.Fragment>
  );
};

export default ChargeViewDialog;
