import React from 'react';
import * as ReactRedux from 'react-redux';

import type { IOffer } from 'types/entities';
import {
  setOffer,
  setOfferState,
  setDialogFullScreen,
  setDialogMaxWidth,
  setDialogOpen,
  setDialogType,
} from 'store/actions';

function useOfferUI() {
  const dispatch = ReactRedux.useDispatch();

  const openToView = React.useCallback(
    (offer: IOffer) => {
      dispatch([
        setOffer(offer),
        setOfferState('view'),
        setDialogType('viewOffer'),
        setDialogFullScreen(true),
        setDialogOpen(),
      ]);
    },
    [dispatch]
  );

  const openToCreate = React.useCallback(() => {
    dispatch([
      setOffer(null),
      setOfferState('create'),
      setDialogType('createOffer'),
      setDialogFullScreen(true),
      setDialogOpen(),
    ]);
  }, [dispatch]);

  const openToEdit = React.useCallback(
    (offer: IOffer) => {
      dispatch([
        setOffer(offer),
        setOfferState('edit'),
        setDialogType('editOffer'),
        setDialogFullScreen(true),
        setDialogOpen(),
      ]);
    },
    [dispatch]
  );

  const openToDelete = React.useCallback(
    (offer: IOffer) => {
      dispatch([
        setOffer(offer),
        setOfferState('delete'),
        setDialogType('deleteOffer'),
        setDialogMaxWidth('sm'),
        setDialogFullScreen(false),
        setDialogOpen(),
      ]);
    },
    [dispatch]
  );

  const refresh = React.useCallback(() => {
    dispatch([setOffer(null), setOfferState('get')]);
  }, [dispatch]);

  return React.useMemo(
    () => ({
      openToView,
      openToCreate,
      openToDelete,
      openToEdit,
      refresh,
    }),
    [openToView, openToCreate, openToDelete, openToEdit, refresh]
  );
}

export default useOfferUI;
