import React from 'react';
import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';
import * as ReactRedux from 'react-redux';
import clsx from 'clsx';

import * as BuyerHooks from 'hooks/useBuyer';
import type { IBuyer } from 'types/entities';
import { getDisplayEmail, getDisplayPhoneNumber, getInitials } from 'helpers';
import { setCustomerPagination, setCustomerState } from 'store/actions';

import useStyles from './CustomerListTable.styles';
import { useAuthContext } from 'hooks/useAuthContext.hook';

function CustomerListTable() {
  const { marketplaceUser } = useAuthContext();

  const classes = useStyles();
  const theme = Mui.useTheme();
  const dispatch = ReactRedux.useDispatch();
  const buyer = BuyerHooks.useBuyer();
  const { listCustomersFiltered, listCustomers, pagination, listType } =
    buyer.state;

  let listCustomersRender = [];

  if (listCustomersRender.length > 0) {
    listCustomersRender = listCustomersFiltered.slice();
  } else {
    listCustomersRender = listCustomers.slice();
  }

  function getDisplaySecondaryEmail(email: string) {
    const width = window.innerWidth;

    if (width < theme.breakpoints.values.sm) {
      return email;
    }

    return '';
  }

  function getDisplaySecondaryPhoneNumber(phoneNumber: string) {
    const width = window.innerWidth;

    if (width < theme.breakpoints.values.md) {
      return phoneNumber;
    }

    return '';
  }

  function handleMouseEnterListItem(event: React.MouseEvent<HTMLLIElement>) {
    const width = window.innerWidth;

    if (width > theme.breakpoints.values.md) {
      const element = event.currentTarget.children[3] as HTMLLIElement;
      element.style.visibility = 'visible';
    }
  }

  function handleMouseLeaveListItem(event: React.MouseEvent<HTMLLIElement>) {
    const width = window.innerWidth;

    if (width > theme.breakpoints.values.md) {
      const element = event.currentTarget.children[3] as HTMLLIElement;
      element.style.visibility = 'hidden';
    }
  }

  function handlePageChange(_: unknown, page: number) {
    if (!pagination) {
      return;
    }

    let newOffset: number;
    if (page + 1 > pagination.page) {
      newOffset = pagination.offset + pagination.limit;
    } else {
      newOffset = pagination.offset - pagination.limit;
    }

    dispatch([
      setCustomerPagination({ ...pagination, offset: newOffset }),
      setCustomerState(listType),
    ]);
  }

  function handleRowsPerPageChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (!pagination) {
      return;
    }

    const limit = Number(event.target.value);

    dispatch([
      setCustomerPagination({ ...pagination, limit, offset: 0 }),
      setCustomerState(listType),
    ]);
  }

  return (
    <Mui.Grid className={classes.container} component={Mui.List} container>
      <Mui.Grid
        className={classes.paginationContainer}
        container
        justify="flex-end"
      >
        <Mui.TablePagination
          SelectProps={{
            classes: {
              icon: classes.icon,
            },
            MenuProps: {
              classes: {
                paper: classes.menuList,
              },
            },
          }}
          backIconButtonText="Voltar página"
          component="div"
          count={pagination?.total || 0}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to === -1 ? count : to} de ${
              count !== -1 ? count : `mais que ${to}`
            }`
          }
          labelRowsPerPage=""
          nextIconButtonText="Próxima página"
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          page={(pagination?.page || 1) - 1}
          rowsPerPage={pagination?.limit || 30}
          rowsPerPageOptions={[30, 50, 100]}
        />
      </Mui.Grid>

      <Mui.ListItem disableGutters>
        <Mui.Grid className={classes.tableCell} item md={3} sm={5} xs={7}>
          <Mui.Typography noWrap variant="h5">
            Nome
          </Mui.Typography>
        </Mui.Grid>
        <Mui.Grid
          className={clsx(classes.tableCell, classes.tableTablet)}
          item
          lg={4}
          md={4}
          sm={5}
        >
          <Mui.Typography noWrap variant="h5">
            E-mail
          </Mui.Typography>
        </Mui.Grid>
        <Mui.Grid
          className={clsx(classes.tableCell, classes.tableDesktop)}
          item
          lg={3}
          md={3}
        >
          <Mui.Typography noWrap variant="h5">
            Celular
          </Mui.Typography>
        </Mui.Grid>
      </Mui.ListItem>

      <Mui.Grid component={Mui.Divider} item xs={12} />

      {listCustomersRender.map((customer: IBuyer) => {
        const phoneNumberFormatted = getDisplayPhoneNumber(
          customer.phone_number
        );
        const displayEmail = getDisplayEmail(customer.email);

        return (
          <React.Fragment key={customer.id}>
            <Mui.ListItem
              className={classes.listItem}
              disableGutters
              onMouseEnter={event => handleMouseEnterListItem(event)}
              onMouseLeave={event => handleMouseLeaveListItem(event)}
            >
              <Mui.Grid className={classes.tableCell} item md={3} sm={5} xs={7}>
                <Mui.ListItemAvatar>
                  <Mui.Avatar>{getInitials(customer.name)}</Mui.Avatar>
                </Mui.ListItemAvatar>
                <Mui.ListItemText
                  disableTypography
                  primary={
                    <Mui.Typography noWrap>{customer.name}</Mui.Typography>
                  }
                  secondary={
                    <Mui.Grid component="span" container direction="column">
                      <Mui.Grid component="span" item xs zeroMinWidth>
                        <Mui.Typography component="div" noWrap variant="body2">
                          {getDisplaySecondaryEmail(displayEmail)}
                        </Mui.Typography>
                      </Mui.Grid>
                      <Mui.Grid component="span" item xs zeroMinWidth>
                        <Mui.Typography component="div" noWrap variant="body2">
                          {getDisplaySecondaryPhoneNumber(phoneNumberFormatted)}
                        </Mui.Typography>
                      </Mui.Grid>
                    </Mui.Grid>
                  }
                />
              </Mui.Grid>
              <Mui.Grid
                className={clsx(classes.tableCell, classes.tableTablet)}
                item
                md={4}
                sm={5}
              >
                <Mui.Typography noWrap variant="body1">
                  {displayEmail}
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid
                className={clsx(classes.tableCell, classes.tableDesktop)}
                item
                md={3}
              >
                <Mui.Typography noWrap variant="body1">
                  {phoneNumberFormatted}
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid
                className={clsx(classes.tableCell, classes.options)}
                item
                md={2}
                sm={2}
                xs={4}
              >
                <Mui.IconButton
                  className={classes.buttonEdit}
                  onClick={() => buyer.ui.openToEdit(customer)}
                >
                  <MuiIcons.Edit className={classes.iconButton} />
                </Mui.IconButton>

                {!marketplaceUser ||
                  (marketplaceUser?.roles.includes('owner') && (
                    <Mui.IconButton
                      onClick={() => buyer.ui.openToDelete(customer)}
                    >
                      <MuiIcons.Delete className={classes.iconButton} />
                    </Mui.IconButton>
                  ))}
              </Mui.Grid>
            </Mui.ListItem>

            <Mui.Grid component={Mui.Divider} item xs={12} />
          </React.Fragment>
        );
      })}

      <Mui.Grid
        className={classes.paginationContainer}
        container
        justify="flex-end"
      >
        <Mui.TablePagination
          SelectProps={{
            classes: {
              icon: classes.icon,
            },
            MenuProps: {
              classes: {
                paper: classes.menuList,
              },
            },
          }}
          backIconButtonText="Voltar página"
          component="div"
          count={pagination?.total || 0}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to === -1 ? count : to} de ${
              count !== -1 ? count : `mais que ${to}`
            }`
          }
          labelRowsPerPage=""
          nextIconButtonText="Próxima página"
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          page={(pagination?.page || 1) - 1}
          rowsPerPage={pagination?.limit || 30}
          rowsPerPageOptions={[30, 50, 100]}
        />
      </Mui.Grid>
    </Mui.Grid>
  );
}

export default CustomerListTable;
