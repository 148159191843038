import React from 'react';
import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';
import * as ReactRedux from 'react-redux';

import * as SaleHooks from 'hooks/useSale';
import type { ITransaction } from 'types/entities';
import { DateFilterUtils, getDisplayMoney, TransactionUtils } from 'helpers';
import { SearchInput } from 'components';
import {
  setDialogFullScreen,
  setDialogMaxWidth,
  setDialogOpen,
  setDialogType,
  setSaleFilter,
} from 'store/actions';

import useStyles from './SalesToolbar.styles';
import { useAuthContext } from 'hooks/useAuthContext.hook';

enum EContentDialogMap {
  SALE_FILTER_DATE = 'saleFilterDate',
  SALE_FILTER_STATUS = 'saleFilterStatus',
  SALE_FILTER_PAYMENT_TYPE = 'saleFilterPaymentType',
  SALE_FILTER_AMOUNT = 'saleFilterAmount',
}

type TContentDialogMap = `${EContentDialogMap}`;

function SalesToolbar() {
  const classes = useStyles();

  const theme = Mui.useTheme();
  const { marketplaceUser } = useAuthContext();

  const dispatch = ReactRedux.useDispatch();
  const sale = SaleHooks.useSale();
  const { filter, pagination } = sale.state;
  const [isExporting, setIsExporting] = React.useState<boolean>(false);

  const lastFilterRef = React.useRef<string>(filter.search);

  function handleSearch(event: React.ChangeEvent<HTMLInputElement>) {
    const search = event.target.value;

    dispatch(setSaleFilter({ search }));

    makeSearch();
  }

  async function handleButtonExportClick() {
    try {
      setIsExporting(true);
      await sale.service.report({
        amountFrom: filter.amountFrom,
        amountTo: filter.amountTo,
        dateEnd: filter.dateEnd,
        dateStart: filter.dateStart,
        limit: pagination?.limit,
        offset: pagination?.offset,
        paymentTypes: filter.paymentTypes,
        search: filter.search,
        status: filter.status,
      });
    } finally {
      setIsExporting(false);
    }
  }

  function handleOpenFilterDateDialog(dialogType: TContentDialogMap) {
    dispatch([
      setDialogType(dialogType),
      setDialogFullScreen(window.innerWidth <= theme.breakpoints.values.sm),
      setDialogMaxWidth(false),
      setDialogOpen(),
    ]);
  }

  const makeSearch = React.useMemo(() => {
    return Mui.debounce(() => {
      sale.ui.refresh();
      sale.ui.refreshMetrics();
    }, 1000);
  }, [sale.ui]);

  const statusLabel = React.useMemo<string>(() => {
    let label = 'Todos os status';
    const totalStatus = Object.keys(TransactionUtils.statusLabelMap).length;
    const arrayStatus = filter.status.split(',');

    if (arrayStatus.length === totalStatus) {
      return label;
    }

    if (filter.status) {
      label = arrayStatus
        .map(status =>
          TransactionUtils.getStatusLabel(status as ITransaction['status'])
        )
        .join(', ');
    }

    return label;
  }, [filter.status]);

  const paymentTypeLabel = React.useMemo<string>(() => {
    let label = 'Todos os tipos de pagamento';
    const totalPaymentTypes = Object.keys(
      TransactionUtils.paymentTypeLabelMap
    ).length;
    const arrayPaymentTypes = filter.paymentTypes.split(',');

    if (arrayPaymentTypes.length === totalPaymentTypes) {
      return label;
    }

    if (filter.paymentTypes) {
      label = arrayPaymentTypes
        .map(paymentType =>
          TransactionUtils.getPaymentTypeLabel(
            paymentType as ITransaction['payment_type']
          )
        )
        .join(', ');
    }

    return label;
  }, [filter.paymentTypes]);

  const amountLabel = React.useMemo<React.ReactNode>(() => {
    let label: React.ReactNode;

    const amountFromParsed = Number(filter.amountFrom) / 100;
    const amountToParsed = Number(filter.amountTo) / 100;
    const displayAmountFrom = getDisplayMoney(amountFromParsed);
    const displayAmountTo = getDisplayMoney(amountToParsed);

    if (amountFromParsed > 0 && amountToParsed > 0) {
      label = (
        <>
          {displayAmountFrom} a {displayAmountTo}
        </>
      );
    } else if (amountFromParsed > 0) {
      label = (
        <>
          {displayAmountFrom} a{' '}
          <MuiIcons.AllInclusive className={classes.selectInnerIcon} />
        </>
      );
    } else if (displayAmountTo > 0) {
      label = <>0 a {displayAmountTo}</>;
    } else {
      label = (
        <>
          0 a <MuiIcons.AllInclusive className={classes.selectInnerIcon} />
        </>
      );
    }

    return label;
  }, [classes.selectInnerIcon, filter.amountFrom, filter.amountTo]);

  React.useEffect(() => {
    lastFilterRef.current = filter.search || filter.dateStart || filter.dateEnd;
  }, [filter]);

  return (
    <div className={classes.root}>
      <div className={classes.row}>
        <div className={classes.leftActionsContainer}>
          <Mui.TextField
            SelectProps={{
              open: false,
              classes: {
                icon: classes.selectIcon,
              },
            }}
            className={classes.selectFilter}
            margin="dense"
            onClick={() => handleOpenFilterDateDialog('saleFilterDate')}
            select
            value="date"
            variant="outlined"
          >
            <Mui.MenuItem value="date">
              {DateFilterUtils.getDateFilterLabel(
                filter.dateStart,
                filter.dateEnd
              )}
            </Mui.MenuItem>
          </Mui.TextField>
          <Mui.TextField
            SelectProps={{
              open: false,
              classes: {
                icon: classes.selectIcon,
              },
            }}
            className={classes.selectFilter}
            margin="dense"
            onClick={() => handleOpenFilterDateDialog('saleFilterStatus')}
            select
            value="status"
            variant="outlined"
          >
            <Mui.MenuItem value="status">{statusLabel}</Mui.MenuItem>
          </Mui.TextField>
          <Mui.TextField
            SelectProps={{
              open: false,
              classes: {
                icon: classes.selectIcon,
              },
            }}
            className={classes.selectFilter}
            margin="dense"
            onClick={() => handleOpenFilterDateDialog('saleFilterPaymentType')}
            select
            value="paymentType"
            variant="outlined"
          >
            <Mui.MenuItem value="paymentType">{paymentTypeLabel}</Mui.MenuItem>
          </Mui.TextField>
          <Mui.TextField
            SelectProps={{
              open: false,
              classes: {
                root: classes.selectRoot,
                icon: classes.selectIcon,
              },
            }}
            className={classes.selectFilter}
            margin="dense"
            onClick={() => handleOpenFilterDateDialog('saleFilterAmount')}
            select
            value="amount"
            variant="outlined"
          >
            <Mui.MenuItem value="amount">{amountLabel}</Mui.MenuItem>
          </Mui.TextField>
          <Mui.Button
            color="primary"
            onClick={sale.ui.filter.clear}
            variant="outlined"
          >
            Limpar Filtro
          </Mui.Button>
        </div>
        <span className={classes.spacer} />

        {(!marketplaceUser ||
          marketplaceUser?.roles.includes('owner') ||
          marketplaceUser?.roles.includes('financial')) && (
          <div className={classes.rightActionsContainer}>
            {/*<Mui.Button className={classes.importButton}>Importar</Mui.Button>*/}
            <Mui.Button
              className={classes.exportButton}
              disabled={isExporting}
              onClick={handleButtonExportClick}
            >
              {isExporting ? 'Gerando' : 'Exportar'}
            </Mui.Button>
          </div>
        )}
      </div>
      <div className={classes.row}>
        <SearchInput
          className={classes.searchInput}
          onChange={handleSearch}
          placeholder="Pesquise uma venda"
          value={filter.search}
        />
      </div>
    </div>
  );
}

export default SalesToolbar;
