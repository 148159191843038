import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { setNotificationsClose } from 'store/actions';

import { Drawer, Typography, IconButton } from '@material-ui/core';

import { makeStyles } from '@material-ui/styles';

import {
  ChevronRight as ChevronRightIcon,
  NotificationsActive as NotificationsActiveIcon,
} from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 250,
    [theme.breakpoints.up('lg')]: {
      height: '100vh',
    },
    '&::-webkit-scrollbar': {
      width: 8,
    },
    '&::-webkit-scrollbar-thumb': {
      height: 56,
      background: 'hsla(0, 0%, 53.3%, 0.4)',
    },
  },
  root: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2),
  },
  notificationsHeader: {},
  notificationsContent: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  noNotifications: {
    fontSize: 40,
  },
}));

const SidebarNotifications = () => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const handleNotificationsClose = () => dispatch(setNotificationsClose());

  const { open } = useSelector(state => state.notificationsState);

  return (
    <Drawer
      anchor="right"
      classes={{
        paper: classes.drawer,
      }}
      onClose={handleNotificationsClose}
      open={open}
      transitionDuration={800}
    >
      <div className={classes.root}>
        <div className={classes.notificationsHeader}>
          <IconButton onClick={handleNotificationsClose}>
            <ChevronRightIcon />
          </IconButton>
        </div>
        <div className={classes.notificationsContent}>
          <NotificationsActiveIcon className={classes.noNotifications} />
          <Typography variant="body2">Sem notificações</Typography>
        </div>
      </div>
    </Drawer>
  );
};

export default SidebarNotifications;
