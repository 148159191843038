import React from 'react';
import * as Mui from '@material-ui/core';

import useStyles from './SubscriptionListLoading.styles';

function SubscriptionListLoading() {
  const classes = useStyles();

  return (
    <Mui.Grid
      alignItems="center"
      className={classes.container}
      container
      justifyContent="center"
      spacing={2}
    >
      <Mui.CircularProgress />
    </Mui.Grid>
  );
}

export default SubscriptionListLoading;
