import * as Mui from '@material-ui/core';

const useStyles = Mui.makeStyles(theme => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    color: '#fff',
  },
  container: {
    padding: theme.spacing(3),
    width: '100%',
    backgroundColor: theme.palette.background.default,
  },
  formControl: {
    minWidth: 120,
    width: '100%',
  },
  containerLoader: {
    paddingTop: 0,
    padding: theme.spacing(2),
  },
  paper: {
    backgroundColor: theme.palette.background.dark,
  },
  selectPaper: {
    backgroundColor: theme.palette.background.dark2,
  },
  selectIcon: {
    color: theme.palette.white,
  },

  // Upload
  uploadContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing(1),
  },
  uploadInput: {
    display: 'none',
  },
  imageContainer: {
    position: 'relative',
    width: 128,
    height: 128,
    margin: theme.spacing(1, 0),
    borderRadius: '0.5rem',
    overflow: 'hidden',

    '& > img': {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },
  },
  imageOverlay: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    width: '100%',
    height: '100%',
    cursor: 'pointer',
    transition: 'background 400ms',

    '&:hover': {
      backgroundColor: 'rgba(9, 12, 21, 0.41)',
      '& > span': {
        visibility: 'visible',
        opacity: 1,
      },
    },
    '& > span': {
      visibility: 'hidden',
      opacity: 0,
      transition: 'opacity 600ms',
    },
    '& svg': {
      color: theme.palette.white,
    },
  },
  uploadActionsContainer: {
    display: 'flex',
    gap: theme.spacing(1),
    margin: theme.spacing(1, 0),

    '@media (max-width: 365px)': {
      flexDirection: 'column',
    },
  },
  imageMock: {
    '& svg': {
      fontSize: '8rem',
    },
  },
  taxInvoiceHeading: {
    display: 'flex',
    alignItems: 'center',
    gap: 12,
  },
}));

export default useStyles;
