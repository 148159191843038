const INITIAL_STATE = {
  steps: [
    'Selecione seus clientes',
    'Configure o pagamento',
    'Confirme a(s) cobrança(s)',
  ],
  currentStep: 0,
  chargeCustomers: [],
  payment_types_accepted: {
    billet: false,
    credit_card: false,
  },
  installments: 1,
  currency: 'BRL',
  expiration_date: new Date(),
  payment_limit_date: null,
  expiration_options: false,
  discount_options: false,
  late_fee: {
    mode: 'PERCENTAGE',
    percentage: null,
  },
  interest: {
    mode: 'MONTHLY_PERCENTAGE',
    percentage: null,
  },
  discount: {
    mode: 'FIXED',
    limit_date: null,
    amount: 0.0,
  },
  amount: 0.0,
  disabledButtons: false,
  state: 'get',
  chargeList: [],
  chargeSelected: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CHARGE_NEXT_STEP':
      return {
        ...state,
        currentStep: state.currentStep + 1,
      };
    case 'CHARGE_PREVIOUS_STEP':
      return {
        ...state,
        currentStep: state.currentStep - 1,
      };
    case 'CHARGE_CUSTOMERS_CHANGED':
      return {
        ...state,
        chargeCustomers: action.payload,
      };
    case 'CHARGE_BILLET_CHANGED':
      return {
        ...state,
        payment_types_accepted: {
          ...state.payment_types_accepted,
          billet: action.payload,
        },
      };
    case 'CHARGE_CREDIT_CARD_CHANGED':
      return {
        ...state,
        payment_types_accepted: {
          ...state.payment_types_accepted,
          credit_card: action.payload,
        },
      };
    case 'CHARGE_AMOUNT_CHANGED':
      return {
        ...state,
        amount: action.payload,
      };
    case 'CHARGE_INSTALLMENTS_CHANGED':
      return {
        ...state,
        installments: action.payload,
      };
    case 'CHARGE_EXPIRATION_DATE_CHANGED':
      return {
        ...state,
        expiration_date: action.payload,
      };
    case 'CHARGE_EXPIRATION_OPTIONS_CHANGED':
      return {
        ...state,
        expiration_options: action.payload,
        payment_limit_date: null,
        late_fee: {
          ...state.late_fee,
          percentage: null,
        },
        interest: {
          ...state.interest,
          percentage: null,
        },
      };
    case 'CHARGE_PAYMENT_LIMIT_DATE_CHANGED':
      return {
        ...state,
        payment_limit_date: action.payload,
      };
    case 'CHARGE_LATE_FEE_PERCENTAGE_CHANGED':
      return {
        ...state,
        late_fee: {
          ...state.late_fee,
          percentage: action.payload,
        },
      };
    case 'CHARGE_INTEREST_PERCENTAGE_CHANGED':
      return {
        ...state,
        interest: {
          ...state.interest,
          percentage: action.payload,
        },
      };
    case 'CHARGE_DISCOUNT_OPTIONS_CHANGED':
      return {
        ...state,
        discount_options: action.payload,
        discount: {
          ...state.discount,
          limit_date: null,
          amount: 0.0,
        },
      };
    case 'CHARGE_DISCOUNT_LIMIT_DATE_CHANGED':
      return {
        ...state,
        discount: {
          ...state.discount,
          limit_date: action.payload,
        },
      };
    case 'CHARGE_DISCOUNT_AMOUNT_CHANGED':
      return {
        ...state,
        discount: {
          ...state.discount,
          amount: action.payload,
        },
      };
    case 'CHARGE_DISABLED_BUTTONS_CHANGED':
      return {
        ...state,
        disabledButtons: action.payload,
      };
    case 'CHARGE_STATE_CHANGED':
      return {
        ...state,
        state: action.payload,
      };
    case 'CHARGE_LIST_CHANGED':
      return {
        ...state,
        chargeList: action.payload,
      };
    case 'CHARGE_STATE_RESETED':
      return {
        ...INITIAL_STATE,
      };
    case 'CHARGE_SELECTED_CHANGED':
      return {
        ...state,
        chargeSelected: action.payload,
      };
    default:
      return state;
  }
};
