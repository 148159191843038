export const getDisplayPhoneNumber = phone_number => {
  let display_phone_number;

  if (phone_number && phone_number.length === 11) {
    display_phone_number = `(${phone_number.substring(
      0,
      2
    )}) ${phone_number.substring(2, 7)}-${phone_number.substring(7, 11)}`;
  } else if (phone_number && phone_number.length === 10) {
    display_phone_number = `(${phone_number.substring(
      0,
      2
    )}) ${phone_number.substring(2, 6)}-${phone_number.substring(6, 10)}`;
  } else {
    display_phone_number = 'Sem número de contato';
  }

  return display_phone_number;
};
