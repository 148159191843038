import React from 'react';

import * as SubscriptionHooks from 'hooks/useSubscription';
import * as InternalComponents from './components';

function SubscriptionsList() {
  const subscription = SubscriptionHooks.useSubscription();
  const { state, listSubscriptions, pagination } = subscription.state;

  React.useEffect(() => {
    if (state === 'get') {
      subscription.service.getAll({
        offset: pagination?.offset,
        limit: pagination?.limit,
      });
    }
  }, [subscription.service, pagination?.limit, pagination?.offset, state]);

  if (state === 'get') {
    return <InternalComponents.SubscriptionListLoading />;
  } else if (listSubscriptions.length > 0) {
    return <InternalComponents.SubscriptionListTable />;
  } else if (state === 'failed') {
    return <InternalComponents.SubscriptionFailedState />;
  } else {
    return <InternalComponents.SubscriptionEmptyState />;
  }
}

export default SubscriptionsList;
