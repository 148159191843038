import React from 'react';

import * as BuyerHooks from 'hooks/useBuyer';
import * as InternalComponents from './components';

function CustomersList() {
  const buyer = BuyerHooks.useBuyer();
  const { state, listType, listCustomers, pagination } = buyer.state;

  React.useEffect(() => {
    if (state === 'get') {
      buyer.service.getAll({
        offset: pagination?.offset,
        limit: pagination?.limit,
      });
    }
  }, [buyer.service, pagination?.limit, pagination?.offset, state]);

  if (state === 'get' || state === 'search') {
    return <InternalComponents.CustomerListLoading />;
  } else if (listCustomers.length > 0) {
    return <InternalComponents.CustomerListTable />;
  } else if (state === 'failed') {
    return <InternalComponents.CustomerFailedState />;
  } else {
    if (listType === 'search') {
      return <InternalComponents.CustomerEmptyStateSearch />;
    }

    return <InternalComponents.CustomerEmptyState />;
  }
}

export default CustomersList;
