import React from 'react';
import * as ReactRedux from 'react-redux';

import type { IPlan } from 'types/entities';
import {
  setPlan,
  setPlanState,
  setDialogFullScreen,
  setDialogOpen,
  setDialogType,
} from 'store/actions';

function usePlanUI() {
  const dispatch = ReactRedux.useDispatch();

  const openToView = React.useCallback(
    (plan: IPlan) => {
      dispatch([
        setPlan(plan),
        setPlanState('view'),
        setDialogType('viewPlan'),
        setDialogFullScreen(true),
        setDialogOpen(),
      ]);
    },
    [dispatch]
  );

  const openToCreate = React.useCallback(() => {
    dispatch([
      setPlanState('create'),
      setDialogType('createPlan'),
      setDialogFullScreen(true),
      setDialogOpen(),
    ]);
  }, [dispatch]);

  const openToEdit = React.useCallback(
    (plan: IPlan) => {
      dispatch([
        setPlan(plan),
        setPlanState('edit'),
        setDialogType('editPlan'),
        setDialogFullScreen(true),
        setDialogOpen(),
      ]);
    },
    [dispatch]
  );

  const refresh = React.useCallback(() => {
    dispatch([setPlan(null), setPlanState('get')]);
  }, [dispatch]);

  return React.useMemo(
    () => ({
      openToView,
      openToCreate,
      openToEdit,
      refresh,
    }),
    [openToView, openToCreate, openToEdit, refresh]
  );
}

export default usePlanUI;
