import voucherCode from 'voucher-code-generator';

import type { ICoupon } from '../types/entities';
import { NumberUtils } from './Number.utils';

enum EFrequencyPartsOfSpeech {
  ADJECTIVE = 'adjective',
  NOUN = 'noun',
  PLURAL_OF_NOUN = 'plural_of_noun',
}

type TFrequencyPartsOfSpeech = `${EFrequencyPartsOfSpeech}`;

export class CouponUtils {
  public static frequencyAdjectiveLabelMap: Record<
    ICoupon['frequency'],
    string
  > = {
    annually: 'Anual',
    daily: 'Diário',
    monthly: 'Mensal',
    weekly: 'Semanal',
    quarterly: 'Trimestral',
  };

  public static frequencyNounLabelMap: Record<ICoupon['frequency'], string> = {
    annually: 'ano',
    daily: 'dia',
    monthly: 'mês',
    weekly: 'semana',
    quarterly: 'trimestral',
  };

  public static frequencyPluralOfNounLabelMap: Record<
    ICoupon['frequency'],
    string
  > = {
    annually: 'anos',
    daily: 'dias',
    monthly: 'meses',
    weekly: 'semanas',
    quarterly: 'trimestrais',
  };

  public static frequencyPartsOfSpeechMap: Record<
    TFrequencyPartsOfSpeech,
    Record<ICoupon['frequency'], string>
  > = {
    adjective: this.frequencyAdjectiveLabelMap,
    noun: this.frequencyNounLabelMap,
    plural_of_noun: this.frequencyPluralOfNounLabelMap,
  };

  public static getFrequencyLabel(
    frequency: ICoupon['frequency'],
    partOfSpeech: TFrequencyPartsOfSpeech = 'adjective'
  ): string {
    return this.frequencyPartsOfSpeechMap[partOfSpeech][frequency] || '';
  }

  public static typeLabelMap: Record<ICoupon['type'], string> = {
    product: 'Produto',
    subscription: 'Assinatura',
  };

  public static getTypeLabel(type: ICoupon['type']): string {
    return this.typeLabelMap[type] || '';
  }

  public static valueTypeLabelMap: Record<ICoupon['valueType'], string> = {
    fixed: 'Fixo',
    percentage: 'Porcentagem',
  };

  public static getValueTypeLabel(valueType: ICoupon['valueType']): string {
    return this.valueTypeLabelMap[valueType] || '';
  }

  public static generateRedeemCode() {
    return voucherCode.generate({
      length: NumberUtils.generateRandom(10, 6),
      count: 1,
    })[0];
  }
}
