import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import {
  setSnackbarType,
  setSnackbarMessage,
  setSnackbarOpen,
} from 'store/actions';
import validate from 'validate.js';
import { getSession } from 'auth';
import { phoneMask, postalCodeMask } from 'helpers';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
} from '@material-ui/core';

import { InputMask, SelectState, SelectCity } from 'components';
import { api } from 'services/api';
import { useLocalStorage } from 'hooks/useLocalStorage';

const schema = {
  name: {
    presence: {
      allowEmpty: false,
      message: '^Nome é necessário',
    },
    length: {
      maximum: 64,
      minimum: 5,
      tooShort: '^Nome muito curto (mínimo 5 caracteres)',
      tooLong: '^Nome muito longo (máximo 64 caracteres)',
    },
  },
  phone_number: {
    presence: {
      allowEmpty: true,
    },
  },
  state: {
    presence: {
      allowEmpty: true,
    },
  },
  city: {
    presence: {
      allowEmpty: true,
    },
  },
  neighborhood: {
    presence: {
      allowEmpty: true,
    },
  },
  street: {
    presence: {
      allowEmpty: true,
    },
  },
  house_number: {
    presence: {
      allowEmpty: true,
    },
  },
  postal_code: {
    presence: {
      allowEmpty: true,
    },
  },
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark2,
  },
}));

const AccountDetails = props => {
  const { user, onChange: setUser, className, ...rest } = props;

  const classes = useStyles();

  const dispatch = useDispatch();
  const storage = useLocalStorage();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      state: '',
    },
    errors: {},
    touched: {},
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: !errors,
      errors: errors || {},
    }));
  }, [formState.values]);

  useEffect(() => {
    if (user) {
      const {
        name,
        phone_number,
        id_state: state,
        street,
        id_city: city,
        neighborhood,
        house_number,
        postal_code,
      } = user;

      setFormState(formState => ({
        ...formState,
        values: {
          name,
          phone_number,
          state,
          street,
          city,
          neighborhood,
          house_number,
          postal_code,
        },
      }));
    }
  }, [user]);

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleSubmit = async event => {
    event.preventDefault();

    const {
      name,
      phone_number,
      state: id_state,
      city: id_city,
      neighborhood,
      street,
      house_number,
      postal_code,
    } = formState.values;

    const phone_number_only_numbers =
      phone_number && phone_number !== ''
        ? phone_number.replace(/[^0-9]/g, '')
        : '';
    const postal_code_only_numbers =
      postal_code && postal_code !== ''
        ? postal_code.replace(/[^0-9]/g, '')
        : '';

    if (
      phone_number_only_numbers !== '' &&
      phone_number_only_numbers.length !== 11 &&
      phone_number_only_numbers.length !== 10
    ) {
      setFormState(formState => ({
        ...formState,
        errors: {
          ...formState.errors,
          phone_number: ['O número de telefone ou celular está inválido!'],
        },
      }));

      document.querySelector('#phone_number').focus();
    } else if (
      postal_code_only_numbers !== '' &&
      postal_code_only_numbers.length !== 8
    ) {
      setFormState(formState => ({
        ...formState,
        errors: {
          ...formState.errors,
          postal_code: ['O cep está inválido!'],
        },
      }));

      document.querySelector('#postal_code').focus();
    } else {
      let state = null;
      let city = null;

      if (id_state) {
        try {
          const response = await axios.get(
            `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${id_state}`
          );

          state = response.data;
        } catch (error) {
          console.log(error);
        }
      }

      if (id_city) {
        try {
          const response = await axios.get(
            `https://servicodados.ibge.gov.br/api/v1/localidades/municipios/${id_city}`
          );

          city = response.data;
        } catch (error) {
          console.log(error);
        }
      }

      const data = {
        name,
        phone_number: phone_number_only_numbers,
        street: street || null,
        house_number: house_number || null,
        postal_code: postal_code_only_numbers,
        neighborhood: neighborhood || null,
        id_city: city ? city.id : null,
        city: city ? city.nome : null,
        id_state: state ? state.id : null,
        state_initials: state ? state.sigla : null,
        state: state ? state.nome : null,
      };

      dispatch(setSnackbarType('info'));
      dispatch(setSnackbarMessage('Processando...'));
      dispatch(setSnackbarOpen());

      if (user.document_number.length === 11) {
        try {
          const id_user = getSession();
          const token = localStorage.getItem('token');

          const response = await api.put(
            `/seller/individuals/${id_user}`,
            data,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          setUser(response.data);

          if (response.data) {
            storage.set('user', response.data);
            dispatch(setSnackbarType('success'));
            dispatch(setSnackbarMessage('Alterações feitas com sucesso !'));
            dispatch(setSnackbarOpen());
          }
        } catch (error) {
          dispatch(setSnackbarType('error'));
          dispatch(
            setSnackbarMessage(
              'Não foi possível fazer as alterações, tente novamente mais tarde !'
            )
          );
          dispatch(setSnackbarOpen());
          console.error('Erro ao fazer alterarações', error);
        }
      } else if (user.document_number.length === 14) {
        try {
          const id_user = getSession();
          const token = localStorage.getItem('token');

          const response = await api.put(
            `/seller/businesses/${id_user}`,
            data,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          setUser(response.data);

          if (response.data) {
            storage.set('user', response.data);
            dispatch(setSnackbarType('success'));
            dispatch(setSnackbarMessage('Alterações feitas com sucesso !'));
            dispatch(setSnackbarOpen());
          }
        } catch (error) {
          dispatch(setSnackbarType('error'));
          dispatch(
            setSnackbarMessage(
              'Não foi possível fazer as alterações, tente novamente mais tarde !'
            )
          );
          dispatch(setSnackbarOpen());
          console.error('Erro ao fazer alterarações', error);
        }
      }
    }
  };

  const hasError = field =>
    !!(formState.touched[field] && formState.errors[field]);

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <form onSubmit={handleSubmit}>
        <CardHeader
          subheader="As informações podem ser editadas"
          title="Perfil"
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <TextField
                error={hasError('name')}
                fullWidth
                helperText={hasError('name') ? formState.errors.name[0] : null}
                id="name"
                label="Nome Completo"
                margin="dense"
                name="name"
                onChange={handleChange}
                value={formState.values.name || ''}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                InputProps={{
                  inputComponent: InputMask,
                }}
                error={hasError('phone_number')}
                fullWidth
                helperText={
                  hasError('phone_number')
                    ? formState.errors.phone_number[0]
                    : 'Ex: (11) 3030-3030 ou (11) 99123-4567'
                }
                id="phone_number"
                inputProps={{
                  mask: phoneMask,
                }}
                label="Telefone ou Celular"
                margin="dense"
                name="phone_number"
                onChange={handleChange}
                type="tel"
                value={formState.values.phone_number || ''} // eslint-disable-next-line
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <SelectState
                condition={'true'}
                error={!!formState.errors.state}
                fullWidth
                inputProps={{
                  id: 'state',
                  name: 'state',
                }}
                margin="dense"
                messageError={
                  hasError('state') ? formState.errors.state[0] : null
                }
                onChange={handleChange}
                value={
                  formState.values.state || formState.values.state === 0
                    ? formState.values.state
                    : ''
                }
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <SelectCity
                condition={'true'}
                error={!!formState.errors.city}
                fullWidth
                inputProps={{
                  id: 'city',
                  name: 'city',
                }}
                margin="dense"
                messageError={
                  hasError('city') ? formState.errors.city[0] : null
                }
                onChange={handleChange}
                state={formState.values.state}
                value={
                  formState.values.city || formState.values.city === 0
                    ? formState.values.city
                    : ''
                }
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                error={hasError('neighborhood')}
                fullWidth
                helperText={
                  hasError('neighborhood')
                    ? formState.errors.neighborhood[0]
                    : 'Ex: Jardim Exemplo'
                }
                id="neighborhood"
                label="Bairro"
                margin="dense"
                name="neighborhood"
                onChange={handleChange}
                value={formState.values.neighborhood || ''}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                error={hasError('street')}
                fullWidth
                helperText={
                  hasError('street')
                    ? formState.errors.street[0]
                    : 'Ex: Rua José Antonio'
                }
                id="street"
                label="Endereço"
                margin="dense"
                name="street"
                onChange={handleChange}
                value={formState.values.street || ''}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                error={hasError('house_number')}
                fullWidth
                helperText={
                  hasError('house_number')
                    ? formState.errors.house_number[0]
                    : 'Ex: 123, apenas números'
                }
                id="house_number"
                label="Número"
                margin="dense"
                name="house_number"
                onChange={handleChange}
                type="tel"
                value={formState.values.house_number || ''}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                InputProps={{
                  inputComponent: InputMask,
                }}
                error={hasError('postal_code')}
                fullWidth
                helperText={
                  hasError('postal_code')
                    ? formState.errors.postal_code[0]
                    : 'Ex: (11) 3030-3030 ou (11) 99123-4567'
                }
                id="postal_code"
                inputProps={{
                  mask: postalCodeMask,
                }}
                label="CEP"
                margin="dense"
                name="postal_code"
                onChange={handleChange}
                type="tel"
                value={formState.values.postal_code || ''} // eslint-disable-next-line
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            color="primary"
            disabled={!formState.isValid}
            type="submit"
            variant="contained"
          >
            Salvar Alterações
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

AccountDetails.propTypes = {
  className: PropTypes.string,
};

export default AccountDetails;
