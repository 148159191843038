import React from 'react';

import * as CouponHooks from 'hooks/useCoupon';
import * as InternalComponents from './components';

function CouponsList() {
  const coupon = CouponHooks.useCoupon();
  const { state, listCoupons, pagination } = coupon.state;

  React.useEffect(() => {
    if (state === 'get') {
      coupon.service.getAll({
        offset: pagination?.offset,
        limit: pagination?.limit,
      });
    }
  }, [coupon.service, pagination?.limit, pagination?.offset, state]);

  if (state === 'get') {
    return <InternalComponents.CouponListLoading />;
  } else if (listCoupons.length > 0) {
    return <InternalComponents.CouponListTable />;
  } else if (state === 'failed') {
    return <InternalComponents.CouponFailedState />;
  } else {
    return <InternalComponents.CouponEmptyState />;
  }
}

export default CouponsList;
