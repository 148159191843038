import React from 'react';
import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';
import * as ReactRedux from 'react-redux';
import clsx from 'clsx';
import moment from 'moment';
import type { Breakpoint } from '@material-ui/core/styles/createBreakpoints';

import * as CouponHooks from 'hooks/useCoupon';
import type { ICoupon } from 'types/entities';
import { getDisplayMoney, NumberUtils } from 'helpers';
import { setCouponPagination, setCouponState } from 'store/actions';

import useStyles from './CouponListTable.styles';
import { useAuthContext } from 'hooks/useAuthContext.hook';

function CouponListTable() {
  const theme = Mui.useTheme();
  const { marketplaceUser } = useAuthContext();
  const dispatch = ReactRedux.useDispatch();
  const coupon = CouponHooks.useCoupon();
  const { pagination, listCoupons } = coupon.state;

  const classes = useStyles();

  function getDisplayMobile(
    string: string | null,
    breakpoint: Breakpoint
  ): string | null {
    const width = window.innerWidth;

    if (width < theme.breakpoints.values[breakpoint]) {
      return string;
    }

    return '';
  }

  function getDisplayCouponValue(
    type: ICoupon['valueType'],
    value: number
  ): string {
    switch (type) {
      case 'fixed':
        return getDisplayMoney(value / 100);
      case 'percentage':
        return `${NumberUtils.toPrecision(value * 100, 2)}%`;
      default:
        return '';
    }
  }

  function handlePageChange(_: unknown, page: number) {
    if (!pagination) {
      return;
    }

    let newOffset: number;
    if (page + 1 > pagination.page) {
      newOffset = pagination.offset + pagination.limit;
    } else {
      newOffset = pagination.offset - pagination.limit;
    }

    dispatch([
      setCouponPagination({ ...pagination, offset: newOffset }),
      setCouponState('get'),
    ]);
  }

  function handleRowsPerPageChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (!pagination) {
      return;
    }

    const limit = Number(event.target.value);

    dispatch([
      setCouponPagination({ ...pagination, limit, offset: 0 }),
      setCouponState('get'),
    ]);
  }

  return (
    <Mui.Grid className={classes.container} component={Mui.List} container>
      <Mui.Grid
        className={classes.paginationContainer}
        container
        justifyContent="flex-end"
      >
        <Mui.TablePagination
          SelectProps={{
            classes: {
              icon: classes.icon,
            },
            MenuProps: {
              classes: {
                paper: classes.menuList,
              },
            },
          }}
          backIconButtonText="Voltar página"
          component="div"
          count={pagination?.total || 0}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to === -1 ? count : to} de ${
              count !== -1 ? count : `mais que ${to}`
            }`
          }
          labelRowsPerPage=""
          nextIconButtonText="Próxima página"
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          page={(pagination?.page || 1) - 1}
          rowsPerPage={pagination?.limit || 30}
          rowsPerPageOptions={[30, 50, 100]}
        />
      </Mui.Grid>

      <Mui.ListItem disableGutters>
        <Mui.Grid className={classes.tableCell} item md={2} sm={3} xs={8}>
          <Mui.Typography noWrap variant="h5">
            Código
          </Mui.Typography>
        </Mui.Grid>
        <Mui.Grid
          className={clsx(classes.tableCell, classes.tableTablet)}
          item
          md={2}
          sm={3}
        >
          <Mui.Typography noWrap variant="h5">
            Desconto
          </Mui.Typography>
        </Mui.Grid>
        <Mui.Grid
          className={clsx(classes.tableCell, classes.tableTablet)}
          item
          md={2}
          sm={3}
        >
          <Mui.Typography noWrap variant="h5">
            Uso / Limite de uso
          </Mui.Typography>
        </Mui.Grid>
        <Mui.Grid
          className={clsx(classes.tableCell, classes.tableDesktop)}
          item
          md={2}
        >
          <Mui.Typography noWrap variant="h5">
            Data de início
          </Mui.Typography>
        </Mui.Grid>
        <Mui.Grid
          className={clsx(classes.tableCell, classes.tableDesktop)}
          item
          md={2}
        >
          <Mui.Typography noWrap variant="h5">
            Data de término
          </Mui.Typography>
        </Mui.Grid>
      </Mui.ListItem>

      <Mui.Grid component={Mui.Divider} item xs={12} />

      {listCoupons.map((couponData: ICoupon) => {
        return (
          <React.Fragment key={couponData.id}>
            <Mui.ListItem className={classes.listItem} disableGutters>
              <Mui.Grid className={classes.tableCell} item md={2} sm={3} xs={8}>
                <Mui.ListItemText
                  disableTypography
                  primary={
                    <Mui.Typography noWrap>
                      {couponData.redeemCode}
                    </Mui.Typography>
                  }
                  secondary={
                    <Mui.Grid component="span" container direction="column">
                      <Mui.Grid component="span" item xs zeroMinWidth>
                        <Mui.Typography component="div" noWrap variant="body2">
                          {getDisplayMobile(
                            getDisplayCouponValue(
                              couponData.valueType,
                              couponData.value
                            ),
                            'sm'
                          )}
                        </Mui.Typography>
                      </Mui.Grid>
                      <Mui.Grid component="span" item xs zeroMinWidth>
                        <Mui.Typography component="div" noWrap variant="body2">
                          <strong>
                            {getDisplayMobile(
                              `${couponData.used} / ${couponData.maxUse} vez(es)`,
                              'sm'
                            )}
                          </strong>
                        </Mui.Typography>
                      </Mui.Grid>
                    </Mui.Grid>
                  }
                />
              </Mui.Grid>
              <Mui.Grid
                className={clsx(classes.tableCell, classes.tableTablet)}
                item
                md={2}
                sm={3}
              >
                <Mui.Typography noWrap variant="body1">
                  {getDisplayCouponValue(
                    couponData.valueType,
                    couponData.value
                  )}
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid
                className={clsx(classes.tableCell, classes.tableTablet)}
                item
                md={2}
                sm={3}
              >
                <Mui.Typography noWrap variant="body1">
                  {`${couponData.used} / ${couponData.maxUse} vez(es)`}
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid
                className={clsx(classes.tableCell, classes.tableDesktop)}
                item
                md={2}
              >
                <Mui.Typography noWrap variant="body1">
                  {couponData.dateStart
                    ? moment(couponData.dateStart).format('DD/MM/YYYY')
                    : 'Sem data definida'}
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid
                className={clsx(classes.tableCell, classes.tableDesktop)}
                item
                md={2}
              >
                <Mui.Typography noWrap variant="body1">
                  {couponData.dateEnd
                    ? moment(couponData.dateEnd).format('DD/MM/YYYY')
                    : 'Sem data definida'}
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid
                className={clsx(classes.tableCell, classes.actionsContainer)}
                item
                md={2}
                sm={3}
                xs={4}
              >
                <Mui.IconButton
                  onClick={() => coupon.ui.openToView(couponData)}
                  size={
                    window.innerWidth > theme.breakpoints.values.sm
                      ? 'medium'
                      : 'small'
                  }
                >
                  <MuiIcons.Visibility className={classes.iconButton} />
                </Mui.IconButton>

                {(!marketplaceUser ||
                  marketplaceUser?.roles.includes('owner') ||
                  marketplaceUser?.roles.includes('seller') ||
                  marketplaceUser?.roles.includes('marketing') ||
                  marketplaceUser?.roles.includes('attendants')) && (
                  <Mui.IconButton
                    onClick={() => coupon.ui.openToEdit(couponData)}
                    size={
                      window.innerWidth > theme.breakpoints.values.sm
                        ? 'medium'
                        : 'small'
                    }
                  >
                    <MuiIcons.Edit className={classes.iconButton} />
                  </Mui.IconButton>
                )}
                <Mui.Tooltip title="Em breve">
                  <div>
                    <Mui.IconButton
                      disabled
                      size={
                        window.innerWidth > theme.breakpoints.values.sm
                          ? 'medium'
                          : 'small'
                      }
                    >
                      <MuiIcons.Delete className={classes.iconButton} />
                    </Mui.IconButton>
                  </div>
                </Mui.Tooltip>
              </Mui.Grid>
            </Mui.ListItem>

            <Mui.Grid component={Mui.Divider} item xs={12} />
          </React.Fragment>
        );
      })}

      <Mui.Grid
        className={classes.paginationContainer}
        container
        justifyContent="flex-end"
      >
        <Mui.TablePagination
          SelectProps={{
            classes: {
              icon: classes.icon,
            },
            MenuProps: {
              classes: {
                paper: classes.menuList,
              },
            },
          }}
          backIconButtonText="Voltar página"
          component="div"
          count={pagination?.total || 0}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to === -1 ? count : to} de ${
              count !== -1 ? count : `mais que ${to}`
            }`
          }
          labelRowsPerPage=""
          nextIconButtonText="Próxima página"
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          page={(pagination?.page || 1) - 1}
          rowsPerPage={pagination?.limit || 30}
          rowsPerPageOptions={[30, 50, 100]}
        />
      </Mui.Grid>
    </Mui.Grid>
  );
}

export default CouponListTable;
