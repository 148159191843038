import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { getDisplayDocumentNumber } from 'helpers';

import {
  setDialogOpen,
  setDialogType,
  setDialogFullScreen,
  setDialogMaxWidth,
  setSnackbarMessage,
  setSnackbarOpen,
  setSnackbarType,
  setBankAccountState,
  setBankAccountsList,
  setBankAccount,
} from 'store/actions';

import clsx from 'clsx';

import {
  Typography,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Divider,
  Grid,
  Button,
  Checkbox,
  useMediaQuery,
  CircularProgress,
} from '@material-ui/core';

import { makeStyles, useTheme } from '@material-ui/styles';

import {
  Delete as DeleteIcon,
  Refresh as RefreshIcon,
} from '@material-ui/icons';
import { api } from 'services/api';
import { useAuthContext } from 'hooks/useAuthContext.hook';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(2),
    backgroundColor: theme.palette.background.dark2,
  },
  container: {
    justifyContent: 'center',
    marginTop: theme.spacing(2),
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  containerCheckbox: {
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'flex-end',
    },
  },
  checkbox: {
    padding: 0,
    margin: 'auto',
  },
  tableCell: {
    display: 'flex',
    alignItems: 'center',
    padding: '0px 7px',
  },
  tableDesktop: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  tableTablet: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  iconButton: {
    fontSize: 18,
  },
  options: {
    visibility: 'hidden',
    [theme.breakpoints.down('md')]: {
      visibility: 'visible',
    },
  },
  justifyContentCenter: {
    justifyContent: 'center',
  },
  listItem: {
    '&:hover': {
      backgroundColor: theme.palette.complementary.grey,
    },
  },
  warningButton: {
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      maxWidth: '90%',
      marginLeft: 0,
    },
  },
  containerProgress: {
    marginTop: theme.spacing(2),
    minHeight: 200,
  },
}));

const BankAccountList = () => {
  const classes = useStyles();
  const theme = useTheme();
  const dialog_full_screen = useMediaQuery(theme.breakpoints.down('sm'));

  const dispatch = useDispatch();
  const { user } = useAuthContext();
  const { state, bank_accounts_list } = useSelector(
    state => state.bankAccountState
  );

  useEffect(() => {
    if (state === 'get') {
      async function getBankAccounts() {
        try {
          const token = localStorage.getItem('token');

          const { data: bank_accounts } = await api.get('/bank_account/', {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          dispatch([
            setBankAccountsList(bank_accounts),
            setBankAccountState('success'),
          ]);
        } catch (error) {
          dispatch([
            setSnackbarMessage(
              'Não foi possível fazer a listagem de Contas Bancárias, tente novamente mais tarde !'
            ),
            setSnackbarType('error'),
            setSnackbarOpen(),
            setBankAccountState('failed'),
          ]);
          console.error('Não foi possível buscar a lista de Contas Bancárias');
        }
      }

      getBankAccounts();
    }
  }, [state, dispatch]);

  const handleCreateBankAccount = () => {
    dispatch(setBankAccountState('create'));
    dispatch(setBankAccount({}));
    dispatch(setDialogType('createBankAccount'));
    dispatch(setDialogFullScreen(dialog_full_screen));
    dispatch(setDialogMaxWidth('sm'));
    dispatch(setDialogOpen());
  };

  const handleDeleteBankAccount = bank_account => {
    dispatch(setDialogType('deleteBankAccount'));
    dispatch(setBankAccount(bank_account));
    dispatch(setDialogMaxWidth('sm'));
    dispatch(setDialogFullScreen(false));
    dispatch(setDialogOpen());
    dispatch(setBankAccountState('delete'));
  };

  const handleMouseEnterListItem = event => {
    const width = window.innerWidth;

    if (width > theme.breakpoints.width('md')) {
      const element = event.currentTarget.children[4];
      element.style.visibility = 'visible';
    }
  };

  const handleMouseLeaveListItem = event => {
    const width = window.innerWidth;

    if (width > theme.breakpoints.width('md')) {
      const element = event.currentTarget.children[4];
      element.style.visibility = 'hidden';
    }
  };

  const handleRefresh = () => {
    dispatch([setBankAccountState('get'), setBankAccount({})]);
  };

  const getDisplayBankAccountType = type =>
    type === 'checking' ? 'C/C' : 'C/I';

  const renderBankAccountsList = () => {
    return bank_accounts_list.map(bank_account => {
      return (
        <React.Fragment key={bank_account.id}>
          <ListItem
            className={classes.listItem}
            disableGutters
            onMouseEnter={event => handleMouseEnterListItem(event)}
            onMouseLeave={event => handleMouseLeaveListItem(event)}
          >
            <Grid
              className={clsx(classes.tableCell, classes.containerCheckbox)}
              item
              xs={1}
            >
              <Checkbox className={classes.checkbox} color="primary" />
            </Grid>
            <Grid
              className={classes.tableCell}
              item
              lg={3}
              md={3}
              sm={4}
              xs={9}
            >
              <ListItemText
                disableTypography
                primary={
                  <Typography noWrap variant="h5">
                    {`${bank_account.bank_code} - ${bank_account.bank_name}`}
                  </Typography>
                }
                secondary={
                  <Grid component="span" container direction="column">
                    <Grid component="span" item xs zeroMinWidth>
                      <Typography component="div" noWrap variant="body2">
                        Ag. {bank_account.routing_number}
                      </Typography>
                    </Grid>
                    <Grid component="span" item xs zeroMinWidth>
                      <Typography component="div" noWrap variant="body2">
                        {getDisplayBankAccountType(bank_account.type)}{' '}
                        {bank_account.account_number}
                      </Typography>
                    </Grid>
                  </Grid>
                }
              />
            </Grid>
            <Grid
              className={clsx(classes.tableCell, classes.tableTablet)}
              item
              lg={3}
              md={3}
              sm={5}
            >
              <Typography noWrap variant="h5">
                {user.name}
              </Typography>
            </Grid>
            <Grid
              className={clsx(classes.tableCell, classes.tableDesktop)}
              item
              lg={3}
              md={3}
            >
              <Typography noWrap variant="h5">
                {getDisplayDocumentNumber(user.document_number)}
              </Typography>
            </Grid>
            <Grid
              className={clsx(
                classes.tableCell,
                classes.options,
                classes.justifyContentCenter
              )}
              item
              xs={2}
            >
              <IconButton onClick={() => handleDeleteBankAccount(bank_account)}>
                <DeleteIcon className={classes.iconButton} />
              </IconButton>
            </Grid>
          </ListItem>
        </React.Fragment>
      );
    });
  };

  if (state === 'get') {
    return (
      <Grid
        alignItems="center"
        className={classes.containerProgress}
        container
        justify="center"
        spacing={2}
      >
        <CircularProgress />
      </Grid>
    );
  } else if (bank_accounts_list.length > 0) {
    return (
      <Grid className={classes.root} component={List} container>
        <ListItem disableGutters>
          <Grid
            className={clsx(classes.tableCell, classes.containerCheckbox)}
            item
            xs={1}
          >
            <Checkbox className={classes.checkbox} color="primary" />
          </Grid>
          <Grid className={classes.tableCell} item md={3} sm={4} xs={9}>
            <Typography noWrap variant="h5">
              Banco
            </Typography>
          </Grid>
          <Grid
            className={clsx(classes.tableCell, classes.tableTablet)}
            item
            lg={3}
            md={3}
            sm={5}
          >
            <Typography noWrap variant="h5">
              Titular
            </Typography>
          </Grid>
          <Grid
            className={clsx(classes.tableCell, classes.tableDesktop)}
            item
            lg={3}
            md={3}
          >
            <Typography noWrap variant="h5">
              CPF/CNPJ
            </Typography>
          </Grid>
        </ListItem>

        <Grid component={Divider} item xs={12} />

        {renderBankAccountsList()}
      </Grid>
    );
  } else if (state === 'failed') {
    return (
      <Grid
        alignItems="center"
        className={classes.containerProgress}
        container
        direction="column"
        justify="center"
        spacing={2}
      >
        <Typography variant="body2">
          Não foi possível se conectar ao servidor. Tente novamente...
        </Typography>
        <IconButton onClick={handleRefresh}>
          <RefreshIcon />
        </IconButton>
      </Grid>
    );
  } else {
    return (
      <Grid className={classes.container} container>
        <Typography className={classes.warning} variant="h5">
          Você ainda não possui contas bancárias cadastradas !
        </Typography>
        <Button
          className={classes.warningButton}
          color="secondary"
          disableFocusRipple
          disableRipple
          onClick={handleCreateBankAccount}
          variant="text"
        >
          Cadastre Agora
        </Button>
      </Grid>
    );
  }
};

export default BankAccountList;
