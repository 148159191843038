import * as Mui from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

import { SearchInput } from 'components';
import * as OfferHooks from 'hooks/useOffer';

import useStyles from './OffersToolbar.styles';
import { useAuthContext } from 'hooks/useAuthContext.hook';

type OffersToolbarProps = React.HTMLAttributes<HTMLDivElement>;

const OffersToolbarComponent = (props: OffersToolbarProps) => {
  const { className, ...rest } = props;

  const { marketplaceUser } = useAuthContext();

  const classes = useStyles();
  const offer = OfferHooks.useOffer();

  const [query, setQuery] = React.useState('');

  function handleSearch(event: React.ChangeEvent<HTMLInputElement>) {
    const query = event.target.value;

    setQuery(query);
  }

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      {(!marketplaceUser ||
        marketplaceUser?.roles.includes('owner') ||
        marketplaceUser?.roles.includes('seller') ||
        marketplaceUser?.roles.includes('marketing') ||
        marketplaceUser?.roles.includes('attendants')) && (
        <div className={classes.row}>
          <div className={classes.leftActionsContainer} />
          <span className={classes.spacer} />
          <div className={classes.rightActionsContainer}>
            {/* <Button className={classes.importButton}>Importar</Button> */}
            <Mui.Tooltip title="Em breve">
              <div>
                <Mui.Button className={classes.exportButton} disabled>
                  Exportar
                </Mui.Button>
              </div>
            </Mui.Tooltip>
            <Mui.Button
              className={classes.buttonCreate}
              color="primary"
              onClick={offer.ui.openToCreate}
              variant="contained"
            >
              Adicionar oferta
            </Mui.Button>
          </div>
        </div>
      )}
      <Mui.Tooltip title="Em breve">
        <div className={classes.row}>
          <SearchInput
            className={classes.searchInput}
            disabled
            onChange={handleSearch}
            placeholder="Pesquise uma oferta"
            value={query}
          />
        </div>
      </Mui.Tooltip>
    </div>
  );
};

export default OffersToolbarComponent;
