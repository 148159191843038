import React from 'react';
import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';

import * as TaxInvoiceHooks from 'hooks/use-tax-invoice';

import * as InternalComponent from './components';
import useStyles from './tax-invoices.styles';

function TaxInvoices() {
  const classes = useStyles();

  const taxInvoice = TaxInvoiceHooks.useTaxInvoice();

  return (
    <Mui.Card className={classes.root}>
      <Mui.CardHeader
        action={
          <Mui.IconButton onClick={taxInvoice.ui.refresh}>
            <MuiIcons.Refresh />
          </Mui.IconButton>
        }
        subheader={
          <Mui.Typography color="textSecondary" variant="h5">
            Gerencie suas notas
          </Mui.Typography>
        }
        title={<Mui.Typography variant="h3">Notas Fiscais</Mui.Typography>}
      />
      <Mui.Divider />
      <Mui.CardContent className={classes.cardContent}>
        <InternalComponent.OffersToolbar />
        <InternalComponent.OffersList />
      </Mui.CardContent>
    </Mui.Card>
  );
}

export default TaxInvoices;
