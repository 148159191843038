import type {
  IOfferReducerAction,
  IOfferReducerActionPayload,
  IOfferReducerState,
} from 'types/redux';

export function setOfferState(state: IOfferReducerState['state']) {
  return {
    type: 'OFFER_STATE_CHANGED',
    payload: state,
  };
}

export function setOffer(offer: IOfferReducerState['offer']) {
  return {
    type: 'OFFER_CHANGED',
    payload: offer,
  };
}

export function setListOffers(offers: IOfferReducerState['listOffers']) {
  return {
    type: 'LIST_OFFERS_CHANGED',
    payload: offers,
  };
}

export function setOfferPagination(
  pagination: IOfferReducerState['pagination']
) {
  return {
    type: 'OFFER_PAGINATION_CHANGED',
    payload: pagination,
  } as IOfferReducerAction<
    IOfferReducerActionPayload['OFFER_PAGINATION_CHANGED']
  >;
}
