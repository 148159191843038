import React from 'react';
import * as ReactRedux from 'react-redux';
import validate from 'validate.js';

import * as MarketplaceUserHooks from 'hooks/use-marketplace-user';
import {
  setDialogClose,
  setDialogType,
  setSnackbarMessage,
  setSnackbarOpen,
  setSnackbarType,
} from 'store/actions';
import { setMarketplaceUser } from 'store/actions/marketplace-user-actions';
import type { IMarketplaceUser } from 'types/entities';

interface IFormState {
  disabled: boolean;
  isValid: boolean;
  values: {
    name: IMarketplaceUser['name'];
    email: IMarketplaceUser['email'];
    roles: IMarketplaceUser['roles'];
    documentNumber: IMarketplaceUser['documentNumber'];
    phoneNumber: IMarketplaceUser['phoneNumber'];
    internalMetadata: IMarketplaceUser['internalMetadata'];
    metadata: IMarketplaceUser['metadata'];
  };
  errors: {
    name?: string;
    email?: string;
    roles?: string;
    documentNumber?: string;
    phoneNumber?: string;
    internalMetadata?: string;
    metadata?: string;
  };
  touched: {
    name?: boolean;
    email?: boolean;
    roles?: boolean;
    documentNumber?: boolean;
    phoneNumber?: boolean;
    internalMetadata?: boolean;
    metadata?: boolean;
  };
}

const schema = {
  redeemCode: {
    length: {
      minimum: 6,
      maximum: 14,
      tooShort: '^O código precisa ter no mínimo %{count} caracteres',
      tooLong: '^O código precisa ter no máximo %{count} caracteres',
    },
  },
};

export function useMarketplaceUserEditDialog() {
  const dispatch = ReactRedux.useDispatch();

  const { state, service } = MarketplaceUserHooks.useMarketplaceUser();

  const { marketplaceUser, state: marketplaceUserState } = state;

  const formStateInitialValue = React.useMemo<IFormState>(
    () => ({
      disabled: false,
      isValid: false,
      values: {
        name: marketplaceUser?.name || '',
        email: marketplaceUser?.email || '',
        roles: marketplaceUser?.roles || [],
        documentNumber: marketplaceUser?.documentNumber || '',
        phoneNumber: marketplaceUser?.phoneNumber || '',
        internalMetadata: marketplaceUser?.internalMetadata || null,
        metadata: marketplaceUser?.metadata || null,
      },
      errors: {},
      touched: {},
    }),
    [marketplaceUser]
  );

  const [formState, setFormState] = React.useState<IFormState>(
    formStateInitialValue
  );

  const handleFormChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.persist();

      const fieldName = event.target.name;
      const value = event.target.value as string | number;

      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          [fieldName]: value,
        },
        touched: {
          ...formState.touched,
          [fieldName]: true,
        },
      }));
    },
    []
  );

  const handleMetadataChange = React.useCallback(
    (metadata: Record<string, string | number> | null) => {
      setFormState(prevState => ({
        ...prevState,
        values: {
          ...prevState.values,
          metadata,
        },
      }));
    },
    []
  );

  const hasError = React.useCallback(
    (field: keyof IFormState['values']) => {
      return !!(formState.touched[field] && formState.errors[field]);
    },
    [formState.errors, formState.touched]
  );

  const handleSubmit = React.useCallback(async () => {
    try {
      setFormState(prevState => ({
        ...prevState,
        disabled: true,
        error: {},
      }));

      const fullName = formState.values.name && formState.values.name.trim();
      const nameParts = fullName ? fullName.split(/\s+/) : [];

      const documentNumberOnlyNumbers =
        formState.values.documentNumber &&
        formState.values.documentNumber !== ''
          ? formState.values.documentNumber.replace(/[^0-9]/g, '')
          : '';
      const phoneNumberOnlyNumbers =
        formState.values.phoneNumber && formState.values.phoneNumber !== ''
          ? formState.values.phoneNumber.replace(/[^0-9]/g, '')
          : '';
      const emailRegex = new RegExp(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );

      if (nameParts.length < 2 || nameParts.some(part => part.length < 2)) {
        setFormState(prevState => ({
          ...prevState,
          errors: {
            ...prevState.errors,
            name: 'Insira o nome e sobrenome!',
          },
        }));

        handleInputFocus('name');
        return;
      }

      if (
        documentNumberOnlyNumbers === '' ||
        (documentNumberOnlyNumbers.length !== 11 &&
          documentNumberOnlyNumbers.length !== 14)
      ) {
        setFormState(prevState => ({
          ...prevState,
          errors: {
            ...prevState.errors,
            documentNumber: 'O documento está inválido!',
          },
        }));
        handleInputFocus('documentNumber');
        return;
      }

      if (
        formState.values.email === '' ||
        !emailRegex.test(formState.values.email)
      ) {
        setFormState(prevState => ({
          ...prevState,
          errors: {
            ...prevState.errors,
            email: 'O e-mail está inválido!',
          },
        }));

        handleInputFocus('email');
        return;
      }

      if (
        phoneNumberOnlyNumbers === '' ||
        (phoneNumberOnlyNumbers.length !== 11 &&
          phoneNumberOnlyNumbers.length !== 10)
      ) {
        setFormState(prevState => ({
          ...prevState,
          errors: {
            ...prevState.errors,
            phoneNumber: 'O número de telefone está inválido!',
          },
        }));

        handleInputFocus('phoneNumber');
        return;
      }

      if (formState.values.roles.length === 0) {
        setFormState(prevState => ({
          ...prevState,
          errors: {
            ...prevState.errors,
            roles: 'Pelo menos um cargo deve ser selecionado!',
          },
        }));

        return;
      }

      if (marketplaceUserState === 'create') {
        const marketplaceData = await service.create({
          name: formState.values.name,
          email: formState.values.email,
          roles: formState.values.roles,
          documentNumber: documentNumberOnlyNumbers,
          phoneNumber: phoneNumberOnlyNumbers,
          internalMetadata: formState.values.internalMetadata,
          metadata: formState.values.metadata,
        });

        await service.sendEmail({ id: marketplaceData.id });
      }

      if (marketplaceUserState === 'edit') {
        await service.edit({
          id: marketplaceUser!.id,
          name: formState.values.name,
          roles: formState.values.roles,
          phoneNumber: phoneNumberOnlyNumbers,
          internalMetadata: formState.values.internalMetadata,
          metadata: formState.values.metadata,
        });
      }

      dispatch([setDialogClose(), setDialogType(null)]);
    } catch (error) {
      console.log(error);
      dispatch([
        setSnackbarMessage(
          'Não foi possível criar ou editar este usuário, tente novamente mais tarde!'
        ),
        setSnackbarType('error'),
        setSnackbarOpen(),
      ]);
    } finally {
      setFormState(prevState => ({
        ...prevState,
        disabled: false,
      }));
    }
  }, [
    dispatch,
    formState.values,
    marketplaceUser,
    marketplaceUserState,
    service,
  ]);

  const handleDialogClose = React.useCallback(() => {
    dispatch([setMarketplaceUser(null), setDialogClose(), setDialogType(null)]);
  }, [dispatch]);

  function handleInputFocus(inputName: keyof IFormState['values']) {
    const inputElement = document.querySelector(
      `#${inputName}`
    ) as HTMLInputElement;

    inputElement.focus();
  }

  React.useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: !errors,
      errors: errors || {},
    }));
  }, [formState.values]);

  return React.useMemo(
    () => ({
      marketplaceUser,
      marketplaceUserState,
      formState,
      setFormState,
      handleDialogClose,
      handleFormChange,
      handleMetadataChange,
      handleSubmit,
      hasError,
    }),
    [
      marketplaceUser,
      marketplaceUserState,
      formState,
      setFormState,
      handleDialogClose,
      handleFormChange,
      handleMetadataChange,
      handleSubmit,
      hasError,
    ]
  );
}
