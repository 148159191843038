import type {
  ICustomerReducerAction,
  ICustomerReducerActionPayload,
  ICustomerReducerState,
} from 'types/redux';

export function setCustomerState(state: ICustomerReducerState['state']) {
  return {
    type: 'CUSTOMER_STATE_CHANGED',
    payload: state,
  } as ICustomerReducerAction<
    ICustomerReducerActionPayload['CUSTOMER_STATE_CHANGED']
  >;
}

export function setCustomer(customer: ICustomerReducerState['customer']) {
  return {
    type: 'CUSTOMER_CHANGED',
    payload: customer,
  } as ICustomerReducerAction<
    ICustomerReducerActionPayload['CUSTOMER_CHANGED']
  >;
}

export function setListCustomers(
  customers: ICustomerReducerState['listCustomers']
) {
  return {
    type: 'LIST_CUSTOMERS_CHANGED',
    payload: customers,
  } as ICustomerReducerAction<
    ICustomerReducerActionPayload['LIST_CUSTOMERS_CHANGED']
  >;
}

export function setListCustomersFiltered(
  customers: ICustomerReducerState['listCustomersFiltered']
) {
  return {
    type: 'LIST_CUSTOMERS_FILTERED_CHANGED',
    payload: customers,
  } as ICustomerReducerAction<
    ICustomerReducerActionPayload['LIST_CUSTOMERS_FILTERED_CHANGED']
  >;
}

export function setListCustomersSelected(
  customers: ICustomerReducerState['listCustomersSelected']
) {
  return {
    type: 'LIST_CUSTOMERS_SELECTED_CHANGED',
    payload: customers,
  } as ICustomerReducerAction<
    ICustomerReducerActionPayload['LIST_CUSTOMERS_SELECTED_CHANGED']
  >;
}

export function setCustomerPagination(
  pagination: ICustomerReducerState['pagination']
) {
  return {
    type: 'CUSTOMER_PAGINATION_CHANGED',
    payload: pagination,
  } as ICustomerReducerAction<
    ICustomerReducerActionPayload['CUSTOMER_PAGINATION_CHANGED']
  >;
}

export function setCustomerListType(
  listType: ICustomerReducerState['listType']
) {
  return {
    type: 'LIST_TYPE_CHANGED',
    payload: listType,
  } as ICustomerReducerAction<
    ICustomerReducerActionPayload['LIST_TYPE_CHANGED']
  >;
}

export function setCustomerFilter(
  filter: Partial<ICustomerReducerState['filter']>
) {
  return {
    type: 'CUSTOMER_FILTER_CHANGED',
    payload: filter,
  } as ICustomerReducerAction<
    ICustomerReducerActionPayload['CUSTOMER_FILTER_CHANGED']
  >;
}
