import type { IPlanReducerAction, IPlanReducerState } from 'types/redux';

const INITIAL_STATE: IPlanReducerState = {
  state: 'get',
  plan: null,
  listPlans: [],
  pagination: null,
};

export default (state = INITIAL_STATE, action: IPlanReducerAction<unknown>) => {
  switch (action.type) {
    case 'PLAN_STATE_CHANGED':
      return {
        ...state,
        state: action.payload,
      };
    case 'PLAN_CHANGED':
      return {
        ...state,
        plan: action.payload,
      };
    case 'LIST_PLANS_CHANGED':
      return {
        ...state,
        listPlans: action.payload,
      };
    case 'PLAN_PAGINATION_CHANGED':
      return {
        ...state,
        pagination: action.payload,
      };
    default:
      return state;
  }
};
