import type {
  ICheckoutReducerAction,
  ICheckoutReducerState,
} from 'types/redux';

const INITIAL_STATE: ICheckoutReducerState = {
  state: 'get',
  checkout: null,
  listCheckouts: [],
  pagination: null,
};

export default (
  state = INITIAL_STATE,
  action: ICheckoutReducerAction<unknown>
) => {
  switch (action.type) {
    case 'CHECKOUT_STATE_CHANGED':
      return {
        ...state,
        state: action.payload,
      };
    case 'CHECKOUT_CHANGED':
      return {
        ...state,
        checkout: action.payload,
      };
    case 'LIST_CHECKOUTS_CHANGED':
      return {
        ...state,
        listCheckouts: action.payload,
      };
    case 'CHECKOUT_PAGINATION_CHANGED':
      return {
        ...state,
        pagination: action.payload,
      };
    default:
      return state;
  }
};
