import React from 'react';
import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';

import * as BuyerHooks from 'hooks/useBuyer';

import useStyles from './CustomerFailedState.styles';

function CustomerFailedState() {
  const classes = useStyles();

  const buyer = BuyerHooks.useBuyer();

  return (
    <Mui.Grid
      alignItems="center"
      className={classes.container}
      container
      direction="column"
      justify="center"
      spacing={2}
    >
      <Mui.Typography variant="body2">
        Não foi possível se conectar ao servidor. Tente novamente...
      </Mui.Typography>
      <Mui.IconButton onClick={buyer.ui.refresh}>
        <MuiIcons.Refresh />
      </Mui.IconButton>
    </Mui.Grid>
  );
}

export default CustomerFailedState;
