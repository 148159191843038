import type { IOffer } from '../types/entities';

export class OfferUtils {
  public static statusLabelMap: Record<IOffer['status'], string> = {
    active: 'Ativado',
    disabled: 'Desativado',
  };

  public static getStatusLabel(status: IOffer['status']): string {
    return this.statusLabelMap[status] || '';
  }
}
