import React from 'react';
import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';
import * as ReactRedux from 'react-redux';
import clsx from 'clsx';
import moment from 'moment';
import type { Breakpoint } from '@material-ui/core/styles/createBreakpoints';

import * as OfferHooks from 'hooks/useOffer';
import type { IOffer } from 'types/entities';
import { getDisplayMoney } from 'helpers';
import { setOfferPagination, setOfferState } from 'store/actions';

import useStyles from './OfferListTable.styles';
import { useAuthContext } from 'hooks/useAuthContext.hook';

const offerStatusMap: Record<IOffer['status'], string> = {
  active: 'Ativado',
  disabled: 'Desativado',
};

function OfferListTable() {
  const theme = Mui.useTheme();
  const { marketplaceUser } = useAuthContext();
  const dispatch = ReactRedux.useDispatch();
  const offer = OfferHooks.useOffer();
  const { pagination, listOffers } = offer.state;

  const classes = useStyles();

  function getDisplayMobile(string: string | null, breakpoint: Breakpoint) {
    const width = window.innerWidth;

    if (width < theme.breakpoints.values[breakpoint]) {
      return string;
    }

    return '';
  }

  function handlePageChange(_: unknown, page: number) {
    if (!pagination) {
      return;
    }

    let newOffset: number;
    if (page + 1 > pagination.page) {
      newOffset = pagination.offset + pagination.limit;
    } else {
      newOffset = pagination.offset - pagination.limit;
    }

    dispatch([
      setOfferPagination({ ...pagination, offset: newOffset }),
      setOfferState('get'),
    ]);
  }

  function handleRowsPerPageChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (!pagination) {
      return;
    }

    const limit = Number(event.target.value);

    dispatch([
      setOfferPagination({ ...pagination, limit, offset: 0 }),
      setOfferState('get'),
    ]);
  }

  return (
    <Mui.Grid className={classes.container} component={Mui.List} container>
      <Mui.Grid
        className={classes.paginationContainer}
        container
        justifyContent="flex-end"
      >
        <Mui.TablePagination
          SelectProps={{
            classes: {
              icon: classes.icon,
            },
            MenuProps: {
              classes: {
                paper: classes.menuList,
              },
            },
          }}
          backIconButtonText="Voltar página"
          component="div"
          count={pagination?.total || 0}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to === -1 ? count : to} de ${
              count !== -1 ? count : `mais que ${to}`
            }`
          }
          labelRowsPerPage=""
          nextIconButtonText="Próxima página"
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          page={(pagination?.page || 1) - 1}
          rowsPerPage={pagination?.limit || 30}
          rowsPerPageOptions={[30, 50, 100]}
        />
      </Mui.Grid>

      <Mui.ListItem disableGutters>
        <Mui.Grid className={classes.tableCell} item md={2} sm={5} xs={8}>
          <Mui.Typography noWrap variant="h5">
            Nome
          </Mui.Typography>
        </Mui.Grid>
        <Mui.Grid
          className={clsx(classes.tableCell, classes.tableTablet)}
          item
          md={2}
          sm={2}
        >
          <Mui.Typography noWrap variant="h5">
            Valor
          </Mui.Typography>
        </Mui.Grid>
        <Mui.Grid
          className={clsx(classes.tableCell, classes.tableTablet)}
          item
          md={2}
          sm={2}
        >
          <Mui.Typography noWrap variant="h5">
            Status
          </Mui.Typography>
        </Mui.Grid>
        <Mui.Grid
          className={clsx(classes.tableCell, classes.tableDesktop)}
          item
          md={2}
        >
          <Mui.Typography noWrap variant="h5">
            Data de criação
          </Mui.Typography>
        </Mui.Grid>
        <Mui.Grid
          className={clsx(classes.tableCell, classes.tableDesktop)}
          item
          md={2}
        >
          <Mui.Typography noWrap variant="h5">
            Última atualização
          </Mui.Typography>
        </Mui.Grid>
      </Mui.ListItem>

      <Mui.Grid component={Mui.Divider} item xs={12} />

      {listOffers.map((offerData: IOffer) => {
        return (
          <React.Fragment key={offerData.id}>
            <Mui.ListItem className={classes.listItem} disableGutters>
              <Mui.Grid className={classes.tableCell} item md={2} sm={5} xs={8}>
                <Mui.ListItemText
                  disableTypography
                  primary={
                    <Mui.Typography noWrap>{offerData.name}</Mui.Typography>
                  }
                  secondary={
                    <Mui.Grid component="span" container direction="column">
                      <Mui.Grid component="span" item xs zeroMinWidth>
                        <Mui.Typography component="div" noWrap variant="body2">
                          {getDisplayMobile(
                            getDisplayMoney(offerData.amount / 100),
                            'sm'
                          )}
                        </Mui.Typography>
                      </Mui.Grid>
                      <Mui.Grid component="span" item xs zeroMinWidth>
                        <Mui.Typography component="div" noWrap variant="body2">
                          {getDisplayMobile(
                            offerStatusMap[offerData.status],
                            'sm'
                          )}
                        </Mui.Typography>
                      </Mui.Grid>
                    </Mui.Grid>
                  }
                />
              </Mui.Grid>
              <Mui.Grid
                className={clsx(classes.tableCell, classes.tableTablet)}
                item
                md={2}
                sm={2}
              >
                <Mui.Typography noWrap variant="body1">
                  {getDisplayMoney(offerData.amount / 100)}
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid
                className={clsx(classes.tableCell, classes.tableTablet)}
                item
                md={2}
                sm={2}
              >
                <Mui.Typography noWrap variant="body1">
                  {offerStatusMap[offerData.status]}
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid
                className={clsx(classes.tableCell, classes.tableDesktop)}
                item
                md={2}
                sm={5}
                xs={7}
              >
                <Mui.ListItemText
                  disableTypography
                  primary={
                    <Mui.Typography noWrap>
                      {moment(offerData.createdAt).format('DD/MM/YYYY')}
                    </Mui.Typography>
                  }
                  secondary={
                    <Mui.Typography noWrap>
                      {moment(offerData.createdAt).format('HH:mm:ss')}
                    </Mui.Typography>
                  }
                />
              </Mui.Grid>
              <Mui.Grid
                className={clsx(classes.tableCell, classes.tableDesktop)}
                item
                md={2}
                sm={5}
                xs={7}
              >
                <Mui.ListItemText
                  disableTypography
                  primary={
                    <Mui.Typography noWrap>
                      {moment(offerData.updatedAt).format('DD/MM/YYYY')}
                    </Mui.Typography>
                  }
                  secondary={
                    <Mui.Typography noWrap>
                      {moment(offerData.updatedAt).format('HH:mm:ss')}
                    </Mui.Typography>
                  }
                />
              </Mui.Grid>
              <Mui.Grid
                className={clsx(classes.tableCell)}
                item
                md={1}
                sm={3}
                xs={4}
              >
                <Mui.Tooltip title="Em breve">
                  <div>
                    <Mui.IconButton
                      className={classes.buttonEdit}
                      disabled
                      onClick={() => offer.ui.openToView(offerData)}
                      size={
                        window.innerWidth > theme.breakpoints.values.sm
                          ? 'medium'
                          : 'small'
                      }
                    >
                      <MuiIcons.Visibility className={classes.iconButton} />
                    </Mui.IconButton>
                  </div>
                </Mui.Tooltip>
                {(!marketplaceUser ||
                  marketplaceUser?.roles.includes('owner') ||
                  marketplaceUser?.roles.includes('seller') ||
                  marketplaceUser?.roles.includes('marketing') ||
                  marketplaceUser?.roles.includes('attendants')) && (
                  <Mui.IconButton
                    className={classes.buttonEdit}
                    onClick={() => offer.ui.openToEdit(offerData)}
                    size={
                      window.innerWidth > theme.breakpoints.values.sm
                        ? 'medium'
                        : 'small'
                    }
                  >
                    <MuiIcons.Edit className={classes.iconButton} />
                  </Mui.IconButton>
                )}
                <Mui.Tooltip title="Em breve">
                  <div>
                    <Mui.IconButton
                      disabled
                      onClick={() => offer.ui.openToDelete(offerData)}
                      size={
                        window.innerWidth > theme.breakpoints.values.sm
                          ? 'medium'
                          : 'small'
                      }
                    >
                      <MuiIcons.Delete className={classes.iconButton} />
                    </Mui.IconButton>
                  </div>
                </Mui.Tooltip>
              </Mui.Grid>
            </Mui.ListItem>

            <Mui.Grid component={Mui.Divider} item xs={12} />
          </React.Fragment>
        );
      })}

      <Mui.Grid
        className={classes.paginationContainer}
        container
        justifyContent="flex-end"
      >
        <Mui.TablePagination
          SelectProps={{
            classes: {
              icon: classes.icon,
            },
            MenuProps: {
              classes: {
                paper: classes.menuList,
              },
            },
          }}
          backIconButtonText="Voltar página"
          component="div"
          count={pagination?.total || 0}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to === -1 ? count : to} de ${
              count !== -1 ? count : `mais que ${to}`
            }`
          }
          labelRowsPerPage=""
          nextIconButtonText="Próxima página"
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          page={(pagination?.page || 1) - 1}
          rowsPerPage={pagination?.limit || 30}
          rowsPerPageOptions={[30, 50, 100]}
        />
      </Mui.Grid>
    </Mui.Grid>
  );
}

export default OfferListTable;
