import React from 'react';

import {
  AppBar,
  Button,
  DialogActions,
  Grid,
  IconButton,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { useMarketplaceUserDeleteDialog } from './marketplace-user-delete-dialog.hook';

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
    paddingRight: '0px !important',
    backgroundColor: theme.palette.error.light,
  },
  title: {
    flex: 1,
    color: '#fff',
  },
  container: {
    padding: theme.spacing(2),
    width: 450,
    maxWidth: '100%',
  },
  button: {
    borderColor: theme.palette.white,
  },
}));

const CustomerDeleteDialog = () => {
  const classes = useStyles();
  const { buttonDisable, handleDialogClose, handleMarketplaceUserDelete } =
    useMarketplaceUserDeleteDialog();
  return (
    <React.Fragment>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Typography className={classes.title} variant="h5">
            Confirmar ação
          </Typography>
          <IconButton
            aria-label="Fechar"
            color="inherit"
            edge="start"
            onClick={handleDialogClose}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Grid className={classes.container} container>
        <Typography variant="body1">
          Você deseja realmente realizar está ação?
        </Typography>
        <Typography variant="body2">
          Lembrando que essas ações não podem ser revertidas!
        </Typography>
      </Grid>
      <DialogActions>
        <Button
          className={classes.button}
          onClick={handleDialogClose}
          variant="outlined"
        >
          Cancelar
        </Button>
        <Button
          className={classes.button}
          disabled={buttonDisable}
          onClick={() => handleMarketplaceUserDelete()}
          variant="outlined"
        >
          OK
        </Button>
      </DialogActions>
    </React.Fragment>
  );
};

export default CustomerDeleteDialog;
