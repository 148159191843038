import React from 'react';

import { Doughnut } from 'react-chartjs-2';

import clsx from 'clsx';

import PropTypes from 'prop-types';

import { makeStyles, useTheme } from '@material-ui/styles';

import { Skeleton } from '@material-ui/lab';

import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core';

import {
  AttachMoney as MoneyIcon,
  AccessTime as AccessTimeIcon,
  MoneyOff as MoneyOffIcon,
  Refresh as RefreshIcon,
} from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    backgroundColor: theme.palette.background.dark2,
  },
  chartContainer: {
    position: 'relative',
    height: '300px',
    display: 'flex',
  },
  stats: {
    marginTop: theme.spacing(2),
  },
  receivable: {
    textAlign: 'center',
    padding: theme.spacing(1),
  },
  receivableIcon: {
    color: theme.palette.icon,
  },
  skeletonChart: {
    width: 250,
    height: 250,
    margin: 'auto',
  },
  skeletonLabel: {
    margin: 'auto',
    transformOrigin: '0px 0px',
  },
}));

const SalesByStatus = props => {
  const {
    sales_succeeded,
    sales_pending,
    sales_failed,
    sales_refunded,
    dashboardState,
  } = props;

  const classes = useStyles();
  const theme = useTheme();

  const data = {
    datasets: [
      {
        data: [
          sales_succeeded || 0,
          sales_pending || 0,
          sales_failed || 0,
          sales_refunded || 0,
        ],
        backgroundColor: [
          theme.palette.success.main,
          theme.palette.warning.main,
          theme.palette.error.main,
          theme.palette.info.main,
        ],
        borderWidth: 6,
        borderColor: theme.palette.background.dark2,
        hoverBorderColor: theme.palette.background.dark2,
      },
    ],
    labels: ['Pagas', 'Pendentes', 'Canceladas', 'Reembolsadas'],
  };

  const options = {
    legend: {
      display: false,
    },
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    cutoutPercentage: 80,
    layout: {
      padding: 0,
    },
    tooltips: {
      enabled: true,
      mode: 'index',
      intersect: false,
      borderWidth: 2,
      borderColor: theme.palette.primary.main,
      backgroundColor: theme.palette.background.dark2,
      titleFontColor: theme.palette.text.primary,
      bodyFontColor: theme.palette.text.primary,
      footerFontColor: theme.palette.text.primary,
    },
  };

  const receivables = [
    {
      title: 'Pagas',
      value: sales_succeeded || 0,
      icon: <MoneyIcon />,
      color: theme.palette.success.main,
    },
    {
      title: 'Pendentes',
      value: sales_pending || 0,
      icon: <AccessTimeIcon />,
      color: theme.palette.warning.main,
    },
    {
      title: 'Canceladas',
      value: sales_failed || 0,
      icon: <MoneyOffIcon />,
      color: theme.palette.error.main,
    },
    {
      title: 'Reembolsadas',
      value: sales_refunded || 0,
      icon: <RefreshIcon />,
      color: theme.palette.info.main,
    },
  ];

  return (
    <Card className={clsx(classes.root)}>
      <CardHeader title="Pagamentos por status" />
      <Divider />
      <CardContent>
        <div className={classes.chartContainer}>
          {dashboardState === 'get' ? (
            <Skeleton
              animation="wave"
              className={classes.skeletonChart}
              variant="circle"
            />
          ) : (
            <Doughnut data={data} options={options} />
          )}
        </div>
        <Grid className={classes.stats} container spacing={2}>
          {receivables.map(receivable => (
            <Grid
              className={classes.receivable}
              item
              key={receivable.title}
              xs={6}
            >
              <span className={classes.receivableIcon}>{receivable.icon}</span>
              <Typography variant="body1">{receivable.title}</Typography>
              {dashboardState === 'get' ? (
                <Skeleton
                  animation="wave"
                  className={classes.skeletonLabel}
                  height={60}
                  variant="text"
                  width={45}
                />
              ) : (
                <Typography
                  style={{
                    color: receivable.color,
                  }}
                  variant="h2"
                >
                  {receivable.value}
                </Typography>
              )}
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

SalesByStatus.propTypes = {
  className: PropTypes.string,
};

export default SalesByStatus;
