import React from 'react';

import clsx from 'clsx';

import PropTypes from 'prop-types';

import { getDisplayMoney } from 'helpers';

import { makeStyles } from '@material-ui/styles';

import { Card, CardContent, Grid, Typography } from '@material-ui/core';

import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.primary.main,
  },
  colorGrey: {
    color: '#fff',
  },
}));

const TotalFutureTransfers = props => {
  const { amount, stateRequest } = props;

  const classes = useStyles();

  return (
    <Card className={clsx(classes.root)}>
      <CardContent>
        <Grid alignItems="center" container direction="column" justify="center">
          <Typography
            className={clsx(classes.colorGrey)}
            gutterBottom
            variant="h6"
          >
            Total de lançamentos futuros
          </Typography>
          {stateRequest === 'get' ? (
            <Skeleton
              animation="pulse"
              height={32}
              variant="text"
              width="40%"
            />
          ) : (
            <Typography className={clsx(classes.colorGrey)} variant="h5">
              {getDisplayMoney(amount / 100)}
            </Typography>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

TotalFutureTransfers.propTypes = {
  amount: PropTypes.number.isRequired,
  className: PropTypes.string,
  stateRequest: PropTypes.string.isRequired,
};

export default TotalFutureTransfers;
