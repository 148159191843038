import * as Mui from '@material-ui/core';
import React from 'react';

import { InputCurrency } from 'components';
import { CreditUtils } from 'helpers/credit-utils';
import type { ITransaction } from 'types/entities';
import type { IFormState } from '../../CheckoutEditDialog.hook';
import useStyles from './checkout-edit-credit.styles';

interface ICheckoutEditCreditProps {
  formState: IFormState;
  handleCreditChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleDiscountValueChange: (
    paymentType: ITransaction['payment_type'],
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;

  hasCreditError: (field: keyof IFormState['values']['credit']) => boolean;
}

export function CheckoutEditCredit(params: ICheckoutEditCreditProps) {
  const {
    formState,
    handleCreditChange,
    handleDiscountValueChange,
    hasCreditError,
  } = params;
  const classes = useStyles();

  return (
    <Mui.Grid container item spacing={2} xs={12}>
      <Mui.Grid item xs={12}>
        <Mui.Typography variant="h4">Cartão de crédito</Mui.Typography>
      </Mui.Grid>

      <Mui.Grid item md={4} sm={6} xs={12}>
        <Mui.TextField
          InputLabelProps={{
            classes: {
              disabled: classes.selectDisabled,
            },
          }}
          SelectProps={{
            classes: {
              icon: classes.selectIcon,
              disabled: classes.selectDisabled,
            },
            MenuProps: {
              classes: {
                paper: classes.selectMenuList,
              },
            },
          }}
          error={hasCreditError('discountType')}
          fullWidth
          helperText={
            hasCreditError('discountType') &&
            formState.errors.credit?.discountType
          }
          label="Tipo de desconto"
          name="credit-discountType"
          onChange={handleCreditChange}
          select
          value={formState.values.credit.discountType}
          variant="outlined"
        >
          {Object.entries(CreditUtils.discountTypeLabelMap).map(
            ([value, label]) => {
              return (
                <Mui.MenuItem
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  ListItemClasses={{
                    root: classes.selectMenuItem,
                    selected: classes.selectItemSelected,
                  }}
                  button
                  key={value}
                  selected={value === formState.values.credit.discountType}
                  value={value}
                >
                  {label}
                </Mui.MenuItem>
              );
            }
          )}
        </Mui.TextField>
      </Mui.Grid>

      <Mui.Grid item md={4} sm={6} xs={12}>
        {formState.values.credit.discountType === 'fixed' ? (
          <InputCurrency
            error={hasCreditError('discount')}
            fullWidth
            helperText={
              hasCreditError('discount') && formState.errors.credit?.discount
            }
            id="credit-discount"
            label="Valor do desconto"
            name="credit-discount"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleDiscountValueChange('credit', event)
            }
            type="tel"
            value={formState.values.credit.discount}
            variant="outlined"
          />
        ) : (
          <Mui.TextField
            error={hasCreditError('discount')}
            fullWidth
            helperText={
              hasCreditError('discount') && formState.errors.credit?.discount
            }
            id="credit-discount"
            label="Valor do desconto"
            name="credit-discount"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleDiscountValueChange('credit', event)
            }
            type="tel"
            value={formState.values.credit.discount}
            variant="outlined"
          />
        )}
      </Mui.Grid>

      <Mui.Grid item md={4} sm={6} xs={12}>
        <Mui.TextField
          InputLabelProps={{
            classes: {
              disabled: classes.selectDisabled,
            },
          }}
          SelectProps={{
            classes: {
              icon: classes.selectIcon,
              disabled: classes.selectDisabled,
            },
            MenuProps: {
              classes: {
                paper: classes.selectMenuList,
              },
            },
          }}
          error={hasCreditError('isRecoverSaleEnabled')}
          fullWidth
          helperText={
            hasCreditError('isRecoverSaleEnabled') &&
            formState.errors.credit?.isRecoverSaleEnabled
          }
          label="Aceitar recuperação de vendas"
          name="credit-isRecoverSaleEnabled"
          onChange={handleCreditChange}
          select
          value={formState.values.credit.isRecoverSaleEnabled}
          variant="outlined"
        >
          <Mui.MenuItem button value="true">
            SIM
          </Mui.MenuItem>
          <Mui.MenuItem button value="false">
            NÃO
          </Mui.MenuItem>
        </Mui.TextField>
      </Mui.Grid>

      {formState.values.credit.isRecoverSaleEnabled.toString() === 'true' && (
        <Mui.Grid item md={4} sm={6} xs={12}>
          <InputCurrency
            error={hasCreditError('recoverSaleAmount')}
            fullWidth
            helperText={
              hasCreditError('recoverSaleAmount') &&
              formState.errors.credit?.recoverSaleAmount
            }
            id="credit-recoverSaleAmount"
            label="Valor de recuperação de vendas"
            name="credit-recoverSaleAmount"
            onChange={handleCreditChange}
            type="tel"
            value={formState.values.credit.recoverSaleAmount}
            variant="outlined"
          />
        </Mui.Grid>
      )}

      <Mui.Grid item md={4} sm={6} xs={12}>
        <Mui.TextField
          InputLabelProps={{
            classes: {
              disabled: classes.selectDisabled,
            },
          }}
          SelectProps={{
            classes: {
              icon: classes.selectIcon,
              disabled: classes.selectDisabled,
            },
            MenuProps: {
              classes: {
                paper: classes.selectMenuList,
              },
            },
          }}
          error={hasCreditError('isInstallmentsEnabled')}
          fullWidth
          helperText={
            hasCreditError('isInstallmentsEnabled') &&
            formState.errors.credit?.isInstallmentsEnabled
          }
          label="Aceitar parcelamento"
          name="credit-isInstallmentsEnabled"
          onChange={handleCreditChange}
          select
          value={formState.values.credit.isInstallmentsEnabled}
          variant="outlined"
        >
          <Mui.MenuItem button value="true">
            SIM
          </Mui.MenuItem>
          <Mui.MenuItem button value="false">
            NÃO
          </Mui.MenuItem>
        </Mui.TextField>
      </Mui.Grid>

      {formState.values.credit.isInstallmentsEnabled && (
        <>
          <Mui.Grid item md={4} sm={6} xs={12}>
            <Mui.TextField
              error={hasCreditError('defaultInstallments')}
              fullWidth
              helperText={
                hasCreditError('defaultInstallments') &&
                formState.errors.credit?.defaultInstallments
              }
              id="credit-defaultInstallments"
              label="Parcelas padrão"
              name="credit-defaultInstallments"
              onChange={handleCreditChange}
              type="number"
              value={formState.values.credit.defaultInstallments || ''}
              variant="outlined"
            />
          </Mui.Grid>

          <Mui.Grid item md={4} sm={6} xs={12}>
            <Mui.TextField
              error={hasCreditError('minInstallments')}
              fullWidth
              helperText={
                hasCreditError('minInstallments') &&
                formState.errors.credit?.minInstallments
              }
              id="credit-minInstallments"
              label="Mínimo de parcelas"
              name="credit-minInstallments"
              onChange={handleCreditChange}
              type="number"
              value={formState.values.credit.minInstallments || ''}
              variant="outlined"
            />
          </Mui.Grid>

          <Mui.Grid item md={4} sm={6} xs={12}>
            <Mui.TextField
              error={hasCreditError('maxInstallments')}
              fullWidth
              helperText={
                hasCreditError('maxInstallments') &&
                formState.errors.credit?.maxInstallments
              }
              id="credit-maxInstallments"
              label="Máximo de parcelas"
              name="credit-maxInstallments"
              onChange={handleCreditChange}
              type="number"
              value={formState.values.credit.maxInstallments || ''}
              variant="outlined"
            />
          </Mui.Grid>
        </>
      )}
    </Mui.Grid>
  );
}
