import React from 'react';
import * as ReactRedux from 'react-redux';

import type { IBuyer } from 'types/entities';
import {
  setCustomer,
  setCustomerFilter,
  setCustomerListType,
  setCustomerState,
  setDialogFullScreen,
  setDialogMaxWidth,
  setDialogOpen,
  setDialogType,
  setListCustomers,
  setListCustomersFiltered,
  setListCustomersSelected,
} from 'store/actions';

function useBuyerUI() {
  const dispatch = ReactRedux.useDispatch();

  const openToCreate = React.useCallback(() => {
    dispatch([
      setCustomer(null),
      setCustomerState('create'),
      setDialogType('createCustomer'),
      setDialogFullScreen(true),
      setDialogOpen(),
    ]);
  }, [dispatch]);

  const openToEdit = React.useCallback(
    (customer: IBuyer) => {
      dispatch([
        setCustomer(customer),
        setCustomerState('edit'),
        setDialogType('editCustomer'),
        setDialogFullScreen(true),
        setDialogOpen(),
      ]);
    },
    [dispatch]
  );

  const openToDelete = React.useCallback(
    (customer: IBuyer) => {
      dispatch([
        setCustomer(customer),
        setCustomerState('delete'),
        setDialogType('deleteCustomer'),
        setDialogMaxWidth('sm'),
        setDialogFullScreen(false),
        setDialogOpen(),
      ]);
    },
    [dispatch]
  );

  const refresh = React.useCallback(() => {
    dispatch([
      setCustomer(null),
      setListCustomers([]),
      setListCustomersSelected([]),
      setListCustomersFiltered([]),
      setCustomerState('get'),
      setCustomerListType('get'),
      setCustomerFilter({ search: '', dateEnd: '', dateStart: '' }),
    ]);
  }, [dispatch]);

  const search = React.useCallback(() => {
    dispatch([setCustomerState('search'), setCustomerListType('search')]);
  }, [dispatch]);

  return React.useMemo(
    () => ({
      openToCreate,
      openToDelete,
      openToEdit,
      refresh,
      search,
    }),
    [openToCreate, openToDelete, openToEdit, refresh, search]
  );
}

export default useBuyerUI;
