import React from 'react';
import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';
import * as ReactRedux from 'react-redux';

import * as SaleHooks from 'hooks/useSale';
import type { ITransaction } from 'types/entities';
import {
  setDialogClose,
  setSaleFilter,
  setSalePagination,
} from 'store/actions';

import useStyles from './SalesFilterPaymentTypeDialog.styles';
import { TransactionUtils } from 'helpers';

const options: ITransaction['payment_type'][] = ['credit', 'boleto', 'pix'];

function SalesFilterPaymentTypeDialog() {
  const classes = useStyles();

  const dispatch = ReactRedux.useDispatch();
  const sale = SaleHooks.useSale();
  const { filter, pagination } = sale.state;

  const selectedPaymentTypesInitialState = React.useMemo(
    () => (filter.paymentTypes ? filter.paymentTypes.split(',') : []),
    [filter.paymentTypes]
  );

  const [selectedPaymentTypes, setSelectedPaymentTypes] = React.useState<
    ITransaction['payment_type'][]
  >(selectedPaymentTypesInitialState as ITransaction['payment_type'][]);

  function handleClose() {
    dispatch(setDialogClose());
  }

  function handleCheckboxChange(
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) {
    const paymentType = event.target.name as ITransaction['payment_type'];

    const newPaymentTypes = selectedPaymentTypes.slice();

    if (checked) {
      newPaymentTypes.push(paymentType);
    } else {
      const statusIndex = newPaymentTypes.findIndex(
        _paymentType => _paymentType === paymentType
      );
      newPaymentTypes.splice(statusIndex, 1);
    }

    setSelectedPaymentTypes(newPaymentTypes);
  }

  function handlePaymentTypeToggle(paymentType: ITransaction['payment_type']) {
    const newPaymentTypes = selectedPaymentTypes.slice();
    const paymentTypeIndex = newPaymentTypes.findIndex(
      _paymentType => _paymentType === paymentType
    );

    if (paymentTypeIndex < 0) {
      newPaymentTypes.push(paymentType);
    } else {
      newPaymentTypes.splice(paymentTypeIndex, 1);
    }

    setSelectedPaymentTypes(newPaymentTypes);
  }

  function handlePaymentTypesChange() {
    sale.ui.search();

    const paymentTypes = selectedPaymentTypes.join(',');

    dispatch([
      setSaleFilter({
        paymentTypes,
      }),
      setSalePagination({
        offset: 0,
        hasMore: pagination?.hasMore || false,
        limit: pagination?.limit || 30,
        total: pagination?.total || 0,
        page: pagination?.page || 1,
      }),
    ]);

    sale.ui.refresh();
    sale.ui.refreshMetrics();

    handleClose();
  }

  function handleClearFilter() {
    setSelectedPaymentTypes([]);
  }

  return (
    <>
      <Mui.AppBar className={classes.appBar}>
        <Mui.Toolbar>
          <Mui.Typography className={classes.title} variant="h5">
            Filtrar por método de pagamento
          </Mui.Typography>
          <Mui.IconButton
            aria-label="Fechar"
            className={classes.closeButton}
            onClick={handleClose}
          >
            <MuiIcons.Close />
          </Mui.IconButton>
        </Mui.Toolbar>
      </Mui.AppBar>
      <Mui.DialogContent className={classes.container} dividers>
        <Mui.List>
          {options.map(option => (
            <Mui.ListItem
              button
              key={option}
              onClick={() => handlePaymentTypeToggle(option)}
            >
              <Mui.Checkbox
                checked={selectedPaymentTypes.includes(option)}
                className={classes.checkbox}
                color="primary"
                name={option}
                onChange={handleCheckboxChange}
              />
              <Mui.ListItemText
                primary={TransactionUtils.getPaymentTypeLabel(option)}
              />
            </Mui.ListItem>
          ))}
        </Mui.List>
      </Mui.DialogContent>
      <Mui.DialogActions>
        <Mui.Button onClick={handleClose}>Cancelar</Mui.Button>
        <Mui.Button
          className={classes.clearFilterButton}
          color="secondary"
          onClick={handleClearFilter}
          variant="contained"
        >
          Limpar filtro
        </Mui.Button>
        <Mui.Button
          color="primary"
          onClick={handlePaymentTypesChange}
          variant="contained"
        >
          OK
        </Mui.Button>
      </Mui.DialogActions>
    </>
  );
}

export default SalesFilterPaymentTypeDialog;
