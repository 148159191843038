import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { TextField, MenuItem } from '@material-ui/core';

import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  menuList: {
    backgroundColor: theme.palette.background.dark2,
  },
  disabled: {
    color: `${theme.palette.complementary.lightGrey2} !important`,
  },
  icon: {
    color: theme.palette.text.primary,
  },
  menuItem: {
    '&:hover': {
      backgroundColor: theme.palette.background.default,
    },
  },
  itemSelected: {
    color: theme.palette.text.primary,
    backgroundColor: `${theme.palette.background.default} !important`,
  },
}));

const SelectState = props => {
  const classes = useStyles();

  const {
    className,
    errors,
    onChange,
    value,
    inputProps,
    condition,
    messageError,
    ...rest
  } = props;

  const [states, setStates] = useState([]);

  useEffect(() => {
    if (condition) {
      if (states.length === 0) {
        const fetchStates = async () => {
          try {
            const response = await axios.get(
              'https://servicodados.ibge.gov.br/api/v1/localidades/estados'
            );
            const states = response.data;

            setStates(states);
          } catch (error) {
            console.error('Não foi possível buscar os estados', error);
          }
        };

        fetchStates();
      }
    }
  }, [condition, states.length]);

  return (
    <TextField
      {...rest}
      InputLabelProps={{
        classes: {
          disabled: classes.disabled,
        },
      }}
      SelectProps={{
        classes: {
          icon: classes.icon,
        },
        MenuProps: {
          classes: {
            paper: classes.menuList,
          },
        },
      }}
      className={className}
      disabled={states.length === 0}
      error={errors}
      helperText={messageError ? messageError : null}
      inputProps={inputProps}
      label="Estado"
      onChange={onChange}
      select
      value={value}
      variant="outlined"
    >
      {states.map(state => {
        return (
          <MenuItem
            ListItemClasses={{
              root: classes.menuItem,
              selected: classes.itemSelected,
            }}
            key={state.id}
            selected={value === state.id}
            value={state.id}
          >
            {state.nome}
          </MenuItem>
        );
      })}
    </TextField>
  );
};

export default SelectState;
