import React from 'react';
import * as ReactRedux from 'react-redux';

import type { RootState } from 'types/redux/redux.types';

import useCheckoutService from './useCheckout.service';
import useCheckoutUI from './useCheckout.ui';

function useCheckout() {
  const service = useCheckoutService();
  const ui = useCheckoutUI();
  const state = ReactRedux.useSelector(
    (state: RootState) => state.checkoutState
  );

  return React.useMemo(
    () => ({
      service,
      ui,
      state,
    }),
    [service, state, ui]
  );
}

export default useCheckout;
