import React from 'react';
import * as ReactRedux from 'react-redux';

import {
  setDialogFullScreen,
  setDialogOpen,
  setDialogType,
} from 'store/actions';
import {
  setTaxInvoice,
  setTaxInvoiceState,
} from 'store/actions/tax-invoice-actions';
import type { ITaxInvoice } from 'types/entities/tax-invoice';

function useTaxInvoiceUI() {
  const dispatch = ReactRedux.useDispatch();

  const openToView = React.useCallback(
    (taxInvoice: ITaxInvoice) => {
      dispatch([
        setTaxInvoice(taxInvoice),
        setTaxInvoiceState('view'),
        setDialogType('viewTaxInvoice'),
        setDialogFullScreen(true),
        setDialogOpen(),
      ]);
    },
    [dispatch]
  );

  const refresh = React.useCallback(() => {
    dispatch([setTaxInvoice(null), setTaxInvoiceState('get')]);
  }, [dispatch]);

  return React.useMemo(
    () => ({
      openToView,
      refresh,
    }),
    [openToView, refresh]
  );
}

export default useTaxInvoiceUI;
