import React from 'react';
import * as Mui from '@material-ui/core';

import * as CheckoutHooks from 'hooks/useCheckout';

import useStyles from './CheckoutEmptyState.styles';
import { useAuthContext } from 'hooks/useAuthContext.hook';

function CheckoutEmptyState() {
  const classes = useStyles();
  const { marketplaceUser } = useAuthContext();
  const checkout = CheckoutHooks.useCheckout();

  return (
    <Mui.Grid className={classes.container} container>
      <Mui.Typography className={classes.text} variant="h5">
        Você ainda não possui checkout!
      </Mui.Typography>
      {(!marketplaceUser ||
        marketplaceUser.roles.includes('owner') ||
        marketplaceUser.roles.includes('seller') ||
        marketplaceUser.roles.includes('marketing') ||
        marketplaceUser.roles.includes('attendants')) && (
        <Mui.Button
          className={classes.button}
          color="primary"
          disableFocusRipple
          disableRipple
          onClick={checkout.ui.openToCreate}
          variant="text"
        >
          Crie um agora
        </Mui.Button>
      )}
    </Mui.Grid>
  );
}

export default CheckoutEmptyState;
