import React from 'react';
import * as ReactRedux from 'react-redux';

import type { IOffer } from 'types/entities';
import type { IOfferReducerState } from 'types/redux';
import type { IResourceListInterface } from 'types/services';
import { api } from 'services/api';
import {
  setSnackbarMessage,
  setSnackbarOpen,
  setSnackbarType,
  setListOffers,
  setOfferState,
  setOfferPagination,
  setOffer,
  setDialogClose,
  setDialogType,
} from 'store/actions';
import { useAuthContext } from '../useAuthContext.hook';

interface IUseOfferService {
  create: {
    productId: IOffer['productId'];
    name: IOffer['name'];
    amount: IOffer['amount'];
    description: IOffer['description'];
    status: IOffer['status'];
    dateEnd: IOffer['dateStart'];
    dateStart: IOffer['dateEnd'];
    automaticallyRecoverSales: IOffer['automaticallyRecoverSales'];
    metadata: Record<string, string | number> | null;
  };
  edit: {
    id: IOffer['id'];
    name: IOffer['name'];
    amount: IOffer['amount'];
    description: IOffer['description'];
    status: IOffer['status'];
    dateEnd: IOffer['dateStart'];
    dateStart: IOffer['dateEnd'];
    automaticallyRecoverSales: IOffer['automaticallyRecoverSales'];
    metadata: Record<string, string | number> | null;
  };
}

interface IOfferServiceGetAll {
  limit?: number;
  offset?: number;
}

function useOfferService() {
  const dispatch = ReactRedux.useDispatch();

  const { user } = useAuthContext();

  const { marketplace_id: marketplaceId, id } = user;

  const getAll = React.useCallback(
    async (props?: IOfferServiceGetAll): Promise<void> => {
      try {
        const response = await api.get<IResourceListInterface<IOffer>>(
          `v2/marketplaces/${marketplaceId}/offers`,
          {
            auth: {
              username: process.env.REACT_APP_BYPASS_API_KEY!,
              password: '',
            },
            params: {
              limit: props?.limit,
              offset: props?.offset,
            },
          }
        );

        const {
          page,
          offset,
          limit,
          has_more: hasMore,
          items: offers,
          total,
        } = response.data;

        const pagination: IOfferReducerState['pagination'] = {
          hasMore,
          limit,
          offset,
          page,
          total,
        };

        dispatch([
          setListOffers(offers),
          setOfferState('success'),
          setOfferPagination(pagination),
        ]);
      } catch (error) {
        dispatch([
          setSnackbarMessage(
            'Não foi possível fazer a listagem das ofertas, tente novamente mais tarde!'
          ),
          setSnackbarType('error'),
          setSnackbarOpen(),
        ]);
        console.error('Não foi possível buscar a lista de ofertas');
        throw error;
      }
    },
    [dispatch, marketplaceId]
  );

  const create = React.useCallback(
    async (params: IUseOfferService['create']) => {
      try {
        const {
          productId,
          name,
          amount,
          description,
          status,
          dateEnd,
          dateStart,
          automaticallyRecoverSales,
          metadata,
        } = params;

        await api.post(
          `v2/marketplaces/${marketplaceId}/offers`,
          {
            sellerId: id,
            productId,
            name,
            amount,
            description,
            status,
            dateEnd,
            dateStart,
            automaticallyRecoverSales,
            metadata,
          },
          {
            auth: {
              username: process.env.REACT_APP_BYPASS_API_KEY!,
              password: '',
            },
          }
        );

        dispatch([
          setOfferState('get'),
          setOffer(null),
          setSnackbarMessage('Oferta criada com sucesso!'),
          setSnackbarType('success'),
          setSnackbarOpen(),
          setDialogClose(),
          setDialogType(null),
        ]);
      } catch (error) {
        dispatch([
          setSnackbarMessage(
            'Não foi possível criar esta oferta, tente novamente mais tarde!'
          ),
          setSnackbarType('error'),
          setSnackbarOpen(),
        ]);
      }
    },
    [dispatch, id, marketplaceId]
  );

  const edit = React.useCallback(
    async (params: IUseOfferService['edit']) => {
      try {
        const {
          id,
          name,
          amount,
          status,
          description,
          dateStart,
          dateEnd,
          automaticallyRecoverSales,
          metadata,
        } = params;

        await api.put(
          `v2/marketplaces/${marketplaceId}/offers/${id}`,
          {
            name,
            amount,
            description,
            status,
            dateStart,
            dateEnd,
            automaticallyRecoverSales,
            metadata,
          },
          {
            auth: {
              username: process.env.REACT_APP_BYPASS_API_KEY!,
              password: '',
            },
          }
        );

        dispatch([
          setOfferState('get'),
          setOffer(null),
          setSnackbarMessage('Oferta editada com sucesso!'),
          setSnackbarType('success'),
          setSnackbarOpen(),
          setDialogClose(),
          setDialogType(null),
        ]);
      } catch (error) {
        dispatch([
          setSnackbarMessage(
            'Não foi possível editar esta oferta, tente novamente mais tarde!'
          ),
          setSnackbarType('error'),
          setSnackbarOpen(),
        ]);
      }
    },
    [dispatch, marketplaceId]
  );

  return React.useMemo(
    () => ({
      getAll,
      create,
      edit,
    }),
    [getAll, create, edit]
  );
}

export default useOfferService;
