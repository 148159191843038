import React from 'react';

import * as MarketplaceUserHooks from 'hooks/use-marketplace-user';
import * as InternalComponents from './components';

function MarketplaceUserList() {
  const marketplaceUser = MarketplaceUserHooks.useMarketplaceUser();
  const { state, listMarketplaceUsers, pagination } = marketplaceUser.state;

  React.useEffect(() => {
    if (state === 'get') {
      marketplaceUser.service.getAll({
        offset: pagination?.offset,
        limit: pagination?.limit,
      });
    }
  }, [marketplaceUser.service, pagination?.limit, pagination?.offset, state]);

  if (state === 'get') {
    return <InternalComponents.MarketplaceUserListLoading />;
  } else if (listMarketplaceUsers.length > 0) {
    return <InternalComponents.MarketplaceUserListTable />;
  } else if (state === 'failed') {
    return <InternalComponents.MarketplaceUserFailedState />;
  } else {
    return <InternalComponents.MarketplaceUserEmptyState />;
  }
}

export default MarketplaceUserList;
