import React from 'react';
import * as ReactRedux from 'react-redux';

import type { RootState } from 'types/redux/redux.types';

import useTaxInvoiceConfigService from './use-tax-invoice-config.service';
import useTaxInvoiceConfigUI from './use-tax-invoice-config.ui';

function useTaxInvoiceConfig() {
  const service = useTaxInvoiceConfigService();
  const ui = useTaxInvoiceConfigUI();
  const state = ReactRedux.useSelector(
    (state: RootState) => state.taxInvoiceConfigState
  );

  return React.useMemo(
    () => ({
      service,
      ui,
      state,
    }),
    [service, state, ui]
  );
}

export default useTaxInvoiceConfig;
