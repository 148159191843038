import React, { useCallback, useEffect, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import {
  setCustomerState,
  setFutureTransfersList,
  setFutureTransfersState,
  setSnackbarMessage,
  setSnackbarOpen,
  setSnackbarType,
} from 'store/actions';

import { Grid, makeStyles } from '@material-ui/core';

import {
  NextPayment,
  TotalFutureTransfers,
  FutureTransfersDetails,
} from './components';
import { api } from 'services/api';
import { useAuthContext } from '../../hooks/useAuthContext.hook';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
  },
}));

const FutureTransfers = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { user } = useAuthContext();

  const { state, future_transfers_list } = useSelector(
    state => state.futureTransfersState
  );

  const expected_billing = useMemo(() => {
    if (
      future_transfers_list &&
      Object.values(future_transfers_list.items).length > 0
    ) {
      return Object.values(future_transfers_list.items).reduce(
        (amount, future_transfer) => amount + Number(future_transfer.amount),
        0
      );
    }

    return 0;
  }, [future_transfers_list]);

  const nextPaymentDate = useMemo(() => {
    if (
      future_transfers_list &&
      Object.values(future_transfers_list.items).length > 0
    ) {
      return Object.keys(future_transfers_list.items)[0];
    }

    return null;
  }, [future_transfers_list]);

  const nextPaymentAmount = useMemo(() => {
    if (
      future_transfers_list &&
      Object.values(future_transfers_list.items).length > 0
    ) {
      return Object.values(future_transfers_list.items)[0].amount;
    }

    return 0;
  }, [future_transfers_list]);

  useEffect(() => {
    if (state === 'get') {
      async function getFutureTransfers() {
        try {
          const token = localStorage.getItem('token');

          const { data: future_transfers } = await api.get(
            `/seller/${user.id}/future-transfers`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
              params: {
                group_by_day: true,
                limit: 1000,
              },
            }
          );

          dispatch([
            setFutureTransfersList(future_transfers),
            setFutureTransfersState('success'),
          ]);
        } catch (error) {
          dispatch([
            setSnackbarMessage(
              'Não foi possível fazer a listagem de lançamentos futuros, tente novamente mais tarde !'
            ),
            setSnackbarType('error'),
            setSnackbarOpen(),
            setCustomerState('failed'),
          ]);
          console.error(
            'Não foi possível buscar a lista de lançamentos futuros'
          );
        }
      }

      getFutureTransfers();
    }
  }, [state, dispatch, user.id]);

  const handleRefresh = useCallback(() => {
    dispatch([setFutureTransfersList(null), setFutureTransfersState('get')]);
  }, [dispatch]);

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item sm={6} xs={12}>
          <NextPayment
            amount={nextPaymentAmount}
            date={nextPaymentDate}
            stateRequest={state}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TotalFutureTransfers
            amount={expected_billing}
            stateRequest={state}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <FutureTransfersDetails
            data={future_transfers_list}
            handleRefresh={handleRefresh}
            stateRequest={state}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default FutureTransfers;
