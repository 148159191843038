import type { ICouponReducerAction, ICouponReducerState } from 'types/redux';

const INITIAL_STATE: ICouponReducerState = {
  state: 'get',
  coupon: null,
  listCoupons: [],
  pagination: null,
};

export default (
  state = INITIAL_STATE,
  action: ICouponReducerAction<unknown>
) => {
  switch (action.type) {
    case 'COUPON_STATE_CHANGED':
      return {
        ...state,
        state: action.payload,
      };
    case 'COUPON_CHANGED':
      return {
        ...state,
        coupon: action.payload,
      };
    case 'LIST_COUPONS_CHANGED':
      return {
        ...state,
        listCoupons: action.payload,
      };
    case 'COUPON_PAGINATION_CHANGED':
      return {
        ...state,
        pagination: action.payload,
      };
    default:
      return state;
  }
};
