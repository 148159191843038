import React, { useEffect, useState } from 'react';

import clsx from 'clsx';

import moment from 'moment-timezone';

import PropTypes from 'prop-types';

import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button,
  IconButton,
} from '@material-ui/core';

import { Skeleton } from '@material-ui/lab';

import { Line } from 'react-chartjs-2';

import options from './chart';

import { makeStyles } from '@material-ui/styles';

import palette from 'theme/palette';

import {
  ArrowRight as ArrowRightIcon,
  NavigateBefore as NavigateBeforeIcon,
  NavigateNext as NavigateNextIcon,
} from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark2,
  },
  chartContainer: {
    height: 400,
    position: 'relative',
  },
  actions: {
    justifyContent: 'flex-end',
  },
  skeletonChart: {
    width: '100%',
  },
  flexGrow: {
    flexGrow: 1,
  },
}));

const LatestSales = props => {
  const { sales, dashboardState, filter, history } = props;

  const [dataChartBar, setDataChartBar] = useState({});
  const [yearOfAnalysis, setYearOfAnalysis] = useState(moment().year());
  const [chartLabel, setChartLabel] = useState(null);
  const filtered_years_limit = sales.reduce((accumulator, currentValue) => {
    const year = moment(currentValue.created_at).year();

    if (!accumulator.includes(year)) {
      accumulator.push(year);
    }

    return accumulator;
  }, []);

  useEffect(() => {
    let data;

    data = sales.reduce((accumulator, currentValue) => {
      const date = moment(currentValue.created_at);
      if (`${date.date()} ${date.format('MMM')}` in accumulator) {
        accumulator[`${date.date()} ${date.format('MMM')}`]++;
      } else {
        accumulator[`${date.date()} ${date.format('MMM')}`] = 1;
      }

      return accumulator;
    }, {});

    if (filter === 'week') {
      let weekdays = {};

      for (const weekday of moment.weekdaysShort(true)) {
        weekdays[weekday] = 0;
      }

      data = sales.reduce((weekdays, currentValue) => {
        const sale_weekday = moment(currentValue.created_at).format('ddd');

        if (sale_weekday in weekdays) {
          weekdays[sale_weekday]++;
        }

        return weekdays;
      }, weekdays);

      const start_date = moment().day(1).startOf('day');
      const end_date = moment().day(7).endOf('day');
      setChartLabel(
        `${start_date.format('MMM')} ${start_date.format(
          'DD'
        )} ~ ${end_date.format('MMM')} ${end_date.format('DD')}`
      );
    } else if (filter === 'month') {
      let days = {};

      for (let index = 1; index <= moment().daysInMonth(); index++) {
        days[index] = 0;
      }

      data = sales.reduce((days, currentValue) => {
        const sale_day = moment(currentValue.created_at).format('D');

        if (sale_day in days) {
          days[sale_day]++;
        }

        return days;
      }, days);

      setChartLabel(moment().format('MMMM').toLocaleLowerCase());
    } else if (filter === 'year') {
      let months = {};

      for (const month of moment.monthsShort()) {
        months[month] = 0;
      }

      data = sales.reduce((months, currentValue) => {
        const sale_month = moment(currentValue.created_at).format('MMM');

        if (sale_month in months) {
          months[sale_month]++;
        }

        return months;
      }, months);

      setChartLabel(moment().format('YYYY'));
    } else {
      let months = {};

      for (const month of moment.monthsShort()) {
        months[month] = 0;
      }

      const sales_this_year = sales.filter(
        sale => moment(sale.created_at).year() === yearOfAnalysis
      );

      data = sales_this_year.reduce((months, currentValue) => {
        const sale_month = moment(currentValue.created_at).format('MMM');

        if (sale_month in months) {
          months[sale_month]++;
        }

        return months;
      }, months);

      setChartLabel(yearOfAnalysis.toString());
    }

    setDataChartBar(data);
  }, [sales, filter, yearOfAnalysis]);

  const data = {
    labels: Object.keys(dataChartBar),
    datasets: [
      {
        backgroundColor: palette.primary.light,
        label: 'Vendas',
        data: Object.values(dataChartBar),
        fill: false,
        pointRadius: 4,
        pointHoverRadius: 6,
      },
    ],
  };

  const classes = useStyles();

  const handleChangeNextYear = () => {
    const nextYear = yearOfAnalysis + 1;

    if (filtered_years_limit.includes(nextYear)) {
      setYearOfAnalysis(nextYear);
    }

    return false;
  };

  const handleChangePreviousYear = () => {
    const previousYear = yearOfAnalysis - 1;

    if (filtered_years_limit.includes(previousYear)) {
      setYearOfAnalysis(previousYear);
    }

    return false;
  };

  const handleViewAllSales = () => history.push('/sales');

  return (
    <Card className={clsx(classes.root)}>
      <CardHeader
        action={
          filter === 'sinceStart' && (
            <React.Fragment>
              <IconButton
                aria-label="voltar"
                disabled={
                  dashboardState === 'get' ||
                  !filtered_years_limit.includes(yearOfAnalysis - 1)
                }
                onClick={handleChangePreviousYear}
              >
                <NavigateBeforeIcon />
              </IconButton>
              ,
              <IconButton
                aria-label="próximo"
                disabled={
                  dashboardState === 'get' ||
                  !filtered_years_limit.includes(yearOfAnalysis + 1)
                }
                onClick={handleChangeNextYear}
              >
                <NavigateNextIcon />
              </IconButton>
            </React.Fragment>
          )
        }
        title={`Vendas de ${chartLabel}`}
      />
      <Divider />
      {dashboardState === 'get' ? (
        <Skeleton
          animation="wave"
          className={classes.skeletonChart}
          height={400}
          variant="rect"
        />
      ) : (
        <CardContent>
          <div className={classes.chartContainer}>
            <Line data={data} options={options} />
          </div>
        </CardContent>
      )}
      <Divider />
      <CardActions className={classes.actions}>
        <Button
          color="primary"
          onClick={handleViewAllSales}
          size="small"
          variant="text"
        >
          Ver todas <ArrowRightIcon />
        </Button>
      </CardActions>
    </Card>
  );
};

LatestSales.propTypes = {
  className: PropTypes.string,
};

export default LatestSales;
