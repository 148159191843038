import * as Mui from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

import { SearchInput } from 'components';
import * as TaxInvoiceConfigHooks from 'hooks/use-tax-invoice-config';

import useStyles from './tax-invoices-toolbar.styles';
import { useAuthContext } from 'hooks/useAuthContext.hook';

type OffersToolbarProps = React.HTMLAttributes<HTMLDivElement>;

const TaxInvoicesToolbarComponent = (props: OffersToolbarProps) => {
  const { className, ...rest } = props;

  const { marketplaceUser } = useAuthContext();
  const classes = useStyles();
  const taxInvoiceConfig = TaxInvoiceConfigHooks.useTaxInvoiceConfig();

  const [query, setQuery] = React.useState('');

  function handleSearch(event: React.ChangeEvent<HTMLInputElement>) {
    const query = event.target.value;

    setQuery(query);
  }

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div className={classes.row}>
        <div className={classes.leftActionsContainer} />
        <span className={classes.spacer} />
        <div className={classes.rightActionsContainer}>
          {/* <Button className={classes.importButton}>Importar</Button> */}
          <Mui.Tooltip title="Em breve">
            <div>
              <Mui.Button className={classes.exportButton} disabled>
                Exportar
              </Mui.Button>
            </div>
          </Mui.Tooltip>
          {(!marketplaceUser ||
            marketplaceUser?.roles.includes('owner') ||
            marketplaceUser?.roles.includes('financial')) && (
            <Mui.Button
              className={classes.buttonCreate}
              color="secondary"
              onClick={taxInvoiceConfig.ui.openToConfig}
              variant="contained"
            >
              Configurar nota fiscal
            </Mui.Button>
          )}

          {/* <Mui.Button
            className={classes.buttonCreate}
            color="primary"
            onClick={taxInvoice.ui.openToCreate}
            variant="contained"
          >
            Adicionar nota fiscal
          </Mui.Button> */}
        </div>
      </div>
      <Mui.Tooltip title="Em breve">
        <div className={classes.row}>
          <SearchInput
            className={classes.searchInput}
            disabled
            onChange={handleSearch}
            placeholder="Pesquise uma nota fiscal"
            value={query}
          />
        </div>
      </Mui.Tooltip>
    </div>
  );
};

export default TaxInvoicesToolbarComponent;
