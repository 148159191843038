import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%',
  },
  grid: {
    height: '100%',
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: '100%',
    maxWidth: 700,
    margin: 'auto',
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: 60,
    [theme.breakpoints.up('lg')]: {
      paddingTop: theme.spacing(5),
    },
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  overflowHidden: {
    overflow: 'hidden',
  },
  containerCards: {
    padding: theme.spacing(2),
  },
  card: {
    maxWidth: 300,
  },
  buttonNext: {
    marginTop: theme.spacing(2),
  },
  icon: {
    fontSize: 100,
    textAlign: 'center',
  },
}));

const SignUpStep03 = props => {
  const { history } = props;

  const classes = useStyles();

  // const handleChange = event => {
  //   event.persist();
  // };

  const handleBack = () => {
    history.goBack();
  };

  const handleSubmit = event => {
    event.preventDefault();
    history.push('/sign-up/step-04');
  };

  return (
    <div className={classes.root}>
      <Grid className={classes.grid} container>
        <Grid className={classes.content} item lg={12} xs={12}>
          <div className={classes.content}>
            <div className={classes.contentHeader}>
              <IconButton onClick={handleBack}>
                <ArrowBackIcon />
              </IconButton>
            </div>
            <div className={classes.contentBody}>
              <Grid
                alignItems="center"
                className={classes.overflowHidden}
                container
                direction="column"
              >
                <Typography className={classes.title} variant="h3">
                  Fala para gente se você tem uma conta bancária!
                </Typography>
                <Grid
                  className={classes.containerCards}
                  container
                  justify="center"
                  spacing={2}
                  wrap="nowrap"
                >
                  <Grid item lg={3} md={4} sm={5} xs={6}>
                    <Card>
                      <CardActionArea>
                        <div className={classes.icon}>
                          <span aria-labelledby="Cara de triste" role="img">
                            😩
                          </span>
                        </div>
                        <CardContent>
                          <Typography align="center" variant="h5">
                            Não, eu não tenho uma conta bancária em meu cpf
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>
                  <Grid item lg={3} md={4} sm={5} xs={6}>
                    <Card>
                      <CardActionArea>
                        <div className={classes.icon}>
                          <span aria-labelledby="Cara de feliz" role="img">
                            😄
                          </span>
                        </div>
                        <CardContent>
                          <Typography align="center" variant="h5">
                            Sim, eu tenho uma conta bancária em meu cpf
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>
                </Grid>
                <Button
                  className={classes.buttonNext}
                  color="primary"
                  onClick={e => handleSubmit(e)}
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  próximo passo
                </Button>
              </Grid>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

SignUpStep03.propTypes = {
  history: PropTypes.object,
};

export default withRouter(SignUpStep03);
