import React from 'react';
import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';
import * as ReactRedux from 'react-redux';
import clsx from 'clsx';
import moment from 'moment';
import type { Breakpoint } from '@material-ui/core/styles/createBreakpoints';

import * as Subscriptions from 'hooks/useSubscription';
import type { IBuyer, IPlan, ISubscription } from 'types/entities';
import { setSubscriptionPagination, setSubscriptionState } from 'store/actions';
import {
  getDisplayMoney,
  getInitials,
  NumberUtils,
  StringUtils,
  SubscriptionUtils,
} from 'helpers';

import useStyles from './SubscriptionListTable.styles';

function SubscriptionListTable() {
  const theme = Mui.useTheme();
  const dispatch = ReactRedux.useDispatch();
  const subscription = Subscriptions.useSubscription();
  const { pagination, listSubscriptions } = subscription.state;

  const classes = useStyles();

  function getDisplayMobile(
    string: string | null,
    breakpoint: Breakpoint
  ): string | null {
    const width = window.innerWidth;

    if (width < theme.breakpoints.values[breakpoint]) {
      return string;
    }

    return '';
  }

  function handlePageChange(_: unknown, page: number) {
    if (!pagination) {
      return;
    }

    let newOffset: number;
    if (page + 1 > pagination.page) {
      newOffset = pagination.offset + pagination.limit;
    } else {
      newOffset = pagination.offset - pagination.limit;
    }

    dispatch([
      setSubscriptionPagination({ ...pagination, offset: newOffset }),
      setSubscriptionState('get'),
    ]);
  }

  function handleRowsPerPageChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (!pagination) {
      return;
    }

    const limit = Number(event.target.value);

    dispatch([
      setSubscriptionPagination({ ...pagination, limit, offset: 0 }),
      setSubscriptionState('get'),
    ]);
  }

  return (
    <Mui.Grid className={classes.container} component={Mui.List} container>
      <Mui.Grid
        className={classes.paginationContainer}
        container
        justifyContent="flex-end"
      >
        <Mui.TablePagination
          SelectProps={{
            classes: {
              icon: classes.icon,
            },
            MenuProps: {
              classes: {
                paper: classes.menuList,
              },
            },
          }}
          backIconButtonText="Voltar página"
          component="div"
          count={pagination?.total || 0}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to === -1 ? count : to} de ${
              count !== -1 ? count : `mais que ${to}`
            }`
          }
          labelRowsPerPage=""
          nextIconButtonText="Próxima página"
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          page={(pagination?.page || 1) - 1}
          rowsPerPage={pagination?.limit || 30}
          rowsPerPageOptions={[30, 50, 100]}
        />
      </Mui.Grid>

      <Mui.ListItem disableGutters>
        <Mui.Grid className={classes.tableCell} item md={2} sm={4} xs={8}>
          <Mui.Typography noWrap variant="h5">
            Cliente
          </Mui.Typography>
        </Mui.Grid>
        <Mui.Grid
          className={clsx(classes.tableCell, classes.tableTablet)}
          item
          md={2}
          sm={3}
        >
          <Mui.Typography noWrap variant="h5">
            Plano
          </Mui.Typography>
        </Mui.Grid>
        <Mui.Grid
          className={clsx(classes.tableCell, classes.tableTablet)}
          item
          md={2}
          sm={2}
        >
          <Mui.Typography noWrap variant="h5">
            Próxima / última cobrança
          </Mui.Typography>
        </Mui.Grid>
        <Mui.Grid
          className={clsx(classes.tableCell, classes.tableDesktop)}
          item
          md={2}
        >
          <Mui.Typography noWrap variant="h5">
            Assinou em
          </Mui.Typography>
        </Mui.Grid>
        <Mui.Grid
          className={clsx(classes.tableCell, classes.tableDesktop)}
          item
          md={2}
        >
          <Mui.Typography noWrap variant="h5">
            Status
          </Mui.Typography>
        </Mui.Grid>
      </Mui.ListItem>

      <Mui.Grid component={Mui.Divider} item xs={12} />

      {listSubscriptions.map((subscriptionData: ISubscription) => {
        const customer = subscriptionData.customer as unknown as IBuyer;
        const plan = subscriptionData.plan as unknown as IPlan;

        return (
          <React.Fragment key={subscriptionData.id}>
            <Mui.ListItem className={classes.listItem} disableGutters>
              <Mui.Grid className={classes.tableCell} item md={2} sm={4} xs={8}>
                <Mui.ListItemAvatar>
                  <Mui.Avatar>{getInitials(customer.name)}</Mui.Avatar>
                </Mui.ListItemAvatar>
                <Mui.ListItemText
                  disableTypography
                  primary={
                    <Mui.Typography noWrap>{customer.name}</Mui.Typography>
                  }
                  secondary={
                    <Mui.Grid component="span" container direction="column">
                      <Mui.Grid component="span" item xs zeroMinWidth>
                        <Mui.Typography component="div" noWrap variant="body2">
                          {customer.email || 'Sem e-mail de contato'}
                        </Mui.Typography>
                      </Mui.Grid>
                      <Mui.Grid component="span" item xs zeroMinWidth>
                        <Mui.Typography component="div" noWrap variant="body2">
                          {getDisplayMobile(plan.name, 'sm')}
                        </Mui.Typography>
                      </Mui.Grid>
                      <Mui.Grid component="span" item xs zeroMinWidth>
                        <Mui.Typography component="div" noWrap variant="body2">
                          {getDisplayMobile(
                            moment(subscriptionData.due_date)
                              .utc()
                              .format('DD/MM/YYYY'),
                            'sm'
                          )}
                        </Mui.Typography>
                      </Mui.Grid>
                      <Mui.Grid component="span" item xs zeroMinWidth>
                        <Mui.Typography component="div" noWrap variant="body2">
                          {getDisplayMobile(
                            StringUtils.capitalize(
                              SubscriptionUtils.getStatusLabel(
                                subscriptionData.status
                              )
                            ),
                            'md'
                          )}
                        </Mui.Typography>
                      </Mui.Grid>
                    </Mui.Grid>
                  }
                />
              </Mui.Grid>
              <Mui.Grid
                className={clsx(classes.tableCell, classes.tableTablet)}
                item
                md={2}
                sm={3}
              >
                <Mui.Typography noWrap variant="body1">
                  <Mui.ListItemText
                    disableTypography
                    primary={
                      <Mui.Typography noWrap>{plan.name}</Mui.Typography>
                    }
                    secondary={
                      <Mui.Typography component="div" noWrap variant="body1">
                        {getDisplayMoney(
                          NumberUtils.toPrecision(plan.amount / 100, 2)
                        )}
                      </Mui.Typography>
                    }
                  />
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid
                className={clsx(classes.tableCell, classes.tableTablet)}
                item
                md={2}
                sm={2}
              >
                <Mui.ListItemText
                  disableTypography
                  primary={
                    <Mui.Typography component="div" noWrap variant="body1">
                      {moment(subscriptionData.due_date)
                        .utc()
                        .format('DD/MM/YYYY')}
                    </Mui.Typography>
                  }
                />
              </Mui.Grid>
              <Mui.Grid
                className={clsx(classes.tableCell, classes.tableDesktop)}
                item
                md={2}
              >
                <Mui.ListItemText
                  disableTypography
                  primary={
                    <Mui.Typography component="div" noWrap variant="body1">
                      {moment(subscriptionData.created_at).format('DD/MM/YYYY')}
                    </Mui.Typography>
                  }
                  secondary={
                    <Mui.Typography component="div" noWrap variant="body1">
                      {moment(subscriptionData.created_at).format('HH:mm:ss')}
                    </Mui.Typography>
                  }
                />
              </Mui.Grid>
              <Mui.Grid
                className={clsx(classes.tableCell, classes.tableDesktop)}
                item
                md={2}
              >
                <Mui.Typography noWrap variant="body1">
                  {StringUtils.capitalize(
                    SubscriptionUtils.getStatusLabel(subscriptionData.status)
                  )}
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid
                className={clsx(classes.tableCell, classes.actionsContainer)}
                item
                md={2}
                sm={3}
                xs={4}
              >
                <Mui.IconButton
                  onClick={() => subscription.ui.openToView(subscriptionData)}
                  size={
                    window.innerWidth > theme.breakpoints.values.sm
                      ? 'medium'
                      : 'small'
                  }
                >
                  <MuiIcons.Visibility className={classes.iconButton} />
                </Mui.IconButton>

                <Mui.IconButton
                  onClick={() => subscription.ui.openToEdit(subscriptionData)}
                  size={
                    window.innerWidth > theme.breakpoints.values.sm
                      ? 'medium'
                      : 'small'
                  }
                >
                  <MuiIcons.Edit className={classes.iconButton} />
                </Mui.IconButton>

                <Mui.Tooltip title="Em breve">
                  <div>
                    <Mui.IconButton
                      disabled
                      size={
                        window.innerWidth > theme.breakpoints.values.sm
                          ? 'medium'
                          : 'small'
                      }
                    >
                      <MuiIcons.Delete className={classes.iconButton} />
                    </Mui.IconButton>
                  </div>
                </Mui.Tooltip>
              </Mui.Grid>
            </Mui.ListItem>

            <Mui.Grid component={Mui.Divider} item xs={12} />
          </React.Fragment>
        );
      })}

      <Mui.Grid
        className={classes.paginationContainer}
        container
        justifyContent="flex-end"
      >
        <Mui.TablePagination
          SelectProps={{
            classes: {
              icon: classes.icon,
            },
            MenuProps: {
              classes: {
                paper: classes.menuList,
              },
            },
          }}
          backIconButtonText="Voltar página"
          component="div"
          count={pagination?.total || 0}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to === -1 ? count : to} de ${
              count !== -1 ? count : `mais que ${to}`
            }`
          }
          labelRowsPerPage=""
          nextIconButtonText="Próxima página"
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          page={(pagination?.page || 1) - 1}
          rowsPerPage={pagination?.limit || 30}
          rowsPerPageOptions={[30, 50, 100]}
        />
      </Mui.Grid>
    </Mui.Grid>
  );
}

export default SubscriptionListTable;
