import type {
  IChargeReducerAction,
  IChargeReducerState,
} from 'types/redux/reducers/charge-reducer.types';

const INITIAL_STATE: IChargeReducerState = {
  state: 'get',
  charge: null,
  listCharges: [],
  pagination: null,
};

export default (
  state = INITIAL_STATE,
  action: IChargeReducerAction<unknown>
) => {
  switch (action.type) {
    case 'CHARGE_STATE_CHANGED':
      return {
        ...state,
        state: action.payload,
      };
    case 'CHARGE_CHANGED':
      return {
        ...state,
        charge: action.payload,
      };
    case 'LIST_CHARGES_CHANGED':
      return {
        ...state,
        listCharges: action.payload,
      };
    case 'CHARGE_PAGINATION_CHANGED':
      return {
        ...state,
        pagination: action.payload,
      };
    default:
      return state;
  }
};
