import React from 'react';
import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';

import * as MarketplaceUserHooks from 'hooks/use-marketplace-user';

import useStyles from './marketplace-user-failed-state.styles';

function MarketplaceUserFailedState() {
  const classes = useStyles();

  const marketplaceUser = MarketplaceUserHooks.useMarketplaceUser();

  return (
    <Mui.Grid
      alignItems="center"
      className={classes.container}
      container
      direction="column"
      justifyContent="center"
      spacing={2}
    >
      <Mui.Typography variant="body2">
        Não foi possível se conectar ao servidor. Tente novamente...
      </Mui.Typography>
      <Mui.IconButton onClick={marketplaceUser.ui.refresh}>
        <MuiIcons.Refresh />
      </Mui.IconButton>
    </Mui.Grid>
  );
}

export default MarketplaceUserFailedState;
