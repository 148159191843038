import type {
  ISaleReducerAction,
  ISaleReducerActionPayload,
  ISaleReducerState,
} from '../../types/redux';

export function setSaleState(state: ISaleReducerState['state']) {
  return {
    type: 'SALE_STATE_CHANGED',
    payload: state,
  } as ISaleReducerAction<ISaleReducerActionPayload['SALE_STATE_CHANGED']>;
}

export function setSale(sale: ISaleReducerState['sale']) {
  return {
    type: 'SALE_CHANGED',
    payload: sale,
  } as ISaleReducerAction<ISaleReducerActionPayload['SALE_CHANGED']>;
}

export function setSalesList(sales: ISaleReducerState['saleList']) {
  return {
    type: 'SALES_LIST_CHANGED',
    payload: sales,
  } as ISaleReducerAction<ISaleReducerActionPayload['SALE_LIST_CHANGED']>;
}

export function setSaleListType(listType: ISaleReducerState['listType']) {
  return {
    type: 'SALE_LIST_TYPE_CHANGED',
    payload: listType,
  } as ISaleReducerAction<ISaleReducerActionPayload['SALE_LIST_TYPE_CHANGED']>;
}

export function setSalePagination(pagination: ISaleReducerState['pagination']) {
  return {
    type: 'SALE_PAGINATION_CHANGED',
    payload: pagination,
  } as ISaleReducerAction<ISaleReducerActionPayload['SALE_PAGINATION_CHANGED']>;
}

export function setSaleFilter(filter: Partial<ISaleReducerState['filter']>) {
  return {
    type: 'SALE_FILTER_CHANGED',
    payload: filter,
  } as ISaleReducerAction<ISaleReducerActionPayload['SALE_FILTER_CHANGED']>;
}

export function setSaleMetricsState(
  state: ISaleReducerState['metrics']['state']
) {
  return {
    type: 'SALE_METRICS_STATE_CHANGED',
    payload: state,
  } as ISaleReducerAction<
    ISaleReducerActionPayload['SALE_METRICS_STATE_CHANGED']
  >;
}

export function setSaleMetricsValues(
  values: ISaleReducerState['metrics']['values']
): ISaleReducerAction<
  ISaleReducerActionPayload['SALE_METRICS_VALUES_CHANGED']
> {
  return {
    type: 'SALE_METRICS_VALUES_CHANGED',
    payload: values,
  };
}
