import React from 'react';
import * as ReactRedux from 'react-redux';

import type { RootState } from 'types/redux/redux.types';

import useCouponService from './useCoupon.service';
import useCouponUI from './useCoupon.ui';

function useCoupon() {
  const service = useCouponService();
  const ui = useCouponUI();
  const state = ReactRedux.useSelector((state: RootState) => state.couponState);

  return React.useMemo(
    () => ({
      service,
      ui,
      state,
    }),
    [service, state, ui]
  );
}

export default useCoupon;
