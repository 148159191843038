import * as Mui from '@material-ui/core';
import React from 'react';

import useStyles from './tax-invoices-empty-state.styles';

function TaxInvoicesEmptyState() {
  const classes = useStyles();

  return (
    <Mui.Grid className={classes.container} container>
      <Mui.Typography className={classes.text} variant="h5">
        Você ainda não possui notas fiscais!
      </Mui.Typography>
    </Mui.Grid>
  );
}

export default TaxInvoicesEmptyState;
