import * as Mui from '@material-ui/core';

const useStyles = Mui.makeStyles(theme => ({
  appBar: {
    position: 'relative',
    paddingRight: '0px !important',
  },
  title: {
    flex: 1,
    color: '#fff',
  },
  container: {
    padding: 0,
    width: '100%',
    maxWidth: '100%',
    [theme.breakpoints.up('md')]: {
      minWidth: 400,
    },
  },
  closeButton: {
    color: theme.palette.white,
  },
  clearFilterButton: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  // Pickers
  rootDialog: {
    backgroundColor: theme.palette.background.dark2,
  },
  inputDatePickerContainer: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  dateStartPicker: {
    marginTop: 0,
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      marginBottom: 0,
      marginRight: theme.spacing(1),
    },
  },
  dateEndPicker: {
    marginTop: 0,
    marginBottom: 0,
  },
  buttonPicker: {
    color: theme.palette.white,
    backgroundColor: 'inherit',
  },
}));

export default useStyles;
