import React from 'react';
import * as ReactRedux from 'react-redux';

import type { RootState } from '../../types/redux';
import useSaleService from './useSale.service';
import useSaleUI from './useSale.ui';

function useSale() {
  const service = useSaleService();
  const ui = useSaleUI();
  const state = ReactRedux.useSelector((state: RootState) => state.saleState);

  return React.useMemo(
    () => ({
      service,
      ui,
      state,
    }),
    [service, state, ui]
  );
}

export default useSale;
