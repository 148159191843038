export function setSnackbarOpen() {
  return {
    type: 'SNACKBAR_OPENED',
  };
}

export function setSnackbarClose() {
  return {
    type: 'SNACKBAR_CLOSED',
  };
}

export function setSnackbarMessage(message) {
  return {
    type: 'SNACKBAR_MESSAGE_CHANGED',
    payload: message,
  };
}

export function setSnackbarType(type) {
  return {
    type: 'SNACKBAR_TYPE_CHANGED',
    payload: type,
  };
}

export function setSnackbarAutoHideDuration(duration) {
  return {
    type: 'SNACKBAR_AUTO_HIDE_DURATION_CHANGED',
    payload: duration,
  };
}

export function setSnackbarAnchorOrigin(vertical, horizontal) {
  return {
    type: 'SNACKBAR_ANCHOR_ORIGIN_CHANGED',
    payload: {
      vertical,
      horizontal,
    },
  };
}
