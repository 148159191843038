import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { TextField, MenuItem } from '@material-ui/core';

import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  menuList: {
    backgroundColor: theme.palette.background.dark2,
  },
  disabled: {
    color: `${theme.palette.complementary.lightGrey2} !important`,
  },
  icon: {
    color: theme.palette.text.primary,
  },
  menuItem: {
    '&:hover': {
      backgroundColor: theme.palette.background.default,
    },
  },
  itemSelected: {
    color: theme.palette.text.primary,
    backgroundColor: `${theme.palette.background.default} !important`,
  },
}));

const SelectCity = props => {
  const classes = useStyles();

  const {
    className,
    errors,
    onChange,
    value,
    inputProps,
    messageError,
    state: id_state,
    ...rest
  } = props;

  const [cities, setCities] = useState([]);

  useEffect(() => {
    if (id_state) {
      setCities([]);

      const fetchCities = async () => {
        try {
          const response = await axios.get(
            `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${id_state}/municipios`
          );
          const cities = response.data;

          setCities(cities);
        } catch (error) {
          console.error('Não foi possível buscar os municípios', error);
        }
      };

      fetchCities();
    }
  }, [id_state]);

  return (
    <TextField
      {...rest}
      InputLabelProps={{
        classes: {
          disabled: classes.disabled,
        },
      }}
      SelectProps={{
        classes: {
          icon: classes.icon,
        },
        MenuProps: {
          classes: {
            paper: classes.menuList,
          },
        },
      }}
      className={className}
      disabled={cities.length === 0}
      error={errors}
      helperText={messageError ? messageError : null}
      inputProps={inputProps}
      label="Cidade"
      onChange={onChange}
      select
      value={value}
      variant="outlined"
    >
      {cities.map(city => {
        return (
          <MenuItem
            ListItemClasses={{
              root: classes.menuItem,
              selected: classes.itemSelected,
            }}
            key={city.id}
            value={city.id}
          >
            {city.nome}
          </MenuItem>
        );
      })}
    </TextField>
  );
};

export default SelectCity;
