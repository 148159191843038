import type {
  ITaxInvoiceReducerAction,
  ITaxInvoiceReducerActionPayload,
  ITaxInvoiceReducerState,
} from 'types/redux/reducers/tax-invoice-reducer.types';

export function setTaxInvoiceState(state: ITaxInvoiceReducerState['state']) {
  return {
    type: 'TAX_INVOICE_STATE_CHANGED',
    payload: state,
  };
}

export function setTaxInvoice(
  taxInvoice: ITaxInvoiceReducerState['taxInvoice']
) {
  return {
    type: 'TAX_INVOICE_CHANGED',
    payload: taxInvoice,
  };
}

export function setListTaxInvoices(
  taxInvoices: ITaxInvoiceReducerState['listTaxInvoices']
) {
  return {
    type: 'LIST_TAX_INVOICES_CHANGED',
    payload: taxInvoices,
  };
}

export function setTaxInvoicePagination(
  pagination: ITaxInvoiceReducerState['pagination']
) {
  return {
    type: 'TAX_INVOICE_PAGINATION_CHANGED',
    payload: pagination,
  } as ITaxInvoiceReducerAction<
    ITaxInvoiceReducerActionPayload['TAX_INVOICE_PAGINATION_CHANGED']
  >;
}
