import React from 'react';
import * as Mui from '@material-ui/core';

import * as BuyerHooks from 'hooks/useBuyer';

import useStyles from './CustomerEmptyStateSearch.styles';

function CustomerEmptySearchState() {
  const classes = useStyles();

  const buyer = BuyerHooks.useBuyer();

  return (
    <Mui.Grid className={classes.container} container>
      <Mui.Typography className={classes.text} variant="h5">
        Não encontramos nenhum cliente com este filtro!
      </Mui.Typography>
      <Mui.Button
        className={classes.button}
        color="secondary"
        disableFocusRipple
        disableRipple
        onClick={buyer.ui.refresh}
        variant="contained"
      >
        Limpar filtro
      </Mui.Button>
    </Mui.Grid>
  );
}

export default CustomerEmptySearchState;
