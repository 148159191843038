import React from 'react';

import * as ProductHooks from 'hooks/useProduct';
import * as InternalComponents from './components';

function ProductsList() {
  const product = ProductHooks.useProdcut();
  const { state, listProducts, pagination } = product.state;

  React.useEffect(() => {
    if (state === 'get') {
      product.service.getAll({
        offset: pagination?.offset,
        limit: pagination?.limit,
      });
    }
  }, [product.service, pagination?.limit, pagination?.offset, state]);

  if (state === 'get') {
    return <InternalComponents.ProductListLoading />;
  } else if (listProducts.length > 0) {
    return <InternalComponents.ProductListTable />;
  } else if (state === 'failed') {
    return <InternalComponents.ProductFailedState />;
  } else {
    return <InternalComponents.ProductEmptyState />;
  }
}

export default ProductsList;
