import React from 'react';
import * as ReactRedux from 'react-redux';

import {
  setDialogFullScreen,
  setDialogMaxWidth,
  setDialogOpen,
  setDialogType,
} from 'store/actions';
import { setCharge, setChargeState } from 'store/actions/charge-actions';
import type { IHttpPopulateCharge } from 'types/entities/charge';

function useChargeUI() {
  const dispatch = ReactRedux.useDispatch();

  const openToCreate = React.useCallback(() => {
    dispatch([
      setCharge(null),
      setChargeState('create'),
      setDialogType('createCharge'),
      setDialogFullScreen(true),
      setDialogOpen(),
    ]);
  }, [dispatch]);

  const openToEdit = React.useCallback(
    (charge: IHttpPopulateCharge) => {
      dispatch([
        setCharge(charge),
        setChargeState('edit'),
        setDialogType('editCharge'),
        setDialogFullScreen(true),
        setDialogOpen(),
      ]);
    },
    [dispatch]
  );

  const openToView = React.useCallback(
    (charge: IHttpPopulateCharge) => {
      dispatch([
        setCharge(charge),
        setChargeState('view'),
        setDialogType('viewCharge'),
        setDialogFullScreen(true),
        setDialogOpen(),
      ]);
    },
    [dispatch]
  );

  const openToDelete = React.useCallback(
    (charge: IHttpPopulateCharge) => {
      dispatch([
        setCharge(charge),
        setChargeState('delete'),
        setDialogType('deleteCharge'),
        setDialogMaxWidth('sm'),
        setDialogFullScreen(false),
        setDialogOpen(),
      ]);
    },
    [dispatch]
  );

  const refresh = React.useCallback(() => {
    dispatch([setCharge(null), setChargeState('get')]);
  }, [dispatch]);

  return React.useMemo(
    () => ({
      openToCreate,
      openToDelete,
      openToEdit,
      openToView,
      refresh,
    }),
    [openToCreate, openToDelete, openToEdit, openToView, refresh]
  );
}

export default useChargeUI;
