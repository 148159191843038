import * as Mui from '@material-ui/core';

const useStyles = Mui.makeStyles(theme => ({
  textFieldContainer: {
    flex: 1,
  },
  metadataContainer: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  actionsContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
  },
}));

export default useStyles;
