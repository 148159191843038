import type { IPlan } from '../types/entities';

enum EFrequencyPartsOfSpeech {
  ADJECTIVE = 'adjective',
  NOUN = 'noun',
  PLURAL_OF_NOUN = 'plural_of_noun',
}

type TFrequencyPartsOfSpeech = `${EFrequencyPartsOfSpeech}`;

export class PlanUtils {
  public static frequencyAdjectiveLabelMap: Record<IPlan['frequency'], string> =
    {
      annually: 'anual',
      daily: 'diária',
      monthly: 'mensal',
      weekly: 'semanal',
    };

  public static frequencyNounLabelMap: Record<IPlan['frequency'], string> = {
    annually: 'ano',
    daily: 'dia',
    monthly: 'mês',
    weekly: 'semana',
  };

  public static frequencyPluralOfNounLabelMap: Record<
    IPlan['frequency'],
    string
  > = {
    annually: 'anos',
    daily: 'dias',
    monthly: 'meses',
    weekly: 'semanas',
  };

  public static frequencyPartsOfSpeechMap: Record<
    TFrequencyPartsOfSpeech,
    Record<IPlan['frequency'], string>
  > = {
    adjective: this.frequencyAdjectiveLabelMap,
    noun: this.frequencyNounLabelMap,
    plural_of_noun: this.frequencyPluralOfNounLabelMap,
  };

  public static statusLabelMap: Record<IPlan['frequency'], string> = {
    annually: 'anual',
    daily: 'diária',
    monthly: 'mensal',
    weekly: 'semanal',
  };

  public static getFrequencyLabel(
    frequency: IPlan['frequency'],
    partOfSpeech: TFrequencyPartsOfSpeech = 'adjective'
  ): string {
    return this.frequencyPartsOfSpeechMap[partOfSpeech][frequency] || '';
  }
}
