import React from 'react';
import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';
import * as ReactRedux from 'react-redux';

import * as SaleHooks from 'hooks/useSale';
import type { ITransaction } from 'types/entities';
import { TransactionUtils } from 'helpers';
import {
  setDialogClose,
  setSaleFilter,
  setSalePagination,
} from 'store/actions';

import useStyles from './SalesFilterStatusDialog.styles';

const options: ITransaction['status'][] = [
  'new',
  'pending',
  'pre_authorized',
  'succeeded',
  'failed',
  'reversed',
  'canceled',
  'refunded',
  'dispute',
  'charged_back',
];

function SalesFilterStatusDialog() {
  const classes = useStyles();

  const dispatch = ReactRedux.useDispatch();
  const sale = SaleHooks.useSale();
  const { filter, pagination } = sale.state;

  const selectedStatusInitialState = React.useMemo(
    () => (filter.status ? filter.status.split(',') : []),
    [filter.status]
  );

  const [selectedStatus, setSelectedStatus] = React.useState<
    ITransaction['status'][]
  >(selectedStatusInitialState as ITransaction['status'][]);

  function handleClose() {
    dispatch(setDialogClose());
  }

  function handleCheckboxChange(
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) {
    const status = event.target.name as ITransaction['status'];

    const newStatus = selectedStatus.slice();

    if (checked) {
      newStatus.push(status);
    } else {
      const statusIndex = newStatus.findIndex(_status => _status === status);
      newStatus.splice(statusIndex, 1);
    }

    setSelectedStatus(newStatus);
  }

  function handleStatusToggle(status: ITransaction['status']) {
    const newStatus = selectedStatus.slice();
    const statusIndex = newStatus.findIndex(_status => _status === status);

    if (statusIndex < 0) {
      newStatus.push(status);
    } else {
      newStatus.splice(statusIndex, 1);
    }

    setSelectedStatus(newStatus);
  }

  function handleStatusChange() {
    sale.ui.search();

    const status = selectedStatus.join(',');

    dispatch([
      setSaleFilter({
        status,
      }),
      setSalePagination({
        offset: 0,
        hasMore: pagination?.hasMore || false,
        limit: pagination?.limit || 30,
        total: pagination?.total || 0,
        page: pagination?.page || 1,
      }),
    ]);

    sale.ui.refresh();

    handleClose();
  }

  function handleClearFilter() {
    setSelectedStatus([]);
  }

  return (
    <>
      <Mui.AppBar className={classes.appBar}>
        <Mui.Toolbar>
          <Mui.Typography className={classes.title} variant="h5">
            Filtrar por status
          </Mui.Typography>
          <Mui.IconButton
            aria-label="Fechar"
            className={classes.closeButton}
            onClick={handleClose}
          >
            <MuiIcons.Close />
          </Mui.IconButton>
        </Mui.Toolbar>
      </Mui.AppBar>
      <Mui.DialogContent className={classes.container} dividers>
        <Mui.List>
          {options.map(option => (
            <Mui.ListItem
              button
              key={option}
              onClick={() => handleStatusToggle(option)}
            >
              <Mui.Checkbox
                checked={selectedStatus.includes(option)}
                className={classes.checkbox}
                color="primary"
                name={option}
                onChange={handleCheckboxChange}
              />
              <Mui.ListItemText
                primary={TransactionUtils.getStatusLabel(option)}
              />
            </Mui.ListItem>
          ))}
        </Mui.List>
      </Mui.DialogContent>
      <Mui.DialogActions>
        <Mui.Button onClick={handleClose}>Cancelar</Mui.Button>
        <Mui.Button
          className={classes.clearFilterButton}
          color="secondary"
          onClick={() => handleClearFilter()}
          variant="contained"
        >
          Limpar filtro
        </Mui.Button>
        <Mui.Button
          color="primary"
          onClick={handleStatusChange}
          variant="contained"
        >
          OK
        </Mui.Button>
      </Mui.DialogActions>
    </>
  );
}

export default SalesFilterStatusDialog;
