import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';
import React from 'react';

import { InputCurrency } from 'components';
import { getDisplayMoney } from 'helpers';

import * as ReactRedux from 'react-redux';
import type { RootState } from 'types/redux';
import { useTaxInvoiceEditDialog } from './tax-invoices-edit-dialog.hook';
import useStyles from './tax-invoices-edit-dialog.styles';
import moment from 'moment-timezone';
import { taxInvoiceStatusMap } from '../tax-invoices-list-table/tax-invoices-list-table.component';

const TaxInvoicesEditDialog = () => {
  const classes = useStyles();
  const { handleDialogClose } = useTaxInvoiceEditDialog();

  const { taxInvoice } = ReactRedux.useSelector(
    (state: RootState) => state.taxInvoiceState
  );

  if (!taxInvoice) return;

  const metadata = Object.entries(taxInvoice.metadata || {});

  return (
    <React.Fragment>
      <Mui.AppBar className={classes.appBar}>
        <Mui.Toolbar>
          <Mui.IconButton
            aria-label="Fechar"
            color="inherit"
            edge="start"
            onClick={handleDialogClose}
          >
            <MuiIcons.Close />
          </Mui.IconButton>
          <Mui.Typography className={classes.title} variant="h5">
            Visualizar nota fiscal
          </Mui.Typography>
        </Mui.Toolbar>
      </Mui.AppBar>
      <Mui.Grid className={classes.container} container spacing={2}>
        <Mui.Grid item xs={12}>
          <Mui.Typography variant="h4">Dados da nota fiscal</Mui.Typography>
        </Mui.Grid>
        <Mui.Grid item md={4} sm={6} xs={12}>
          <Mui.TextField
            InputLabelProps={{
              classes: {
                disabled: classes.inputDisabled,
              },
            }}
            InputProps={{
              classes: {
                disabled: classes.inputDisabled,
              },
            }}
            disabled
            fullWidth
            id="provider"
            label="Provedor"
            maxRows="4"
            multiline
            name="provider"
            value={taxInvoice.provider?.toUpperCase()}
            variant="outlined"
          />
        </Mui.Grid>

        <Mui.Grid item md={4} sm={6} xs={12}>
          <Mui.TextField
            InputLabelProps={{
              classes: {
                disabled: classes.inputDisabled,
              },
            }}
            InputProps={{
              classes: {
                disabled: classes.inputDisabled,
              },
            }}
            disabled
            fullWidth
            id="description"
            label="Descrição"
            maxRows="4"
            multiline
            name="description"
            value={taxInvoice.description}
            variant="outlined"
          />
        </Mui.Grid>

        <Mui.Grid item md={4} sm={6} xs={12}>
          <InputCurrency
            disabled
            fullWidth
            id="amount"
            label="Valor da oferta"
            name="amount"
            type="tel"
            value={getDisplayMoney(taxInvoice.amount / 100)}
            variant="outlined"
          />
        </Mui.Grid>

        <Mui.Grid item md={4} sm={6} xs={12}>
          <Mui.TextField
            InputLabelProps={{
              classes: {
                disabled: classes.inputDisabled,
              },
            }}
            InputProps={{
              classes: {
                disabled: classes.inputDisabled,
              },
            }}
            disabled
            fullWidth
            id="status"
            label="Status"
            name="status"
            value={taxInvoiceStatusMap[taxInvoice.status]}
            variant="outlined"
          />
        </Mui.Grid>

        <Mui.Grid item md={4} sm={6} xs={12}>
          <Mui.TextField
            InputLabelProps={{
              classes: {
                disabled: classes.inputDisabled,
              },
            }}
            InputProps={{
              classes: {
                disabled: classes.inputDisabled,
              },
            }}
            disabled
            fullWidth
            id="createdAt"
            label="Criado em"
            name="createdAt"
            value={moment(taxInvoice.createdAt).format('DD/MM/YYYY HH:mm:ss')}
            variant="outlined"
          />
        </Mui.Grid>

        <Mui.Grid item md={4} sm={6} xs={12}>
          <Mui.TextField
            InputLabelProps={{
              classes: {
                disabled: classes.inputDisabled,
              },
            }}
            InputProps={{
              classes: {
                disabled: classes.inputDisabled,
              },
            }}
            disabled
            fullWidth
            id="updatedAt"
            label="Atualizado em"
            name="updatedAt"
            value={moment(taxInvoice.updatedAt).format('DD/MM/YYYY HH:mm:ss')}
            variant="outlined"
          />
        </Mui.Grid>

        {metadata.length > 0 && (
          <>
            <Mui.Grid item xs={12}>
              <Mui.Divider />
            </Mui.Grid>
            <Mui.Grid item xs={12}>
              <Mui.Typography variant="h4">Metadados</Mui.Typography>
            </Mui.Grid>
            <Mui.Grid container direction="column" item spacing={1}>
              {metadata.map(([key, value]) => (
                <Mui.Grid item key={key} xs={4}>
                  <Mui.Typography variant="body1">
                    <strong>{key}: </strong>
                    {value}
                  </Mui.Typography>
                </Mui.Grid>
              ))}
            </Mui.Grid>
          </>
        )}
      </Mui.Grid>
    </React.Fragment>
  );
};

export default TaxInvoicesEditDialog;
