import React from 'react';
import * as ReactRedux from 'react-redux';

import type { IProduct } from 'types/entities';
import {
  setProduct,
  setProductState,
  setDialogFullScreen,
  setDialogMaxWidth,
  setDialogOpen,
  setDialogType,
} from 'store/actions';

function useProductUI() {
  const dispatch = ReactRedux.useDispatch();

  const openToView = React.useCallback(
    (product: IProduct) => {
      dispatch([
        setProduct(product),
        setProductState('view'),
        setDialogType('viewProduct'),
        setDialogFullScreen(true),
        setDialogOpen(),
      ]);
    },
    [dispatch]
  );

  const openToCreate = React.useCallback(() => {
    dispatch([
      setProduct(null),
      setProductState('create'),
      setDialogType('createProduct'),
      setDialogFullScreen(true),
      setDialogOpen(),
    ]);
  }, [dispatch]);

  const openToEdit = React.useCallback(
    (product: IProduct) => {
      dispatch([
        setProduct(product),
        setProductState('edit'),
        setDialogType('editProduct'),
        setDialogFullScreen(true),
        setDialogOpen(),
      ]);
    },
    [dispatch]
  );

  const openToDelete = React.useCallback(
    (product: IProduct) => {
      dispatch([
        setProduct(product),
        setProductState('delete'),
        setDialogType('deleteProduct'),
        setDialogMaxWidth('sm'),
        setDialogFullScreen(false),
        setDialogOpen(),
      ]);
    },
    [dispatch]
  );

  const refresh = React.useCallback(() => {
    dispatch([setProduct(null), setProductState('get')]);
  }, [dispatch]);

  return React.useMemo(
    () => ({
      openToCreate,
      openToDelete,
      openToEdit,
      openToView,
      refresh,
    }),
    [openToCreate, openToDelete, openToEdit, openToView, refresh]
  );
}

export default useProductUI;
