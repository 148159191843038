import React, { useState } from 'react';

import { useDispatch } from 'react-redux';

import { setDashboardFilter, setDashboardState } from 'store/actions';

import {
  Button,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/styles';

import {
  ArrowDropDown as ArrowDropDownIcon,
  FilterList as FilterListIcon,
  Refresh,
} from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  root: {
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
  titleMenu: {
    marginRight: theme.spacing(1),
  },
  flexGrow: {
    flexGrow: 1,
  },
  paper: {
    backgroundColor: theme.palette.primary.dark,
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  refreshButton: {
    marginTop: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      marginTop: 0,
    },
  },
}));

const filters = ['year', 'month', 'week', 'sinceStart'];

const Toolbar = props => {
  const { filter, filterDisplay, getDashboardData } = props;

  const classes = useStyles();

  const dispatch = useDispatch();

  const [menuOpen, setMenuOpen] = useState(null);

  const handleChangeMenuOpen = event => {
    setMenuOpen(event.currentTarget);
  };

  const handleChangeMenuClose = () => {
    setMenuOpen(null);
  };

  const handleChangeFilter = filter => {
    dispatch(setDashboardFilter(filter));
    dispatch(setDashboardState('get'));
    setMenuOpen(null);
  };

  const renderMenuItem = () => {
    return filters.map(filterMap => {
      if (filterMap === filter) {
        return null;
      }

      return (
        <MenuItem key={filterMap} onClick={() => handleChangeFilter(filterMap)}>
          <FilterListIcon className={classes.icon} color="inherit" />
          <Typography className={classes.titleMenu} variant="h6">
            {filterDisplay[filterMap]}
          </Typography>
        </MenuItem>
      );
    });
  };

  return (
    <Grid
      alignItems="center"
      className={classes.root}
      container
      justify="flex-end"
      spacing={1}
    >
      <Grid item md={2} sm={6} xs={12}>
        <Button
          aria-controls="Menu"
          aria-haspopup="true"
          color="primary"
          fullWidth
          onClick={handleChangeMenuOpen}
          variant="contained"
        >
          Filtro: {filterDisplay[filter]} <ArrowDropDownIcon />
        </Button>
        <Menu
          anchorEl={menuOpen}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          classes={{
            paper: classes.paper,
          }}
          getContentAnchorEl={null}
          id="menu-filter"
          keepMounted
          onClose={handleChangeMenuClose}
          open={Boolean(menuOpen)}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          {renderMenuItem()}
        </Menu>
      </Grid>
      <Tooltip title="Recarregar">
        <IconButton
          className={classes.refreshButton}
          onClick={() => getDashboardData(true)}
        >
          <Refresh />
        </IconButton>
      </Tooltip>
    </Grid>
  );
};

export default Toolbar;
