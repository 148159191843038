import React, { useState, useEffect, useCallback } from 'react';

import { useDispatch } from 'react-redux';

import validate from 'validate.js';

import clsx from 'clsx';

import { api } from 'services/api';

import { useQueryParams } from 'hooks/useQueryParams';
import history from 'hooks/useHistory';

import {
  creditCardExpiryMask,
  creditCardNumberMask,
  creditCardSecurityCodeMask,
  getDisplayMoney,
  phoneMask,
} from 'helpers';

import {
  setSnackbarMessage,
  setSnackbarOpen,
  setSnackbarType,
} from 'store/actions';

import {
  Typography,
  Grid,
  Button,
  TextField,
  Checkbox,
  Link,
  FormHelperText,
  useMediaQuery,
  IconButton,
  MenuItem,
} from '@material-ui/core';

import {
  Error as ErrorIcon,
  ExpandMore as ExpandMoreIcon,
} from '@material-ui/icons';

import Cards from 'react-credit-cards';

import { InputMask } from 'components';

import 'react-credit-cards/es/styles-compiled.css';

import { makeStyles, useTheme } from '@material-ui/styles';

import { base64_to_utf8 } from 'helpers/base64_to_utf8';

import TagManager from 'react-gtm-module';

import whatsappHelp from 'assets/images/whatsapp-fale-conosco.png';

const schema = {
  name: {
    presence: {
      allowEmpty: false,
      message: '^Preencha o nome completo',
    },
    format: {
      pattern:
        /^[a-zA-ZáàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ]+( [a-zA-ZáàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ]+)+$/,
      message: '^Preencha seu nome completo',
    },
  },
  email: {
    presence: {
      allowEmpty: false,
      message: '^Preencha seu e-mail',
    },
    email: {
      message: '^Preencha seu e-mail corretamente',
    },
    length: {
      maximum: 64,
    },
  },
  document_number: {
    presence: {
      allowEmpty: false,
      message: '^Insira o número do seu CPF',
    },
    length: value => {
      if (value) {
        value = value
          .replace(/_/g, '')
          .replace(/\./g, '')
          .replace(/\//g, '')
          .replace(/-/g, '');

        if (value.length !== 11) {
          return {
            is: 16,
            message: '^Preencha o CPF corretamente',
          };
        }

        return null;
      }

      return null;
    },
  },
  phone_number: {
    presence: {
      allowEmpty: false,
      message: '^Preencha seu celular',
    },
    length: {
      minimum: 10,
    },
  },
  holder_name: {
    presence: {
      allowEmpty: false,
      message: '^Preencha o nome do titular',
    },
    format: {
      pattern: /^[A-ZÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ]+( [A-ZÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ]+)+$/,
      message: '^Preencha o nome do titular corretamente',
    },
  },
  card_number: {
    presence: {
      allowEmpty: false,
      message: '^Preencha o número do cartão',
    },
    length: value => {
      if (value) {
        value = value.replace(/_/g, '').replace(/ /g, '');

        if (value.length < 14 || value.length > 16) {
          return {
            is: value.length,
            message: '^Preencha corretamente os números do cartão',
          };
        }

        return null;
      }

      return null;
    },
  },
  expiry_date: {
    presence: {
      allowEmpty: false,
      message: '^Preencha a validade do cartão',
    },
    length: value => {
      if (value) {
        value = value.replace(/_/g, '').replace(/\//g, '');

        if (value.length !== 4) {
          return {
            is: 4,
            message: '^Deve conter mês e o ano da expiração',
          };
        }

        return null;
      }

      return null;
    },
  },
  security_code: {
    presence: {
      allowEmpty: false,
      message: '^Preencha o código de segurança',
    },
    length: value => {
      if (value) {
        value = value.replace(/_/g, '');

        if (value.length !== 3 && value.length !== 4) {
          return {
            is: 4,
            message: '^Preencha corretamente o código de segurança',
          };
        }

        return null;
      }

      return null;
    },
  },
  policy: {
    presence: {
      allowEmpty: false,
      message: '^Você deve aceitar os termos e condições para continuar',
    },
    checked: {
      message: '^Os termos e condições devem ser lidos e aceitos.',
    },
  },
};

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 0,
    },
  },
  containerLeft: {
    width: '100%',
    backgroundColor: props => props.backgroundColor,
    padding: theme.spacing(2),
    justifyContent: 'center',
    alignItems: 'center',
  },
  containerRight: {
    width: '100%',
    backgroundColor: '#fdfdfd',
    padding: theme.spacing(2),
    justifyContent: 'center',
    alignItems: 'center',
  },
  contentItemFpass: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '180px',
    },
    [theme.breakpoints.up('sm')]: {
      maxWidth: '220px',
    },
  },
  contentItemPayment: {
    marginBottom: theme.spacing(3),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(2),
      maxWidth: '450px',
    },
  },
  imgFpass: {
    width: '100%',
    height: 'auto',
    display: 'block',
  },
  containerLogo: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  logo: {
    marginRight: '1rem',
    maxWidth: 50,
    [theme.breakpoints.up('sm')]: {
      maxWidth: 60,
    },
  },
  textLogo: {
    color: '#fff',
    fontSize: 30,
    [theme.breakpoints.up('sm')]: {
      fontSize: 36,
    },
  },
  imgPayment: {
    width: props => props.productWidth,
    maxWidth: '100%',
    height: 'auto',
    margin: 'auto',
    display: 'block',
  },
  textTitle: {
    color: '#fff',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '1.3',
    textAlign: 'center',
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      fontSize: '20px',
      maxWidth: '550px',
    },
  },
  containerPrice: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: props => props.primaryColor,
    borderRadius: '12px',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: theme.spacing(3),
    paddingBottom: '20px',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  containerCoupon: {
    marginBottom: theme.spacing(1),
  },
  textCoupon: {
    color: '#f3f3f3',
    marginLeft: theme.spacing(1),
  },
  textPrice: {
    fontSize: '30px',
    fontWeight: 'bold',
    color: '#fff',
    [theme.breakpoints.up('sm')]: {
      fontSize: '40px',
    },
  },
  textMonth: {
    fontSize: '24px',
    color: '#fff',
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      fontSize: '36px',
    },
  },
  iconButtonExpandMoreIcon: {
    marginTop: theme.spacing(2),
    color: '#fff',
  },
  expandMoreIcon: {
    color: '#fff',
  },
  containerCreditCard: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  contentCreditCard: {
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '0px !important',
      paddingRight: '0px !important',
    },
    marginBottom: theme.spacing(3),
  },
  textField: {
    color: props => `${props.primaryColor} !important`,
  },
  inputLabelRoot: {
    color: theme.palette.divider,
  },
  inputLabelFocused: {
    color: props => `${props.primaryColor} !important`,
    fontWeight: 'bold',
  },
  input: {
    color: theme.palette.divider,
    borderColor: props => `${props.primaryColor}`,
    '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: props => `${props.primaryColor} !important`,
    },
    '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: props => props.primaryColor,
    },
    '&.MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(0, 0, 0, 0.38)',
    },
  },
  policy: {
    marginTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
  },
  policyCheckbox: {
    color: props => `${props.primaryColor} !important`,
  },
  checkBoxRoot: {
    color: theme.palette.divider,
  },
  privateTitle: {
    color: theme.palette.divider,
  },
  link: {
    cursor: 'pointer',
    color: props => props.primaryColor,
  },
  textErrorPolicy: {
    marginTop: '-10px',
    marginLeft: theme.spacing(1.5),
  },
  button: {
    backgroundColor: props => props.primaryColor,
    color: '#fff',
    '&:hover': {
      backgroundColor: props => props.primaryColor,
    },
  },
  lineThrough: {
    textDecoration: 'line-through',
  },
  subscriptionTerm: {
    color: '#bbb',
  },
  containerError: {
    padding: theme.spacing(4),
  },
  contentError: {
    paddingTop: 150,
    textAlign: 'center',
  },
  whatsappHelp: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '200px',
    marginTop: theme.spacing(2),
  },
  whatsappHelpImage: {
    width: '100%',
  },
  menuList: {
    backgroundColor: theme.palette.background.dark2,
  },
  menuItem: {
    '&:hover': {
      backgroundColor: theme.palette.background.default,
    },
  },
  itemSelected: {
    color: theme.palette.text.primary,
    backgroundColor: `${theme.palette.background.default} !important`,
  },
}));

const CheckoutWhitelabel = () => {
  const dispatch = useDispatch();
  const queryParams = useQueryParams();
  const defaultTheme = useTheme();
  const isMobile = useMediaQuery(defaultTheme.breakpoints.down('sm'), {
    defaultMatches: true,
  });

  const dataQueryParam = queryParams.get('data');
  const userName = queryParams.get('userName');
  const userEmail = queryParams.get('userEmail');
  const userPhoneNumber = queryParams.get('userPhoneNumber');

  let metadata;
  let theme;

  if (dataQueryParam) {
    metadata = JSON.parse(base64_to_utf8(dataQueryParam));
  }

  if (metadata && metadata.metadata && metadata.metadata.theme) {
    theme = metadata.metadata.theme;
  }

  const classes = useStyles({
    backgroundColor: theme ? theme.backgroundColor : '#282c34',
    primaryColor: theme
      ? theme.primaryColor
      : defaultTheme.palette.secondary.light,
    productWidth: metadata?.paymentFrequency === 'once' ? 'auto' : '100%',
  });

  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      name: userName || '',
      email: userEmail || '',
      phone_number: userPhoneNumber || '',
    },
    errors: {},
    touched: {},
    focus: null,
  });
  const [state, setState] = useState('get');
  const [installments, setInstallments] = useState(
    metadata && metadata.installments ? metadata.installments : 1
  );

  const returnUrl = metadata?.returnUrl || '';
  const productName = metadata?.productName || '';
  const productId = metadata?.productId || '';
  const productValue = metadata?.productValue || 0;

  useEffect(() => {
    const tagManagerArgs = {
      gtmId: 'GTM-KTWQRBX',
    };

    TagManager.initialize(tagManagerArgs);
  }, []);

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: !errors,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleChangeForm = event => {
    event.persist();

    if (event.target.name === 'holder_name') {
      event.target.value = event.target.value.toUpperCase();
    }

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleInputFocus = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      focus: event.target.name,
    }));
  };

  const handleChangeInstallments = event => {
    event.persist();

    setInstallments(event.target.value);
  };

  const handleConfirmPayment = useCallback(async () => {
    const {
      name,
      document_number,
      card_number,
      holder_name,
      expiry_date,
      security_code,
      email,
      phone_number,
    } = formState.values;

    const document_number_only_numbers =
      document_number && document_number !== ''
        ? document_number.replace(/[^0-9]/g, '')
        : '';
    const phone_number_only_numbers =
      phone_number && phone_number !== ''
        ? phone_number.replace(/[^0-9]/g, '')
        : '';
    const emailRegex = new RegExp(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

    if (email && email !== '' && !emailRegex.test(email)) {
      setFormState(formState => ({
        ...formState,
        errors: {
          ...formState.errors,
          email: ['O e-mail está inválido!'],
        },
      }));

      document.querySelector('#email').focus();
    } else if (
      document_number_only_numbers.length !== 14 &&
      document_number_only_numbers.length !== 11
    ) {
      setFormState(formState => ({
        ...formState,
        errors: {
          ...formState.errors,
          document_number: ['O documento está inválido!'],
        },
      }));

      document.querySelector('#document_number').focus();
    } else if (
      phone_number_only_numbers !== '' &&
      phone_number_only_numbers.length !== 11 &&
      phone_number_only_numbers.length !== 10
    ) {
      setFormState(formState => ({
        ...formState,
        errors: {
          ...formState.errors,
          phone_number: ['O número de telefone ou celular está inválido!'],
        },
      }));

      document.querySelector('#phone_number').focus();
    } else {
      const data = {
        name,
        document_number: document_number_only_numbers,
        email,
        phone_number: phone_number_only_numbers,
        installments,
        card: {
          card_number: card_number.replace(/[^0-9]/g, ''),
          holder_name,
          expiration_month: expiry_date.split('/')[0],
          expiration_year: expiry_date.split('/')[1],
          security_code,
        },
      };

      const dataQueryParam = queryParams.get('data');
      setState('pending');

      if (dataQueryParam) {
        data.metadata = JSON.parse(base64_to_utf8(dataQueryParam));
      }

      data.metadata.userEmail = email;
      data.metadata.userName = name;
      data.metadata.userPhoneNumber = phone_number_only_numbers;
      data.metadata.userDocumentNumber = document_number_only_numbers;

      try {
        await api.post('/fpass/checkout', data);
        try {
          await api.post(
            'https://legacy-api.fpass.com.br/internal-createConversion',
            {
              name,
              email,
              phoneNumber: phone_number_only_numbers,
              documentNumber: document_number_only_numbers,
              productName: productName,
              productId: productId,
              amount: productValue,
            }
          );
        } catch (error) {
          console.error(error);
        }

        setState('success');

        dispatch(setSnackbarType('success'));
        dispatch(
          setSnackbarMessage(
            'Compra realizada com sucesso! Você será redirecionado em breve...'
          )
        );
        dispatch(setSnackbarOpen());

        setTimeout(() => {
          window.location.href = returnUrl;
        }, 4000);
      } catch (error) {
        setState('failed');
        dispatch(setSnackbarType('error'));
        dispatch(
          setSnackbarMessage(
            'Não foi possível realizar a compra, tente novamente mais tarde!'
          )
        );
        dispatch(setSnackbarOpen());
      }
    }
  }, [
    dispatch,
    formState.values,
    queryParams,
    returnUrl,
    productName,
    productId,
    productValue,
    installments,
  ]);

  const hasError = field =>
    !!(formState.touched[field] && formState.errors[field]);

  const renderPaymentOptions = () => {
    const rows = [];
    const amount = productValue / 100;

    for (let index = 1; index <= metadata.installments; index++) {
      rows.push(
        <MenuItem
          ListItemClasses={{
            root: classes.menuItem,
            selected: classes.itemSelected,
          }}
          key={index}
          value={index}
        >{`${index} parcela(s) de R$ ${getDisplayMoney(
          amount / index
        )} s/ juros`}</MenuItem>
      );
    }

    return rows;
  };

  if (!metadata) {
    return (
      <div className={classes.containerError}>
        <Grid container justify="center" spacing={4}>
          <Grid item lg={6} xs={12}>
            <div className={classes.contentError}>
              <Typography variant="h1">
                Houve um problema na geração do pagamento
              </Typography>
              <Typography
                style={{
                  marginTop: 16,
                }}
                variant="subtitle2"
              >
                Tente gerar novamente um novo pagamento na plataforma ou envia
                um e-mail para{' '}
                <Link
                  className={classes.link}
                  href="mailto:contato.recebafacil@gmail.com"
                  underline="always"
                >
                  contato.recebafacil@gmail.com
                </Link>
              </Typography>
              <Button
                color="primary"
                onClick={() => history.goBack()}
                size="large"
                style={{
                  marginTop: 32,
                }}
                variant="contained"
              >
                Voltar
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }

  return (
    <Grid className={classes.root} container>
      <Grid
        className={classes.containerLeft}
        container
        direction="column"
        item
        md={6}
        sm={12}
        wrap="nowrap"
      >
        {theme ? (
          <Grid
            className={classes.contentItemFpass}
            component={Link}
            href="https://fpass.com.br"
            item
            target="_blank"
          >
            <img
              alt={`Logo ${metadata.company}`}
              className={classes.imgFpass}
              src={theme.logoUrl}
            />
          </Grid>
        ) : (
          <Grid
            alignItems="center"
            className={classes.containerLogo}
            container
            justify="center"
          >
            <img
              alt="Logo Receba Fácil"
              className={classes.logo}
              src="/images/logos/logo-white.svg"
            />
            <Typography className={classes.textLogo} variant="h3">
              Receba Fácil
            </Typography>
          </Grid>
        )}

        <Grid className={classes.contentItemPayment} item>
          <img
            alt={metadata.productImageAlt}
            className={classes.imgPayment}
            src={metadata.productImage}
          />
        </Grid>
        <Typography className={classes.textTitle} variant="h5">
          {metadata.productDescription}
        </Typography>
        {metadata.couponValue && (
          <Grid
            alignItems="center"
            className={classes.containerCoupon}
            container
            item
            justify="center"
            wrap="nowrap"
          >
            <ErrorIcon htmlColor="gold" />
            {metadata.paymentFrequency !== 'once' ? (
              <Typography className={classes.textCoupon} variant="body1">
                Cupom adicionado, no primeiro mês você paga{' '}
                {getDisplayMoney(productValue / 100)}
              </Typography>
            ) : (
              <Typography className={classes.textCoupon} variant="body1">
                Cupom de{' '}
                {metadata.couponValue <= 100
                  ? `${metadata.couponValue}%`
                  : getDisplayMoney(metadata.couponValue / 100)}{' '}
                adicionado
              </Typography>
            )}
          </Grid>
        )}
        <Grid className={classes.containerPrice} item>
          <Typography className={clsx(classes.textPrice)} variant="h5">
            {metadata.paymentFrequency !== 'once'
              ? getDisplayMoney(productValue / 100)
              : `${metadata.installments}x de ${getDisplayMoney(
                  productValue / metadata.installments / 100
                )} s/ juros`}
          </Typography>
          {metadata.paymentFrequency !== 'once' && (
            <Typography className={classes.textMonth} variant="h5">
              / mês
            </Typography>
          )}
        </Grid>
        <Grid item>
          {metadata.paymentFrequency !== 'once' ? (
            <Typography
              align="center"
              className={classes.subscriptionTerm}
              variant="body2"
            >
              Essa assinatura é renovada automaticamente por{' '}
              {getDisplayMoney(productValue / 100)}/mês. Você pode cancelar a
              qualquer momento em{' '}
              <Link
                className={classes.link}
                href={theme ? theme.baseUrl : ''}
                target="_blank"
                underline="always"
              >
                {metadata.company}
              </Link>
            </Typography>
          ) : (
            <div />
          )}
          <Typography
            align="center"
            className={classes.subscriptionTerm}
            variant="body2"
          >
            Em caso de problemas, possíveis dúvidas ou reclamações entre em
            contato com{' '}
            <Link
              className={classes.link}
              href={`mailto:${
                theme ? theme.contactEmail : 'contato.recebafacil@gmail.com'
              }`}
              target="_blank"
              underline="always"
            >
              {theme ? theme.contactEmail : 'contato.recebafacil@gmail.com'}
            </Link>
          </Typography>
        </Grid>
        <Link
          className={classes.whatsappHelp}
          href="https://api.whatsapp.com/send?phone=5511910424620&text=Ol%C3%A1,%20tudo%20bem?%20Quero%20conversar%20sobre%20o%20Fpass."
          target="_blank"
        >
          <img
            alt="WhatsApp Fale Conosco"
            className={classes.whatsappHelpImage}
            src={whatsappHelp}
          />
        </Link>
        {isMobile && (
          <Link
            className={classes.iconButtonExpandMoreIcon}
            component={IconButton}
          >
            <ExpandMoreIcon className={classes.expandMoreIcon} />
          </Link>
        )}
      </Grid>

      <Grid
        className={classes.containerRight}
        container
        id="main"
        item
        md={6}
        sm={12}
      >
        <Grid item>
          <Grid
            alignItems="center"
            className={classes.containerCreditCard}
            container
            direction="column"
            spacing={2}
          >
            <Grid className={classes.contentCreditCard} item>
              <Cards
                cvc={formState.values.security_code || ''}
                expiry={formState.values.expiry_date || ''}
                focused={formState.focus || ''}
                name={formState.values.holder_name || ''}
                number={formState.values.card_number || ''}
                placeholders={{
                  name: 'SEU NOME AQUI',
                }}
              />
            </Grid>
            <Grid item md={10} sm={8} xl={8}>
              <form>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      InputLabelProps={{
                        classes: {
                          root: classes.inputLabelRoot,
                          focused: classes.inputLabelFocused,
                        },
                      }}
                      InputProps={{
                        classes: {
                          root: classes.input,
                        },
                      }}
                      classes={{
                        root: classes.textField,
                      }}
                      disabled={state === 'pending' || state === 'success'}
                      error={hasError('name')}
                      fullWidth
                      helperText={
                        hasError('name') ? formState.errors.name[0] : null
                      }
                      id="name"
                      label="Nome completo"
                      name="name"
                      onChange={handleChangeForm}
                      type="text"
                      value={formState.values.name || ''}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      InputLabelProps={{
                        classes: {
                          root: classes.inputLabelRoot,
                          focused: classes.inputLabelFocused,
                        },
                      }}
                      InputProps={{
                        classes: {
                          root: classes.input,
                        },
                      }}
                      classes={{
                        root: classes.textField,
                      }}
                      disabled={state === 'pending' || state === 'success'}
                      error={hasError('email')}
                      fullWidth
                      helperText={
                        hasError('email') ? formState.errors.email[0] : null
                      }
                      id="email"
                      label="Seu melhor e-mail"
                      name="email"
                      onChange={handleChangeForm}
                      type="email"
                      value={formState.values.email || ''}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      FormHelperTextProps={{
                        classes: {
                          root: classes.inputLabelRoot,
                        },
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.inputLabelRoot,
                          focused: classes.inputLabelFocused,
                        },
                      }}
                      InputProps={{
                        inputComponent: InputMask,
                        classes: {
                          root: classes.input,
                        },
                      }}
                      classes={{
                        root: classes.textField,
                      }}
                      disabled={state === 'pending' || state === 'success'}
                      error={hasError('document_number')}
                      fullWidth
                      helperText={
                        hasError('document_number')
                          ? formState.errors.document_number[0]
                          : 'Ex: 123.456.789-00'
                      }
                      id="document_number"
                      inputProps={{
                        mask: [
                          /[0-9]/,
                          /\d/,
                          /\d/,
                          '.',
                          /\d/,
                          /\d/,
                          /\d/,
                          '.',
                          /\d/,
                          /\d/,
                          /\d/,
                          '-',
                          /\d/,
                          /\d/,
                        ],
                      }}
                      label="CPF"
                      name="document_number"
                      onChange={handleChangeForm}
                      required
                      type="tel"
                      value={formState.values.document_number || ''}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      FormHelperTextProps={{
                        classes: {
                          root: classes.inputLabelRoot,
                        },
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.inputLabelRoot,
                          focused: classes.inputLabelFocused,
                        },
                      }}
                      InputProps={{
                        classes: {
                          root: classes.input,
                        },
                        inputComponent: InputMask,
                      }}
                      classes={{
                        root: classes.textField,
                      }}
                      disabled={state === 'pending' || state === 'success'}
                      error={hasError('phone_number')}
                      fullWidth
                      helperText={
                        hasError('phone_number')
                          ? formState.errors.phone_number[0]
                          : 'Ex: (11) 3030-3030 ou (11) 99123-4567'
                      }
                      id="phone_number"
                      inputProps={{
                        mask: phoneMask,
                      }}
                      label="Telefone ou celular"
                      name="phone_number"
                      onChange={handleChangeForm}
                      type="text"
                      value={formState.values.phone_number || ''}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      FormHelperTextProps={{
                        classes: {
                          root: classes.inputLabelRoot,
                        },
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.inputLabelRoot,
                          focused: classes.inputLabelFocused,
                        },
                      }}
                      InputProps={{
                        inputComponent: InputMask,
                        classes: {
                          root: classes.input,
                        },
                      }}
                      classes={{
                        root: classes.textField,
                      }}
                      disabled={state === 'pending' || state === 'success'}
                      error={hasError('card_number')}
                      fullWidth
                      helperText={
                        hasError('card_number')
                          ? formState.errors.card_number[0]
                          : 'Um número de cartão pode conter entre 14 a 16 digítos'
                      }
                      id="card_number"
                      inputProps={{
                        mask: creditCardNumberMask,
                      }}
                      label="Número do cartão"
                      name="card_number"
                      onChange={handleChangeForm}
                      onFocus={handleInputFocus}
                      required // eslint-disable-next-line
                      type="tel"
                      value={formState.values.card_number || ''}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      InputLabelProps={{
                        classes: {
                          root: classes.inputLabelRoot,
                          focused: classes.inputLabelFocused,
                        },
                      }}
                      InputProps={{
                        classes: {
                          root: classes.input,
                        },
                      }}
                      classes={{
                        root: classes.textField,
                      }}
                      disabled={state === 'pending' || state === 'success'}
                      error={hasError('holder_name')}
                      fullWidth
                      helperText={
                        hasError('holder_name')
                          ? formState.errors.holder_name[0]
                          : ''
                      }
                      id="holder_name"
                      label="Nome do titular"
                      name="holder_name"
                      onChange={handleChangeForm}
                      onFocus={handleInputFocus}
                      required
                      value={formState.values.holder_name || ''}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      InputLabelProps={{
                        classes: {
                          root: classes.inputLabelRoot,
                          focused: classes.inputLabelFocused,
                        },
                      }}
                      InputProps={{
                        inputComponent: InputMask,
                        classes: {
                          root: classes.input,
                        },
                      }}
                      classes={{
                        root: classes.textField,
                      }}
                      disabled={state === 'pending' || state === 'success'}
                      error={hasError('expiry_date')}
                      fullWidth
                      helperText={
                        hasError('expiry_date')
                          ? formState.errors.expiry_date[0]
                          : ''
                      }
                      id="expiry_date"
                      inputProps={{
                        mask: creditCardExpiryMask,
                      }}
                      label="Data de expiração"
                      name="expiry_date"
                      onChange={handleChangeForm}
                      onFocus={handleInputFocus}
                      required // eslint-disable-next-line
                      type="tel"
                      value={formState.values.expiry_date || ''}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      InputLabelProps={{
                        classes: {
                          root: classes.inputLabelRoot,
                          focused: classes.inputLabelFocused,
                        },
                      }}
                      InputProps={{
                        inputComponent: InputMask,
                        classes: {
                          root: classes.input,
                        },
                      }}
                      classes={{
                        root: classes.textField,
                      }}
                      disabled={state === 'pending' || state === 'success'}
                      error={hasError('security_code')}
                      fullWidth
                      helperText={
                        hasError('security_code')
                          ? formState.errors.security_code[0]
                          : ''
                      }
                      id="security_code"
                      inputProps={{
                        mask: creditCardSecurityCodeMask,
                      }}
                      label="CVC"
                      name="security_code"
                      onChange={handleChangeForm}
                      onFocus={handleInputFocus}
                      required // eslint-disable-next-line
                      type="tel"
                      value={formState.values.security_code || ''}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      InputLabelProps={{
                        classes: {
                          root: classes.inputLabelRoot,
                          focused: classes.inputLabelFocused,
                        },
                      }}
                      InputProps={{
                        classes: {
                          root: classes.input,
                        },
                      }}
                      SelectProps={{
                        MenuProps: {
                          classes: {
                            paper: classes.menuList,
                          },
                        },
                      }}
                      disabled={state === 'pending' || state === 'success'}
                      fullWidth
                      id="installments"
                      label="Número de parcelas"
                      name="installments"
                      onChange={handleChangeInstallments}
                      onFocus={handleInputFocus}
                      select
                      value={installments}
                      variant="outlined"
                    >
                      {renderPaymentOptions()}
                    </TextField>
                  </Grid>
                  <Grid className={classes.policy}>
                    <Checkbox
                      checked={formState.values.policy || false}
                      classes={{
                        root: classes.checkBoxRoot,
                        checked: classes.policyCheckbox,
                      }}
                      disabled={state === 'pending' || state === 'success'}
                      name="policy"
                      onChange={handleChangeForm}
                    />
                    <Typography
                      className={classes.privateTitle}
                      variant="body1"
                    >
                      Eu li e aceito os{' '}
                      <Link
                        className={classes.link}
                        color="primary"
                        href="https://recebafacil.com/termos-de-uso.html"
                        target="_blank"
                        underline="always"
                        variant="h6"
                      >
                        Termos
                      </Link>{' '}
                      e{' '}
                      <Link
                        className={classes.link}
                        color="primary"
                        href="https://recebafacil.com/politica-de-privacidade.html"
                        target="_blank"
                        underline="always"
                        variant="h6"
                      >
                        Condições
                      </Link>
                    </Typography>
                  </Grid>
                  <Grid className={classes.textErrorPolicy} item xs={12}>
                    {hasError('policy') && (
                      <FormHelperText error>
                        {formState.errors.policy[0]}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      className={classes.button}
                      color="primary"
                      disabled={
                        !formState.isValid ||
                        state === 'pending' ||
                        state === 'success'
                      }
                      fullWidth
                      onClick={handleConfirmPayment}
                      variant="contained"
                    >
                      Confirmar Pagamento
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CheckoutWhitelabel;
