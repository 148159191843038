import React from 'react';
import * as Mui from '@material-ui/core';

import * as SaleHooks from 'hooks/useSale';

import useStyles from './SaleEmptySearchState.styles';

function SaleEmptySearchState() {
  const classes = useStyles();
  const sale = SaleHooks.useSale();

  return (
    <Mui.Grid className={classes.container} container>
      <Mui.Typography className={classes.text} variant="h5">
        Não encontramos nenhuma compra com este filtro!
      </Mui.Typography>
      <Mui.Button
        className={classes.button}
        color="secondary"
        disableFocusRipple
        disableRipple
        onClick={sale.ui.filter.clear}
        variant="contained"
      >
        Limpar filtro
      </Mui.Button>
    </Mui.Grid>
  );
}

export default SaleEmptySearchState;
