import React from 'react';
import * as ReactRedux from 'react-redux';

import type { IPlan } from 'types/entities';
import type { IPlanReducerState } from 'types/redux';
import type { IResourceListInterface } from 'types/services';
import { api } from 'services/api';
import {
  setDialogClose,
  setDialogType,
  setListPlans,
  setPlan,
  setPlanPagination,
  setPlanState,
  setSnackbarMessage,
  setSnackbarOpen,
  setSnackbarType,
} from 'store/actions';

import { useAuthContext } from '../useAuthContext.hook';

interface IPlanServiceGetAll {
  limit?: number;
  offset?: number;
}

interface IUsePlanService {
  create: {
    name: IPlan['name'];
    description?: IPlan['description'];
    amount: IPlan['amount'];
    duration: IPlan['duration'];
    frequency: IPlan['frequency'];
    interval: IPlan['interval'];
    metadata: IPlan['metadata'];
  };
  edit: {
    id: IPlan['id'];
    name: IPlan['name'];
    description?: IPlan['description'];
    amount: IPlan['amount'];
    duration: IPlan['duration'];
    frequency: IPlan['frequency'];
    interval: IPlan['interval'];
    metadata: IPlan['metadata'];
  };
}

function usePlanService() {
  const dispatch = ReactRedux.useDispatch();

  const { user } = useAuthContext();

  const { marketplace_id } = user;

  const getAll = React.useCallback(
    async (props?: IPlanServiceGetAll): Promise<void> => {
      try {
        const response = await api.get<IResourceListInterface<IPlan>>(
          `v2/marketplaces/${marketplace_id}/plans`,
          {
            auth: {
              username: process.env.REACT_APP_BYPASS_API_KEY!,
              password: '',
            },
            params: {
              limit: props?.limit,
              offset: props?.offset,
            },
          }
        );

        const {
          page,
          offset,
          limit,
          has_more: hasMore,
          items: plans,
          total,
        } = response.data;

        const pagination: IPlanReducerState['pagination'] = {
          hasMore,
          limit,
          offset,
          page,
          total,
        };

        dispatch([
          setListPlans(plans),
          setPlanState('success'),
          setPlanPagination(pagination),
        ]);
      } catch (error) {
        dispatch([
          setSnackbarMessage(
            'Não foi possível fazer a listagem dos planos, tente novamente mais tarde!'
          ),
          setSnackbarType('error'),
          setSnackbarOpen(),
        ]);
        console.error('Não foi possível buscar a lista de planos');
        throw error;
      }
    },
    [dispatch, marketplace_id]
  );

  const create = React.useCallback(
    async (params: IUsePlanService['create']) => {
      try {
        const {
          name,
          description,
          amount,
          duration,
          frequency,
          interval,
          metadata,
        } = params;

        await api.post(
          `v2/marketplaces/${marketplace_id}/plans`,
          {
            name,
            description,
            amount,
            duration,
            frequency,
            interval,
            paymentMethods: ['credit'],
            setupAmount: 0,
            currency: 'BRL',
            metadata,
          },
          {
            auth: {
              username: process.env.REACT_APP_BYPASS_API_KEY!,
              password: '',
            },
          }
        );
        dispatch([
          setPlanState('get'),
          setPlan(null),
          setSnackbarMessage('Plano criado com sucesso!'),
          setSnackbarType('success'),
          setSnackbarOpen(),
          setDialogClose(),
          setDialogType(null),
        ]);
      } catch (error) {
        dispatch([
          setSnackbarMessage(
            'Não foi possível criar este plano, tente novamente mais tarde!'
          ),
          setSnackbarType('error'),
          setSnackbarOpen(),
        ]);

        console.error('Erro ao criar o plano', error);
      }
    },
    [dispatch, marketplace_id]
  );

  const edit = React.useCallback(
    async (params: IUsePlanService['edit']) => {
      try {
        const {
          id,
          name,
          description,
          amount,
          duration,
          frequency,
          interval,
          metadata,
        } = params;

        await api.put(
          `v2/marketplaces/${marketplace_id}/plans/${id}`,
          {
            name,
            description,
            amount,
            duration,
            frequency,
            interval,
            metadata,
            setupAmount: 0,
          },
          {
            auth: {
              username: process.env.REACT_APP_BYPASS_API_KEY!,
              password: '',
            },
          }
        );

        dispatch([
          setPlanState('get'),
          setPlan(null),
          setSnackbarMessage('Plano editado com sucesso!'),
          setSnackbarType('success'),
          setSnackbarOpen(),
          setDialogClose(),
          setDialogType(null),
        ]);
      } catch (error) {
        dispatch([
          setSnackbarMessage(
            'Não foi possível editar este plano, tente novamente mais tarde!'
          ),
          setSnackbarType('error'),
          setSnackbarOpen(),
        ]);

        console.error('Erro ao editar o plano', error);
      }
    },
    [dispatch, marketplace_id]
  );

  return React.useMemo(
    () => ({
      getAll,
      create,
      edit,
    }),
    [getAll, create, edit]
  );
}

export default usePlanService;
