import React, { useState, useEffect } from 'react';

import { useDispatch } from 'react-redux';

import moment from 'moment-timezone';

import validate from 'validate.js';

import clsx from 'clsx';

import {
  getDisplayMoney,
  creditCardExpiryMask,
  creditCardNumberMask,
} from 'helpers';

import {
  setSnackbarMessage,
  setSnackbarOpen,
  setSnackbarType,
} from 'store/actions';

import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Typography,
  Grid,
  Button,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  List,
  ListItem,
  ListItemText,
  TextField,
  MenuItem,
} from '@material-ui/core';

import { Alert, AlertTitle } from '@material-ui/lab';

import Cards from 'react-credit-cards';

import { InputMask } from 'components';

import { Description as DescriptionIcon } from '@material-ui/icons';

import 'react-credit-cards/es/styles-compiled.css';

import { makeStyles, useTheme } from '@material-ui/styles';
import { api } from 'services/api';

const schema = {
  name: {
    presence: {
      allowEmpty: false,
      message: '^Preencha o nome do titular',
    },
  },
  number: {
    presence: {
      allowEmpty: false,
      message: '^Preencha o número do cartão',
    },
    length: value => {
      if (value) {
        value = value.replace(/_/g, '').replace(/ /g, '');

        if (value.length !== 16) {
          return {
            is: 16,
            message: '^Preencha corretamente os números do cartão',
          };
        }

        return null;
      }

      return null;
    },
  },
  expiry: {
    presence: {
      allowEmpty: false,
      message: '^Preencha a validade do cartão',
    },
    length: value => {
      if (value) {
        value = value.replace(/_/g, '').replace(/\//g, '');

        if (value.length !== 4) {
          return {
            is: 4,
            message: '^Deve conter mês e o ano da expiração',
          };
        }

        return null;
      }

      return null;
    },
  },
  cvc: {
    presence: {
      allowEmpty: false,
      message: '^Preencha o código de segurança',
    },
    length: value => {
      if (value) {
        value = value.replace(/_/g, '');

        if (value.length !== 3) {
          return {
            is: 4,
            message: '^Preencha corretamente o código de segurança',
          };
        }

        return null;
      }

      return null;
    },
  },
};

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 1100,
    marginLeft: 'auto',
    backgroundColor: theme.palette.background.dark2,
    marginRight: 'auto',
    marginTop: theme.spacing(2),
    padding: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
    },
  },
  container: {
    paddingBottom: '15px',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 0,
    },
  },
  content: {
    marginTop: theme.spacing(2),
  },
  panel: {
    maxWidth: 1100,
    margin: 'auto !important',
    background: '#1E222F',
  },
  panelHeader: {
    display: 'none',
  },
  tableCell: {
    display: 'flex',
    alignItems: 'center',
    padding: '0px 7px',
  },
  tableDesktop: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  tableTablet: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  listItem: {
    '&:hover': {
      backgroundColor: '#f5f5f5',
    },
  },
  menuList: {
    backgroundColor: theme.palette.background.dark2,
  },
  containerCreditCard: {
    marginTop: theme.spacing(2),
  },
  contentCreditCard: {
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '0px !important',
      paddingRight: '0px !important',
    },
  },
}));

const Checkout = props => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();

  const { token: checkout } = props.match.params;

  const [charge, setCharge] = useState({});
  const [charges, setCharges] = useState([]);

  const [fee_amount, setFeeAmount] = useState(0);
  const [discount_amount, setDiscountAmount] = useState(0);
  const [charge_expire, setChargeExpire] = useState({});
  const [charge_discount_expire, setChargeDiscountExpire] = useState({});
  const [checkout_disable, setCheckoutDisable] = useState(true);

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    errors: {},
    touched: {},
    focus: null,
  });

  const [expanded, setExpanded] = useState(false);
  const [installments, setInstallments] = useState(1);
  const [state, setState] = useState('get');

  useEffect(() => {
    if (checkout) {
      async function getChargeByToken() {
        try {
          const response = await api.get(`/charge/${checkout}/token/`);

          if (response.data) {
            setCharge(response.data);
          }
        } catch (error) {
          console.log(error);
        }
      }

      getChargeByToken();
    }
  }, [checkout]);

  useEffect(() => {
    if (charge.id && state === 'get') {
      async function getCharges() {
        try {
          const response = await api.get(
            `/charge/${charge.group_charge_id}/group/`
          );

          if (response.data) {
            const charges = response.data;

            const fee = charges.reduce((fee, charge) => {
              if (moment.utc().isAfter(moment.utc(charge.expiration_date))) {
                const amount = charge.amount / 100;
                const late_fee_amount =
                  amount * (charge.late_fee_percentage / 100);
                const diffence_days = moment
                  .utc()
                  .diff(moment.utc(charge.expiration_date).format(), 'days');
                const interest_amount =
                  (charge.interest_percentage / 30 / 100) *
                  diffence_days *
                  amount;

                const current_fee = late_fee_amount + interest_amount;

                return fee + current_fee;
              }

              return fee;
            }, 0);

            const discount = charges.reduce((discount, charge) => {
              if (
                moment.utc().isBefore(moment.utc(charge.discount_limit_date))
              ) {
                const current_discount = charge.discount_amount / 100;

                return discount + current_discount;
              }

              return discount;
            }, 0);

            charges.reverse();

            const charge_expire = charges.reduce((charge, current_charge) => {
              if (
                moment.utc().isAfter(moment.utc(current_charge.expiration_date))
              ) {
                charge = current_charge;
              }

              return charge;
            }, {});

            const charge_discount_expire = charges.reduce(
              (charge, current_charge) => {
                if (
                  moment
                    .utc()
                    .isBefore(moment.utc(current_charge.discount_limit_date))
                ) {
                  charge = current_charge;
                }

                return charge;
              },
              {}
            );

            charges.reverse();

            setCharges(response.data);
            setFeeAmount(fee);
            setDiscountAmount(discount);
            setChargeExpire(charge_expire);
            setChargeDiscountExpire(charge_discount_expire);
            setCheckoutDisable(false);
            setState('view');
          }
        } catch (error) {
          console.log(error);
        }
      }

      getCharges();
    }
  }, [charge, state]);

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: !errors,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleChangePaymentType = payment_type => {
    if (expanded && expanded === 'credit_card' && payment_type === 'billet') {
      setExpanded(payment_type);
    } else if (
      expanded &&
      expanded === 'billet' &&
      payment_type === 'credit_card'
    ) {
      setExpanded(payment_type);
    } else if (!expanded) {
      setExpanded(payment_type);
    } else {
      setExpanded(false);
    }
  };

  const handleChangeForm = event => {
    event.persist();

    if (event.target.name === 'name') {
      event.target.value = event.target.value.toUpperCase();
    }

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleInputFocus = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      focus: event.target.name,
    }));
  };

  const handleChangeInstallments = event => {
    event.persist();

    setInstallments(event.target.value);
  };

  const checkChargeStatus = charge => {
    return !(
      charge.status === 'new' ||
      charge.status === 'pending' ||
      charge.status === 'pending_zoop'
    );
  };

  const handlePaymentCreditCard = async () => {
    dispatch(setSnackbarType('info'));
    dispatch(setSnackbarMessage('Processando...'));
    dispatch(setSnackbarOpen());
    setCheckoutDisable(true);

    const { name, expiry, number, cvc } = formState.values;

    const fee = fee_amount * 100;
    const discount = discount_amount * 100;

    const cardData = {
      holder_name: name,
      expiration_month: expiry.split('/')[0],
      expiration_year: expiry.split('/')[1],
      card_number: number.replace(/ /g, ''),
      security_code: cvc,
    };

    const data = {
      seller_id: charge.seller_id,
      buyer_id: charge.buyer_id,
      currency: charge.currency,
      payment_type: 'credit',
      amount: charge.original_amount - discount + fee,
      card: cardData,
      charge_id: charge.id,
      number_installments: installments,
    };

    try {
      const response = await api.post('/transaction/', data);

      if (response.data) {
        setCharge({
          ...charge,
          status: 'paid',
        });
        dispatch(setSnackbarType('success'));
        dispatch(setSnackbarMessage('Transação feita com sucesso!'));
        dispatch(setSnackbarOpen());
      }
    } catch (error) {
      dispatch(setSnackbarType('error'));
      dispatch(
        setSnackbarMessage(
          'Não foi possível efeutar a transação, tente novamente mais tarde!'
        )
      );
      dispatch(setSnackbarOpen());
      console.log(error);
    }

    setCheckoutDisable(false);
  };

  const handlePaymentBillet = async charge => {
    dispatch(setSnackbarType('info'));
    dispatch(setSnackbarMessage('Processando...'));
    dispatch(setSnackbarOpen());
    setCheckoutDisable(true);

    if (charge.billet) {
      dispatch(setSnackbarType('success'));
      dispatch(
        setSnackbarMessage('Boleto gerado com sucesso! Redirecionando...')
      );
      dispatch(setSnackbarOpen());
      window.open(charge.billet);
    } else {
      const data = {
        charge_id: charge.id,
        seller_id: charge.seller_id,
        buyer_id: charge.buyer_id,
        currency: charge.currency,
        amount: charge.amount,
        original_amount: charge.original_amount,
        payment_type: 'boleto',
        expiration_date: charge.expiration_date,
        payment_limit_date: charge.payment_limit_date,
        late_fee_mode: charge.late_fee_mode,
        late_fee_percentage: charge.late_fee_percentage,
        interest_mode: charge.interest_mode,
        interest_percentage: charge.interest_percentage,
        discount_mode: charge.discount_mode,
        discount_limit_date: charge.discount_limit_date,
        discount_amount: charge.discount_amount,
      };

      try {
        const response = await api.post('/transaction/', data);

        if (response.data) {
          setState('get');
          dispatch(setSnackbarType('success'));
          dispatch(
            setSnackbarMessage('Boleto gerado com sucesso! Redirecionando...')
          );
          dispatch(setSnackbarOpen());
          window.open(response.data.billet);
        }
      } catch (error) {
        dispatch(setSnackbarType('error'));
        dispatch(
          setSnackbarMessage(
            'Não foi possível gerar o boleto, tente novamente mais tarde!'
          )
        );
        dispatch(setSnackbarOpen());
        console.log(error);
      }
    }

    setCheckoutDisable(false);
  };

  const getDisplayStatus = status => {
    switch (status) {
      case 'new':
        return 'CRIADA';
      case 'failed':
        return 'FALHOU';
      case 'succeeded':
        return 'PAGA';
      case 'pending':
      case 'pending_zoop':
        return 'PENDENTE';
      case 'canceled':
        return 'CANCELADA';
      case 'dispute':
        return 'DISPUTADA';
      case 'refunded':
      case 'charged_back':
        return 'REEMBOLSADO';
      case 'expired':
        return 'EXPIRADA';
      default:
        return '';
    }
  };

  const getDisplayInstallmentsSecondary = (installment, installments) => {
    const width = window.innerWidth;

    if (width < theme.breakpoints.width('sm')) {
      return `Parcelas: (${installment}/${installments})`;
    }

    return '';
  };

  const getDisplayExpirationDateSecondary = expiration_date => {
    const width = window.innerWidth;

    if (width < theme.breakpoints.width('sm')) {
      return `Vencimento: ${moment(expiration_date)
        .tz('America/Sao_Paulo')
        .format('DD/MM/YYYY')}`;
    }

    return '';
  };

  const getAmount = (charge, number_installments = 1) => {
    const original_amount = charge.original_amount / 100;
    let amount = original_amount;

    const applyDiscount = moment
      .utc()
      .isBefore(moment.utc(charge.discount_limit_date));
    const applyLateFee = moment
      .utc()
      .isAfter(moment.utc(charge.expiration_date));

    if (charge.discount_limit_date && applyDiscount) {
      const discount_amount =
        (charge.discount_amount / 100) * charge.installments;

      amount = amount - discount_amount;
    }

    if (charge.expiration_date && applyLateFee) {
      const late_fee_amount =
        original_amount * (charge.late_fee_percentage / 100);
      const diffence_days = moment
        .utc()
        .diff(moment.utc(charge.expiration_date).format(), 'days');
      const interest_amount =
        (charge.interest_percentage / 30 / 100) *
        diffence_days *
        original_amount;

      amount = amount + late_fee_amount + interest_amount;
    }

    return amount / number_installments;
  };

  const renderBilletTable = () => {
    if (charges.length > 0) {
      return charges.map(charge => (
        <React.Fragment key={charge.id}>
          <ListItem className={classes.listItem} disableGutters>
            <Grid
              className={clsx(classes.tableCell, classes.tableTablet)}
              item
              md={3}
              sm={4}
            >
              <Typography noWrap variant="body1">
                {moment(charge.expiration_date)
                  .tz('America/Sao_Paulo')
                  .format('DD/MM/YYYY')}
              </Typography>
            </Grid>
            <Grid
              className={clsx(classes.tableCell, classes.tableTablet)}
              item
              md={2}
              sm={2}
            >
              <Typography
                noWrap
                variant="body1"
              >{`(${charge.installment}/${charge.installments})`}</Typography>
            </Grid>
            <Grid className={classes.tableCell} item md={3} sm={2} xs={5}>
              <ListItemText
                disableTypography
                primary={
                  <Typography noWrap>
                    {getDisplayMoney(getAmount(charge, charge.installments))}
                  </Typography>
                }
                secondary={
                  <Grid component="span" container direction="column">
                    <Grid component="span" item xs zeroMinWidth>
                      <Typography component="div" noWrap variant="body2">
                        {getDisplayInstallmentsSecondary(
                          charge.installment,
                          charge.installments
                        )}
                      </Typography>
                    </Grid>
                    <Grid component="span" item xs zeroMinWidth>
                      <Typography component="div" noWrap variant="body2">
                        {getDisplayExpirationDateSecondary(
                          charge.expiration_date
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                }
              />
            </Grid>
            <Grid className={classes.tableCell} item md={2} sm={2} xs={3}>
              <Typography noWrap variant="body1">
                {getDisplayStatus(charge.status)}
              </Typography>
            </Grid>
            <Grid
              className={clsx(
                classes.tableCell,
                classes.options,
                classes.justifyCenter
              )}
              item
              md={2}
              sm={2}
              xs={4}
            >
              <Button
                color="primary"
                disabled={checkChargeStatus(charge) || checkout_disable}
                onClick={() => handlePaymentBillet(charge)}
                size="small"
                startIcon={<DescriptionIcon />}
                variant="outlined"
              >
                Boleto
              </Button>
            </Grid>
          </ListItem>

          <Grid component={Divider} item xs={12} />
        </React.Fragment>
      ));
    }

    return <div />;
  };

  const renderPaymentOptions = () => {
    const rows = [];
    const amount = charge.original_amount / 100 - discount_amount + fee_amount;

    for (let index = 1; index <= charge.installments; index++) {
      rows.push(
        <MenuItem
          key={index}
          value={index}
        >{`${index} parcela(s) de R$ ${getDisplayMoney(
          amount / index
        )}`}</MenuItem>
      );
    }

    return rows;
  };

  const renderAlert = () => {
    let elements = [];

    if (charge_discount_expire.discount_limit_date && discount_amount > 0) {
      const amount =
        charge_discount_expire.original_amount / 100 - discount_amount;

      elements.push(
        <Alert
          className={classes.panel}
          key="alert_discount"
          severity="info"
          variant="filled"
        >
          <AlertTitle color="inherit">Desconto Promocional</AlertTitle>
          <Typography variant="inherit">
            Você tem um desconto no valor de {getDisplayMoney(discount_amount)}
          </Typography>
          <Typography variant="inherit">
            Valor total com desconto: {getDisplayMoney(amount)} ou{' '}
            {charge_discount_expire.installments}x{' '}
            {getDisplayMoney(amount / charge_discount_expire.installments)}
          </Typography>
          <Typography variant="inherit">
            Validade até:{' '}
            {moment(charge_discount_expire.discount_limit_date)
              .tz('America/Sao_Paulo')
              .format('DD/MM/YYYY HH:mm:ss')}
          </Typography>
        </Alert>,
        <Divider
          key="divider_alert_discount"
          style={{
            marginTop: '10px',
          }}
        />
      );
    }

    if (charge_expire.expiration_date && fee_amount > 0) {
      const diffence_days = moment
        .utc()
        .diff(moment.utc(charge_expire.expiration_date).format(), 'days');

      elements.push(
        <Alert
          className={classes.panel}
          key="alert_fee"
          severity="error"
          variant="filled"
        >
          <AlertTitle color="inherit">Taxa de atraso</AlertTitle>
          <Typography variant="inherit">
            Você tem uma taxa de atraso no valor de{' '}
            {getDisplayMoney(fee_amount)}
          </Typography>
          <Typography variant="inherit">
            Dias de atraso: {diffence_days}{' '}
            {diffence_days === 1 ? 'dia' : 'dias'}
          </Typography>
          <Typography variant="inherit">
            Parcela em atraso: {`${charge_expire.installment}ª parcela`}
          </Typography>
        </Alert>,
        <Divider
          key="divider_alert_fee"
          style={{
            marginTop: '10px',
          }}
        />
      );
    }

    return elements;
  };

  const hasError = field =>
    !!(formState.touched[field] && formState.errors[field]);

  if (charge.id) {
    return (
      <div className={classes.container}>
        <Card className={classes.root}>
          {renderAlert()}
          <CardHeader
            subheader={
              <Typography color="textSecondary" variant="h5">
                Este ambiente está seguro.
              </Typography>
            }
            title={
              <Typography variant="h3">
                Dados da cobrança{' '}
                {`(${charge.installment}/${charge.installments})`}
              </Typography>
            }
          />
          <Divider />
          <CardContent>
            <Grid container>
              <Grid item xs={12}>
                <Grid alignItems="flex-end" container direction="column">
                  <Grid item>
                    <Typography variant="h5">
                      Emitida por: {charge.seller_name}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="h5">
                      E-mail: {charge.seller_email}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              className={classes.content}
              container
              justify="center"
              spacing={2}
            >
              <Grid item md={3} xs={6}>
                <Grid alignItems="center" container direction="column">
                  <Typography color="textSecondary" variant="h5">
                    DATA DE VENCIMENTO
                  </Typography>
                  <Typography variant="h4">
                    {moment(charge.expiration_date)
                      .tz('America/Sao_Paulo')
                      .format('DD/MM/YYYY')}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item md={3} xs={6}>
                <Grid alignItems="center" container direction="column">
                  <Typography color="textSecondary" variant="h5">
                    PARCELAS
                  </Typography>
                  <Typography variant="h4">{`${
                    charge.installments
                  }x ${getDisplayMoney(
                    getAmount(charge, charge.installments)
                  )}`}</Typography>
                </Grid>
              </Grid>
              <Grid item md={3} xs={6}>
                <Grid alignItems="center" container direction="column">
                  <Typography color="textSecondary" variant="h5">
                    VALOR TOTAL
                  </Typography>
                  <Typography variant="h4">
                    {getDisplayMoney(
                      charge.original_amount / 100 -
                        discount_amount +
                        fee_amount
                    )}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item md={3} xs={6}>
                <Grid alignItems="center" container direction="column">
                  <Typography color="textSecondary" variant="h5">
                    STATUS
                  </Typography>
                  <Typography variant="h4">
                    {getDisplayStatus(charge.status)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardHeader
            title={<Typography variant="h3">Dados do pagador</Typography>}
          />
          <Divider />
          <CardContent>
            <Grid
              className={classes.content}
              container
              justify="center"
              spacing={2}
            >
              <Grid item md={3} xs={6}>
                <Grid alignItems="center" container direction="column">
                  <Typography color="textSecondary" variant="h5">
                    NOME
                  </Typography>
                  <Typography variant="h4">{charge.buyer_name}</Typography>
                </Grid>
              </Grid>
              <Grid item md={3} xs={6}>
                <Grid alignItems="center" container direction="column">
                  <Typography color="textSecondary" variant="h5">
                    CPF/CNPJ
                  </Typography>
                  <Typography variant="h4">{charge.document_number}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
          <CardActions>
            <Grid container justify="center" spacing={2}>
              <Grid item xs={6}>
                <Button
                  color="primary"
                  disabled={
                    !!(
                      checkChargeStatus(charge) ||
                      checkout_disable ||
                      !charge.payment_types_accepted.includes('billet')
                    )
                  }
                  fullWidth
                  onClick={() => handleChangePaymentType('billet')}
                  size="small"
                  variant="contained"
                >
                  Pagar com boleto bancário
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  color="primary"
                  disabled={
                    !!(
                      checkChargeStatus(charge) ||
                      checkout_disable ||
                      !charge.payment_types_accepted.includes('credit_card')
                    )
                  }
                  fullWidth
                  onClick={() => handleChangePaymentType('credit_card')}
                  size="small"
                  variant="contained"
                >
                  Pagar com cartão de crédito
                </Button>
              </Grid>
            </Grid>
          </CardActions>
        </Card>
        <ExpansionPanel
          className={classes.panel}
          expanded={expanded === 'billet'}
        >
          <ExpansionPanelSummary
            aria-controls="panel-billet"
            className={classes.panelHeader}
            id="panel-billet"
          />
          <ExpansionPanelDetails>
            <Grid component={List} container>
              <ListItem disableGutters>
                <Grid
                  className={clsx(classes.tableCell, classes.tableTablet)}
                  item
                  md={3}
                  sm={4}
                >
                  <Typography noWrap variant="h5">
                    Data de Vencimento
                  </Typography>
                </Grid>
                <Grid
                  className={clsx(classes.tableCell, classes.tableTablet)}
                  item
                  md={2}
                  sm={2}
                >
                  <Typography noWrap variant="h5">
                    Parcela
                  </Typography>
                </Grid>
                <Grid className={classes.tableCell} item md={3} sm={2} xs={5}>
                  <Typography noWrap variant="h5">
                    Valor
                  </Typography>
                </Grid>
                <Grid className={classes.tableCell} item md={2} sm={2} xs={3}>
                  <Typography noWrap variant="h5">
                    Status
                  </Typography>
                </Grid>
              </ListItem>

              <Grid component={Divider} item xs={12} />

              {renderBilletTable()}
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          className={classes.panel}
          expanded={expanded === 'credit_card'}
        >
          <ExpansionPanelSummary
            aria-controls="panel-credit_card"
            className={classes.panelHeader}
            id="panel-credit_card"
          />
          <ExpansionPanelDetails>
            <Grid
              alignItems="center"
              className={classes.containerCreditCard}
              container
              spacing={2}
            >
              <Grid className={classes.contentCreditCard} item md={6} xs={12}>
                <Cards
                  cvc={formState.values.cvc || ''}
                  expiry={formState.values.expiry || ''}
                  focused={formState.focus || ''}
                  name={formState.values.name || ''}
                  number={formState.values.number || ''}
                  placeholders={{
                    name: 'SEU NOME AQUI',
                  }}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <form>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        InputProps={{
                          inputComponent: InputMask,
                        }}
                        error={hasError('number')}
                        fullWidth
                        helperText={
                          hasError('number') ? formState.errors.number[0] : ''
                        }
                        id="number"
                        inputProps={{
                          mask: creditCardNumberMask,
                        }}
                        label="Número do cartão"
                        name="number"
                        onChange={handleChangeForm}
                        onFocus={handleInputFocus}
                        required
                        type="tel" // eslint-disable-next-line
                        value={formState.values.number || ''}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        error={hasError('name')}
                        fullWidth
                        helperText={
                          hasError('name') ? formState.errors.name[0] : ''
                        }
                        id="name"
                        label="Nome do Titular"
                        name="name"
                        onChange={handleChangeForm}
                        onFocus={handleInputFocus}
                        required
                        value={formState.values.name || ''}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        InputProps={{
                          inputComponent: InputMask,
                        }}
                        error={hasError('expiry')}
                        fullWidth
                        helperText={
                          hasError('expiry') ? formState.errors.expiry[0] : ''
                        }
                        id="expiry"
                        inputProps={{
                          mask: creditCardExpiryMask,
                        }}
                        label="Data de expiração"
                        name="expiry"
                        onChange={handleChangeForm}
                        onFocus={handleInputFocus}
                        required
                        type="tel" // eslint-disable-next-line
                        value={formState.values.expiry || ''}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        InputProps={{
                          inputComponent: InputMask,
                        }}
                        error={hasError('cvc')}
                        fullWidth
                        helperText={
                          hasError('cvc') ? formState.errors.cvc[0] : ''
                        }
                        id="cvc"
                        inputProps={{
                          mask: [/[0-9]/, /\d/, /\d/],
                        }}
                        label="CVC"
                        name="cvc"
                        onChange={handleChangeForm}
                        onFocus={handleInputFocus}
                        required
                        type="tel" // eslint-disable-next-line
                        value={formState.values.cvc || ''}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        SelectProps={{
                          MenuProps: {
                            classes: {
                              paper: classes.menuList,
                            },
                          },
                        }}
                        fullWidth
                        id="installments"
                        label="Número de parcelas"
                        name="installments"
                        onChange={handleChangeInstallments}
                        onFocus={handleInputFocus}
                        select
                        value={installments}
                        variant="outlined"
                      >
                        {renderPaymentOptions()}
                      </TextField>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        color="primary"
                        disabled={
                          !!(
                            checkChargeStatus(charge) ||
                            !formState.isValid ||
                            checkout_disable
                          )
                        }
                        fullWidth
                        onClick={handlePaymentCreditCard}
                        variant="contained"
                      >
                        Confirmar Pagamento
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Grid>
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    );
  } else {
    return <div />;
  }
};

export default Checkout;
