import * as Mui from '@material-ui/core';

const useStyles = Mui.makeStyles(theme => ({
  appBar: {
    position: 'relative',
    paddingRight: '0px !important',
  },
  title: {
    flex: 1,
    color: '#fff',
  },
  container: {
    padding: 0,
    width: '100%',
    maxWidth: '100%',
    [theme.breakpoints.up('md')]: {
      minWidth: 400,
    },
  },
  closeButton: {
    color: theme.palette.white,
  },
  clearFilterButton: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  checkbox: {
    marginRight: theme.spacing(1),
  },
}));

export default useStyles;
