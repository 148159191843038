import React from 'react';
import * as ReactRedux from 'react-redux';
import moment from 'moment';

import type { IBuyer } from 'types/entities';
import type { ICustomerReducerState } from 'types/redux';
import type { IResourceListInterface } from 'types/services';
import { api } from 'services/api';
import {
  setCustomerListType,
  setCustomerPagination,
  setCustomerState,
  setListCustomers,
  setSnackbarMessage,
  setSnackbarOpen,
  setSnackbarType,
} from 'store/actions';

interface IBuyerServicePaginationParams {
  limit?: number;
  offset?: number;
}

type TBuyerServiceGetAllParams = IBuyerServicePaginationParams;

interface IBuyerServiceSearchParams extends IBuyerServicePaginationParams {
  search?: string;
  dateStart?: string;
  dateEnd?: string;
}

type IBuyerServiceReportParams = IBuyerServiceSearchParams;

function useBuyerService() {
  const dispatch = ReactRedux.useDispatch();

  const getAll = React.useCallback(
    async (params?: TBuyerServiceGetAllParams): Promise<void> => {
      try {
        const token = localStorage.getItem('token');

        const response = await api.get<IResourceListInterface<IBuyer>>(
          '/buyer',
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              limit: params?.limit,
              offset: params?.offset,
            },
          }
        );

        const {
          page,
          offset,
          limit,
          has_more: hasMore,
          items: customers,
          total,
        } = response.data;

        const pagination: ICustomerReducerState['pagination'] = {
          hasMore,
          limit,
          offset,
          page,
          total,
        };

        dispatch([
          setListCustomers(customers),
          setCustomerListType('get'),
          setCustomerState('success'),
          setCustomerPagination(pagination),
        ]);
      } catch (error) {
        dispatch([
          setSnackbarMessage(
            'Não foi possível fazer a listagem de clientes, tente novamente mais tarde!'
          ),
          setSnackbarType('error'),
          setSnackbarOpen(),
        ]);
        console.error('Não foi possível buscar a lista de clientes');
        throw error;
      }
    },
    [dispatch]
  );

  const search = React.useCallback(
    async (params: IBuyerServiceSearchParams) => {
      try {
        const {
          search,
          dateStart,
          dateEnd,
          offset: offsetParam,
          limit: limitParam,
        } = params;
        const token = localStorage.getItem('token');

        const response = await api.get<IResourceListInterface<IBuyer>>(
          '/buyer/search',
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              query: search,
              dateStart: dateStart
                ? moment.utc(dateStart).startOf('day').format()
                : undefined,
              dateEnd: dateEnd
                ? moment.utc(dateEnd).endOf('day').format()
                : undefined,
              limit: limitParam,
              offset: offsetParam,
            },
          }
        );

        const {
          page,
          offset,
          limit,
          has_more: hasMore,
          items: customers,
          total,
        } = response.data;

        const pagination: ICustomerReducerState['pagination'] = {
          hasMore,
          limit,
          offset,
          page,
          total,
        };

        dispatch([
          setListCustomers(customers),
          setCustomerListType('search'),
          setCustomerState('success'),
          setCustomerPagination(pagination),
        ]);
      } catch (error) {
        dispatch([
          setSnackbarMessage(
            'Não foi possível fazer a listagem de clientes, tente novamente mais tarde!'
          ),
          setSnackbarType('error'),
          setSnackbarOpen(),
        ]);
        console.error('Não foi possível buscar a lista de clientes');
        throw error;
      }
    },
    [dispatch]
  );

  const report = React.useCallback(
    async (params: IBuyerServiceReportParams) => {
      try {
        const { search, dateStart, dateEnd, offset, limit } = params;
        const token = localStorage.getItem('token');

        const response = await api.get('/buyer/report', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            query: search,
            dateStart: dateStart
              ? moment.utc(dateStart).startOf('day').format()
              : undefined,
            dateEnd: dateEnd
              ? moment.utc(dateEnd).endOf('day').format()
              : undefined,
            limit,
            offset,
          },
          responseType: 'blob',
        });

        const blob = response.data;
        const linkElement = document.createElement('a');
        linkElement.href = URL.createObjectURL(blob);
        linkElement.download = 'customers.csv';
        linkElement.click();
        setTimeout(() => URL.revokeObjectURL(linkElement.href), 0);
      } catch (error) {
        dispatch([
          setSnackbarMessage(
            'Não foi possível gerar o relatório de clientes, tente novamente mais tarde!'
          ),
          setSnackbarType('error'),
          setSnackbarOpen(),
          setCustomerState('failed'),
        ]);
        console.error('Não foi possível gerar o relatório de clientes');
        throw error;
      }
    },
    [dispatch]
  );

  return React.useMemo(
    () => ({
      getAll,
      report,
      search,
    }),
    [getAll, search, report]
  );
}

export default useBuyerService;
