import type {
  IPlanReducerAction,
  IPlanReducerActionPayload,
  IPlanReducerState,
} from 'types/redux';

export function setPlanState(
  state: IPlanReducerState['state']
): IPlanReducerAction<IPlanReducerActionPayload['PLAN_STATE_CHANGED']> {
  return {
    type: 'PLAN_STATE_CHANGED',
    payload: state,
  };
}

export function setPlan(
  plan: IPlanReducerState['plan']
): IPlanReducerAction<IPlanReducerActionPayload['PLAN_CHANGED']> {
  return {
    type: 'PLAN_CHANGED',
    payload: plan,
  };
}

export function setListPlans(
  plans: IPlanReducerState['listPlans']
): IPlanReducerAction<IPlanReducerActionPayload['LIST_PLANS_CHANGED']> {
  return {
    type: 'LIST_PLANS_CHANGED',
    payload: plans,
  };
}

export function setPlanPagination(pagination: IPlanReducerState['pagination']) {
  return {
    type: 'PLAN_PAGINATION_CHANGED',
    payload: pagination,
  } as IPlanReducerAction<IPlanReducerActionPayload['PLAN_PAGINATION_CHANGED']>;
}
