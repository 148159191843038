import React from 'react';
import * as Mui from '@material-ui/core';

import * as BuyerHooks from 'hooks/useBuyer';

import useStyles from './CustomerEmptyState.styles';

function CustomerEmptyState() {
  const classes = useStyles();

  const buyer = BuyerHooks.useBuyer();

  return (
    <Mui.Grid className={classes.container} container>
      <Mui.Typography className={classes.text} variant="h5">
        Você ainda não possui cliente!
      </Mui.Typography>
      <Mui.Button
        className={classes.button}
        color="primary"
        disableFocusRipple
        disableRipple
        onClick={buyer.ui.openToCreate}
        variant="text"
      >
        Crie um agora
      </Mui.Button>
    </Mui.Grid>
  );
}

export default CustomerEmptyState;
