import * as Mui from '@material-ui/core';
import React from 'react';

import { InputCurrency } from 'components';
import { PixUtils } from 'helpers/pix-utils';
import type { ITransaction } from 'types/entities';
import type { IFormState } from '../../CheckoutEditDialog.hook';
import useStyles from './checkout-edit-pix.styles';

interface ICheckoutEditPixProps {
  formState: IFormState;
  handlePixChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleDiscountValueChange: (
    paymentType: ITransaction['payment_type'],
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;

  hasPixError: (field: keyof IFormState['values']['pix']) => boolean;
}

export function CheckoutEditPix(params: ICheckoutEditPixProps) {
  const { formState, handlePixChange, handleDiscountValueChange, hasPixError } =
    params;
  const classes = useStyles();

  return (
    <Mui.Grid container item spacing={2} xs={12}>
      <Mui.Grid item xs={12}>
        <Mui.Typography variant="h4">PIX</Mui.Typography>
      </Mui.Grid>

      <Mui.Grid item md={4} sm={6} xs={12}>
        <Mui.TextField
          InputLabelProps={{
            classes: {
              disabled: classes.selectDisabled,
            },
          }}
          SelectProps={{
            classes: {
              icon: classes.selectIcon,
              disabled: classes.selectDisabled,
            },
            MenuProps: {
              classes: {
                paper: classes.selectMenuList,
              },
            },
          }}
          error={hasPixError('discountType')}
          fullWidth
          helperText={
            hasPixError('discountType') && formState.errors.pix?.discountType
          }
          label="Tipo de desconto"
          name="pix-discountType"
          onChange={handlePixChange}
          select
          value={formState.values.pix.discountType}
          variant="outlined"
        >
          {Object.entries(PixUtils.discountTypeLabelMap).map(
            ([value, label]) => {
              return (
                <Mui.MenuItem
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  ListItemClasses={{
                    root: classes.selectMenuItem,
                    selected: classes.selectItemSelected,
                  }}
                  button
                  key={value}
                  selected={value === formState.values.pix.discountType}
                  value={value}
                >
                  {label}
                </Mui.MenuItem>
              );
            }
          )}
        </Mui.TextField>
      </Mui.Grid>

      <Mui.Grid item md={4} sm={6} xs={12}>
        {formState.values.pix.discountType === 'fixed' ? (
          <InputCurrency
            error={hasPixError('discount')}
            fullWidth
            helperText={
              hasPixError('discount') && formState.errors.pix?.discount
            }
            id="pix-discount"
            label="Valor do desconto"
            name="pix-discount"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleDiscountValueChange('pix', event)
            }
            type="tel"
            value={formState.values.pix.discount}
            variant="outlined"
          />
        ) : (
          <Mui.TextField
            error={hasPixError('discount')}
            fullWidth
            helperText={
              hasPixError('discount') && formState.errors.pix?.discount
            }
            id="pix-discount"
            label="Valor do desconto"
            name="pix-discount"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleDiscountValueChange('pix', event)
            }
            type="tel"
            value={formState.values.pix.discount}
            variant="outlined"
          />
        )}
      </Mui.Grid>

      <Mui.Grid item md={4} sm={6} xs={12}>
        <Mui.TextField
          InputLabelProps={{
            classes: {
              disabled: classes.selectDisabled,
            },
          }}
          SelectProps={{
            classes: {
              icon: classes.selectIcon,
              disabled: classes.selectDisabled,
            },
            MenuProps: {
              classes: {
                paper: classes.selectMenuList,
              },
            },
          }}
          error={hasPixError('isInstallmentsEnabled')}
          fullWidth
          helperText={
            hasPixError('isInstallmentsEnabled') &&
            formState.errors.pix?.isInstallmentsEnabled
          }
          label="Aceitar parcelamento"
          name="pix-isInstallmentsEnabled"
          onChange={handlePixChange}
          select
          value={formState.values.pix.isInstallmentsEnabled}
          variant="outlined"
        >
          <Mui.MenuItem button value="true">
            SIM
          </Mui.MenuItem>
          <Mui.MenuItem button value="false">
            NÃO
          </Mui.MenuItem>
        </Mui.TextField>
      </Mui.Grid>

      {formState.values.pix.isInstallmentsEnabled.toString() === 'true' && (
        <>
          <Mui.Grid item md={4} sm={6} xs={12}>
            <Mui.TextField
              error={hasPixError('defaultInstallments')}
              fullWidth
              helperText={
                hasPixError('defaultInstallments') &&
                formState.errors.pix?.defaultInstallments
              }
              id="pix-defaultInstallments"
              label="Parcelas padrão"
              name="pix-defaultInstallments"
              onChange={handlePixChange}
              type="number"
              value={formState.values.pix.defaultInstallments || ''}
              variant="outlined"
            />
          </Mui.Grid>

          <Mui.Grid item md={4} sm={6} xs={12}>
            <Mui.TextField
              error={hasPixError('minInstallments')}
              fullWidth
              helperText={
                hasPixError('minInstallments') &&
                formState.errors.pix?.minInstallments
              }
              id="pix-minInstallments"
              label="Mínimo de parcelas"
              name="pix-minInstallments"
              onChange={handlePixChange}
              type="number"
              value={formState.values.pix.minInstallments || ''}
              variant="outlined"
            />
          </Mui.Grid>

          <Mui.Grid item md={4} sm={6} xs={12}>
            <Mui.TextField
              error={hasPixError('maxInstallments')}
              fullWidth
              helperText={
                hasPixError('maxInstallments') &&
                formState.errors.pix?.maxInstallments
              }
              id="pix-maxInstallments"
              label="Máximo de parcelas"
              name="pix-maxInstallments"
              onChange={handlePixChange}
              type="number"
              value={formState.values.pix.maxInstallments || ''}
              variant="outlined"
            />
          </Mui.Grid>
        </>
      )}
    </Mui.Grid>
  );
}
