export function setSalesPlanState(state) {
  return {
    type: 'SALES_PLAN_STATE_CHANGED',
    payload: state,
  };
}

export function setSalesPlan(plan) {
  return {
    type: 'SALES_PLAN_CHANGED',
    payload: plan,
  };
}
