import React from 'react';

import * as ChargeHooks from 'hooks/use-charge';
import * as InternalComponents from './components';

function ChargeList() {
  const charge = ChargeHooks.useCharge();
  const { state, listCharges, pagination } = charge.state;

  React.useEffect(() => {
    if (state === 'get') {
      charge.service.getAll({
        offset: pagination?.offset,
        limit: pagination?.limit,
      });
    }
  }, [charge.service, pagination?.limit, pagination?.offset, state]);

  if (state === 'get') {
    return <InternalComponents.ChargeListLoading />;
  } else if (listCharges.length > 0) {
    return <InternalComponents.ChargeListTable />;
  } else if (state === 'failed') {
    return <InternalComponents.ChargeFailedState />;
  } else {
    return <InternalComponents.ChargeEmptyState />;
  }
}

export default ChargeList;
