import React from 'react';
import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';
import * as ReactRedux from 'react-redux';
import clsx from 'clsx';
import moment from 'moment-timezone';

import * as SaleHooks from 'hooks/useSale';
import type { ICard, ITransaction } from 'types/entities';
import type { ICardBrandIconProps } from 'components';
import { CardBrandIcon } from 'components';
import { getDisplayMoney } from 'helpers';
import PixImg from 'assets/images/pix-icon.png';
import {
  setDialogFullScreen,
  setDialogMaxWidth,
  setDialogOpen,
  setDialogType,
  setSale,
  setSaleListType,
  setSalePagination,
  setSaleState,
} from 'store/actions';

import useStyles from './SaleListTable.styles';
import { getDisplayEmail, getDisplayPhoneNumber } from 'helpers';

const variantIcon: Record<
  ITransaction['status'],
  Record<'label' | 'icon', string | MuiIcons.SvgIconComponent>
> = {
  canceled: {
    label: 'Cancelada',
    icon: MuiIcons.Cancel,
  },
  charged_back: {
    label: 'Chargeback',
    icon: MuiIcons.ThreeSixty,
  },
  dispute: {
    label: 'Disputa',
    icon: MuiIcons.Gavel,
  },
  failed: {
    label: 'Falhada',
    icon: MuiIcons.Cancel,
  },
  new: {
    label: 'Nova',
    icon: MuiIcons.AccessTime,
  },
  pending: {
    label: 'Pendente',
    icon: MuiIcons.AccessTime,
  },
  pre_authorized: {
    label: 'Pré-autorizada',
    icon: MuiIcons.AccessTime,
  },
  refunded: {
    label: 'Estornada',
    icon: MuiIcons.ThreeSixty,
  },
  reversed: {
    label: 'Revertida',
    icon: MuiIcons.ThreeSixty,
  },
  succeeded: {
    label: 'Aprovada',
    icon: MuiIcons.Done,
  },
};

function SaleListTable() {
  const classes = useStyles();
  const theme = Mui.useTheme();
  const dispatch = ReactRedux.useDispatch();
  const sale = SaleHooks.useSale();
  const { pagination, saleList } = sale.state;

  function handlePageChange(_: unknown, page: number) {
    if (!pagination) {
      return;
    }

    let newOffset: number;
    if (page + 1 > pagination.page) {
      newOffset = pagination.offset + pagination.limit;
    } else {
      newOffset = pagination.offset - pagination.limit;
    }

    dispatch([
      setSaleState('get'),
      setSaleListType('get'),
      setSalePagination({ ...pagination, offset: newOffset }),
    ]);
  }

  function handleRowsPerPageChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (!pagination) {
      return;
    }

    const limit = Number(event.target.value);

    dispatch([
      setSaleState('get'),
      setSaleListType('get'),
      setSalePagination({ ...pagination, limit, offset: 0 }),
    ]);
  }

  function getDisplaySecondaryAmount(amount: number) {
    const width = window.innerWidth;

    if (width < theme.breakpoints.values.sm) {
      return `Valor: ${amount}`;
    }

    return '';
  }

  function getDisplayPaymentMethod(
    sale: ITransaction
  ): React.ReactNode | React.ReactNode[] {
    const { payment_type, installment_plan } = sale;

    if (payment_type === 'boleto') {
      return 'Boleto Bancário';
    } else if (payment_type === 'credit') {
      const card = sale.payment_method as ICard;

      if (!installment_plan || installment_plan === 1) {
        return (
          <div className={classes.paymentMethodContainer}>
            <div className={classes.cardBrandIconContainer}>
              <CardBrandIcon
                brand={
                  card.card_brand.toLowerCase() as ICardBrandIconProps['brand']
                }
              />
            </div>
            Crédito à vista
          </div>
        );
      } else {
        return (
          <div className={classes.paymentMethodContainer}>
            <div className={classes.cardBrandIconContainer}>
              <CardBrandIcon
                brand={
                  card.card_brand.toLowerCase() as ICardBrandIconProps['brand']
                }
              />
            </div>
            Crédito em {installment_plan}x
          </div>
        );
      }
    } else if (payment_type === 'pix') {
      return (
        <div className={classes.paymentMethodContainer}>
          <img alt="pix" className={classes.pixIcon} src={PixImg} />{' '}
          <span>PIX</span>
        </div>
      );
    }
  }

  function handleViewSale(sale: ITransaction) {
    dispatch([
      setSale(sale),
      setSaleState('view'),
      setDialogType('viewSale'),
      setDialogFullScreen(true),
      setDialogMaxWidth(false),
      setDialogOpen(),
    ]);
  }

  return (
    <Mui.Grid className={classes.root} component={Mui.List} container>
      <Mui.Grid item xs={12}>
        <Mui.TablePagination
          SelectProps={{
            classes: {
              icon: classes.icon,
            },
            MenuProps: {
              classes: {
                paper: classes.menuList,
              },
            },
          }}
          backIconButtonText="Voltar página"
          component="div"
          count={pagination?.total || 0}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to === -1 ? count : to} de ${
              count !== -1 ? count : `mais que ${to}`
            }`
          }
          labelRowsPerPage=""
          nextIconButtonText="Próxima página"
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          page={(pagination?.page || 1) - 1}
          rowsPerPage={pagination?.limit || 30}
          rowsPerPageOptions={[30, 50, 100]}
        />
      </Mui.Grid>
      <Mui.ListItem disableGutters>
        <Mui.Grid
          className={clsx(classes.tableCell, classes.justifyContentCenter)}
          item
          sm={1}
          xs={3}
        >
          <Mui.Typography noWrap variant="h5">
            Status
          </Mui.Typography>
        </Mui.Grid>
        <Mui.Grid className={clsx(classes.tableCell)} item md={3} sm={3} xs={8}>
          <Mui.Typography noWrap variant="h5">
            Cliente
          </Mui.Typography>
        </Mui.Grid>
        <Mui.Grid
          className={clsx(classes.tableCell, classes.tableTablet)}
          item
          sm={2}
        >
          <Mui.Typography noWrap variant="h5">
            Valor
          </Mui.Typography>
        </Mui.Grid>
        <Mui.Grid
          className={clsx(classes.tableCell, classes.tableTablet)}
          item
          md={2}
          sm={2}
        >
          <Mui.Typography noWrap variant="h5">
            Data da venda
          </Mui.Typography>
        </Mui.Grid>
        <Mui.Grid
          className={clsx(classes.tableCell, classes.tableTablet)}
          item
          md={2}
          sm={3}
        >
          <Mui.Typography noWrap variant="h5">
            Forma de Pagamento
          </Mui.Typography>
        </Mui.Grid>
      </Mui.ListItem>

      <Mui.Grid component={Mui.Divider} item xs={12} />

      {saleList.map(sale => {
        const { label, icon: Icon } = variantIcon[sale.status];
        const amount_display = getDisplayMoney(sale.amount / 100);

        return (
          <Mui.ListItem
            className={classes.listItem}
            disableGutters
            key={sale.id}
          >
            <Mui.Grid
              className={clsx(classes.tableCell, classes.justifyContentCenter)}
              item
              sm={1}
              xs={3}
            >
              <Mui.Tooltip title={label}>
                <Icon className={classes[sale.status]} />
              </Mui.Tooltip>
            </Mui.Grid>
            <Mui.Grid className={classes.tableCell} item md={3} sm={3} xs={8}>
              <Mui.ListItemText
                disableTypography
                primary={
                  <Mui.Typography noWrap variant="h5">
                    {sale.customer.name}
                  </Mui.Typography>
                }
                secondary={
                  <Mui.Grid component="span" container direction="column">
                    <Mui.Grid component="span" item xs zeroMinWidth>
                      <Mui.Typography component="div" noWrap variant="body2">
                        {getDisplayEmail(sale.customer.email)}
                      </Mui.Typography>
                    </Mui.Grid>
                    <Mui.Grid component="span" item xs zeroMinWidth>
                      <Mui.Typography component="div" noWrap variant="body2">
                        {getDisplayPhoneNumber(sale.customer.phone_number)}
                      </Mui.Typography>
                    </Mui.Grid>
                    <Mui.Grid
                      className={classes.displayAmountOnCustomer}
                      component="span"
                      item
                      xs
                      zeroMinWidth
                    >
                      <Mui.Typography component="div" noWrap variant="body2">
                        {getDisplaySecondaryAmount(amount_display)}
                      </Mui.Typography>
                    </Mui.Grid>
                  </Mui.Grid>
                }
              />
            </Mui.Grid>
            <Mui.Grid
              className={clsx(classes.tableCell, classes.tableTablet)}
              item
              sm={2}
            >
              <Mui.Typography noWrap variant="body1">
                {getDisplayMoney(sale.amount / 100)}
              </Mui.Typography>
            </Mui.Grid>
            <Mui.Grid
              className={clsx(classes.tableCell, classes.tableTablet)}
              item
              md={2}
              sm={2}
            >
              <Mui.ListItemText
                disableTypography
                primary={
                  <Mui.Typography component="div" noWrap variant="body1">
                    {moment(sale.created_at).format('DD/MM/YYYY')}
                  </Mui.Typography>
                }
                secondary={
                  <Mui.Typography component="div" noWrap variant="body1">
                    {moment(sale.created_at).format('HH:mm:ss')}
                  </Mui.Typography>
                }
              />
            </Mui.Grid>
            <Mui.Grid
              className={clsx(classes.tableCell, classes.tableTablet)}
              item
              md={2}
              sm={3}
            >
              <Mui.Typography noWrap variant="body1">
                {getDisplayPaymentMethod(sale)}
              </Mui.Typography>
            </Mui.Grid>
            <Mui.Grid
              className={clsx(classes.tableCell, classes.justifyContentCenter)}
              item
              xs={1}
            >
              <Mui.Tooltip title="Visualizar">
                <Mui.IconButton onClick={() => handleViewSale(sale)}>
                  <MuiIcons.Visibility className={classes.iconButton} />
                </Mui.IconButton>
              </Mui.Tooltip>
            </Mui.Grid>
          </Mui.ListItem>
        );
      })}

      <Mui.Grid component={Mui.Divider} item xs={12} />

      <Mui.Grid item xs={12}>
        <Mui.TablePagination
          SelectProps={{
            classes: {
              icon: classes.icon,
            },
            MenuProps: {
              classes: {
                paper: classes.menuList,
              },
            },
          }}
          backIconButtonText="Voltar página"
          component="div"
          count={pagination?.total || 0}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to === -1 ? count : to} de ${
              count !== -1 ? count : `mais que ${to}`
            }`
          }
          labelRowsPerPage=""
          nextIconButtonText="Próxima página"
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          page={(pagination?.page || 1) - 1}
          rowsPerPage={pagination?.limit || 30}
          rowsPerPageOptions={[30, 50, 100]}
        />
      </Mui.Grid>
    </Mui.Grid>
  );
}

export default SaleListTable;
