import React from 'react';
import * as ReactRedux from 'react-redux';

import {
  setDialogFullScreen,
  setDialogOpen,
  setDialogType,
} from 'store/actions';
import {
  setTaxInvoiceConfig,
  setTaxInvoiceConfigState,
} from 'store/actions/tax-invoice-config-actions';

function useTaxInvoiceConfigUI() {
  const dispatch = ReactRedux.useDispatch();

  const openToConfig = React.useCallback(() => {
    dispatch([
      setTaxInvoiceConfig(null),
      setTaxInvoiceConfigState('config'),
      setDialogType('configTaxInvoice'),
      setDialogFullScreen(true),
      setDialogOpen(),
    ]);
  }, [dispatch]);

  const refresh = React.useCallback(() => {
    dispatch([setTaxInvoiceConfig(null), setTaxInvoiceConfigState('get')]);
  }, [dispatch]);

  return React.useMemo(
    () => ({
      openToConfig,
      refresh,
    }),
    [openToConfig, refresh]
  );
}

export default useTaxInvoiceConfigUI;
