import type {
  ISubscriptionReducerAction,
  ISubscriptionReducerActionPayload,
  ISubscriptionReducerState,
} from 'types/redux';

export function setSubscriptionState(
  state: ISubscriptionReducerState['state']
): ISubscriptionReducerAction<
  ISubscriptionReducerActionPayload['SUBSCRIPTION_STATE_CHANGED']
> {
  return {
    type: 'SUBSCRIPTION_STATE_CHANGED',
    payload: state,
  };
}

export function setSubscription(
  subscription: ISubscriptionReducerState['subscription']
): ISubscriptionReducerAction<
  ISubscriptionReducerActionPayload['SUBSCRIPTION_CHANGED']
> {
  return {
    type: 'SUBSCRIPTION_CHANGED',
    payload: subscription,
  };
}

export function setListSubscriptions(
  subscriptions: ISubscriptionReducerState['listSubscriptions']
): ISubscriptionReducerAction<
  ISubscriptionReducerActionPayload['LIST_SUBSCRIPTIONS_CHANGED']
> {
  return {
    type: 'LIST_SUBSCRIPTIONS_CHANGED',
    payload: subscriptions,
  };
}

export function setSubscriptionPagination(
  pagination: ISubscriptionReducerState['pagination']
) {
  return {
    type: 'SUBSCRIPTION_PAGINATION_CHANGED',
    payload: pagination,
  } as ISubscriptionReducerAction<
    ISubscriptionReducerActionPayload['SUBSCRIPTION_PAGINATION_CHANGED']
  >;
}
