import type { ITransaction } from '../types/entities';

export class TransactionUtils {
  public static statusLabelMap: Record<ITransaction['status'], string> = {
    succeeded: 'Aprovada',
    new: 'Nova',
    refunded: 'Estornada',
    pending: 'Pendente',
    canceled: 'Cancelada',
    failed: 'Falhada',
    charged_back: 'Chargeback',
    dispute: 'Em disputa',
    pre_authorized: 'Pré-autorizada',
    reversed: 'Revertida',
  };

  public static paymentTypeLabelMap: Record<
    ITransaction['payment_type'],
    string
  > = {
    credit: 'Cartão',
    boleto: 'Boleto',
    pix: 'PIX',
  };

  public static getStatusLabel(status: ITransaction['status']): string {
    return this.statusLabelMap[status] || '';
  }

  public static getPaymentTypeLabel(
    paymentType: ITransaction['payment_type']
  ): string {
    return this.paymentTypeLabelMap[paymentType] || '';
  }
}
