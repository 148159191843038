import React from 'react';
import * as Mui from '@material-ui/core';
import clsx from 'clsx';

import { SearchInput } from 'components';

import useStyles from './SubscriptionsToolbar.styles';

type SubscriptionsToolbarProps = React.HTMLAttributes<HTMLDivElement>;

const SubscriptionsToolbarComponent = (props: SubscriptionsToolbarProps) => {
  const { className, ...rest } = props;

  const classes = useStyles();

  const [query, setQuery] = React.useState('');

  function handleSearch(event: React.ChangeEvent<HTMLInputElement>) {
    const query = event.target.value;

    setQuery(query);
  }

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div className={classes.row}>
        <div className={classes.leftActionsContainer} />
        <span className={classes.spacer} />
        <div className={classes.rightActionsContainer}>
          {/* <Button className={classes.importButton}>Importar</Button> */}
          <Mui.Tooltip title="Em breve">
            <div>
              <Mui.Button className={classes.exportButton} disabled>
                Exportar
              </Mui.Button>
            </div>
          </Mui.Tooltip>
          <Mui.Tooltip title="Em breve">
            <div>
              <Mui.Button
                className={classes.buttonCreate}
                color="primary"
                disabled
                variant="contained"
              >
                Adicionar assinatura
              </Mui.Button>
            </div>
          </Mui.Tooltip>
        </div>
      </div>
      <Mui.Tooltip title="Em breve">
        <div className={classes.row}>
          <SearchInput
            className={classes.searchInput}
            disabled
            onChange={handleSearch}
            placeholder="Pesquise uma assinatura"
            value={query}
          />
        </div>
      </Mui.Tooltip>
    </div>
  );
};

export default SubscriptionsToolbarComponent;
