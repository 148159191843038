import React from 'react';
import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';

import * as PlanHooks from 'hooks/usePlan';

import useStyles from './PlanFailedState.styles';

function PlanFailedState() {
  const classes = useStyles();

  const plan = PlanHooks.usePlan();

  return (
    <Mui.Grid
      alignItems="center"
      className={classes.container}
      container
      direction="column"
      justifyContent="center"
      spacing={2}
    >
      <Mui.Typography variant="body2">
        Não foi possível se conectar ao servidor. Tente novamente...
      </Mui.Typography>
      <Mui.IconButton onClick={plan.ui.refresh}>
        <MuiIcons.Refresh />
      </Mui.IconButton>
    </Mui.Grid>
  );
}

export default PlanFailedState;
