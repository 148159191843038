import React from 'react';
import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';
import * as MuiPickers from '@material-ui/pickers';
import * as ReactRedux from 'react-redux';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import type { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import type { Moment } from 'moment';

import * as SaleHooks from 'hooks/useSale';
import { DateFilterUtils } from 'helpers';
import {
  setDialogClose,
  setSaleFilter,
  setSalePagination,
} from 'store/actions';

import useStyles from './SalesFilterDateDialog.styles';

interface Options {
  label: string;
  dateStart: Moment | null;
  dateEnd: Moment | null;
}

const options: Options[] = [
  {
    label: 'Hoje',
    dateStart: moment(DateFilterUtils.startOfToday),
    dateEnd: moment(DateFilterUtils.endOfToday),
  },
  {
    label: 'Ontem',
    dateStart: moment(DateFilterUtils.startOfYesterday),
    dateEnd: moment(DateFilterUtils.endOfYesterday),
  },
  {
    label: 'Esta semana',
    dateStart: moment(DateFilterUtils.startOfWeek),
    dateEnd: moment(DateFilterUtils.endOfWeek),
  },
  {
    label: 'Semana passada',
    dateStart: moment(DateFilterUtils.startOfLastWeek),
    dateEnd: moment(DateFilterUtils.endOfLastWeek),
  },
  {
    label: 'Este mês',
    dateStart: moment(DateFilterUtils.startOfMonth),
    dateEnd: moment(DateFilterUtils.endOfMonth),
  },
  {
    label: 'Mês passado',
    dateStart: moment(DateFilterUtils.startOfLastMonth),
    dateEnd: moment(DateFilterUtils.endOfLastMonth),
  },
  {
    label: 'Todas',
    dateStart: null,
    dateEnd: null,
  },
];

function SalesFilterDateDialog() {
  const classes = useStyles();

  const dispatch = ReactRedux.useDispatch();
  const sale = SaleHooks.useSale();
  const { filter, pagination } = sale.state;

  const [dateStart, setDateStart] = React.useState<Moment | null>(
    filter.dateStart ? moment(filter.dateStart) : null
  );
  const [dateEnd, setDateEnd] = React.useState<Moment | null>(
    filter.dateEnd ? moment(filter.dateEnd) : null
  );

  function handleClose() {
    dispatch(setDialogClose());
  }

  function handleFilterDateChange() {
    sale.ui.search();

    dispatch([
      setSaleFilter({
        dateStart: dateStart ? dateStart.toISOString() : '',
        dateEnd: dateEnd ? dateEnd.toISOString() : '',
      }),
      setSalePagination({
        offset: 0,
        hasMore: pagination?.hasMore || false,
        limit: pagination?.limit || 30,
        total: pagination?.total || 0,
        page: pagination?.page || 1,
      }),
    ]);

    sale.ui.refresh();
    sale.ui.refreshMetrics();

    handleClose();
  }

  function handleDateChange(dateStart: Moment | null, dateEnd: Moment | null) {
    setDateStart(dateStart);
    setDateEnd(dateEnd);
  }

  function handleDisableDateEnd(momentDate: MaterialUiPickersDate) {
    return (
      !!momentDate?.isBefore(dateStart) ||
      !!momentDate?.isAfter(moment().endOf('month'))
    );
  }

  return (
    <>
      <Mui.AppBar className={classes.appBar}>
        <Mui.Toolbar>
          <Mui.Typography className={classes.title} variant="h5">
            Filtrar por data
          </Mui.Typography>
          <Mui.IconButton
            aria-label="Fechar"
            className={classes.closeButton}
            onClick={handleClose}
          >
            <MuiIcons.Close />
          </Mui.IconButton>
        </Mui.Toolbar>
      </Mui.AppBar>
      <Mui.DialogContent className={classes.container} dividers>
        <Mui.List>
          {options.map(option => (
            <Mui.ListItem
              button
              key={option.label}
              onClick={() => handleDateChange(option.dateStart, option.dateEnd)}
            >
              <Mui.ListItemText
                primary={option.label}
                secondary={
                  option.dateStart && option.dateEnd
                    ? `${moment(option.dateStart).format(
                        'DD/MM/YYYY HH:mm:ss'
                      )} até ${moment(option.dateEnd).format(
                        'DD/MM/YYYY HH:mm:ss'
                      )}`
                    : 'Todas as vendas realizadas até o momento'
                }
              />
            </Mui.ListItem>
          ))}
          <Mui.ListItem className={classes.inputDatePickerContainer}>
            <MuiPickers.MuiPickersUtilsProvider
              libInstance={moment}
              locale="pt-br"
              utils={MomentUtils}
            >
              <MuiPickers.DatePicker
                DialogProps={{
                  PaperProps: {
                    classes: {
                      root: classes.rootDialog,
                    },
                  },
                }}
                cancelLabel="CANCELAR"
                className={classes.dateStartPicker}
                clearLabel="LIMPAR"
                clearable
                disableFuture
                format="DD/MM/YYYY"
                fullWidth
                id="dateStart"
                inputVariant="outlined"
                label="De"
                leftArrowButtonProps={{
                  classes: {
                    root: classes.buttonPicker,
                  },
                }}
                margin="dense"
                name="dateStart"
                onChange={setDateStart}
                rightArrowButtonProps={{
                  classes: {
                    root: classes.buttonPicker,
                  },
                }}
                value={dateStart}
              />
              <MuiPickers.DatePicker
                DialogProps={{
                  PaperProps: {
                    classes: {
                      root: classes.rootDialog,
                    },
                  },
                }}
                cancelLabel="CANCELAR"
                className={classes.dateEndPicker}
                clearLabel="LIMPAR"
                clearable
                format="DD/MM/YYYY"
                fullWidth
                id="dateEnd"
                inputVariant="outlined"
                label="Até"
                leftArrowButtonProps={{
                  classes: {
                    root: classes.buttonPicker,
                  },
                }}
                margin="dense"
                name="dateEnd"
                onChange={setDateEnd}
                rightArrowButtonProps={{
                  classes: {
                    root: classes.buttonPicker,
                  },
                }}
                shouldDisableDate={handleDisableDateEnd}
                value={dateEnd}
              />
            </MuiPickers.MuiPickersUtilsProvider>
          </Mui.ListItem>
        </Mui.List>
      </Mui.DialogContent>
      <Mui.DialogActions>
        <Mui.Button onClick={handleClose}>Cancelar</Mui.Button>
        <Mui.Button
          className={classes.clearFilterButton}
          color="secondary"
          onClick={() =>
            handleDateChange(
              moment(DateFilterUtils.startOfToday),
              moment(DateFilterUtils.endOfToday)
            )
          }
          variant="contained"
        >
          Limpar filtro
        </Mui.Button>
        <Mui.Button
          color="primary"
          onClick={handleFilterDateChange}
          variant="contained"
        >
          OK
        </Mui.Button>
      </Mui.DialogActions>
    </>
  );
}

export default SalesFilterDateDialog;
