const INITIAL_STATE = {
  open: false,
  type: 'info',
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  autoHideDuration: 4000,
  message: '',
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SNACKBAR_OPENED':
      return {
        ...state,
        open: true,
      };
    case 'SNACKBAR_CLOSED':
      return {
        ...state,
        open: false,
      };
    case 'SNACKBAR_MESSAGE_CHANGED':
      return {
        ...state,
        message: action.payload,
      };
    case 'SNACKBAR_TYPE_CHANGED':
      return {
        ...state,
        type: action.payload,
      };
    case 'SNACKBAR_AUTO_HIDE_DURATION_CHANGED':
      return {
        ...state,
        autoHideDuration: action.payload,
      };
    case 'SNACKBAR_ANCHOR_ORIGIN_CHANGED':
      return {
        ...state,
        anchorOrigin: action.payload,
      };
    default:
      return state;
  }
};
