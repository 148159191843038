import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getSession } from '../../auth';
import history from '../../hooks/useHistory';
import { AuthProvider } from '../../contexts';
import { Dialog } from '../index';

const PrivateRouteWithLayout = ({
  layout: Layout,
  component: Component,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={props =>
        getSession() ? (
          <AuthProvider history={history}>
            <Layout {...props}>
              <Component {...props} />
            </Layout>
            <Dialog />
          </AuthProvider>
        ) : (
          <Redirect
            to={{
              pathname: '/sign-in',
              state: {
                from: props.location,
              },
            }}
          />
        )
      }
    />
  );
};

PrivateRouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string,
};

export default PrivateRouteWithLayout;
