import createNumberMask from 'text-mask-addons/src/createNumberMask';

export const moneyMask = createNumberMask({
  prefix: 'R$ ',
  thousandsSeparatorSymbol: '.',
  allowDecimal: true,
  decimalSymbol: ',',
});

export const phoneMask = value => {
  value = value.replace(/[^0-9]/g, '');

  if (value.length > 10) {
    return [
      '(',
      /[0-9]/,
      /\d/,
      ')',
      ' ',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ];
  } else {
    return [
      '(',
      /[0-9]/,
      /\d/,
      ')',
      ' ',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ];
  }
};

export const documentMask = value => {
  value = value.replace(/[^0-9]/g, '');

  if (value.length < 12) {
    return [
      /[0-9]/,
      /\d/,
      /\d/,
      '.',
      /\d/,
      /\d/,
      /\d/,
      '.',
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
    ];
  } else {
    return [
      /[0-9]/,
      /\d/,
      '.',
      /\d/,
      /\d/,
      /\d/,
      '.',
      /\d/,
      /\d/,
      /\d/,
      '/',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
    ];
  }
};

export const creditCardSecurityCodeMask = value => {
  value = value.replace(/[^0-9]/g, '');

  if (value.length < 4) {
    return [/[0-9]/, /\d/, /\d/];
  } else {
    return [/[0-9]/, /\d/, /\d/, /\d/];
  }
};

export const postalCodeMask = () => [
  /[0-9]/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
];

export const dateMask = () => [
  /[0-9]/,
  /\d/,
  '/',
  /\d/,
  /\d/,
  '/',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export const creditCardNumberMask = value => {
  value = value.replace(/[^0-9]/g, '');

  if (value.length <= 14) {
    return [
      /[0-9]/,
      /\d/,
      /\d/,
      /\d/,
      ' ',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      ' ',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      ' ',
      /\d/,
      /\d/,
    ];
  } else if (value.length === 15) {
    return [
      /[0-9]/,
      /\d/,
      /\d/,
      /\d/,
      ' ',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      ' ',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      ' ',
      /\d/,
      /\d/,
      /\d/,
    ];
  } else {
    return [
      /[0-9]/,
      /\d/,
      /\d/,
      /\d/,
      ' ',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      ' ',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      ' ',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ];
  }
};

export const creditCardExpiryMask = () => [/[0-9]/, /\d/, '/', /\d/, /\d/];
