export const getDisplayDocumentNumber = document_number => {
  if (document_number.length === 11) {
    return `${document_number.substring(0, 3)}.${document_number.substring(
      3,
      6
    )}.${document_number.substring(6, 9)}-${document_number.substring(9, 11)}`;
  } else if (document_number.length === 14) {
    return `${document_number.substring(0, 2)}.${document_number.substring(
      2,
      5
    )}.${document_number.substring(5, 8)}/${document_number.substring(
      8,
      12
    )}-${document_number.substring(12, 14)}`;
  } else {
    return '';
  }
};
