import React from 'react';
import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';

import * as SubscriptionHooks from 'hooks/useSubscription';

import * as InternalComponent from './components';
import useStyles from './Subscriptions.styles';

function Subscriptions() {
  const classes = useStyles();
  const subscription = SubscriptionHooks.useSubscription();

  return (
    <Mui.Card className={classes.root}>
      <Mui.CardHeader
        action={
          <Mui.IconButton onClick={subscription.ui.refresh}>
            <MuiIcons.Refresh />
          </Mui.IconButton>
        }
        subheader={
          <Mui.Typography color="textSecondary" variant="h5">
            Gerencie suas assinaturas
          </Mui.Typography>
        }
        title={<Mui.Typography variant="h3">Assinaturas</Mui.Typography>}
      />
      <Mui.Divider />
      <Mui.CardContent className={classes.cardContent}>
        <InternalComponent.SubscriptionsToolbar />
        <InternalComponent.SubscriptionsList />
      </Mui.CardContent>
    </Mui.Card>
  );
}

export default Subscriptions;
