import React from 'react';
import MaskedInput from 'react-text-mask';

const InputMask = props => {
  const { inputRef, mask, ...others } = props;

  return (
    <MaskedInput
      {...others}
      mask={mask}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
    />
  );
};

export default InputMask;
