import React from 'react';
import * as ReactRedux from 'react-redux';

import * as TaxInvoiceConfigHooks from 'hooks/use-tax-invoice-config';
import {
  setDialogClose,
  setDialogType,
  setPlan,
  setSnackbarMessage,
  setSnackbarOpen,
  setSnackbarType,
} from 'store/actions';
import type { ITaxInvoiceConfig } from 'types/entities/tax-invoice-config';

interface IFormState {
  disabled: boolean;
  isValid: boolean;
  values: {
    provider: ITaxInvoiceConfig['provider'] | null;
    active: 'true' | 'false';
    apiKey: string;
    street: string;
    streetNumber: string;
    streetComplement: string;
    neighborhood: string;
    city: string;
    postalCode: string;
    state: string;
    country: string;
    metadata: ITaxInvoiceConfig['metadata'];
  };
  errors: {
    provider?: string;
    active?: string;
    apiKey?: string;
    street?: string;
    streetNumber?: string;
    streetComplement?: string;
    neighborhood?: string;
    city?: string;
    postalCode?: string;
    state?: string;
    country?: string;
    metadata?: string;
  };
  touched: {
    provider?: boolean;
    active?: boolean;
    apiKey?: boolean;
    street?: boolean;
    streetNumber?: boolean;
    streetComplement?: boolean;
    neighborhood?: boolean;
    city?: boolean;
    postalCode?: boolean;
    state?: boolean;
    country?: boolean;
    metadata?: boolean;
  };
}

export function useTaxInvoiceConfigDialog() {
  const dispatch = ReactRedux.useDispatch();

  const { state, service } = TaxInvoiceConfigHooks.useTaxInvoiceConfig();

  const { taxInvoiceConfig, state: taxInvoiceConfigState } = state;

  const formStateInitialValue = React.useMemo<IFormState>(
    () => ({
      disabled: true,
      isValid: true,
      values: {
        provider: null,
        active: 'false',
        apiKey: '',
        street: '',
        streetNumber: '',
        streetComplement: '',
        neighborhood: '',
        city: '',
        postalCode: '',
        state: '',
        country: '',
        metadata: null,
        modified: false,
      },
      errors: {},
      touched: {
        provider: true,
        apiKey: true,
      },
    }),
    []
  );

  const [formState, setFormState] = React.useState<IFormState>(
    formStateInitialValue
  );

  const handleDecoratorValue = React.useCallback(
    (fieldName: keyof IFormState['values'], value: string | number) => {
      switch (fieldName) {
        default:
          return value;
      }
    },
    []
  );

  const handleFormChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.persist();

      const fieldName = event.target.name;
      let value = event.target.value as string | number;

      value = handleDecoratorValue(
        fieldName as keyof IFormState['values'],
        value
      );

      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          [fieldName]: value,
        },
        touched: {
          ...formState.touched,
          [fieldName]: true,
        },
      }));
    },
    [handleDecoratorValue]
  );

  const handleMetadataChange = React.useCallback(
    (metadata: Record<string, string | number> | null) => {
      setFormState(prevState => ({
        ...prevState,
        values: {
          ...prevState.values,
          metadata,
        },
      }));
    },
    []
  );

  const hasError = React.useCallback(
    (field: keyof IFormState['values']) => {
      return !!(formState.touched[field] && formState.errors[field]);
    },
    [formState.errors, formState.touched]
  );

  const handleSubmit = React.useCallback(async () => {
    try {
      if (taxInvoiceConfigState !== 'config') return;

      setFormState(prevState => ({
        ...prevState,
        disabled: true,
        errors: {},
      }));

      const { active, apiKey, metadata, provider } = formState.values;

      const postalCode = formState.values.postalCode.replace(/\D/g, '');
      const city = formState.values.city.trim();
      const country = formState.values.country.trim();
      const neighborhood = formState.values.neighborhood.trim();
      const street = formState.values.street.trim();
      const streetComplement = formState.values.streetComplement.trim();
      const streetNumber = formState.values.streetNumber.trim();
      const state = formState.values.state.trim();

      if (provider === null) {
        setFormState(formState => ({
          ...formState,
          errors: {
            ...formState.errors,
            provider: 'Selecione um provedor!',
          },
        }));
        handleInputFocus('provider');
        return;
      }

      if (apiKey === '') {
        setFormState(formState => ({
          ...formState,
          errors: {
            ...formState.errors,
            apiKey: 'A Chave da API está vazia!',
          },
        }));
        handleInputFocus('apiKey');
        return;
      }

      if (state.length > 2) {
        setFormState(formState => ({
          ...formState,
          errors: {
            ...formState.errors,
            state: 'O estado deve ter no máximo 2 caracteres!',
          },
        }));
        handleInputFocus('state');
        return;
      }

      if (country.length > 2) {
        setFormState(formState => ({
          ...formState,
          errors: {
            ...formState.errors,
            country: 'O país deve ter no máximo 2 caracteres!',
          },
        }));
        handleInputFocus('country');
        return;
      }

      if (taxInvoiceConfig) {
        await service.edit({
          id: taxInvoiceConfig.id,
          active: active === 'true',
          provider,
          internalMetadata: {
            [`${provider}ApiKey`]: apiKey || null,
            country: country || null,
            street: street || null,
            streetComplement: streetComplement || null,
            streetNumber: streetNumber || null,
            city: city || null,
            neighborhood: neighborhood || null,
            postalCode: postalCode || null,
            state: state || null,
          },
          metadata,
        });
        return;
      }

      await service.create({
        active: active === 'true',
        provider,
        internalMetadata: {
          [`${provider}ApiKey`]: apiKey || null,
          country: country || null,
          street: street || null,
          streetComplement: streetComplement || null,
          streetNumber: streetNumber || null,
          city: city || null,
          neighborhood: neighborhood || null,
          postalCode: postalCode || null,
          state: state || null,
        },
        metadata,
      });

      dispatch([setDialogClose(), setDialogType(null)]);
    } catch (error) {
      console.log(error);
      dispatch([
        setSnackbarMessage(
          'Não foi possível criar esta configuração, tente novamente mais tarde!'
        ),
        setSnackbarType('error'),
        setSnackbarOpen(),
      ]);
    } finally {
      setFormState(prevState => ({
        ...prevState,
        disabled: false,
      }));
    }
  }, [
    dispatch,
    formState.values,
    service,
    taxInvoiceConfig,
    taxInvoiceConfigState,
  ]);

  const handleDialogClose = React.useCallback(() => {
    dispatch([setPlan(null), setDialogClose(), setDialogType(null)]);
  }, [dispatch]);

  function handleInputFocus(inputName: keyof IFormState['values']) {
    const inputElement = document.querySelector(
      `#${inputName}`
    ) as HTMLInputElement;

    inputElement.focus();
  }

  React.useEffect(() => {
    service.getBySellerId().finally(async () => {
      setFormState(prevState => ({
        ...prevState,
        disabled: false,
      }));
    });
  }, [service]);

  React.useEffect(() => {
    if (!taxInvoiceConfig) return;

    setFormState(prevState => ({
      ...prevState,
      values: {
        ...prevState.values,
        active: taxInvoiceConfig.active ? 'true' : 'false',
        apiKey: taxInvoiceConfig.internalMetadata?.notazzApiKey || '',
        country: taxInvoiceConfig.internalMetadata?.country || '',
        street: taxInvoiceConfig.internalMetadata?.street || '',
        streetComplement:
          taxInvoiceConfig.internalMetadata?.streetComplement || '',
        streetNumber: taxInvoiceConfig.internalMetadata?.streetNumber || '',
        city: taxInvoiceConfig.internalMetadata?.city || '',
        neighborhood: taxInvoiceConfig.internalMetadata?.neighborhood || '',
        postalCode: taxInvoiceConfig.internalMetadata?.postalCode || '',
        state: taxInvoiceConfig.internalMetadata?.state || '',
        metadata: taxInvoiceConfig.metadata,
        provider: taxInvoiceConfig.provider,
      },
    }));
  }, [taxInvoiceConfig]);

  return React.useMemo(
    () => ({
      taxInvoiceConfig,
      taxInvoiceConfigState,
      formState,
      handleDialogClose,
      handleFormChange,
      handleMetadataChange,
      handleSubmit,
      hasError,
    }),
    [
      taxInvoiceConfig,
      taxInvoiceConfigState,
      formState,
      handleDialogClose,
      handleFormChange,
      handleMetadataChange,
      handleSubmit,
      hasError,
    ]
  );
}
