import React, { useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import validate from 'validate.js';

import { documentMask } from 'helpers';

import {
  setDialogClose,
  setSnackbarOpen,
  setSnackbarMessage,
  setSnackbarType,
  setBankAccountState,
} from 'store/actions';

import {
  AppBar,
  Button,
  ButtonBase,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core';

import { Autocomplete } from '@material-ui/lab';

import { InputMask } from 'components';

import { makeStyles } from '@material-ui/styles';

import { Close as CloseIcon } from '@material-ui/icons';

import banco_do_brasil_img from 'assets/images/banco-do-brasil.svg';
import bradesco_img from 'assets/images/bradesco.svg';
import caixa_img from 'assets/images/caixa.svg';
import citibank_img from 'assets/images/citibank.svg';
import hsbc_img from 'assets/images/hsbc.svg';
import itau_img from 'assets/images/itau.svg';
import safra_img from 'assets/images/safra.svg';
import santander_img from 'assets/images/santander.svg';
import { api } from 'services/api';
import { useAuthContext } from 'hooks/useAuthContext.hook';

const banks_shortcut = [
  {
    name: 'Banco do Brasil S.A',
    code: '001',
    img: banco_do_brasil_img,
  },
  {
    name: 'Banco Santander (Brasil) S.A.',
    code: '033',
    img: santander_img,
  },
  {
    name: 'Caixa Econômica Federal',
    code: '104',
    img: caixa_img,
  },
  {
    name: 'Banco Bradesco S.A.',
    code: '237',
    img: bradesco_img,
  },
  {
    name: 'Itaú Unibanco S.A.',
    code: '341',
    img: itau_img,
  },
  {
    name: 'Kirton Bank S.A. - Banco Múltiplo',
    code: '399',
    img: hsbc_img,
  },
  {
    name: 'Banco Safra S.A.',
    code: '422',
    img: safra_img,
  },
  {
    name: 'Citibank N.A.',
    code: '477',
    img: citibank_img,
  },
];

const schema = {
  bank: {
    presence: {
      allowEmpty: false,
      message: '^Selecione um banco',
    },
  },
  routing_number: {
    presence: {
      allowEmpty: false,
      message: '^Insira o número da sua agência',
    },
    length: {
      maximum: 15,
      minimum: 3,
      tooShort: '^Número da agência muito curto (mínimo 3 caracteres)',
      tooLong: '^Número da agência muito longo (máximo de 15 caracteres)',
    },
  },
  account_number: {
    presence: {
      allowEmpty: false,
      message: '^Insira o número da sua conta',
    },
    length: {
      maximum: 20,
      minimum: 3,
      tooShort: '^Número da conta muito curto (mínimo 3 caracteres)',
      tooLong: '^Número da conta muito longo (máximo de 20 caracteres)',
    },
  },
  holder_name: {
    presence: {
      allowEmpty: false,
      message: '^Insira o nome do titular da conta',
    },
    length: {
      maximum: 120,
      minimum: 5,
      tooLong: '^Nome do titular muito longo (máximo de 120 caracteres)',
      tooShort: '^Nome do titular muito curto (mínimo de 5 caracteres)',
    },
  },
  document_number: {
    presence: {
      allowEmpty: false,
      message: '^Insira o número do seu CPF/CNPJ',
    },
  },
};

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    color: '#fff',
  },
  container: {
    padding: theme.spacing(3),
    width: '100%',
    margin: 0,
  },
  buttonCreate: {
    marginTop: theme.spacing(1),
  },
  buttonBanksShortcut: {
    fontSize: 12,
    width: 50,
  },
  bankShortcutImage: {
    width: '100%',
    height: 38,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
  },
  paper: {
    backgroundColor: theme.palette.background.dark2,
  },
}));

const BankAccountCreateDialog = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const { user } = useAuthContext();

  const { open: dialogOpen } = useSelector(state => state.dialogState);

  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      type: 'checking',
    },
    errors: {},
    touched: {},
  });

  const [banks, setBanks] = useState([]);

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: !errors,
      errors: errors || {},
    }));
  }, [formState.values]);

  useEffect(() => {
    if (dialogOpen) {
      async function getBanks() {
        const response = await api.get('/bank/');

        if (response.data) {
          setBanks(response.data);
        }
      }

      getBanks();
    }
  }, [dialogOpen]);

  const handleChangeForm = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleChangeBank = (event, valueSelected) => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        bank: valueSelected,
      },
      touched: {
        ...formState.touched,
        bank: true,
      },
    }));
  };

  const handleDialogClose = () => {
    dispatch(setDialogClose());
  };

  const handleSubmitForm = async () => {
    let {
      holder_name,
      bank,
      routing_number,
      account_number,
      document_number,
      type,
    } = formState.values;

    document_number = document_number.replace(/[^0-9]/g, '');

    if (document_number.length !== 14 && document_number.length !== 11) {
      setFormState(formState => ({
        ...formState,
        errors: {
          ...formState.errors,
          document_number: ['O documento está inválido'],
        },
      }));

      document.querySelector('#document_number').focus();
    } else {
      dispatch(setSnackbarType('info'));
      dispatch(setSnackbarMessage('Processando...'));
      dispatch(setSnackbarOpen());

      try {
        const token = localStorage.getItem('token');

        const data = {
          customer_id: user.id,
          holder_name,
          bank_code: bank.code,
          bank_name: bank.name,
          routing_number,
          account_number,
          document_number,
          type,
        };

        const response = await api.post('/bank_account/', data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data) {
          dispatch(setBankAccountState('get'));
          dispatch(setDialogClose());
          dispatch(setSnackbarType('success'));
          dispatch(setSnackbarMessage('Conta bancária deletada com sucesso !'));
          dispatch(setSnackbarOpen());
        }
      } catch (error) {
        dispatch(setDialogClose());
        dispatch(setSnackbarType('error'));
        dispatch(
          setSnackbarMessage(
            'Não foi possível criar uma conta bancária, tente novamente mais tarde !'
          )
        );
        dispatch(setSnackbarOpen());
        console.error('Não foi possível criar está conta bancária', error);
      }

      // console.log(`
      //     customer_id: ${user.id},
      //     holder_name: ${holder_name},
      //     bank_code: ${bank.code},
      //     bank_name: ${bank.name},
      //     routing_number: ${routing_number}
      //     account_number: ${account_number},
      //     document_number: ${document_number}
      //     type: ${type},
      // `);
    }
  };

  const hasError = field =>
    !!(formState.touched[field] && formState.errors[field]);

  const renderBanksShortcut = () => {
    return banks_shortcut.map(bank => (
      <Grid item key={bank.code}>
        <ButtonBase
          className={classes.buttonBanksShortcut}
          focusRipple
          key={bank.code}
          onClick={event => handleChangeBank(event, bank)}
        >
          <span
            className={classes.bankShortcutImage}
            style={{
              backgroundImage: `url(${bank.img})`,
            }}
          />
        </ButtonBase>
      </Grid>
    ));
  };

  return (
    <React.Fragment>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            aria-label="Fechar"
            color="inherit"
            edge="start"
            onClick={handleDialogClose}
          >
            <CloseIcon />
          </IconButton>
          <Typography className={classes.title} variant="h5">
            Cadastrar conta bancária
          </Typography>
          <Button
            color="inherit"
            disabled={!formState.isValid}
            onClick={handleSubmitForm}
          >
            Criar
          </Button>
        </Toolbar>
      </AppBar>
      <Grid
        alignItems="center"
        className={classes.container}
        container
        spacing={2}
      >
        <Grid item xs={12}>
          <Typography variant="h5">Dados da conta</Typography>
        </Grid>
        <Grid item xs={12}>
          <RadioGroup
            aria-label="tipo de conta"
            name="type"
            onChange={handleChangeForm}
            value={formState.values.type || ''}
          >
            <Grid item sm={6} xs={12}>
              <FormControlLabel
                control={<Radio color="primary" />}
                label="Conta Corrente"
                value="checking"
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <FormControlLabel
                control={<Radio color="primary" />}
                label="Conta Poupança"
                value="saving"
              />
            </Grid>
          </RadioGroup>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">Atalhos (mais utilizados)</Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid alignItems="center" container justify="center" spacing={2}>
            {renderBanksShortcut()}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            autoHighlight
            autoSelect
            classes={{
              paper: classes.paper,
            }}
            clearOnEscape
            clearText="Limpar"
            closeText="Fechar"
            getOptionLabel={bank => {
              if (bank.name) {
                return `${bank.code} - ${bank.name}`;
              } else {
                return '';
              }
            }}
            id="bank-autocomplete"
            name="bank-autocomplete"
            noOptionsText="Não há opções"
            onChange={handleChangeBank}
            openText="Abrir"
            options={banks}
            renderInput={params => (
              <TextField
                {...params}
                SelectProps={{
                  MenuProps: {
                    classes: {
                      paper: classes.menuList,
                    },
                  },
                }}
                error={hasError('bank')}
                fullWidth
                helperText={hasError('bank') ? formState.errors.bank[0] : null}
                id="bank"
                label="Banco"
                name="bank"
                required
                variant="outlined"
              />
            )}
            value={formState.values.bank || ''}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            error={hasError('routing_number')}
            fullWidth
            helperText={
              hasError('routing_number')
                ? formState.errors.routing_number[0]
                : 'Apenas números'
            }
            id="routing_number"
            label="Agência"
            name="routing_number"
            onChange={handleChangeForm}
            required
            type="tel"
            value={formState.values.routing_number || ''}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            error={hasError('account_number')}
            fullWidth
            helperText={
              hasError('account_number')
                ? formState.errors.account_number[0]
                : 'Apenas números'
            }
            id="account_number"
            label="Conta"
            name="account_number"
            onChange={handleChangeForm}
            required
            type="tel"
            value={formState.values.account_number || ''}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            error={hasError('holder_name')}
            fullWidth
            helperText={
              hasError('holder_name')
                ? formState.errors.holder_name[0]
                : 'Nome completo do titular da conta'
            }
            id="holder_name"
            label="Titular"
            name="holder_name"
            onChange={handleChangeForm}
            required
            type="text"
            value={formState.values.holder_name || ''}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            InputProps={{
              inputComponent: InputMask,
            }}
            error={hasError('document_number')}
            fullWidth
            helperText={
              hasError('document_number')
                ? formState.errors.document_number[0]
                : 'Ex: 123.456.789-00 ou 12.345.678/0001-01'
            }
            id="document_number"
            inputProps={{
              mask: documentMask,
            }}
            label="CPF/CNPJ"
            name="document_number"
            onChange={handleChangeForm}
            required
            type="tel"
            value={formState.values.document_number || ''}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            className={classes.buttonCreate}
            color="primary"
            disabled={!formState.isValid}
            fullWidth
            onClick={handleSubmitForm}
            variant="contained"
          >
            Criar
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default BankAccountCreateDialog;
