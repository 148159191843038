import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';
import moment from 'moment';
import React from 'react';
import * as ReactRedux from 'react-redux';

import { setDialogClose, setDialogType } from 'store/actions';
import type { RootState } from 'types/redux';

import { getDisplayMoney } from 'helpers';
import { ChargeUtils } from 'helpers/charge-utils';
import { setCharge } from 'store/actions/charge-actions';
import useStyles from './charge-view-dialog.styles';

function ChargeViewDialog() {
  const classes = useStyles();
  const dispatch = ReactRedux.useDispatch();
  const { charge } = ReactRedux.useSelector((state: RootState) => state.charge);

  if (!charge) {
    return null;
  }

  const integrations = Object.entries(charge.integrations || {});
  const metadata = Object.entries(charge.metadata || {});

  function handleDialogClose() {
    dispatch([setCharge(null), setDialogClose(), setDialogType(null)]);
  }

  return (
    <React.Fragment>
      <Mui.AppBar className={classes.appBar}>
        <Mui.Toolbar>
          <Mui.Typography className={classes.title} variant="h5">
            {`Informações da cobrança (${charge.buyerId})`}
          </Mui.Typography>
          <Mui.IconButton
            aria-label="Fechar"
            color="inherit"
            edge="end"
            onClick={handleDialogClose}
          >
            <MuiIcons.Close />
          </Mui.IconButton>
        </Mui.Toolbar>
      </Mui.AppBar>
      <Mui.Grid className={classes.container} container spacing={2}>
        <Mui.Grid item xs={12}>
          <Mui.Typography variant="h4">Ações</Mui.Typography>
        </Mui.Grid>

        <div className={classes.actionsContainer}>
          <Mui.Tooltip title="Em breve">
            <div className={classes.actionButton}>
              <Mui.Button
                color="secondary"
                disabled
                fullWidth
                variant="contained"
              >
                DELETAR
              </Mui.Button>
            </div>
          </Mui.Tooltip>
        </div>

        <Mui.Grid item xs={12}>
          <Mui.Divider />
        </Mui.Grid>
        <Mui.Grid item xs={12}>
          <Mui.Typography variant="h4">Dados da cobrança</Mui.Typography>
        </Mui.Grid>

        <Mui.Grid item md={3} sm={4} xs={12}>
          <Mui.Typography variant="body1">
            <strong>Identificador: </strong>
            {charge.id}
          </Mui.Typography>
        </Mui.Grid>

        <Mui.Grid item md={3} sm={4} xs={12}>
          <Mui.Typography variant="body1">
            <strong>Identificador grupo de cobranças: </strong>
            {charge.groupChargeId}
          </Mui.Typography>
        </Mui.Grid>

        <Mui.Grid item md={3} sm={4} xs={12}>
          <Mui.Typography variant="body1">
            <strong>Identificador do marketplace: </strong>
            {charge.marketplaceId}
          </Mui.Typography>
        </Mui.Grid>

        {charge.couponId && (
          <Mui.Grid item md={3} sm={4} xs={12}>
            <Mui.Typography variant="body1">
              <strong>Identificador do cupom: </strong>
              {charge.couponId}
            </Mui.Typography>
          </Mui.Grid>
        )}

        <Mui.Grid item md={3} sm={4} xs={12}>
          <Mui.Typography variant="body1">
            <strong>Comprador: </strong>
            {charge.buyerId}
          </Mui.Typography>
        </Mui.Grid>

        <Mui.Grid item md={3} sm={4} xs={12}>
          <Mui.Typography variant="body1">
            <strong>Descrição: </strong>
            {charge.description}
          </Mui.Typography>
        </Mui.Grid>

        <Mui.Grid item md={3} sm={4} xs={12}>
          <Mui.Typography variant="body1">
            <strong>Status: </strong>
            {ChargeUtils.toStatusLabel(charge.status)}
          </Mui.Typography>
        </Mui.Grid>

        <Mui.Grid item md={3} sm={4} xs={12}>
          <Mui.Typography variant="body1">
            <strong>Valor: </strong>
            {getDisplayMoney(charge.amount / 100)}
          </Mui.Typography>
        </Mui.Grid>

        <Mui.Grid item md={3} sm={4} xs={12}>
          <Mui.Typography variant="body1">
            <strong>Parcelas: </strong>
            {charge.installment}/{charge.installments}
          </Mui.Typography>
        </Mui.Grid>

        <Mui.Grid item md={3} sm={4} xs={12}>
          <Mui.Typography variant="body1">
            <strong>Data limite: </strong>
            {moment.utc(charge.paymentLimitDate).format('DD/MM/YYYY HH:mm:ss')}
          </Mui.Typography>
        </Mui.Grid>

        {charge.emailSendAt && (
          <Mui.Grid item md={3} sm={4} xs={12}>
            <Mui.Typography variant="body1">
              <strong>Data do envio: </strong>
              {moment.utc(charge.emailSendAt).format('DD/MM/YYYY HH:mm:ss')}
            </Mui.Typography>
          </Mui.Grid>
        )}

        <Mui.Grid item md={3} sm={4} xs={12}>
          <Mui.Typography variant="body1">
            <strong>Status do envio: </strong>
            {ChargeUtils.toEmailStatusLabel(charge.emailStatus)}
          </Mui.Typography>
        </Mui.Grid>

        <Mui.Grid item md={3} sm={4} xs={12}>
          <Mui.Typography variant="body1">
            <strong>Envio de email: </strong>
            {charge.emailEnabled ? 'Ativado' : 'Desativado'}
          </Mui.Typography>
        </Mui.Grid>

        <Mui.Grid item md={3} sm={4} xs={12}>
          <Mui.Typography variant="body1">
            <strong>Moeda: </strong>
            {charge.currency}
          </Mui.Typography>
        </Mui.Grid>

        <Mui.Grid item md={3} sm={4} xs={12}>
          <Mui.Typography variant="body1">
            <strong>Formas de pagamento: </strong>
            {ChargeUtils.toPaymentTypesAcceptedLabel(
              charge.paymentTypesAccepted
            ).join(', ')}
          </Mui.Typography>
        </Mui.Grid>

        <Mui.Grid item md={3} sm={4} xs={12}>
          <Mui.Typography variant="body1">
            <strong>Última atualização: </strong>
            {moment.utc(charge.updatedAt).format('DD/MM/YYYY HH:mm:ss')}
          </Mui.Typography>
        </Mui.Grid>

        <Mui.Grid item md={3} sm={4} xs={12}>
          <Mui.Typography variant="body1">
            <strong>Data de criação: </strong>
            {moment.utc(charge.createdAt).format('DD/MM/YYYY HH:mm:ss')}
          </Mui.Typography>
        </Mui.Grid>

        {integrations.length > 0 && (
          <>
            <Mui.Grid item xs={12}>
              <Mui.Divider />
            </Mui.Grid>
            <Mui.Grid item xs={12}>
              <Mui.Typography variant="h4">Integrações</Mui.Typography>
            </Mui.Grid>
            <Mui.Grid container direction="column" item spacing={1}>
              {metadata.map(([key, value]) => (
                <Mui.Grid item key={key} xs={4}>
                  <Mui.Typography variant="body1">
                    <strong>{key}: </strong>
                    {value}
                  </Mui.Typography>
                </Mui.Grid>
              ))}
            </Mui.Grid>
          </>
        )}

        {metadata.length > 0 && (
          <>
            <Mui.Grid item xs={12}>
              <Mui.Divider />
            </Mui.Grid>
            <Mui.Grid item xs={12}>
              <Mui.Typography variant="h4">Metadados</Mui.Typography>
            </Mui.Grid>
            <Mui.Grid container direction="column" item spacing={1}>
              {metadata.map(([key, value]) => (
                <Mui.Grid item key={key} xs={4}>
                  <Mui.Typography variant="body1">
                    <strong>{key}: </strong>
                    {value}
                  </Mui.Typography>
                </Mui.Grid>
              ))}
            </Mui.Grid>
          </>
        )}
      </Mui.Grid>
    </React.Fragment>
  );
}

export default ChargeViewDialog;
