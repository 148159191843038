import React from 'react';

import clsx from 'clsx';

import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';

import { Card, CardContent, Grid, Typography, Avatar } from '@material-ui/core';

import { Skeleton } from '@material-ui/lab';

import { PeopleOutlined as PeopleIcon } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    backgroundColor: theme.palette.background.dark2,
  },
  content: {
    alignItems: 'center',
    display: 'flex',
  },
  title: {
    fontWeight: 700,
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    height: 56,
    width: 56,
  },
  icon: {
    height: 32,
    width: 32,
  },
  difference: {
    marginTop: 10,
    display: 'flex',
    alignItems: 'center',
  },
  up: {
    color: theme.palette.success.main,
  },
  down: {
    color: theme.palette.error.main,
  },
  differenceValue: {
    marginRight: theme.spacing(1),
  },
}));

const TotalCustomers = props => {
  const { total_customers, difference_value, dashboardState } = props;

  const classes = useStyles();

  return (
    <Card className={clsx(classes.root)}>
      <CardContent>
        <Grid container justify="space-between">
          <Grid item>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
              variant="body2"
            >
              TOTAL DE CLIENTES
            </Typography>
            {dashboardState === 'get' ? (
              <Skeleton
                animation="pulse"
                height={32}
                variant="text"
                width={50}
              />
            ) : (
              <Typography variant="h3">{total_customers || 0}</Typography>
            )}
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <PeopleIcon className={classes.icon} />
            </Avatar>
          </Grid>
        </Grid>
        {difference_value ? (
          dashboardState === 'get' ? (
            <Skeleton
              animation="pulse"
              height={32}
              variant="text"
              width={120}
            />
          ) : (
            <div className={classes.difference}>
              {/*<ArrowTypewardIcon className={classes[difference_type]} />*/}
              {/*<Typography*/}
              {/*  className={clsx(classes.differenceValue, classes[difference_type])}*/}
              {/*  variant="body2"*/}
              {/*>*/}
              {/*  {difference_value.toFixed(1)}%*/}
              {/*</Typography>*/}
              {/*<Typography*/}
              {/*  className={classes.caption}*/}
              {/*  variant="caption"*/}
              {/*>*/}
              {/*  Desde o último {filterLegend}*/}
              {/*</Typography>*/}
            </div>
          )
        ) : null}
      </CardContent>
    </Card>
  );
};

TotalCustomers.propTypes = {
  className: PropTypes.string,
};

export default TotalCustomers;
