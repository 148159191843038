import React, { useState } from 'react';

import { useDispatch } from 'react-redux';

import {
  setBankAccountState,
  setBankAccount,
  setDialogType,
  setDialogFullScreen,
  setDialogMaxWidth,
  setDialogOpen,
} from 'store/actions';

import { Button, useMediaQuery } from '@material-ui/core';

import { SearchInput } from 'components';

import { makeStyles, useTheme } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    flexWrap: 'wrap',
  },
  spacer: {
    flexGrow: 1,
  },
  searchInput: {
    flex: 1,
    backgroundColor: theme.palette.background.default,
  },
  buttonCreate: {
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(1),
      width: '100%',
    },
  },
}));

const BankAccountToolbar = () => {
  const classes = useStyles();
  const theme = useTheme();
  const dialog_full_screen = useMediaQuery(theme.breakpoints.down('sm'));

  const dispatch = useDispatch();

  const [query, setQuery] = useState('');

  const handleCreateBankAccount = () => {
    dispatch(setBankAccountState('create'));
    dispatch(setBankAccount({}));
    dispatch(setDialogType('createBankAccount'));
    dispatch(setDialogFullScreen(dialog_full_screen));
    dispatch(setDialogMaxWidth('sm'));
    dispatch(setDialogOpen());
  };

  const handleSearch = event => {
    const query = event.target.value;

    setQuery(query);
  };

  return (
    <div className={classes.root}>
      <div className={classes.row}>
        <span className={classes.spacer} />
        <Button
          className={classes.buttonCreate}
          color="primary"
          onClick={handleCreateBankAccount}
          variant="contained"
        >
          Adicionar conta bancária
        </Button>
      </div>
      <div className={classes.row}>
        <SearchInput
          className={classes.searchInput}
          onChange={event => handleSearch(event)}
          placeholder="Pesquise uma conta bancária"
          value={query}
        />
      </div>
    </div>
  );
};

export default BankAccountToolbar;
