import React from 'react';
import * as Mui from '@material-ui/core';

import useStyles from './SubscriptonEmptyState.styles';

function SubscriptonEmptyState() {
  const classes = useStyles();

  return (
    <Mui.Grid className={classes.container} container>
      <Mui.Typography className={classes.text} variant="h5">
        Você ainda não possui assinaturas!
      </Mui.Typography>
      {/* <Mui.Button
        className={classes.button}
        color="primary"
        disableFocusRipple
        disableRipple
        onClick={product.ui.openToCreate}
        variant="text"
      >
        Crie um agora
      </Mui.Button> */}
    </Mui.Grid>
  );
}

export default SubscriptonEmptyState;
