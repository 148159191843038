import type {
  ICheckoutReducerAction,
  ICheckoutReducerActionPayload,
  ICheckoutReducerState,
} from 'types/redux';

export function setCheckoutState(
  state: ICheckoutReducerState['state']
): ICheckoutReducerAction<
  ICheckoutReducerActionPayload['CHECKOUT_STATE_CHANGED']
> {
  return {
    type: 'CHECKOUT_STATE_CHANGED',
    payload: state,
  };
}

export function setCheckout(
  checkout: ICheckoutReducerState['checkout']
): ICheckoutReducerAction<ICheckoutReducerActionPayload['CHECKOUT_CHANGED']> {
  return {
    type: 'CHECKOUT_CHANGED',
    payload: checkout,
  };
}

export function setListCheckouts(
  checkouts: ICheckoutReducerState['listCheckouts']
): ICheckoutReducerAction<
  ICheckoutReducerActionPayload['LIST_CHECKOUTS_CHANGED']
> {
  return {
    type: 'LIST_CHECKOUTS_CHANGED',
    payload: checkouts,
  };
}

export function setCheckoutPagination(
  pagination: ICheckoutReducerState['pagination']
) {
  return {
    type: 'CHECKOUT_PAGINATION_CHANGED',
    payload: pagination,
  } as ICheckoutReducerAction<
    ICheckoutReducerActionPayload['CHECKOUT_PAGINATION_CHANGED']
  >;
}
