import React from 'react';
import * as ReactRedux from 'react-redux';

import type { RootState } from 'types/redux/redux.types';

import useTaxInvoiceService from './use-tax-invoice.service';
import useTaxInvoiceUI from './use-tax-invoice.ui';

function useTaxInvoice() {
  const service = useTaxInvoiceService();
  const ui = useTaxInvoiceUI();
  const state = ReactRedux.useSelector(
    (state: RootState) => state.taxInvoiceState
  );

  return React.useMemo(
    () => ({
      service,
      ui,
      state,
    }),
    [service, state, ui]
  );
}

export default useTaxInvoice;
