import React from 'react';
import * as ReactRedux from 'react-redux';

import type { IResourceListInterface } from 'types/services';
import { api } from 'services/api';
import {
  setSnackbarMessage,
  setSnackbarOpen,
  setSnackbarType,
  setDialogClose,
  setDialogType,
} from 'store/actions';
import { useAuthContext } from '../useAuthContext.hook';
import type { ITaxInvoice } from 'types/entities/tax-invoice';
import type { ITaxInvoiceReducerState } from 'types/redux/reducers/tax-invoice-reducer.types';
import {
  setListTaxInvoices,
  setTaxInvoice,
  setTaxInvoicePagination,
  setTaxInvoiceState,
} from 'store/actions/tax-invoice-actions';

interface IUseTaxInvoiceService {
  create: {
    marketplaceId: ITaxInvoice['marketplaceId'];
    customerId: ITaxInvoice['customerId'];
    providerId: ITaxInvoice['providerId'];
    provider: ITaxInvoice['provider'];
    amount: ITaxInvoice['amount'];
    status: ITaxInvoice['status'];
    description: ITaxInvoice['description'];
    internalMetadata: ITaxInvoice['internalMetadata'];
    metadata: ITaxInvoice['metadata'];
  };
  edit: {
    marketplaceId: ITaxInvoice['marketplaceId'];
    sellerId: ITaxInvoice['sellerId'];
    customerId: ITaxInvoice['customerId'];
    providerId: ITaxInvoice['providerId'];
    provider: ITaxInvoice['provider'];
    amount: ITaxInvoice['amount'];
    status: ITaxInvoice['status'];
    description: ITaxInvoice['description'];
    internalMetadata: ITaxInvoice['internalMetadata'];
    metadata: ITaxInvoice['metadata'];
  };
}

interface ITaxInvoiceServiceGetAll {
  limit?: number;
  offset?: number;
}

function useTaxInvoiceService() {
  const dispatch = ReactRedux.useDispatch();

  const { user } = useAuthContext();

  const { marketplace_id, id } = user;

  const getAll = React.useCallback(
    async (props?: ITaxInvoiceServiceGetAll): Promise<void> => {
      try {
        const response = await api.get<IResourceListInterface<ITaxInvoice>>(
          `/v2/marketplaces/${marketplace_id}/tax-invoices`,
          {
            auth: {
              username: process.env.REACT_APP_BYPASS_API_KEY!,
              password: '',
            },
            params: {
              limit: props?.limit,
              offset: props?.offset,
            },
          }
        );

        const {
          page,
          offset,
          limit,
          has_more: hasMore,
          items: taxInvoices,
          total,
        } = response.data;

        const pagination: ITaxInvoiceReducerState['pagination'] = {
          hasMore,
          limit,
          offset,
          page,
          total,
        };

        dispatch([
          setListTaxInvoices(taxInvoices),
          setTaxInvoiceState('success'),
          setTaxInvoicePagination(pagination),
        ]);
      } catch (error) {
        dispatch([
          setSnackbarMessage(
            'Não foi possível fazer a listagem das notas fiscais, tente novamente mais tarde!'
          ),
          setSnackbarType('error'),
          setSnackbarOpen(),
        ]);
        console.error('Não foi possível buscar a lista de notas fiscais');
        throw error;
      }
    },
    [dispatch, marketplace_id]
  );

  const create = React.useCallback(
    async (params: IUseTaxInvoiceService['create']) => {
      try {
        const {
          amount,
          customerId,
          description,
          internalMetadata,
          marketplaceId,
          metadata,
          status,
          provider,
          providerId,
        } = params;

        await api.post(
          `/marketplaces/${marketplace_id}/sellers/tax-invoices`,
          {
            customerId,
            marketplaceId,
            providerId,
            sellerId: id,
            amount,
            description,
            status,
            internalMetadata,
            metadata,
            provider,
          },
          {
            auth: {
              username: process.env.REACT_APP_BYPASS_API_KEY!,
              password: '',
            },
          }
        );

        dispatch([
          setTaxInvoiceState('get'),
          setTaxInvoice(null),
          setSnackbarMessage('Nota fiscal criada com sucesso!'),
          setSnackbarType('success'),
          setSnackbarOpen(),
          setDialogClose(),
          setDialogType(null),
        ]);
      } catch (error) {
        dispatch([
          setSnackbarMessage(
            'Não foi possível criar esta nota fiscal, tente novamente mais tarde!'
          ),
          setSnackbarType('error'),
          setSnackbarOpen(),
        ]);
      }
    },
    [dispatch, id, marketplace_id]
  );

  const edit = React.useCallback(
    async (params: IUseTaxInvoiceService['edit']) => {
      try {
        const {
          customerId,
          marketplaceId,
          providerId,
          sellerId,
          amount,
          status,
          description,
          internalMetadata,
          metadata,
          provider,
        } = params;

        await api.put(
          `/marketplaces/${marketplace_id}/offers/${sellerId}`,
          {
            customerId,
            marketplaceId,
            providerId,
            sellerId,
            amount,
            status,
            description,
            internalMetadata,
            metadata,
            provider,
          },
          {
            auth: {
              username: process.env.REACT_APP_BYPASS_API_KEY!,
              password: '',
            },
          }
        );

        dispatch([
          setTaxInvoiceState('get'),
          setTaxInvoice(null),
          setSnackbarMessage('Nota fiscal editada com sucesso!'),
          setSnackbarType('success'),
          setSnackbarOpen(),
          setDialogClose(),
          setDialogType(null),
        ]);
      } catch (error) {
        dispatch([
          setSnackbarMessage(
            'Não foi possível editar esta nota fiscal, tente novamente mais tarde!'
          ),
          setSnackbarType('error'),
          setSnackbarOpen(),
        ]);
      }
    },
    [dispatch, marketplace_id]
  );

  return React.useMemo(
    () => ({
      getAll,
      create,
      edit,
    }),
    [getAll, create, edit]
  );
}

export default useTaxInvoiceService;
