import React from 'react';
import * as Mui from '@material-ui/core';

import useStyles from './SaleEmptyState.styles';

function SaleEmptyState() {
  const classes = useStyles();

  return (
    <Mui.Grid className={classes.container} container>
      <Mui.Typography variant="h5">
        Você ainda não possui vendas !
      </Mui.Typography>
      <Mui.Button
        className={classes.warningButton}
        color="secondary"
        disableFocusRipple
        disableRipple
        variant="text"
        // onClick={handleCreateCustomer}
      >
        Crie uma agora
      </Mui.Button>
    </Mui.Grid>
  );
}

export default SaleEmptyState;
