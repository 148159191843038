import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';
import React from 'react';

import { Metadata } from 'components/utils/Metadata';

import { InputMask } from 'components';
import { documentMask, phoneMask } from 'helpers';
import type { EMarketplaceUserRole } from 'types/entities/marketplace-user';
import { useMarketplaceUserEditDialog } from './marketplace-user-edit-dialog.hook';
import useStyles from './marketplace-user-edit-dialog.styles';
import { MarketplaceUserUtils } from 'helpers/marketplace-user-utils';

function MarketplaceUserEditDialog() {
  const classes = useStyles();

  const {
    marketplaceUser,
    marketplaceUserState,
    formState,
    setFormState,
    handleDialogClose,
    handleFormChange,
    handleMetadataChange,
    handleSubmit,
    hasError,
  } = useMarketplaceUserEditDialog();

  function handleCheckboxChange(
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) {
    const roles = event.target
      .name as keyof typeof MarketplaceUserUtils.rolesLabelMap;

    const newRoles = formState.values.roles.slice();

    if (checked) {
      newRoles.push(roles);
    } else {
      const roleIndex = newRoles.findIndex(_roles => _roles === roles);
      newRoles.splice(roleIndex, 1);
    }

    setFormState(prevState => ({
      ...prevState,
      values: { ...prevState.values, roles: newRoles },
    }));
  }

  function handleRoleToggle(value: string) {
    const newRoles = formState.values.roles.slice();
    const roleIndex = newRoles.findIndex(_role => _role === value);

    if (roleIndex < 0) {
      newRoles.push(value as EMarketplaceUserRole);
    } else {
      newRoles.splice(roleIndex, 1);
    }

    setFormState(prevState => ({
      ...prevState,
      values: { ...prevState.values, roles: newRoles },
    }));
  }

  return (
    <React.Fragment>
      <Mui.AppBar className={classes.appBar}>
        <Mui.Toolbar>
          <Mui.IconButton
            aria-label="Fechar"
            color="inherit"
            edge="start"
            onClick={handleDialogClose}
          >
            <MuiIcons.Close />
          </Mui.IconButton>
          <Mui.Typography className={classes.appBarTitle} variant="h5">
            {marketplaceUserState === 'create'
              ? 'Criar novo usuário'
              : 'Editar usuário'}
          </Mui.Typography>
          <Mui.Button
            color="inherit"
            disabled={!formState.isValid || formState.disabled}
            onClick={handleSubmit}
          >
            {marketplaceUserState === 'create' ? 'Criar' : 'Salvar'}
          </Mui.Button>
        </Mui.Toolbar>
      </Mui.AppBar>
      <Mui.Grid className={classes.container} container spacing={2}>
        {marketplaceUserState === 'edit' && (
          <>
            <Mui.Grid item xs={12}>
              <Mui.Typography variant="h4">Ações</Mui.Typography>
            </Mui.Grid>
            <div className={classes.actionsContainer}>
              <Mui.Tooltip title="Em breve">
                <div className={classes.actionButton}>
                  <Mui.Button
                    color="secondary"
                    disabled
                    fullWidth
                    variant="contained"
                  >
                    Deletar
                  </Mui.Button>
                </div>
              </Mui.Tooltip>
            </div>
            <Mui.Grid item xs={12}>
              <Mui.Divider />
            </Mui.Grid>
          </>
        )}
        <Mui.Grid item xs={12}>
          <Mui.Typography variant="h4">Dados do usuário</Mui.Typography>
        </Mui.Grid>

        <Mui.Grid item sm={6} xs={12}>
          <Mui.TextField
            error={hasError('name')}
            fullWidth
            helperText={hasError('name') && formState.errors.name}
            id="name"
            label="Nome Completo"
            name="name"
            onChange={handleFormChange}
            type="text"
            value={formState.values.name}
            variant="outlined"
          />
        </Mui.Grid>

        <Mui.Grid item sm={6} xs={12}>
          <Mui.TextField
            InputLabelProps={{
              classes: {
                disabled: classes.inputDisabled,
              },
            }}
            InputProps={{
              classes: {
                disabled: classes.inputDisabled,
              },
            }}
            disabled={marketplaceUserState === 'edit'}
            error={hasError('email')}
            fullWidth
            helperText={hasError('email') && formState.errors.email}
            id="email"
            label="E-mail"
            name="email"
            onChange={handleFormChange}
            type="email"
            value={formState.values.email}
            variant="outlined"
          />
        </Mui.Grid>

        <Mui.Grid item sm={6} xs={12}>
          <Mui.TextField
            InputProps={{
              inputComponent: InputMask,
            }}
            error={hasError('documentNumber')}
            fullWidth
            helperText={
              hasError('documentNumber')
                ? formState.errors.documentNumber
                : 'Ex: 123.456.789-00 ou 12.345.678/0001-01'
            }
            id="documentNumber"
            inputProps={{
              mask: documentMask,
            }}
            label="CPF ou CNPJ"
            name="documentNumber"
            onChange={handleFormChange}
            required
            type="tel" // eslint-disable-next-line
            value={formState.values.documentNumber || ''}
            variant="outlined"
          />
        </Mui.Grid>

        <Mui.Grid item sm={6} xs={12}>
          <Mui.TextField
            InputProps={{
              inputComponent: InputMask,
            }}
            error={!!formState.errors.phoneNumber}
            fullWidth
            helperText={
              formState.errors.phoneNumber
                ? formState.errors.phoneNumber
                : 'Ex: (11) 3030-3030 ou (11) 99123-4567'
            }
            id="phoneNumber"
            inputProps={{
              mask: phoneMask,
            }}
            label="Telefone ou celular"
            name="phoneNumber"
            onChange={handleFormChange}
            type="tel"
            value={formState.values.phoneNumber || ''} // eslint-disable-next-line
            variant="outlined"
          />
        </Mui.Grid>

        <Mui.Grid item xs={12}>
          <Mui.Divider />
        </Mui.Grid>

        <Mui.Grid item xs={12}>
          <Mui.Typography variant="h4">Cargos</Mui.Typography>

          <Mui.List className={classes.checkboxList}>
            {Object.entries(MarketplaceUserUtils.rolesLabelMap).map(
              ([value, label]) => (
                <Mui.ListItem
                  button
                  className={classes.checkboxListItem}
                  key={value}
                  onClick={() => handleRoleToggle(value)}
                >
                  <Mui.Checkbox
                    checked={formState.values.roles.includes(
                      value as EMarketplaceUserRole
                    )}
                    color="primary"
                    name={value}
                    onChange={handleCheckboxChange}
                  />
                  <Mui.ListItemText primary={label} />
                </Mui.ListItem>
              )
            )}
          </Mui.List>

          <Mui.FormHelperText error>
            {formState.errors.roles}
          </Mui.FormHelperText>
        </Mui.Grid>

        <Mui.Grid item xs={12}>
          <Mui.Divider />
        </Mui.Grid>
        <Mui.Grid item xs={12}>
          <Mui.Typography variant="h4">Metadados</Mui.Typography>
        </Mui.Grid>
        <Mui.Grid item lg={8} md={10} xs={12}>
          <Metadata
            metadata={marketplaceUser?.metadata}
            onChange={handleMetadataChange}
          />
        </Mui.Grid>
      </Mui.Grid>
    </React.Fragment>
  );
}

export default MarketplaceUserEditDialog;
