import moment from 'moment';

export class DateFilterUtils {
  // Today
  public static readonly startOfToday = moment()
    .startOf('day')
    .utc()
    .toISOString();
  public static readonly endOfToday = moment().endOf('day').utc().toISOString();

  // Yesterday
  public static readonly startOfYesterday = moment()
    .add(-1, 'day')
    .startOf('day')
    .utc()
    .toISOString();
  public static readonly endOfYesterday = moment()
    .add(-1, 'day')
    .endOf('day')
    .utc()
    .toISOString();

  // This week
  public static readonly startOfWeek = moment()
    .startOf('week')
    .utc()
    .toISOString();
  public static readonly endOfWeek = moment().endOf('week').utc().toISOString();

  // Last week
  public static readonly startOfLastWeek = moment()
    .add(-1, 'week')
    .startOf('week')
    .utc()
    .toISOString();
  public static readonly endOfLastWeek = moment()
    .add(-1, 'week')
    .endOf('week')
    .utc()
    .toISOString();

  // This month
  public static readonly startOfMonth = moment()
    .startOf('month')
    .utc()
    .toISOString();
  public static readonly endOfMonth = moment()
    .endOf('month')
    .utc()
    .toISOString();

  // Last month
  public static readonly startOfLastMonth = moment()
    .add(-1, 'month')
    .startOf('month')
    .utc()
    .toISOString();
  public static readonly endOfLastMonth = moment()
    .add(-1, 'month')
    .endOf('month')
    .utc()
    .toISOString();

  public static getDateFilterLabel(dateStart: string, dateEnd: string): string {
    let label = '';

    const dateStartFormatted = moment(dateStart).format('DD/MM/YY HH:mm:ss');
    const dateEndFormatted = moment(dateEnd).format('DD/MM/YY HH:mm:ss');

    if (dateStart && dateEnd) {
      const labelMapped = this.getDateFilterLabelMapped(dateStart, dateEnd);

      if (labelMapped) {
        return labelMapped;
      }

      label = `${dateStartFormatted} até ${dateEndFormatted}`;
    } else if (dateStart) {
      label = `${dateStartFormatted} até ∞`;
    } else if (dateEnd) {
      label = `∞ até ${dateEndFormatted}`;
    } else {
      label = 'Todas até o momento';
    }

    return label;
  }

  private static getDateFilterLabelMapped(
    dateStart: string,
    dateEnd: string
  ): string | undefined {
    const dateStartMoment = moment.utc(dateStart);
    const dateEndMoment = moment.utc(dateEnd);

    if (
      dateStartMoment.isSame(this.startOfToday) &&
      dateEndMoment.isSame(this.endOfToday)
    ) {
      return 'Hoje';
    } else if (
      dateStartMoment.isSame(this.startOfYesterday) &&
      dateEndMoment.isSame(this.endOfYesterday)
    ) {
      return 'Ontem';
    } else if (
      dateStartMoment.isSame(this.startOfWeek) &&
      dateEndMoment.isSame(this.endOfWeek)
    ) {
      return 'Esta semana';
    } else if (
      dateStartMoment.isSame(this.startOfLastWeek) &&
      dateEndMoment.isSame(this.endOfLastWeek)
    ) {
      return 'Semana passada';
    } else if (
      dateStartMoment.isSame(this.startOfMonth) &&
      dateEndMoment.isSame(this.endOfMonth)
    ) {
      return 'Este mês';
    } else if (
      dateStartMoment.isSame(this.startOfLastMonth) &&
      dateEndMoment.isSame(this.endOfLastMonth)
    ) {
      return 'Mês passado';
    }
  }
}
