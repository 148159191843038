import * as Mui from '@material-ui/core';

const useStyles = Mui.makeStyles(theme => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    color: '#fff',
  },
  container: {
    padding: theme.spacing(3),
    width: '100%',
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    width: '100%',
    padding: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  actionButton: {
    flex: 1,
    maxWidth: 320,
  },
  cardBrandContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  cardBrandIconContainer: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(1),

    '& > img': {
      marginRight: theme.spacing(1),
      objectFit: 'contain',
      minWidth: 50,
      maxWidth: 50,
      minHeight: 25,
      maxHeight: 25,
    },

    '& > svg': {
      marginRight: theme.spacing(1),
    },
  },

  selectMenuList: {
    backgroundColor: theme.palette.background.dark2,
  },
  selectDisabled: {
    color: `${theme.palette.complementary.lightGrey2} !important`,
  },
  selectIcon: {
    color: theme.palette.text.primary,
  },
  selectMenuItem: {
    '&:hover': {
      backgroundColor: theme.palette.background.default,
    },
  },
  selectItemSelected: {
    color: theme.palette.text.primary,
    backgroundColor: `${theme.palette.background.default} !important`,
  },
  formHelperText: {
    fontSize: 14,
  },
}));

export default useStyles;
