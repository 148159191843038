import * as Mui from '@material-ui/core';

const useStyles = Mui.makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(2),
    backgroundColor: theme.palette.background.dark2,
    borderRadius: 4,
  },
  tableCell: {
    display: 'flex',
    alignItems: 'center',
    padding: '0px 7px',
  },
  tableDesktop: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  tableTablet: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  actionsContainer: {
    display: 'flex',
    gap: theme.spacing(0.5),
  },
  iconButton: {
    fontSize: 18,
  },
  listItem: {
    '&:hover': {
      backgroundColor: theme.palette.complementary.grey,
    },
  },
  icon: {
    color: theme.palette.white,
  },
  menuList: {
    backgroundColor: theme.palette.background.dark2,
  },
  paginationContainer: {
    marginTop: theme.spacing(1),
  },
}));

export default useStyles;
