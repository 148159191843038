import type { ISubscription } from '../types/entities';

export class SubscriptionUtils {
  public static statusLabelMap: Record<ISubscription['status'], string> = {
    active: 'ativa',
    canceled: 'cancelada',
    expired: 'expirada',
    suspended: 'suspensa',
  };

  public static getStatusLabel(status: ISubscription['status']): string {
    return this.statusLabelMap[status] || '';
  }
}
