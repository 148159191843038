import React, { useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import moment from 'moment-timezone';

import {
  setChargeList,
  setChargeState,
  setChargeSelected,
  setSnackbarOpen,
  setSnackbarMessage,
  setSnackbarType,
  setDialogType,
  setDialogFullScreen,
  setDialogOpen,
} from 'store/actions';

import {
  getDisplayMoney,
  getDisplayPhoneNumber,
  getDisplayEmail,
} from 'helpers';

import clsx from 'clsx';

import { makeStyles, useTheme } from '@material-ui/styles';

import {
  Typography,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Divider,
  Grid,
  Button,
  Checkbox,
  CircularProgress,
} from '@material-ui/core';

import {
  Done as DoneIcon,
  AccessTime as AccessTimeIcon,
  Cancel as CancelIcon,
  Delete as DeleteIcon,
  Visibility as VisibilityIcon,
  Gavel as GavelIcon,
  ThreeSixty as ThreeSixtyIcon,
  Refresh as RefreshIcon,
} from '@material-ui/icons';

import { green, amber } from '@material-ui/core/colors';
import { api } from 'services/api';

const variantIcon = {
  succeeded: DoneIcon,
  pending: AccessTimeIcon,
  pending_zoop: AccessTimeIcon,
  new: AccessTimeIcon,
  dispute: GavelIcon,
  refunded: ThreeSixtyIcon,
  charged_back: ThreeSixtyIcon,
  canceled: CancelIcon,
  failed: CancelIcon,
};

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(2),
  },
  succeeded: {
    color: green[600],
  },
  pending: {
    color: amber[700],
  },
  pending_zoop: {
    color: amber[700],
  },
  canceled: {
    color: theme.palette.error.dark,
  },
  failed: {
    color: theme.palette.error.dark,
  },
  charged_back: {
    color: theme.palette.error.dark,
  },
  container: {
    justifyContent: 'center',
    marginTop: theme.spacing(2),
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  containerCheckbox: {
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'flex-end',
    },
  },
  checkbox: {
    padding: 0,
    margin: 'auto',
  },
  tableCell: {
    display: 'flex',
    alignItems: 'center',
    padding: '0px 7px',
  },
  tableDesktop: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  tableTablet: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  iconButton: {
    fontSize: 18,
  },
  buttonView: {
    marginRight: theme.spacing(1),
  },
  options: {
    visibility: 'hidden',
    [theme.breakpoints.down('md')]: {
      visibility: 'visible',
    },
  },
  listItem: {
    '&:hover': {
      backgroundColor: theme.palette.complementary.grey,
    },
  },
  justifyCenter: {
    justifyContent: 'center',
  },
  warning: {
    padding: theme.spacing(2),
  },
  warningButton: {
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      maxWidth: '90%',
      marginLeft: 0,
    },
  },
  containerProgress: {
    marginTop: theme.spacing(2),
    minHeight: 200,
  },
}));

const ChargeList = ({ history }) => {
  const classes = useStyles();

  const theme = useTheme();

  const dispatch = useDispatch();

  const { chargeList, state: chargeState } = useSelector(
    state => state.chargeState
  );

  const [chargeListSelected, setChargeListSelected] = useState([]);

  useEffect(() => {
    if (chargeState === 'get') {
      async function getCharges() {
        try {
          const token = localStorage.getItem('token');
          const response = await api.get('/charge', {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          if (response.data) {
            dispatch([setChargeList(response.data), setChargeState('success')]);
          }
        } catch (error) {
          dispatch([
            setSnackbarMessage(
              'Não foi possível fazer a listagem das cobranças, tente novamente mais tarde !'
            ),
            setSnackbarType('error'),
            setSnackbarOpen(),
            setChargeState('failed'),
          ]);
          console.error('Não foi possível buscar a lista de cobranças');
        }
      }

      getCharges();
    }
  }, [chargeState, dispatch]);

  const handleViewCharge = charge => {
    dispatch(setChargeState('view'));
    dispatch(setDialogType('viewCharge'));
    dispatch(setChargeSelected(charge));
    dispatch(setDialogFullScreen(true));
    dispatch(setDialogOpen());
  };

  const handleDeleteCharge = charge => {
    dispatch(setChargeState('delete'));
    dispatch(setDialogType('deleteCharge'));
    dispatch(setChargeSelected(charge));
    dispatch(setDialogFullScreen(false));
    dispatch(setDialogOpen());
  };

  const handleCreateCharge = () => {
    history.push('charge');
  };

  const handleMouseEnterListItem = event => {
    const width = window.innerWidth;

    if (width > theme.breakpoints.width('md')) {
      const element = event.currentTarget.children[6];
      element.style.visibility = 'visible';
    }
  };

  const handleMouseLeaveListItem = event => {
    const width = window.innerWidth;

    if (width > theme.breakpoints.width('md')) {
      const element = event.currentTarget.children[6];
      element.style.visibility = 'hidden';
    }
  };

  const handleSelectAll = event => {
    let selectedCharges;

    if (event.target.checked) {
      // if (listCustomersFiltered.length > 0) {
      //   selectedCharges = listCustomersFiltered.slice();
      // } else {
      selectedCharges = chargeList.slice();
      // }
    } else {
      selectedCharges = [];
    }

    setChargeListSelected(selectedCharges);
  };

  const handleSelectOne = (event, charge) => {
    const selectedIndex = chargeListSelected
      .map(charge => charge.id)
      .indexOf(charge.id);
    let newSelectedCharges = [];

    if (selectedIndex === -1) {
      newSelectedCharges = newSelectedCharges.concat(
        chargeListSelected,
        charge
      );
    } else if (selectedIndex === 0) {
      newSelectedCharges = newSelectedCharges.concat(
        chargeListSelected.slice(1)
      );
    } else if (selectedIndex === chargeListSelected.length - 1) {
      newSelectedCharges = newSelectedCharges.concat(
        chargeListSelected.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelectedCharges = newSelectedCharges.concat(
        chargeListSelected.slice(0, selectedIndex),
        chargeListSelected.slice(selectedIndex + 1)
      );
    }

    setChargeListSelected(newSelectedCharges);
  };

  const handleRefresh = () => {
    dispatch([setChargeSelected({}), setChargeState('get')]);
  };

  const getDisplayInstallmentOrCash = (installment, installments) => {
    if (installments !== 1) {
      return `(${installment}/${installments})`;
    } else {
      return 'à vista';
    }
  };

  const renderChargeList = () => {
    return chargeList.map(charge => {
      const Icon = variantIcon[charge.status];

      return (
        <React.Fragment key={charge.id}>
          <ListItem
            className={classes.listItem}
            onMouseEnter={event => handleMouseEnterListItem(event)}
            onMouseLeave={event => handleMouseLeaveListItem(event)}
          >
            <Grid
              className={clsx(classes.tableCell, classes.containerCheckbox)}
              item
              xs={1}
            >
              <Checkbox
                checked={
                  chargeListSelected
                    .map(charge => charge.id)
                    .indexOf(charge.id) !== -1
                }
                className={classes.checkbox}
                color="primary"
                onChange={event => handleSelectOne(event, charge)}
                value="true"
              />
            </Grid>
            <Grid
              className={clsx(classes.tableCell, classes.justifyCenter)}
              item
              md={1}
              xs={2}
            >
              <Icon className={clsx(classes[charge.status])} />
            </Grid>
            <Grid className={classes.tableCell} item md={2} sm={3} xs={5}>
              <ListItemText
                disableTypography
                primary={<Typography noWrap>{charge.buyer_name}</Typography>}
                secondary={
                  <Grid component="span" container direction="column">
                    <Grid component="span" item xs zeroMinWidth>
                      <Typography component="div" noWrap variant="body2">
                        {getDisplayEmail(charge.buyer_email)}
                      </Typography>
                    </Grid>
                    <Grid component="span" item xs zeroMinWidth>
                      <Typography component="div" noWrap variant="body2">
                        {getDisplayPhoneNumber(charge.buyer_phone_number)}
                      </Typography>
                    </Grid>
                  </Grid>
                }
              />
            </Grid>
            <Grid
              className={clsx(classes.tableCell, classes.tableTablet)}
              item
              sm={2}
            >
              <Typography noWrap variant="body1">
                {getDisplayMoney(charge.amount / 100)}{' '}
                {getDisplayInstallmentOrCash(
                  charge.installment,
                  charge.installments
                )}
              </Typography>
            </Grid>
            <Grid
              className={clsx(classes.tableCell, classes.tableDesktop)}
              item
              md={2}
            >
              <Typography noWrap variant="body1">
                {moment(charge.created_at).format('DD/MM/YYYY')}
              </Typography>
            </Grid>
            <Grid
              className={clsx(classes.tableCell, classes.tableDesktop)}
              item
              md={2}
            >
              <Typography noWrap variant="body1">
                {moment(charge.expiration_date).format('DD/MM/YYYY')}
              </Typography>
            </Grid>
            <Grid
              className={clsx(
                classes.tableCell,
                classes.options,
                classes.justifyCenter
              )}
              item
              md={2}
              sm={3}
              xs={4}
            >
              <IconButton
                className={classes.buttonView}
                onClick={() => handleViewCharge(charge)}
              >
                <VisibilityIcon className={classes.iconButton} />
              </IconButton>
              <IconButton onClick={() => handleDeleteCharge(charge)}>
                <DeleteIcon className={classes.iconButton} />
              </IconButton>
            </Grid>
          </ListItem>

          <Grid component={Divider} item xs={12} />
        </React.Fragment>
      );
    });
  };

  if (chargeState === 'get') {
    return (
      <Grid
        alignItems="center"
        className={classes.containerProgress}
        container
        justify="center"
        spacing={2}
      >
        <CircularProgress />
      </Grid>
    );
  } else if (chargeList.length > 0) {
    return (
      <Grid className={classes.root} component={List} container>
        <React.Fragment>
          <ListItem>
            <Grid
              className={clsx(classes.tableCell, classes.containerCheckbox)}
              item
              xs={1}
            >
              <Checkbox
                checked={chargeListSelected.length === chargeList.length}
                className={classes.checkbox}
                color="primary"
                indeterminate={
                  chargeListSelected.length > 0 &&
                  chargeListSelected.length < chargeList.length
                }
                onChange={handleSelectAll}
              />
            </Grid>
            <Grid
              className={clsx(classes.tableCell, classes.justifyCenter)}
              item
              md={1}
              xs={2}
            >
              <Typography noWrap variant="h5">
                Status
              </Typography>
            </Grid>
            <Grid className={classes.tableCell} item md={2} sm={3} xs={5}>
              <Typography noWrap variant="h5">
                Cliente
              </Typography>
            </Grid>
            <Grid
              className={clsx(classes.tableCell, classes.tableTablet)}
              item
              sm={2}
            >
              <Typography noWrap variant="h5">
                Valor
              </Typography>
            </Grid>
            <Grid
              className={clsx(classes.tableCell, classes.tableDesktop)}
              item
              md={2}
            >
              <Typography noWrap variant="h5">
                Emissão
              </Typography>
            </Grid>
            <Grid
              className={clsx(classes.tableCell, classes.tableDesktop)}
              item
              md={2}
            >
              <Typography noWrap variant="h5">
                Vencimento
              </Typography>
            </Grid>
          </ListItem>

          <Grid component={Divider} item xs={12} />

          {renderChargeList()}
        </React.Fragment>
      </Grid>
    );
  } else if (chargeState === 'failed') {
    return (
      <Grid
        alignItems="center"
        className={classes.containerProgress}
        container
        direction="column"
        justify="center"
        spacing={2}
      >
        <Typography variant="body2">
          Não foi possível se conectar ao servidor. Tente novamente...
        </Typography>
        <IconButton onClick={handleRefresh}>
          <RefreshIcon />
        </IconButton>
      </Grid>
    );
  } else {
    return (
      <Grid className={classes.container} container>
        <Typography className={classes.warning} variant="h5">
          Você ainda não possui cobranças !
        </Typography>
        <Button
          className={classes.warningButton}
          color="secondary"
          disableFocusRipple
          disableRipple
          onClick={handleCreateCharge}
          variant="text"
        >
          Crie um agora
        </Button>
      </Grid>
    );
  }
};

export default ChargeList;
