import moment from 'moment';

import type {
  ISaleReducerAction,
  ISaleReducerActionPayload,
  ISaleReducerState,
} from 'types/redux';

const INITIAL_STATE: ISaleReducerState = {
  listType: 'get',
  pagination: null,
  sale: null,
  saleList: [],
  state: 'get',
  metrics: {
    state: 'fetching',
    values: {
      approved_total_amount: 0,
      average_ticket: 0,
      canceled_total_amount: 0,
      chargeback_total_amount: 0,
      failed_total_amount: 0,
      refunded_total_amount: 0,
    },
  },
  filter: {
    dateStart: moment().startOf('day').utc().toISOString(),
    dateEnd: moment().endOf('day').utc().toISOString(),
    search: '',
    status: '',
    paymentTypes: '',
    amountFrom: '',
    amountTo: '',
  },
};

export default (state = INITIAL_STATE, action: ISaleReducerAction<unknown>) => {
  switch (action.type) {
    case 'SALE_STATE_CHANGED':
      return {
        ...state,
        state: action.payload,
      };
    case 'SALE_CHANGED':
      return {
        ...state,
        sale: action.payload,
      };
    case 'SALES_LIST_CHANGED':
      return {
        ...state,
        saleList: action.payload,
      };
    case 'SALE_LIST_TYPE_CHANGED':
      return {
        ...state,
        listType: action.payload,
      };
    case 'SALE_PAGINATION_CHANGED':
      return {
        ...state,
        pagination: action.payload,
      };
    case 'SALE_FILTER_CHANGED':
      return {
        ...state,
        filter: {
          ...state.filter,
          ...(action.payload as ISaleReducerActionPayload['SALE_FILTER_CHANGED']),
        },
      };
    case 'SALE_METRICS_STATE_CHANGED':
      return {
        ...state,
        metrics: {
          ...state.metrics,
          state: action.payload,
        },
      };
    case 'SALE_METRICS_VALUES_CHANGED':
      return {
        ...state,
        metrics: {
          ...state.metrics,
          values: action.payload,
        },
      };
    default:
      return state;
  }
};
