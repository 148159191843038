export function setNotificationsOpen() {
  return {
    type: 'NOTIFICATIONS_OPEN_CHANGED',
  };
}

export function setNotificationsClose() {
  return {
    type: 'NOTIFICATIONS_CLOSE_CHANGED',
  };
}

export function setNotifications(notifications) {
  return {
    type: 'NOTIFICATIONS_CHANGED',
    payload: notifications,
  };
}
