import React from 'react';
import * as ReactRedux from 'react-redux';

import * as MarketplaceUserHooks from 'hooks/use-marketplace-user';
import {
  setDialogClose,
  setDialogType,
  setSnackbarMessage,
  setSnackbarOpen,
  setSnackbarType,
} from 'store/actions';
import { setMarketplaceUser } from 'store/actions/marketplace-user-actions';

export function useMarketplaceUserDeleteDialog() {
  const dispatch = ReactRedux.useDispatch();

  const { state, service } = MarketplaceUserHooks.useMarketplaceUser();

  const { marketplaceUser, state: marketplaceUserState } = state;

  const [buttonDisable, setButtonDisable] = React.useState(true);

  const handleDialogClose = React.useCallback(() => {
    dispatch([setMarketplaceUser(null), setDialogClose(), setDialogType(null)]);
  }, [dispatch]);

  const handleMarketplaceUserDelete = React.useCallback(async () => {
    try {
      dispatch([
        setSnackbarMessage('Processando...'),
        setSnackbarType('info'),
        setSnackbarOpen(),
      ]);

      if (!marketplaceUser || marketplaceUserState !== 'delete') return;

      await service.deleteMarketplaceUser({ id: marketplaceUser.id });

      dispatch([setDialogClose(), setDialogType(null)]);
    } catch (error) {
      console.log(error);
      dispatch([
        setSnackbarMessage(
          'Não foi possível deletar este usuário, tente novamente mais tarde!'
        ),
        setSnackbarType('error'),
        setSnackbarOpen(),
      ]);
    }
  }, [dispatch, marketplaceUser, marketplaceUserState, service]);

  React.useEffect(() => {
    if (marketplaceUser) {
      setButtonDisable(false);
    }
  }, [marketplaceUser]);

  return React.useMemo(
    () => ({
      marketplaceUser,
      marketplaceUserState,
      buttonDisable,
      handleDialogClose,
      handleMarketplaceUserDelete,
    }),
    [
      marketplaceUser,
      marketplaceUserState,
      buttonDisable,
      handleDialogClose,
      handleMarketplaceUserDelete,
    ]
  );
}
