import React from 'react';
import * as ReactRedux from 'react-redux';
import { validate } from 'validate.js';

import * as ProductHooks from 'hooks/useProduct';

import { useTaxInvoiceConfigService } from 'hooks/use-tax-invoice-config';
import {
  setDialogClose,
  setDialogType,
  setProduct,
  setSnackbarMessage,
  setSnackbarOpen,
  setSnackbarType,
} from 'store/actions';
import type { IProduct } from 'types/entities';

import {
  ETaxInvoiceType,
  type TTaxInvoiceType,
} from 'types/entities/tax-invoice';
import type { RootState } from 'types/redux';

interface IFormState {
  disabled: boolean;
  isValid: boolean;

  values: {
    name: string;
    description: string | null;
    image: string;
    imageFile: File | null;
    status: IProduct['status'];
    metadata: Record<string, string | number> | null;
    taxInvoiceActive?: boolean;
    taxInvoiceProductId?: string;
    taxInvoiceProductType?: TTaxInvoiceType;
    taxInvoiceDescription?: string;
  };
  errors: {
    name?: string;
    description?: string;
    image?: string;
    imageFile?: string;
    status?: string;
    metadata?: string;
    taxInvoiceActive?: string;
    taxInvoiceProductId?: string;
    taxInvoiceProductType?: string;
    taxInvoiceDescription?: string;
  };
  touched: {
    name?: boolean;
    description?: boolean;
    image?: boolean;
    imageFile?: boolean;
    status?: boolean;
    metadata?: boolean;
    taxInvoiceActive?: boolean;
    taxInvoiceProductId?: boolean;
    taxInvoiceProductType: boolean;
    taxInvoiceDescription?: boolean;
  };
}

const schema = {
  name: {
    presence: {
      allowEmpty: false,
      message: '^Nome é obrigatório',
    },
  },
  image: {
    presence: {
      allowEmpty: false,
      message: '^Imagem é obrigatória',
    },
  },
  description: {
    presence: {
      allowEmpty: false,
      message: '^Descrição é obrigatória',
    },
  },
};

export function useProductEditDialog() {
  const dispatch = ReactRedux.useDispatch();

  const { state, service } = ProductHooks.useProdcut();

  const taxInvoiceConfigService = useTaxInvoiceConfigService();
  const { taxInvoiceConfig } = ReactRedux.useSelector(
    (state: RootState) => state.taxInvoiceConfigState
  );

  const { product, state: productState } = state;

  const formStateInitialValue = React.useMemo(
    () => ({
      disabled: false,
      isValid: false,
      values: {
        name: product?.name || '',
        description: product?.description || '',
        image: product?.image || '',
        imageFile: null,
        status: product?.status || 'active',
        metadata: null,
        taxInvoiceActive: !!product?.internalMetadata?.taxInvoiceActive,
        taxInvoiceProductId:
          product?.internalMetadata?.taxInvoiceProductId || '',
        taxInvoiceDescription:
          product?.internalMetadata?.taxInvoiceDescription || '',
        taxInvoiceProductType:
          product?.internalMetadata?.taxInvoiceType || ETaxInvoiceType.PRODUCT,
      },
      errors: {},
      touched: {
        image: true,
        imageFile: true,
        taxInvoiceActive: true,
        taxInvoiceProductId: true,
        taxInvoiceDescription: true,
        taxInvoiceProductType: true,
      },
    }),
    [product]
  );

  const [formState, setFormState] = React.useState<IFormState>(
    formStateInitialValue
  );

  const handleDialogClose = React.useCallback(() => {
    dispatch([setProduct(null), setDialogClose(), setDialogType(null)]);
  }, [dispatch]);

  const handleChangeForm = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.persist();

      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          [event.target.name]:
            event.target.type === 'checkbox'
              ? event.target.checked
              : event.target.value,
        },
        touched: {
          ...formState.touched,
          [event.target.name]: true,
        },
      }));
    },
    []
  );

  const handleImageChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const files = event.target.files;

      if (files?.length === 0) {
        return;
      }

      const file = files![0];

      setFormState(prevState => ({
        ...prevState,
        values: {
          ...prevState.values,
          image: URL.createObjectURL(file),
          imageFile: file,
        },
      }));
    },
    []
  );

  const handleMetadataChange = React.useCallback(
    (metadata: Record<string, string | number> | null) => {
      setFormState(prevState => ({
        ...prevState,
        values: {
          ...prevState.values,
          metadata,
        },
      }));
    },
    []
  );

  const hasError = React.useCallback(
    (field: keyof IFormState['values']) =>
      !!(formState.touched[field] && formState.errors[field]),
    [formState.errors, formState.touched]
  );

  function handleInputFocus(inputName: keyof IFormState['values']) {
    const inputElement = document.querySelector(
      `#${inputName}`
    ) as HTMLInputElement;

    inputElement.focus();
  }

  const handleSubmit = React.useCallback(async () => {
    try {
      setFormState(prevState => ({
        ...prevState,
        error: {},
      }));

      const {
        description,
        name,
        status,
        imageFile,
        metadata,
        taxInvoiceActive,
        taxInvoiceProductId,
        taxInvoiceDescription,
        taxInvoiceProductType,
      } = formState.values;

      if (name === '') {
        setFormState(prevState => ({
          ...prevState,
          errors: {
            ...prevState.errors,
            name: 'Nome é obrigatório!',
          },
        }));

        handleInputFocus('name');
        return;
      }

      if (taxInvoiceActive) {
        if (!taxInvoiceProductType) {
          setFormState(prevState => ({
            ...prevState,
            errors: {
              ...prevState.errors,
              taxInvoiceDescription: 'Tipo da nota fiscal é obrigatória!',
            },
          }));

          handleInputFocus('taxInvoiceDescription');
          return;
        }

        if (!taxInvoiceDescription) {
          setFormState(prevState => ({
            ...prevState,
            errors: {
              ...prevState.errors,
              taxInvoiceDescription: 'Descrição da nota fiscal é obrigatória!',
            },
          }));

          handleInputFocus('taxInvoiceDescription');
          return;
        }

        if (
          taxInvoiceConfig?.provider === 'notazz' &&
          taxInvoiceProductType === 'product' &&
          !taxInvoiceProductId
        ) {
          setFormState(prevState => ({
            ...prevState,
            errors: {
              ...prevState.errors,
              taxInvoiceProductId: 'Identificador do produto é obrigatório!',
            },
          }));

          handleInputFocus('taxInvoiceProductId');
          return;
        }
      }

      if (productState === 'create' && !imageFile) {
        setFormState(prevState => ({
          ...prevState,
          errors: {
            ...prevState.errors,
            imageFile: 'Imagem é obrigatória',
          },
        }));

        return;
      }

      if (productState === 'create') {
        await service.create({
          description,
          image: imageFile!,
          name,
          status,
          internalMetadata: {
            taxInvoiceActive,
            taxInvoiceDescription,
            taxInvoiceProductId,
            taxInvoiceType: taxInvoiceProductType,
          },
          metadata,
        });
      }

      if (productState === 'edit') {
        await service.edit({
          id: product!.id,
          description,
          image: imageFile,
          name,
          status,
          internalMetadata: {
            taxInvoiceActive,
            taxInvoiceDescription,
            taxInvoiceProductId,
            taxInvoiceType: taxInvoiceProductType,
          },
          metadata,
        });
      }
    } catch (error) {
      console.log(error);
      dispatch([
        setSnackbarMessage(
          'Não foi possível criar este produto, tente novamente mais tarde!'
        ),
        setSnackbarType('error'),
        setSnackbarOpen(),
      ]);
    }
  }, [
    dispatch,
    formState.values,
    product,
    productState,
    service,
    taxInvoiceConfig?.provider,
  ]);

  React.useEffect(() => {
    taxInvoiceConfigService.getBySellerId().finally(async () => {
      setFormState(prevState => ({
        ...prevState,
        disabled: false,
      }));
    });
  }, [taxInvoiceConfigService]);

  React.useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: !errors,
      errors: errors || {},
    }));
  }, [formState.values]);

  return React.useMemo(
    () => ({
      formState,
      product,
      productState,
      taxInvoiceConfig,
      handleChangeForm,
      handleDialogClose,
      handleImageChange,
      handleMetadataChange,
      handleSubmit,
      hasError,
      setFormState,
    }),
    [
      formState,
      product,
      productState,
      taxInvoiceConfig,
      handleChangeForm,
      handleDialogClose,
      handleImageChange,
      handleMetadataChange,
      handleSubmit,
      hasError,
      setFormState,
    ]
  );
}
