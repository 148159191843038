import React from 'react';

import { JSONUtils, ObjectUtils } from 'helpers';

export function useLocalStorage() {
  const PREFIX = '@recebafacil-dashboard/';

  const getKeyWithPrefix = React.useCallback(
    (key: string): string => `${PREFIX}${key}`,
    []
  );

  const set = React.useCallback(
    (key: string, value: string | object) => {
      const keyWithPrefix = getKeyWithPrefix(key);

      if (ObjectUtils.isObject(value)) {
        value = JSON.stringify(value);
      }

      return localStorage.setItem(keyWithPrefix, value);
    },
    [getKeyWithPrefix]
  );

  const get = React.useCallback(
    <T = string | object | null>(key: string): T => {
      const keyWithPrefix = getKeyWithPrefix(key);

      let result = localStorage.getItem(keyWithPrefix);

      if (JSONUtils.isJson(result)) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        result = JSON.parse(result!);
      }

      return result as unknown as T;
    },
    [getKeyWithPrefix]
  );

  const remove = React.useCallback(
    (key: string) => {
      const keyWithPrefix = getKeyWithPrefix(key);

      localStorage.removeItem(keyWithPrefix);
    },
    [getKeyWithPrefix]
  );

  return React.useMemo(
    () => ({
      remove,
      set,
      get,
    }),
    [remove, set, get]
  );
}
