import React from 'react';
import * as ReactRedux from 'react-redux';
import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';

import * as SaleHooks from 'hooks/useSale';
import { InputCurrency } from 'components';
import { NumberUtils } from 'helpers';
import {
  setDialogClose,
  setSaleFilter,
  setSalePagination,
} from 'store/actions';

import useStyles from './SalesFilterAmountDialog.styles';

function SalesFilterAmountDialog() {
  const classes = useStyles();

  const dispatch = ReactRedux.useDispatch();
  const sale = SaleHooks.useSale();
  const { filter, pagination } = sale.state;

  const amountFromParsed = Number(filter.amountFrom) / 100;
  const amountToParsed = Number(filter.amountTo) / 100;
  const [amountFrom, setAmountFrom] = React.useState<number>(
    amountFromParsed || 0
  );
  const [amountTo, setAmountTo] = React.useState<number>(amountToParsed || 0);

  function handleClose() {
    dispatch(setDialogClose());
  }

  function handleAmountFromInputChange(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    const value = event.target.value;

    if (Number(value) >= Number.MAX_SAFE_INTEGER) {
      setAmountFrom(Number.MAX_SAFE_INTEGER);
      return;
    }

    setAmountFrom(Number(value));
  }

  function handleAmountToInputChange(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    const value = event.target.value;

    if (Number(value) >= Number.MAX_SAFE_INTEGER) {
      setAmountTo(Number.MAX_SAFE_INTEGER);
      return;
    }

    setAmountTo(Number(value));
  }

  function handleFilterDateChange() {
    sale.ui.search();

    const amountFromParsed =
      amountFrom > 0
        ? NumberUtils.toPrecision(amountFrom * 100, 0).toString()
        : undefined;
    const amountToParsed =
      amountTo > 0
        ? NumberUtils.toPrecision(amountTo * 100, 0).toString()
        : undefined;

    dispatch([
      setSaleFilter({
        amountFrom: amountFromParsed,
        amountTo: amountToParsed,
      }),
      setSalePagination({
        offset: 0,
        hasMore: pagination?.hasMore || false,
        limit: pagination?.limit || 30,
        total: pagination?.total || 0,
        page: pagination?.page || 1,
      }),
    ]);

    sale.ui.refresh();
    sale.ui.refreshMetrics();

    handleClose();
  }

  function handleClearFilter() {
    setAmountFrom(0);
    setAmountTo(0);
  }

  return (
    <>
      <Mui.AppBar className={classes.appBar}>
        <Mui.Toolbar>
          <Mui.Typography className={classes.title} variant="h5">
            Filtrar por valor
          </Mui.Typography>
          <Mui.IconButton
            aria-label="Fechar"
            className={classes.closeButton}
            onClick={handleClose}
          >
            <MuiIcons.Close />
          </Mui.IconButton>
        </Mui.Toolbar>
      </Mui.AppBar>
      <Mui.DialogContent className={classes.container} dividers>
        <Mui.List>
          <Mui.ListItem className={classes.inputContainer}>
            <InputCurrency
              className={classes.amountFromInput}
              fullWidth
              label="De"
              onChange={handleAmountFromInputChange}
              value={amountFrom}
              variant="outlined"
            />
            <InputCurrency
              className={classes.amountToInput}
              fullWidth
              label="Até"
              onChange={handleAmountToInputChange}
              value={amountTo}
              variant="outlined"
            />
          </Mui.ListItem>
        </Mui.List>
      </Mui.DialogContent>
      <Mui.DialogActions>
        <Mui.Button onClick={handleClose}>Cancelar</Mui.Button>
        <Mui.Button
          className={classes.clearFilterButton}
          color="secondary"
          onClick={handleClearFilter}
          variant="contained"
        >
          Limpar filtro
        </Mui.Button>
        <Mui.Button
          color="primary"
          onClick={handleFilterDateChange}
          variant="contained"
        >
          OK
        </Mui.Button>
      </Mui.DialogActions>
    </>
  );
}

export default SalesFilterAmountDialog;
