export class NumberUtils {
  public static generateRandom(max: number, min: number) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  public static toPrecision(number: number, precision: number): number {
    const power = 10 ** precision;
    return Math.round(number * power) / power;
  }
}
