import React from 'react';
import * as ReactRedux from 'react-redux';

import type { ICoupon } from 'types/entities';
import {
  setCoupon,
  setCouponState,
  setDialogFullScreen,
  setDialogMaxWidth,
  setDialogOpen,
  setDialogType,
} from 'store/actions';

function useCouponUI() {
  const dispatch = ReactRedux.useDispatch();

  const openToCreate = React.useCallback(() => {
    dispatch([
      setCoupon(null),
      setCouponState('create'),
      setDialogType('createCoupon'),
      setDialogFullScreen(true),
      setDialogOpen(),
    ]);
  }, [dispatch]);

  const openToEdit = React.useCallback(
    (coupon: ICoupon) => {
      dispatch([
        setCoupon(coupon),
        setCouponState('edit'),
        setDialogType('editCoupon'),
        setDialogFullScreen(true),
        setDialogOpen(),
      ]);
    },
    [dispatch]
  );

  const openToView = React.useCallback(
    (coupon: ICoupon) => {
      dispatch([
        setCoupon(coupon),
        setCouponState('view'),
        setDialogType('viewCoupon'),
        setDialogFullScreen(true),
        setDialogOpen(),
      ]);
    },
    [dispatch]
  );

  const openToDelete = React.useCallback(
    (coupon: ICoupon) => {
      dispatch([
        setCoupon(coupon),
        setCouponState('delete'),
        setDialogType('deleteCoupon'),
        setDialogMaxWidth('sm'),
        setDialogFullScreen(false),
        setDialogOpen(),
      ]);
    },
    [dispatch]
  );

  const refresh = React.useCallback(() => {
    dispatch([setCoupon(null), setCouponState('get')]);
  }, [dispatch]);

  return React.useMemo(
    () => ({
      openToCreate,
      openToDelete,
      openToEdit,
      openToView,
      refresh,
    }),
    [openToCreate, openToDelete, openToEdit, openToView, refresh]
  );
}

export default useCouponUI;
