import React from 'react';

import { getDisplayMoney } from 'helpers';
import { useSale } from 'hooks/useSale';

import * as InternalComponents from './components';
import useStyles from './SalesMetrics.styles';
import { useAuthContext } from 'hooks/useAuthContext.hook';

function SalesMetrics() {
  const classes = useStyles();
  const sale = useSale();
  const { marketplaceUser } = useAuthContext();

  const { filter, metrics } = sale.state;

  React.useEffect(() => {
    if (metrics.state === 'fetching' || metrics.state === 'refreshing') {
      sale.service.metrics({
        dateStart: filter.dateStart,
        dateEnd: filter.dateEnd,
        search: filter.search,
        paymentTypes: filter.paymentTypes,
        amountFrom: filter.amountFrom,
        amountTo: filter.amountTo,
      });
    }
  }, [
    filter.amountFrom,
    filter.amountTo,
    filter.dateEnd,
    filter.dateStart,
    filter.paymentTypes,
    filter.search,
    metrics.state,
    sale.service,
  ]);

  return (
    <div className={classes.root}>
      {(!marketplaceUser ||
        marketplaceUser?.roles.includes('owner') ||
        marketplaceUser?.roles.includes('financial')) && (
        <>
          <InternalComponents.SalesMetricsCard
            failed={metrics.state === 'failed'}
            loaded={
              metrics.state !== 'fetching' && metrics.state !== 'refreshing'
            }
            onRefresh={sale.ui.refreshMetrics}
            text={getDisplayMoney(metrics.values.approved_total_amount / 100)}
            title="Aprovadas"
          />
          <InternalComponents.SalesMetricsCard
            failed={metrics.state === 'failed'}
            loaded={
              metrics.state !== 'fetching' && metrics.state !== 'refreshing'
            }
            onRefresh={sale.ui.refreshMetrics}
            text={getDisplayMoney(metrics.values.failed_total_amount / 100)}
            title="Falhadas"
          />
        </>
      )}

      <InternalComponents.SalesMetricsCard
        failed={metrics.state === 'failed'}
        loaded={metrics.state !== 'fetching' && metrics.state !== 'refreshing'}
        onRefresh={sale.ui.refreshMetrics}
        text={getDisplayMoney(metrics.values.average_ticket / 100)}
        title="Tiket médio"
      />
      {(!marketplaceUser ||
        marketplaceUser?.roles.includes('owner') ||
        marketplaceUser?.roles.includes('financial')) && (
        <>
          <InternalComponents.SalesMetricsCard
            failed={metrics.state === 'failed'}
            loaded={
              metrics.state !== 'fetching' && metrics.state !== 'refreshing'
            }
            onRefresh={sale.ui.refreshMetrics}
            text={getDisplayMoney(metrics.values.refunded_total_amount / 100)}
            title="Estornadas"
          />
          <InternalComponents.SalesMetricsCard
            failed={metrics.state === 'failed'}
            loaded={
              metrics.state !== 'fetching' && metrics.state !== 'refreshing'
            }
            onRefresh={sale.ui.refreshMetrics}
            text={getDisplayMoney(metrics.values.chargeback_total_amount / 100)}
            title="Chargebacks"
          />
        </>
      )}
    </div>
  );
}

export default SalesMetrics;
