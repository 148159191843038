import * as Mui from '@material-ui/core';
import React from 'react';

import { InputCurrency } from 'components';
import { BoletoUtils } from 'helpers/boleto-utils';
import type { ITransaction } from 'types/entities';
import type { IFormState } from '../../CheckoutEditDialog.hook';
import useStyles from './checkout-edit-boleto.styles';

interface ICheckoutEditBoletoProps {
  formState: IFormState;
  handleBoletoChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleDiscountValueChange: (
    paymentType: ITransaction['payment_type'],
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;

  hasBoletoError: (field: keyof IFormState['values']['boleto']) => boolean;
}

export function CheckoutEditBoleto(params: ICheckoutEditBoletoProps) {
  const {
    formState,
    handleBoletoChange,
    handleDiscountValueChange,
    hasBoletoError,
  } = params;

  const classes = useStyles();

  return (
    <Mui.Grid container item spacing={2} xs={12}>
      <Mui.Grid item xs={12}>
        <Mui.Typography variant="h4">Boleto bancário</Mui.Typography>
      </Mui.Grid>

      <Mui.Grid item md={4} sm={6} xs={12}>
        <Mui.TextField
          error={hasBoletoError('bodyInstructions')}
          fullWidth
          helperText={
            hasBoletoError('bodyInstructions')
              ? formState.errors.boleto?.bodyInstructions
              : ''
          }
          id="boleto-bodyInstructions"
          label="Instruções do boleto"
          maxRows="4"
          multiline
          name="boleto-bodyInstructions"
          onChange={handleBoletoChange}
          value={formState.values.boleto.bodyInstructions}
          variant="outlined"
        />
      </Mui.Grid>

      <Mui.Grid item md={4} sm={6} xs={12}>
        <Mui.TextField
          error={hasBoletoError('maxDaysToPay')}
          fullWidth
          helperText={
            hasBoletoError('maxDaysToPay') &&
            formState.errors.boleto?.maxDaysToPay
          }
          id="boleto-maxDaysToPay"
          label="Máximo de dias para pagar"
          name="boleto-maxDaysToPay"
          onChange={handleBoletoChange}
          type="number"
          value={formState.values.boleto.maxDaysToPay || ''}
          variant="outlined"
        />
      </Mui.Grid>

      <Mui.Grid item md={4} sm={6} xs={12}>
        <Mui.TextField
          InputLabelProps={{
            classes: {
              disabled: classes.selectDisabled,
            },
          }}
          SelectProps={{
            classes: {
              icon: classes.selectIcon,
              disabled: classes.selectDisabled,
            },
            MenuProps: {
              classes: {
                paper: classes.selectMenuList,
              },
            },
          }}
          error={hasBoletoError('discountType')}
          fullWidth
          helperText={
            hasBoletoError('discountType') &&
            formState.errors.boleto?.discountType
          }
          label="Tipo de desconto"
          name="boleto-discountType"
          onChange={handleBoletoChange}
          select
          value={formState.values.boleto.discountType}
          variant="outlined"
        >
          {Object.entries(BoletoUtils.discountTypeLabelMap).map(
            ([value, label]) => {
              return (
                <Mui.MenuItem
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  ListItemClasses={{
                    root: classes.selectMenuItem,
                    selected: classes.selectItemSelected,
                  }}
                  button
                  key={value}
                  selected={value === formState.values.boleto.discountType}
                  value={value}
                >
                  {label}
                </Mui.MenuItem>
              );
            }
          )}
        </Mui.TextField>
      </Mui.Grid>

      <Mui.Grid item md={4} sm={6} xs={12}>
        {formState.values.boleto.discountType === 'fixed' ? (
          <InputCurrency
            error={hasBoletoError('discount')}
            fullWidth
            helperText={
              hasBoletoError('discount') && formState.errors.boleto?.discount
            }
            id="boleto-discount"
            label="Valor do desconto"
            name="boleto-discount"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleDiscountValueChange('boleto', event)
            }
            type="tel"
            value={formState.values.boleto.discount}
            variant="outlined"
          />
        ) : (
          <Mui.TextField
            error={hasBoletoError('discount')}
            fullWidth
            helperText={
              hasBoletoError('discount') && formState.errors.boleto?.discount
            }
            id="boleto-discount"
            label="Valor do desconto"
            name="boleto-discount"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleDiscountValueChange('boleto', event)
            }
            type="tel"
            value={formState.values.boleto.discount}
            variant="outlined"
          />
        )}
      </Mui.Grid>

      {/* <Mui.Grid item md={4} sm={6} xs={12}>
        <Mui.TextField
          InputLabelProps={{
            classes: {
              disabled: classes.selectDisabled,
            },
          }}
          SelectProps={{
            classes: {
              icon: classes.selectIcon,
              disabled: classes.selectDisabled,
            },
            MenuProps: {
              classes: {
                paper: classes.selectMenuList,
              },
            },
          }}
          error={hasBoletoError('boleto')}
          fullWidth
          helperText={hasBoletoError('boleto') && formState.errors.boleto}
          label="Aceitar pagemento com PIX"
          name="boleto-withPix"
          onChange={handleBoletoChange}
          select
          value={formState.values.boleto.withPix}
          variant="outlined"
        >
          <Mui.MenuItem button value="true">
            SIM
          </Mui.MenuItem>
          <Mui.MenuItem button value="false">
            NÃO
          </Mui.MenuItem>
        </Mui.TextField>
      </Mui.Grid>

      {formState.values.boleto.withPix.toString() === 'true' && (
        <Mui.Grid item md={4} sm={6} xs={12}>
          <InputCurrency
            error={hasBoletoError('boleto')}
            fullWidth
            helperText={hasBoletoError('boleto') && formState.errors.boleto}
            id="boleto-pixAmount"
            label="Valor do PIX"
            name="boleto-pixAmount"
            onChange={handleBoletoChange}
            type="tel"
            value={formState.values.boleto.pixAmount}
            variant="outlined"
          />
        </Mui.Grid>
      )} */}

      <Mui.Grid item md={4} sm={6} xs={12}>
        <Mui.TextField
          InputLabelProps={{
            classes: {
              disabled: classes.selectDisabled,
            },
          }}
          SelectProps={{
            classes: {
              icon: classes.selectIcon,
              disabled: classes.selectDisabled,
            },
            MenuProps: {
              classes: {
                paper: classes.selectMenuList,
              },
            },
          }}
          error={hasBoletoError('isInstallmentsEnabled')}
          fullWidth
          helperText={
            hasBoletoError('isInstallmentsEnabled') &&
            formState.errors.boleto?.isInstallmentsEnabled
          }
          label="Aceitar parcelamento"
          name="boleto-isInstallmentsEnabled"
          onChange={handleBoletoChange}
          select
          value={formState.values.boleto.isInstallmentsEnabled}
          variant="outlined"
        >
          <Mui.MenuItem button value="true">
            SIM
          </Mui.MenuItem>
          <Mui.MenuItem button value="false">
            NÃO
          </Mui.MenuItem>
        </Mui.TextField>
      </Mui.Grid>

      {formState.values.boleto.isInstallmentsEnabled.toString() === 'true' && (
        <>
          <Mui.Grid item md={4} sm={6} xs={12}>
            <Mui.TextField
              error={hasBoletoError('defaultInstallments')}
              fullWidth
              helperText={
                hasBoletoError('defaultInstallments') &&
                formState.errors.boleto?.defaultInstallments
              }
              id="boleto-defaultInstallments"
              label="Parcelas padrão"
              name="boleto-defaultInstallments"
              onChange={handleBoletoChange}
              type="number"
              value={formState.values.boleto.defaultInstallments || ''}
              variant="outlined"
            />
          </Mui.Grid>

          <Mui.Grid item md={4} sm={6} xs={12}>
            <Mui.TextField
              error={hasBoletoError('minInstallments')}
              fullWidth
              helperText={
                hasBoletoError('minInstallments') &&
                formState.errors.boleto?.minInstallments
              }
              id="boleto-minInstallments"
              label="Mínimo de parcelas"
              name="boleto-minInstallments"
              onChange={handleBoletoChange}
              type="number"
              value={formState.values.boleto.minInstallments || ''}
              variant="outlined"
            />
          </Mui.Grid>

          <Mui.Grid item md={4} sm={6} xs={12}>
            <Mui.TextField
              error={hasBoletoError('maxInstallments')}
              fullWidth
              helperText={
                hasBoletoError('maxInstallments') &&
                formState.errors.boleto?.maxInstallments
              }
              id="boleto-maxInstallments"
              label="Máximo de parcelas"
              name="boleto-maxInstallments"
              onChange={handleBoletoChange}
              type="number"
              value={formState.values.boleto.maxInstallments || ''}
              variant="outlined"
            />
          </Mui.Grid>
        </>
      )}

      {/* <Mui.Grid item xs={12} /> */}

      {/* <Mui.Grid container item md={6} xs={12}>
        <Mui.Grid item xs={12}>
          <div className={classes.textHeading}>
            <Mui.Typography variant="h4">Multa</Mui.Typography>

            <Mui.Switch
              checked={formState.values.boleto.isLateFeeActive}
              color="primary"
              id="boleto-isLateFeeActive"
              name="boleto-isLateFeeActive"
              onChange={(_, checked) =>
                setFormState(prevState => ({
                  ...prevState,
                  values: {
                    ...prevState.values,
                    boleto: {
                      ...prevState.values.boleto,
                      isLateFeeActive: checked,
                    },
                  },
                }))
              }
            />
          </div>
        </Mui.Grid>

        {formState.values.boleto.isLateFeeActive && (
          <CheckoutEditBoletoLateFee />
        )}
      </Mui.Grid> */}

      {/* <Mui.Grid container item md={6} xs={12}>
        <Mui.Grid item xs={12}>
          <div className={classes.textHeading}>
            <Mui.Typography variant="h4">Juros</Mui.Typography>

            <Mui.Switch
              checked={formState.values.boleto.isInterestActive}
              color="primary"
              id="boleto-isInterestActive"
              name="boleto-isInterestActive"
              onChange={(_, checked) =>
                setFormState(prevState => ({
                  ...prevState,
                  values: {
                    ...prevState.values,
                    boleto: {
                      ...prevState.values.boleto,
                      isInterestActive: checked,
                    },
                  },
                }))
              }
            />
          </div>
        </Mui.Grid>

        {formState.values.boleto.isInterestActive && (
          <CheckoutEditBoletoInterest />
        )}
      </Mui.Grid> */}

      {/* <Mui.Grid container item md={4} xs={12}>
        <Mui.Grid item xs={12}>
          <div className={classes.textHeading}>
            <Mui.Typography variant="h4">Descontos</Mui.Typography>

            <Mui.Switch
              checked={formState.values.boleto.isDiscountsActive}
              color="primary"
              id="boleto-isDiscountsActive"
              name="boleto-isDiscountsActive"
              onChange={(_, checked) =>
                setFormState(prevState => ({
                  ...prevState,
                  values: {
                    ...prevState.values,
                    boleto: {
                      ...prevState.values.boleto,
                      isDiscountsActive: checked,
                    },
                  },
                }))
              }
            />
          </div>
        </Mui.Grid>

        {formState.values.boleto.isDiscountsActive && (
          <CheckoutEditBoletoDiscounts />
        )}
      </Mui.Grid> */}
    </Mui.Grid>
  );
}
