import React from 'react';

import { useDispatch } from 'react-redux';

import { setChargeState, setChargeSelected } from 'store/actions';

import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Divider,
  IconButton,
} from '@material-ui/core';

import { ChargeToolbar, ChargeList } from './components';

import { makeStyles } from '@material-ui/styles';

import { Refresh as RefreshIcon } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(3),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.dark2,
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1),
      margin: theme.spacing(1),
    },
  },
  cardContent: {
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1),
    },
  },
}));

const ChargeReport = ({ history }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleRefresh = () => {
    dispatch([setChargeSelected({}), setChargeState('get')]);
  };

  return (
    <Card className={classes.root}>
      <CardHeader
        action={
          <IconButton onClick={handleRefresh}>
            <RefreshIcon />
          </IconButton>
        }
        subheader={
          <Typography color="textSecondary" variant="h5">
            Gerencie suas conbranças
          </Typography>
        }
        title={<Typography variant="h3">Cobranças</Typography>}
      />
      <Divider />
      <CardContent className={classes.cardContent}>
        <ChargeToolbar history={history} />
        <ChargeList history={history} />
      </CardContent>
    </Card>
  );
};

export default ChargeReport;
