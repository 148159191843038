const INITIAL_STATE = {
  state: 'get',
  total_sales: 0,
  total_customers: 0,
  customer_difference_value: null,
  expected_billing: null,
  current_balance: null,
  sales: [],
  sales_difference_value: null,
  sales_succeeded: 0,
  sales_pending: 0,
  sales_failed: 0,
  sales_refunded: 0,
  filter: 'month',
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'DASHBOARD_STATE_CHANGED':
      return {
        ...state,
        state: action.payload,
      };
    case 'TOTAL_SALES_CHANGED':
      return {
        ...state,
        total_sales: action.payload,
      };
    case 'TOTAL_CUSTOMERS_CHANGED':
      return {
        ...state,
        total_customers: action.payload,
      };
    case 'CUSTOMER_DIFFERENCE_VALUE_CHANGED':
      return {
        ...state,
        customer_difference_value: action.payload,
      };
    case 'EXPECTED_BILLING_CHANGED':
      return {
        ...state,
        expected_billing: action.payload,
      };
    case 'EXPECTED_BILLING_DIFFERENCE_VALUE_CHANGED':
      return {
        ...state,
        expected_billing_difference_value: action.payload,
      };
    case 'CURRENT_BALANCE_CHANGED':
      return {
        ...state,
        current_balance: action.payload,
      };
    case 'CURRENT_BALANCE_DIFFERENCE_VALUE_CHANGED':
      return {
        ...state,
        current_balance_difference_value: action.payload,
      };
    case 'SALES_CHANGED':
      return {
        ...state,
        sales: action.payload,
      };
    case 'SALES_DIFFERENCE_VALUE_CHANGED':
      return {
        ...state,
        sales_difference_value: action.payload,
      };
    case 'SALES_SUCCEEDED_CHANGED':
      return {
        ...state,
        sales_succeeded: action.payload,
      };
    case 'SALES_PENDING_CHANGED':
      return {
        ...state,
        sales_pending: action.payload,
      };
    case 'SALES_FAILED_CHANGED':
      return {
        ...state,
        sales_failed: action.payload,
      };
    case 'SALES_REFUNDED_CHANGED':
      return {
        ...state,
        sales_refunded: action.payload,
      };
    case 'DASHBOARD_FILTER_CHANGED':
      return {
        ...state,
        filter: action.payload,
      };
    default:
      return state;
  }
};
