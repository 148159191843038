import React from 'react';
import toCurrency from './currency-conversion';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  disabled: {
    color: `${theme.palette.complementary.lightGrey2} !important`,
  },
}));

const InputCurrency = props => {
  const classes = useStyles();
  const { onChange, value, ...safeProps } = props;

  const handleChange = event => {
    const valueAsCurrency = toCurrency(event.target.value);

    const amountDigits = valueAsCurrency.replace(/(-(?!\d))|[^0-9|-]/g, '');

    const cents = amountDigits.substring(amountDigits.length - 2);
    const moneyWithoutCents = amountDigits.substring(
      0,
      amountDigits.length - 2
    );

    const amount = parseFloat(`${moneyWithoutCents}.${cents}`);

    const fakeEvent = {
      target: {
        name: event.target.name,
        value: amount,
      },
      persist: event.persist,
    };

    if (onChange) {
      event.persist();
      onChange(fakeEvent);
    }
  };

  return (
    <TextField
      FormHelperTextProps={{
        classes: {
          disabled: classes.disabled,
        },
      }}
      InputLabelProps={{
        classes: {
          disabled: classes.disabled,
        },
      }}
      InputProps={{
        classes: {
          disabled: classes.disabled,
        },
      }}
      pattern="\d*"
      type="text"
      {...safeProps}
      onChange={handleChange}
      value={value.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      })}
    />
  );
};

export default InputCurrency;
