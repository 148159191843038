import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';
import * as MuiLab from '@material-ui/lab';
import clsx from 'clsx';
import React from 'react';

import { Metadata } from 'components/utils/Metadata';

import { CheckoutUtils } from 'helpers/checkout-utils';
import { useCheckoutEditDialog } from './CheckoutEditDialog.hook';
import useStyles from './CheckoutEditDialog.styles';
import { CheckoutEditBoleto } from './components/checkout-edit-boleto/checkout-edit-boleto.component';
import { CheckoutEditCredit } from './components/checkout-edit-credit/checkout-edit-credit.component';
import { CheckoutEditPix } from './components/checkout-edit-pix/checkout-edit-pix.component';

function CheckoutEditDialog() {
  const classes = useStyles();

  const {
    checkout,
    checkoutState,
    formState,
    linkCopied,
    offers,
    plans,
    handleBoletoChange,
    handleCheckboxChange,
    handleCopyToClipBoard,
    handleCreditChange,
    handleDialogClose,
    handleFormChange,
    handleMetadataChange,
    handleOfferChange,
    handlePixChange,
    handleProductChange,
    handleSelectAllText,
    handleSubmit,
    handleDiscountValueChange,
    hasError,
    hasBoletoError,
    hasCreditError,
    hasPixError,
  } = useCheckoutEditDialog();

  return (
    <React.Fragment>
      <Mui.AppBar className={classes.appBar}>
        <Mui.Toolbar>
          <Mui.IconButton
            aria-label="Fechar"
            color="inherit"
            edge="start"
            onClick={handleDialogClose}
          >
            <MuiIcons.Close />
          </Mui.IconButton>
          <Mui.Typography className={classes.title} variant="h5">
            {checkoutState === 'create'
              ? 'Criar novo checkout'
              : 'Editar checkout'}
          </Mui.Typography>
          <Mui.Button
            color="inherit"
            disabled={!formState.isValid}
            onClick={handleSubmit}
          >
            {checkoutState === 'create' ? 'Criar' : 'Salvar'}
          </Mui.Button>
        </Mui.Toolbar>
      </Mui.AppBar>
      <Mui.Grid className={classes.container} container spacing={2}>
        <Mui.Grid item xs={12}>
          <Mui.Typography variant="h4">Ações</Mui.Typography>
        </Mui.Grid>
        <div className={classes.actionsContainer}>
          <Mui.Tooltip title="Em breve">
            <div className={classes.actionButton}>
              <Mui.Button
                color="secondary"
                disabled
                fullWidth
                variant="contained"
              >
                Deletar
              </Mui.Button>
            </div>
          </Mui.Tooltip>
        </div>
        <Mui.Grid item xs={12}>
          <Mui.Divider />
        </Mui.Grid>
        <Mui.Grid item xs={12}>
          <Mui.Typography variant="h4">Dados do checkout</Mui.Typography>
        </Mui.Grid>

        <Mui.Grid item md={4} sm={6} xs={12}>
          <Mui.TextField
            error={hasError('name')}
            fullWidth
            helperText={hasError('name') && formState.errors.name}
            id="name"
            label="Nome"
            name="name"
            onChange={handleFormChange}
            value={formState.values.name || ''}
            variant="outlined"
          />
        </Mui.Grid>

        <Mui.Grid item sm={4} xs={12}>
          <Mui.TextField
            error={hasError('general')}
            fullWidth
            helperText={
              hasError('general')
                ? formState.errors.general
                : 'https://example.com'
            }
            id="general"
            label="URL de redirecionamento"
            name="general"
            onChange={handleFormChange}
            type="tel"
            value={formState.values.general}
            variant="outlined"
          />
        </Mui.Grid>

        <Mui.Grid item md={4} sm={6} xs={12}>
          <Mui.TextField
            InputLabelProps={{
              classes: {
                disabled: classes.selectDisabled,
              },
            }}
            SelectProps={{
              classes: {
                icon: classes.selectIcon,
                disabled: classes.selectDisabled,
              },
              MenuProps: {
                classes: {
                  paper: classes.selectMenuList,
                },
              },
            }}
            disabled={checkoutState === 'edit'}
            error={hasError('checkoutProductType')}
            fullWidth
            helperText={
              hasError('checkoutProductType') &&
              formState.errors.checkoutProductType
            }
            label="Tipo do produto"
            name="checkoutProductType"
            onChange={handleFormChange}
            select
            value={formState.values.checkoutProductType}
            variant="outlined"
          >
            {Object.entries(CheckoutUtils.checkoutProductTypeLabelMap).map(
              ([value, label]) => {
                return (
                  <Mui.MenuItem
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    ListItemClasses={{
                      root: classes.selectMenuItem,
                      selected: classes.selectItemSelected,
                    }}
                    button
                    key={value}
                    selected={value === formState.values.checkoutProductType}
                    value={value}
                  >
                    {label}
                  </Mui.MenuItem>
                );
              }
            )}
          </Mui.TextField>
        </Mui.Grid>

        {formState.values.checkoutProductType === 'product' && (
          <Mui.Grid item sm={4} xs={12}>
            <MuiLab.Autocomplete
              autoHighlight
              autoSelect
              classes={{
                paper: classes.paperAutocomplete,
              }}
              clearOnEscape
              clearText="Limpar"
              closeText="Fechar"
              disabled={checkoutState === 'edit'}
              forcePopupIcon
              getOptionLabel={offer => offer.name}
              getOptionSelected={(option, value) => option.id === value.id}
              id="offer"
              noOptionsText="Não há opções"
              onChange={handleOfferChange}
              openText="Abrir"
              options={offers}
              renderInput={params => (
                <Mui.TextField
                  {...params}
                  InputLabelProps={{
                    classes: {
                      disabled: classes.inputDisabled,
                    },
                  }}
                  InputProps={{
                    ...params.InputProps,
                    classes: {
                      disabled: classes.inputDisabled,
                    },
                  }}
                  disabled={checkoutState === 'edit'}
                  error={hasError('offer')}
                  fullWidth
                  helperText={hasError('offer') ? formState.errors.offer : ''}
                  id="offer-input"
                  label="Oferta"
                  name="offer"
                  type="text"
                  variant="outlined"
                />
              )}
              value={formState.values.offer}
            />
          </Mui.Grid>
        )}

        {formState.values.checkoutProductType === 'plan' && (
          <>
            <Mui.Grid item sm={4} xs={12}>
              <MuiLab.Autocomplete
                autoHighlight
                autoSelect
                classes={{
                  paper: classes.paperAutocomplete,
                }}
                clearOnEscape
                clearText="Limpar"
                closeText="Fechar"
                disabled={checkoutState === 'edit'}
                forcePopupIcon
                getOptionLabel={plan => plan.name}
                getOptionSelected={(option, value) => option.id === value.id}
                id="plan"
                noOptionsText="Não há opções"
                onChange={handleProductChange}
                openText="Abrir"
                options={plans}
                renderInput={params => (
                  <Mui.TextField
                    {...params}
                    InputLabelProps={{
                      classes: {
                        disabled: classes.inputDisabled,
                      },
                    }}
                    InputProps={{
                      ...params.InputProps,
                      classes: {
                        disabled: classes.inputDisabled,
                      },
                    }}
                    disabled={checkoutState === 'edit'}
                    error={hasError('plan')}
                    fullWidth
                    helperText={hasError('plan') ? formState.errors.plan : ''}
                    id="plan-input"
                    label="Plano"
                    name="plan"
                    type="text"
                    variant="outlined"
                  />
                )}
                value={formState.values.plan}
              />
            </Mui.Grid>

            <Mui.Grid item md={4} sm={6} xs={12}>
              <Mui.TextField
                error={hasError('planDueDate')}
                fullWidth
                helperText={
                  (hasError('planDueDate') && formState.errors.planDueDate) ||
                  `Definido ${formState.values.planDueDate} dias de teste(trial)`
                }
                id="planDueDate"
                label="Dias de teste(trial)"
                name="planDueDate"
                onChange={handleFormChange}
                type="number"
                value={formState.values.planDueDate || ''}
                variant="outlined"
              />
            </Mui.Grid>
          </>
        )}

        {checkoutState === 'edit' && (
          <>
            <Mui.Grid item xs={12}>
              <Mui.Divider />
            </Mui.Grid>
            <Mui.Grid item xs={12}>
              <Mui.Typography variant="h4">Links</Mui.Typography>
            </Mui.Grid>

            <Mui.Grid item xs={12}>
              <Mui.Typography variant="overline">
                <strong>Checkout</strong>
              </Mui.Typography>

              <div className={classes.linkContainer}>
                <Mui.InputBase
                  className={classes.inputLink}
                  id="checkout-link"
                  onClick={handleSelectAllText}
                  value={`${process.env.REACT_APP_BASE_URL_CHECKOUT}/${
                    checkout!.id
                  }`}
                />
                <Mui.Button
                  className={classes.copyLinkButton}
                  color="secondary"
                  onClick={() => handleCopyToClipBoard('checkout')}
                  startIcon={<MuiIcons.FileCopy />}
                  variant="contained"
                >
                  {linkCopied.checkout ? 'Copiado!' : 'Copiar'}
                </Mui.Button>
              </div>

              <Mui.Typography variant="overline">
                <strong>Resgate</strong>
              </Mui.Typography>
              <div className={classes.linkContainer}>
                <Mui.InputBase
                  className={classes.inputLink}
                  id="redeem-link"
                  onClick={handleSelectAllText}
                  value={`${process.env.REACT_APP_BASE_URL_CHECKOUT}/${
                    checkout!.id
                  }/redeem`}
                />
                <Mui.Button
                  className={classes.copyLinkButton}
                  color="secondary"
                  onClick={() => handleCopyToClipBoard('redeem')}
                  startIcon={<MuiIcons.FileCopy />}
                  variant="contained"
                >
                  {linkCopied.redeem ? 'Copiado!' : 'Copiar'}
                </Mui.Button>
              </div>
            </Mui.Grid>
          </>
        )}

        <Mui.Grid item xs={12}>
          <Mui.Divider />
        </Mui.Grid>
        <Mui.Grid item xs={12}>
          <Mui.Typography variant="h4">
            Configurações de pagamentos
          </Mui.Typography>
        </Mui.Grid>

        <Mui.Grid item xs={12}>
          <Mui.Typography variant="body2">
            Selecione as opções de pagamentos aceitos:
          </Mui.Typography>
        </Mui.Grid>

        <Mui.Grid
          className={classes.paymentTypeAcceptedContainer}
          container
          item
          lg={6}
          md={8}
          sm={12}
          spacing={2}
          xs={12}
        >
          <Mui.Grid item sm={4} xs={12}>
            <Mui.Grid
              alignItems="center"
              aria-haspopup="true"
              classes={{
                root: clsx(classes.paper, {
                  [classes.paperDisabled]: !formState.values.boleto.isActive,
                }),
              }}
              component={Mui.Paper}
              container
              justifyContent="center"
              onClick={() => handleCheckboxChange('boleto')}
            >
              <Mui.Checkbox
                checked={formState.values.boleto.isActive}
                className={classes.checkbox}
                classes={{
                  checked: classes.checked,
                }}
                color="default"
                name="boleto"
                onChange={handleBoletoChange}
                value="true"
              />
              <Mui.Typography variant="h5">Boleto bancário</Mui.Typography>
            </Mui.Grid>
          </Mui.Grid>
          <Mui.Grid item sm={4} xs={12}>
            <Mui.Grid
              alignItems="center"
              aria-haspopup="true"
              classes={{
                root: clsx(classes.paper, {
                  [classes.paperDisabled]: !formState.values.pix.isActive,
                }),
              }}
              component={Mui.Paper}
              container
              justifyContent="center"
              onClick={() => handleCheckboxChange('pix')}
            >
              <Mui.Checkbox
                checked={formState.values.pix.isActive}
                className={classes.checkbox}
                classes={{
                  checked: classes.checked,
                }}
                color="default"
                name="pix"
                onChange={handlePixChange}
                value="true"
              />
              <Mui.Typography variant="h5">PIX</Mui.Typography>
            </Mui.Grid>
          </Mui.Grid>
          <Mui.Grid item sm={4} xs={12}>
            <Mui.Grid
              alignItems="center"
              aria-haspopup="true"
              classes={{
                root: clsx(classes.paper, {
                  [classes.paperDisabled]: !formState.values.credit.isActive,
                }),
              }}
              component={Mui.Paper}
              container
              justifyContent="center"
              onClick={() => handleCheckboxChange('credit')}
            >
              <Mui.Checkbox
                checked={formState.values.credit.isActive}
                className={classes.checkbox}
                classes={{
                  checked: classes.checked,
                }}
                color="default"
                name="credit"
                onChange={handleCreditChange}
                value="true"
              />
              <Mui.Typography variant="h5">Cartão de crédito</Mui.Typography>
            </Mui.Grid>
          </Mui.Grid>
          {hasError('credit') && (
            <Mui.FormHelperText className={classes.formHelperText} error>
              {formState.errors.credit}
            </Mui.FormHelperText>
          )}
        </Mui.Grid>

        <Mui.Grid container item />

        {formState.values.boleto.isActive && (
          <>
            <Mui.Grid container item xs={12} />

            <CheckoutEditBoleto
              formState={formState}
              handleBoletoChange={handleBoletoChange}
              handleDiscountValueChange={handleDiscountValueChange}
              hasBoletoError={hasBoletoError}
            />
          </>
        )}

        {formState.values.pix.isActive && (
          <>
            <Mui.Grid container item xs={12} />

            <CheckoutEditPix
              formState={formState}
              handleDiscountValueChange={handleDiscountValueChange}
              handlePixChange={handlePixChange}
              hasPixError={hasPixError}
            />
          </>
        )}

        {formState.values.credit.isActive && (
          <>
            <Mui.Grid container item xs={12} />

            <CheckoutEditCredit
              formState={formState}
              handleCreditChange={handleCreditChange}
              handleDiscountValueChange={handleDiscountValueChange}
              hasCreditError={hasCreditError}
            />
          </>
        )}

        <Mui.Grid item xs={12}>
          <Mui.Divider />
        </Mui.Grid>
        <Mui.Grid item xs={12}>
          <Mui.Typography variant="h4">Metadados</Mui.Typography>
        </Mui.Grid>
        <Mui.Grid item lg={8} md={10} xs={12}>
          <Metadata
            metadata={checkout?.metadata}
            onChange={handleMetadataChange}
          />
        </Mui.Grid>
      </Mui.Grid>
    </React.Fragment>
  );
}

export default CheckoutEditDialog;
