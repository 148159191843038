export enum EMarketplaceUserRole {
  OWNER = 'owner',
  MARKETING = 'marketing',
  DEVELOPER = 'developer',
  FINANCIAL = 'financial',
  SELLER = 'seller',
  ATTENDANTS = 'attendants',
}

export interface IMarketplaceUser<
  InternalMetadata = object,
  Metadata = object
> {
  id: string;
  resource: 'marketplace-user';
  marketplaceId: string;
  sellerId: string;
  name: string;
  email: string;
  avatar: string | null;
  roles: `${EMarketplaceUserRole}`[];
  password: string;
  documentNumber: string;
  phoneNumber: string;
  internalMetadata: InternalMetadata | null;
  metadata: Metadata | null;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
}
