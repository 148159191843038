import React from 'react';
import { Redirect, Switch } from 'react-router-dom';

import { PrivateRouteWithLayout, RouteWithLayout } from './components';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';

import {
  Account as AccountView,
  BankAccount as BankAccountView,
  Charge as ChargeView,
  ChargeReport as ChargeReportView,
  Checkouts as CheckoutsView,
  Coupons as CouponsView,
  Customer as CustomerView,
  TaxInvoice as TaxInvoiceView,
  MarketplaceUser as MarketplaceUserView,
  Dashboard as DashboardView,
  FutureTransfers as FutureTransfersView,
  NotFound as NotFoundView,
  Offers as OffersView,
  Plans as PlansView,
  Products as ProductsView,
  Sales as SalesView,
  Settings as SettingsView,
  SignIn as SignInView,
  SignUp as SignUpView,
  SignUpStep02 as SignUpStep02View,
  SignUpStep03 as SignUpStep03View,
  SignUpStep04 as SignUpStep04View,
  SignUpStep05 as SignUpStep05View,
  SignUpStep06 as SignUpStep06View,
  SignUpStep07 as SignUpStep07View,
  SignUpStep08 as SignUpStep08View,
  SignUpStep09 as SignUpStep09View,
  Subscriptions as SubscriptionsView,
} from './views';
import { MarketplaceUserSignIn } from 'views/SignIn/marketplace-user-sign-in';

const Routes = () => {
  return (
    <Switch>
      <Redirect exact from="/" to="/dashboard" />
      <PrivateRouteWithLayout
        component={DashboardView}
        exact
        layout={MainLayout}
        path="/dashboard"
      />
      <PrivateRouteWithLayout
        component={CustomerView}
        exact
        layout={MainLayout}
        path="/customers"
      />
      <PrivateRouteWithLayout
        component={ChargeReportView}
        exact
        layout={MainLayout}
        path="/charge-list"
      />
      <PrivateRouteWithLayout
        component={SalesView}
        exact
        layout={MainLayout}
        path="/sales"
      />
      <PrivateRouteWithLayout
        component={AccountView}
        exact
        layout={MainLayout}
        path="/account"
      />
      <PrivateRouteWithLayout
        component={SettingsView}
        exact
        layout={MainLayout}
        path="/settings"
      />
      <PrivateRouteWithLayout
        component={ChargeView}
        exact
        layout={MainLayout}
        path="/charges"
      />
      <PrivateRouteWithLayout
        component={FutureTransfersView}
        exact
        layout={MainLayout}
        path="/future-transfers"
      />
      <PrivateRouteWithLayout
        component={BankAccountView}
        exact
        layout={MainLayout}
        path="/bank-account"
      />
      <PrivateRouteWithLayout
        component={ProductsView}
        exact
        layout={MainLayout}
        path="/products"
      />
      <PrivateRouteWithLayout
        component={OffersView}
        exact
        layout={MainLayout}
        path="/offers"
      />
      <PrivateRouteWithLayout
        component={CouponsView}
        exact
        layout={MainLayout}
        path="/coupons"
      />
      <PrivateRouteWithLayout
        component={TaxInvoiceView}
        exact
        layout={MainLayout}
        path="/tax-invoice"
      />

      <PrivateRouteWithLayout
        component={MarketplaceUserView}
        exact
        layout={MainLayout}
        path="/users"
      />
      <PrivateRouteWithLayout
        component={SubscriptionsView}
        exact
        layout={MainLayout}
        path="/subscriptions"
      />
      <PrivateRouteWithLayout
        component={PlansView}
        exact
        layout={MainLayout}
        path="/plans"
      />
      <PrivateRouteWithLayout
        component={CheckoutsView}
        exact
        layout={MainLayout}
        path="/checkouts"
      />
      <RouteWithLayout
        component={SignUpView}
        exact
        layout={MinimalLayout}
        path="/sign-up"
      />
      <RouteWithLayout
        component={SignUpStep02View}
        exact
        layout={MinimalLayout}
        path="/sign-up/step-02"
      />
      <RouteWithLayout
        component={SignUpStep03View}
        exact
        layout={MinimalLayout}
        path="/sign-up/step-03"
      />
      <RouteWithLayout
        component={SignUpStep04View}
        exact
        layout={MinimalLayout}
        path="/sign-up/step-04"
      />
      <RouteWithLayout
        component={SignUpStep05View}
        exact
        layout={MinimalLayout}
        path="/sign-up/step-05"
      />
      <RouteWithLayout
        component={SignUpStep06View}
        exact
        layout={MinimalLayout}
        path="/sign-up/step-06"
      />
      <RouteWithLayout
        component={SignUpStep07View}
        exact
        layout={MinimalLayout}
        path="/sign-up/step-07"
      />
      <RouteWithLayout
        component={SignUpStep08View}
        exact
        layout={MinimalLayout}
        path="/sign-up/step-08"
      />
      <RouteWithLayout
        component={SignUpStep09View}
        exact
        layout={MinimalLayout}
        path="/sign-up/step-09"
      />
      <RouteWithLayout
        component={SignInView}
        exact
        layout={MinimalLayout}
        path="/sign-in"
      />
      <RouteWithLayout
        component={MarketplaceUserSignIn}
        exact
        layout={MinimalLayout}
        path="/marketplaces/:marketplaceId/sign-in"
      />
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
