import * as Mui from '@material-ui/core';
import type { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import * as MuiIcons from '@material-ui/icons';
import clsx from 'clsx';
import moment from 'moment';
import React from 'react';
import * as ReactRedux from 'react-redux';

import * as TaxInvoiceHooks from 'hooks/use-tax-invoice';

import { getDisplayMoney } from 'helpers';

import type { ITaxInvoice } from 'types/entities/tax-invoice';
import useStyles from './tax-invoices-list-table.styles';
import {
  setTaxInvoicePagination,
  setTaxInvoiceState,
} from 'store/actions/tax-invoice-actions';

export const taxInvoiceStatusMap: Record<ITaxInvoice['status'], string> = {
  succeeded: 'Emitido',
  failed: 'Falhado',
  pending: 'Pendente',
};

function TaxInvoicesListTable() {
  const theme = Mui.useTheme();
  const dispatch = ReactRedux.useDispatch();
  const useTaxInvoice = TaxInvoiceHooks.useTaxInvoice();
  const { pagination, listTaxInvoices } = useTaxInvoice.state;

  const classes = useStyles();

  function getDisplayMobile(string: string | null, breakpoint: Breakpoint) {
    const width = window.innerWidth;

    if (width < theme.breakpoints.values[breakpoint]) {
      return string;
    }

    return '';
  }

  function handlePageChange(_: unknown, page: number) {
    if (!pagination) {
      return;
    }

    let newOffset: number;
    if (page + 1 > pagination.page) {
      newOffset = pagination.offset + pagination.limit;
    } else {
      newOffset = pagination.offset - pagination.limit;
    }

    dispatch([
      setTaxInvoicePagination({ ...pagination, offset: newOffset }),
      setTaxInvoiceState('get'),
    ]);
  }

  function handleRowsPerPageChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (!pagination) {
      return;
    }

    const limit = Number(event.target.value);

    dispatch([
      setTaxInvoicePagination({ ...pagination, limit, offset: 0 }),
      setTaxInvoiceState('get'),
    ]);
  }

  return (
    <Mui.Grid className={classes.container} component={Mui.List} container>
      <Mui.Grid
        className={classes.paginationContainer}
        container
        justifyContent="flex-end"
      >
        <Mui.TablePagination
          SelectProps={{
            classes: {
              icon: classes.icon,
            },
            MenuProps: {
              classes: {
                paper: classes.menuList,
              },
            },
          }}
          backIconButtonText="Voltar página"
          component="div"
          count={pagination?.total || 0}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to === -1 ? count : to} de ${
              count !== -1 ? count : `mais que ${to}`
            }`
          }
          labelRowsPerPage=""
          nextIconButtonText="Próxima página"
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          page={(pagination?.page || 1) - 1}
          rowsPerPage={pagination?.limit || 30}
          rowsPerPageOptions={[30, 50, 100]}
        />
      </Mui.Grid>
      <Mui.ListItem disableGutters>
        <Mui.Grid className={classes.tableCell} item md={2} sm={5} xs={8}>
          <Mui.Typography noWrap variant="h5">
            Descrição
          </Mui.Typography>
        </Mui.Grid>
        <Mui.Grid
          className={clsx(classes.tableCell, classes.tableTablet)}
          item
          md={2}
          sm={2}
        >
          <Mui.Typography noWrap variant="h5">
            Valor
          </Mui.Typography>
        </Mui.Grid>
        <Mui.Grid
          className={clsx(classes.tableCell, classes.tableTablet)}
          item
          md={2}
          sm={2}
        >
          <Mui.Typography noWrap variant="h5">
            Status
          </Mui.Typography>
        </Mui.Grid>
        <Mui.Grid
          className={clsx(classes.tableCell, classes.tableDesktop)}
          item
          md={2}
        >
          <Mui.Typography noWrap variant="h5">
            Data de criação
          </Mui.Typography>
        </Mui.Grid>
        <Mui.Grid
          className={clsx(classes.tableCell, classes.tableDesktop)}
          item
          md={2}
        >
          <Mui.Typography noWrap variant="h5">
            Última atualização
          </Mui.Typography>
        </Mui.Grid>
      </Mui.ListItem>
      <Mui.Grid component={Mui.Divider} item xs={12} />
      {listTaxInvoices.map((taxInvoice: ITaxInvoice) => {
        return (
          <React.Fragment key={taxInvoice.id}>
            <Mui.ListItem className={classes.listItem} disableGutters>
              <Mui.Grid className={classes.tableCell} item md={2} sm={5} xs={8}>
                <Mui.ListItemText
                  disableTypography
                  primary={
                    <Mui.Typography noWrap>
                      {taxInvoice.description}
                    </Mui.Typography>
                  }
                  secondary={
                    <Mui.Grid component="span" container direction="column">
                      <Mui.Grid component="span" item xs zeroMinWidth>
                        <Mui.Typography component="div" noWrap variant="body2">
                          {getDisplayMobile(
                            getDisplayMoney(taxInvoice.amount / 100),
                            'sm'
                          )}
                        </Mui.Typography>
                      </Mui.Grid>
                      <Mui.Grid component="span" item xs zeroMinWidth>
                        <Mui.Typography component="div" noWrap variant="body2">
                          {getDisplayMobile(
                            taxInvoiceStatusMap[taxInvoice.status],
                            'sm'
                          )}
                        </Mui.Typography>
                      </Mui.Grid>
                    </Mui.Grid>
                  }
                />
              </Mui.Grid>
              <Mui.Grid
                className={clsx(classes.tableCell, classes.tableTablet)}
                item
                md={2}
                sm={2}
              >
                <Mui.Typography noWrap variant="body1">
                  {getDisplayMoney(taxInvoice.amount / 100)}
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid
                className={clsx(classes.tableCell, classes.tableTablet)}
                item
                md={2}
                sm={2}
              >
                <Mui.Typography noWrap variant="body1">
                  {taxInvoiceStatusMap[taxInvoice.status]}
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid
                className={clsx(classes.tableCell, classes.tableDesktop)}
                item
                md={2}
                sm={5}
                xs={7}
              >
                <Mui.ListItemText
                  disableTypography
                  primary={
                    <Mui.Typography noWrap>
                      {moment(taxInvoice.createdAt).format('DD/MM/YYYY')}
                    </Mui.Typography>
                  }
                  secondary={
                    <Mui.Typography noWrap>
                      {moment(taxInvoice.createdAt).format('HH:mm:ss')}
                    </Mui.Typography>
                  }
                />
              </Mui.Grid>
              <Mui.Grid
                className={clsx(classes.tableCell, classes.tableDesktop)}
                item
                md={2}
                sm={5}
                xs={7}
              >
                <Mui.ListItemText
                  disableTypography
                  primary={
                    <Mui.Typography noWrap>
                      {moment(taxInvoice.updatedAt).format('DD/MM/YYYY')}
                    </Mui.Typography>
                  }
                  secondary={
                    <Mui.Typography noWrap>
                      {moment(taxInvoice.updatedAt).format('HH:mm:ss')}
                    </Mui.Typography>
                  }
                />
              </Mui.Grid>
              <Mui.Grid
                className={clsx(classes.tableCellView)}
                item
                md={1}
                sm={3}
                xs={4}
              >
                <Mui.IconButton
                  className={classes.buttonEdit}
                  onClick={() => useTaxInvoice.ui.openToView(taxInvoice)}
                  size={
                    window.innerWidth > theme.breakpoints.values.sm
                      ? 'medium'
                      : 'small'
                  }
                >
                  <MuiIcons.Visibility className={classes.iconButton} />
                </Mui.IconButton>
              </Mui.Grid>
            </Mui.ListItem>

            <Mui.Grid component={Mui.Divider} item xs={12} />
          </React.Fragment>
        );
      })}
      <Mui.Grid
        className={classes.paginationContainer}
        container
        justifyContent="flex-end"
      >
        <Mui.TablePagination
          SelectProps={{
            classes: {
              icon: classes.icon,
            },
            MenuProps: {
              classes: {
                paper: classes.menuList,
              },
            },
          }}
          backIconButtonText="Voltar página"
          component="div"
          count={pagination?.total || 0}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to === -1 ? count : to} de ${
              count !== -1 ? count : `mais que ${to}`
            }`
          }
          labelRowsPerPage=""
          nextIconButtonText="Próxima página"
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          page={(pagination?.page || 1) - 1}
          rowsPerPage={pagination?.limit || 30}
          rowsPerPageOptions={[30, 50, 100]}
        />
      </Mui.Grid>
    </Mui.Grid>
  );
}

export default TaxInvoicesListTable;
