import React from 'react';
import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';

import * as CheckoutHooks from 'hooks/useCheckout';

import * as InternalComponent from './components';
import useStyles from './Checkouts.styles';

function Checkouts() {
  const classes = useStyles();

  const checkout = CheckoutHooks.useCheckout();

  return (
    <Mui.Card className={classes.root}>
      <Mui.CardHeader
        action={
          <Mui.IconButton onClick={checkout.ui.refresh}>
            <MuiIcons.Refresh />
          </Mui.IconButton>
        }
        subheader={
          <Mui.Typography color="textSecondary" variant="h5">
            Gerencie seus checkouts
          </Mui.Typography>
        }
        title={<Mui.Typography variant="h3">Checkouts</Mui.Typography>}
      />
      <Mui.Divider />
      <Mui.CardContent className={classes.cardContent}>
        <InternalComponent.CheckoutsToolbar />
        <InternalComponent.CheckoutsList />
      </Mui.CardContent>
    </Mui.Card>
  );
}

export default Checkouts;
