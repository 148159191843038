import type {
  ITaxInvoiceReducerAction,
  ITaxInvoiceReducerState,
} from 'types/redux/reducers/tax-invoice-reducer.types';

const INITIAL_STATE: ITaxInvoiceReducerState = {
  state: 'get',
  taxInvoice: null,
  listTaxInvoices: [],
  pagination: null,
};

export default (
  state = INITIAL_STATE,
  action: ITaxInvoiceReducerAction<unknown>
) => {
  switch (action.type) {
    case 'TAX_INVOICE_STATE_CHANGED':
      return {
        ...state,
        state: action.payload,
      };
    case 'TAX_INVOICE_CHANGED':
      return {
        ...state,
        taxInvoice: action.payload,
      };
    case 'LIST_TAX_INVOICES_CHANGED':
      return {
        ...state,
        listTaxInvoices: action.payload,
      };
    case 'TAX_INVOICE_PAGINATION_CHANGED':
      return {
        ...state,
        pagination: action.payload,
      };
    default:
      return state;
  }
};
