import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';
import clsx from 'clsx';
import moment from 'moment';
import React from 'react';
import * as ReactRedux from 'react-redux';

import * as ChargeHooks from 'hooks/use-charge';

import useStyles from './charge-list-table.styles';

import {
  getDisplayEmail,
  getDisplayMoney,
  getDisplayPhoneNumber,
  getInitials,
} from 'helpers';
import { ChargeUtils } from 'helpers/charge-utils';
import {
  setChargePagination,
  setChargeState,
} from 'store/actions/charge-actions';
import type { IHttpPopulateCharge } from 'types/entities/charge';

function ChargeListTable() {
  const theme = Mui.useTheme();
  const dispatch = ReactRedux.useDispatch();
  const charge = ChargeHooks.useCharge();

  const { pagination, listCharges } = charge.state;

  const classes = useStyles();

  function handlePageChange(_: unknown, page: number) {
    if (!pagination) {
      return;
    }

    let newOffset: number;
    if (page + 1 > pagination.page) {
      newOffset = pagination.offset + pagination.limit;
    } else {
      newOffset = pagination.offset - pagination.limit;
    }

    dispatch([
      setChargePagination({ ...pagination, offset: newOffset }),
      setChargeState('get'),
    ]);
  }

  function handleRowsPerPageChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (!pagination) {
      return;
    }

    const limit = Number(event.target.value);

    dispatch([
      setChargePagination({ ...pagination, limit, offset: 0 }),
      setChargeState('get'),
    ]);
  }

  return (
    <Mui.Grid className={classes.container} component={Mui.List} container>
      <Mui.Grid
        className={classes.paginationContainer}
        container
        justifyContent="flex-end"
      >
        <Mui.TablePagination
          SelectProps={{
            classes: {
              icon: classes.icon,
            },
            MenuProps: {
              classes: {
                paper: classes.menuList,
              },
            },
          }}
          backIconButtonText="Voltar página"
          component="div"
          count={pagination?.total || 0}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to === -1 ? count : to} de ${
              count !== -1 ? count : `mais que ${to}`
            }`
          }
          labelRowsPerPage=""
          nextIconButtonText="Próxima página"
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          page={(pagination?.page || 1) - 1}
          rowsPerPage={pagination?.limit || 30}
          rowsPerPageOptions={[30, 50, 100]}
        />
      </Mui.Grid>

      <Mui.ListItem disableGutters>
        <Mui.Grid className={classes.tableCell} item md={3} sm={3} xs={8}>
          <Mui.Typography noWrap variant="h5">
            Comprador
          </Mui.Typography>
        </Mui.Grid>
        <Mui.Grid
          className={clsx(classes.tableCell, classes.tableTablet)}
          item
          md={3}
          sm={3}
        >
          <Mui.Typography noWrap variant="h5">
            Descrição
          </Mui.Typography>
        </Mui.Grid>
        <Mui.Grid
          className={clsx(classes.tableCell, classes.tableTablet)}
          item
          md={1}
          sm={3}
        >
          <Mui.Typography noWrap variant="h5">
            Valor
          </Mui.Typography>
        </Mui.Grid>
        <Mui.Grid
          className={clsx(classes.tableCell, classes.tableTablet)}
          item
          md={1}
          sm={3}
        >
          <Mui.Typography noWrap variant="h5">
            Parcelas
          </Mui.Typography>
        </Mui.Grid>
        <Mui.Grid
          className={clsx(classes.tableCell, classes.tableTablet)}
          item
          md={1}
          sm={3}
        >
          <Mui.Typography noWrap variant="h5">
            Status
          </Mui.Typography>
        </Mui.Grid>
        <Mui.Grid
          className={clsx(classes.tableCell, classes.tableTablet)}
          item
          md={2}
          sm={3}
        >
          <Mui.Typography noWrap variant="h5">
            Data limite
          </Mui.Typography>
        </Mui.Grid>
      </Mui.ListItem>

      <Mui.Grid component={Mui.Divider} item xs={12} />

      {listCharges.map((chargeData: IHttpPopulateCharge) => {
        return (
          <React.Fragment key={chargeData.id}>
            <Mui.ListItem className={classes.listItem} disableGutters>
              <Mui.Grid className={classes.tableCell} item md={3} sm={3} xs={8}>
                <Mui.ListItemAvatar>
                  <Mui.Avatar>{getInitials(chargeData.buyer?.name)}</Mui.Avatar>
                </Mui.ListItemAvatar>

                <Mui.ListItemText
                  disableTypography
                  primary={
                    <Mui.Typography noWrap variant="h5">
                      {chargeData.buyer?.name}
                    </Mui.Typography>
                  }
                  secondary={
                    <Mui.Grid component="span" container direction="column">
                      <Mui.Grid component="span" item xs zeroMinWidth>
                        <Mui.Typography component="div" noWrap variant="body2">
                          {getDisplayEmail(chargeData.buyer?.email)}
                        </Mui.Typography>
                      </Mui.Grid>
                      <Mui.Grid component="span" item xs zeroMinWidth>
                        <Mui.Typography component="div" noWrap variant="body2">
                          {getDisplayPhoneNumber(chargeData.buyer?.phoneNumber)}
                        </Mui.Typography>
                      </Mui.Grid>
                    </Mui.Grid>
                  }
                />
              </Mui.Grid>
              <Mui.Grid
                className={clsx(classes.tableCell, classes.tableTablet)}
                item
                md={3}
                sm={3}
              >
                <Mui.Typography noWrap variant="body1">
                  {chargeData.description}
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid
                className={clsx(classes.tableCell, classes.tableDesktop)}
                item
                md={1}
              >
                <Mui.Typography noWrap variant="body1">
                  {getDisplayMoney(chargeData.amount / 100)}
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid
                className={clsx(classes.tableCell, classes.tableTablet)}
                item
                md={1}
                sm={3}
              >
                <Mui.Typography noWrap variant="body1">
                  {chargeData.installment}/{chargeData.installments}
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid
                className={clsx(classes.tableCell, classes.tableTablet)}
                item
                md={1}
                sm={3}
              >
                <Mui.Typography noWrap variant="body1">
                  {ChargeUtils.toStatusLabel(chargeData.status)}
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid
                className={clsx(classes.tableCell, classes.tableDesktop)}
                item
                md={2}
              >
                <Mui.Typography noWrap variant="body1">
                  {chargeData.paymentLimitDate
                    ? moment
                        .utc(chargeData.paymentLimitDate)
                        .format('DD/MM/YYYY')
                    : 'Sem data definida'}
                </Mui.Typography>
              </Mui.Grid>

              <Mui.Grid
                className={clsx(classes.tableCell, classes.actionsContainer)}
                item
                md={1}
                sm={3}
                xs={4}
              >
                <Mui.IconButton
                  onClick={() => charge.ui.openToView(chargeData)}
                  size={
                    window.innerWidth > theme.breakpoints.values.sm
                      ? 'medium'
                      : 'small'
                  }
                >
                  <MuiIcons.Visibility className={classes.iconButton} />
                </Mui.IconButton>
              </Mui.Grid>
            </Mui.ListItem>

            <Mui.Grid component={Mui.Divider} item xs={12} />
          </React.Fragment>
        );
      })}

      <Mui.Grid
        className={classes.paginationContainer}
        container
        justifyContent="flex-end"
      >
        <Mui.TablePagination
          SelectProps={{
            classes: {
              icon: classes.icon,
            },
            MenuProps: {
              classes: {
                paper: classes.menuList,
              },
            },
          }}
          backIconButtonText="Voltar página"
          component="div"
          count={pagination?.total || 0}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to === -1 ? count : to} de ${
              count !== -1 ? count : `mais que ${to}`
            }`
          }
          labelRowsPerPage=""
          nextIconButtonText="Próxima página"
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          page={(pagination?.page || 1) - 1}
          rowsPerPage={pagination?.limit || 30}
          rowsPerPageOptions={[30, 50, 100]}
        />
      </Mui.Grid>
    </Mui.Grid>
  );
}

export default ChargeListTable;
