import React from 'react';
import * as ReactRedux from 'react-redux';
import moment from 'moment';

import type { ITransaction } from 'types/entities';
import {
  setDialogFullScreen,
  setDialogMaxWidth,
  setDialogOpen,
  setDialogType,
  setSale,
  setSaleListType,
  setSaleMetricsState,
  setSaleState,
  setSalesList,
  setSaleFilter,
  setSalePagination,
} from 'store/actions';

function useSaleUI() {
  const dispatch = ReactRedux.useDispatch();

  const openToView = React.useCallback(
    (sale: ITransaction) => {
      dispatch([
        setSale(sale),
        setSaleState('view'),
        setDialogType('viewSale'),
        setDialogFullScreen(true),
        setDialogMaxWidth(false),
        setDialogOpen(),
      ]);
    },
    [dispatch]
  );

  const refresh = React.useCallback(() => {
    dispatch([
      setSale(null),
      setSalesList([]),
      setSaleState('get'),
      setSaleListType('get'),
    ]);
  }, [dispatch]);

  const clearFilers = React.useCallback(() => {
    dispatch([
      setSale(null),
      setSalesList([]),
      setSaleState('get'),
      setSaleListType('get'),
      setSaleMetricsState('refreshing'),
      setSaleFilter({
        search: '',
        dateStart: moment().startOf('day').utc().toISOString(),
        dateEnd: moment().endOf('day').utc().toISOString(),
        paymentTypes: '',
        amountFrom: '',
        amountTo: '',
        status: '',
      }),
      setSalePagination(null),
    ]);
  }, [dispatch]);

  const refreshMetrics = React.useCallback(() => {
    dispatch([setSaleMetricsState('refreshing')]);
  }, [dispatch]);

  const search = React.useCallback(() => {
    dispatch([setSaleState('search'), setSaleListType('search')]);
  }, [dispatch]);

  return React.useMemo(
    () => ({
      openToView,
      refresh,
      refreshMetrics,
      search,
      filter: {
        clear: clearFilers,
      },
    }),
    [clearFilers, openToView, refresh, refreshMetrics, search]
  );
}

export default useSaleUI;
