import React from 'react';

import * as OfferHooks from 'hooks/useOffer';
import * as InternalComponents from './components';

function OffersList() {
  const offer = OfferHooks.useOffer();
  const { state, listOffers, pagination } = offer.state;

  React.useEffect(() => {
    if (state === 'get') {
      offer.service.getAll({
        offset: pagination?.offset,
        limit: pagination?.limit,
      });
    }
  }, [offer.service, pagination?.limit, pagination?.offset, state]);

  if (state === 'get') {
    return <InternalComponents.OfferListLoading />;
  } else if (listOffers.length > 0) {
    return <InternalComponents.OfferListTable />;
  } else if (state === 'failed') {
    return <InternalComponents.OfferFailedState />;
  } else {
    return <InternalComponents.OfferEmptyState />;
  }
}

export default OffersList;
