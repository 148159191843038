import React from 'react';
import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';

import * as InternalComponents from './components';
import useStyles from './Sales.styles';
import * as SaleHooks from 'hooks/useSale';

const Sales = () => {
  const classes = useStyles();
  const sale = SaleHooks.useSale();

  return (
    <Mui.Grid className={classes.root}>
      <InternalComponents.SalesMetrics />
      <Mui.Card className={classes.cardContainer}>
        <Mui.CardHeader
          action={
            <Mui.IconButton onClick={sale.ui.refresh}>
              <MuiIcons.Refresh />
            </Mui.IconButton>
          }
          subheader={
            <Mui.Typography color="textSecondary" variant="h5">
              Gerencie suas vendas
            </Mui.Typography>
          }
          title={<Mui.Typography variant="h3">Vendas</Mui.Typography>}
        />
        <Mui.Divider />
        <Mui.CardContent className={classes.cardContent}>
          <InternalComponents.SalesToolbar />
          <InternalComponents.SalesList />
        </Mui.CardContent>
      </Mui.Card>
    </Mui.Grid>
  );
};

export default Sales;
