import type {
  EChargeEmailStatus,
  EChargePaymentTypesAccepted,
  EChargeStatus,
  IHttpPopulateCharge,
} from 'types/entities/charge';

export class ChargeUtils {
  public static statusLabelMap: Record<EChargeStatus, string> = {
    pending: 'Pendente',
    paid: 'Pago',
    failed: 'Falhou',
    canceled: 'Cancelado',
    refunded: 'Reembolsado',
    dispute: 'Em disputa',
    charged_back: 'Estornado',
  };

  public static toStatusLabel(status: IHttpPopulateCharge['status']): string {
    return ChargeUtils.statusLabelMap[status];
  }

  public static chargeEmailStatusLabelMap: Record<EChargeEmailStatus, string> =
    {
      not_send: 'Não enviado',
      send: 'Enviado',
      viewed: 'Visualizado',
    };

  public static toEmailStatusLabel(
    status: IHttpPopulateCharge['emailStatus']
  ): string {
    return ChargeUtils.chargeEmailStatusLabelMap[status];
  }

  public static paymentTypesAcceptedLabelMap: Record<
    EChargePaymentTypesAccepted,
    string
  > = {
    credit: 'Cartão de crédito',
    pix: 'Pix',
    boleto: 'Boleto',
  };

  public static toPaymentTypesAcceptedLabel(
    paymentTypesAccepted: IHttpPopulateCharge['paymentTypesAccepted']
  ): string[] {
    return paymentTypesAccepted.map(
      paymentTypeAccepted =>
        ChargeUtils.paymentTypesAcceptedLabelMap[paymentTypeAccepted]
    );
  }
}
