import type {
  ITaxInvoiceConfigReducerAction,
  ITaxInvoiceConfigReducerState,
} from 'types/redux/reducers/tax-invoice-config-reducer.types';

const INITIAL_STATE: ITaxInvoiceConfigReducerState = {
  state: 'get',
  taxInvoiceConfig: null,
};

export default (
  state = INITIAL_STATE,
  action: ITaxInvoiceConfigReducerAction<unknown>
) => {
  switch (action.type) {
    case 'TAX_INVOICE_CONFIG_STATE_CHANGED':
      return {
        ...state,
        state: action.payload,
      };
    case 'TAX_INVOICE_CONFIG_CHANGED':
      return {
        ...state,
        taxInvoiceConfig: action.payload,
      };
    default:
      return state;
  }
};
