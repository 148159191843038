import React from 'react';
import * as Mui from '@material-ui/core';

// import * as ChargeHooks from 'hooks/use-charge';

import useStyles from './charge-empty-state.styles';

function ChargeEmptyState() {
  const classes = useStyles();

  // const charge = ChargeHooks.useCharge();

  return (
    <Mui.Grid className={classes.container} container>
      <Mui.Typography className={classes.text} variant="h5">
        Você ainda não possui cobranças!
      </Mui.Typography>
      {/* <Mui.Button
        className={classes.button}
        color="primary"
        disableFocusRipple
        disableRipple
        onClick={charge.ui.openToCreate}
        variant="text"
      >
        Crie um agora
      </Mui.Button> */}
    </Mui.Grid>
  );
}

export default ChargeEmptyState;
