import React from 'react';
import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';

import * as BuyerHooks from 'hooks/useBuyer';

import { CustomersToolbar, CustomersList } from './components';

const useStyles = Mui.makeStyles(theme => ({
  root: {
    margin: theme.spacing(3),
    backgroundColor: theme.palette.background.dark2,
    padding: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1),
      margin: theme.spacing(1),
    },
  },
  cardContent: {
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1),
    },
  },
}));

interface CustomerProps {
  textSecondary: React.ReactNode | React.ReactNode[];
}

function Customer(props: CustomerProps) {
  const { textSecondary } = props;
  const classes = useStyles();
  const buyer = BuyerHooks.useBuyer();

  if (textSecondary) {
    return (
      <React.Fragment>
        <Mui.CardHeader
          action={
            <Mui.IconButton onClick={buyer.ui.refresh}>
              <MuiIcons.Refresh />
            </Mui.IconButton>
          }
          subheader={
            <Mui.Typography color="textSecondary" variant="h5">
              {textSecondary}
            </Mui.Typography>
          }
          title={<Mui.Typography variant="h3">Clientes</Mui.Typography>}
        />
        <Mui.Divider />
        <Mui.CardContent className={classes.cardContent}>
          <CustomersToolbar />
          <CustomersList />
        </Mui.CardContent>
      </React.Fragment>
    );
  }

  return (
    <Mui.Card className={classes.root}>
      <Mui.CardHeader
        action={
          <Mui.IconButton onClick={buyer.ui.refresh}>
            <MuiIcons.Refresh />
          </Mui.IconButton>
        }
        subheader={
          <Mui.Typography color="textSecondary" variant="h5">
            Gerencie seus clientes
          </Mui.Typography>
        }
        title={<Mui.Typography variant="h3">Clientes</Mui.Typography>}
      />
      <Mui.Divider />
      <Mui.CardContent className={classes.cardContent}>
        <CustomersToolbar />
        <CustomersList />
      </Mui.CardContent>
    </Mui.Card>
  );
}

export default Customer;
