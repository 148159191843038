import React from 'react';
import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';

import { Metadata } from 'components/utils/Metadata';

import useStyles from './ProductEditDialog.styles';
import { useProductEditDialog } from './ProductEditDialog.hook';
import type { TTaxInvoiceType } from 'types/entities/tax-invoice';
import { ETaxInvoiceType } from 'types/entities/tax-invoice';

const taxInvoiceProductTypeMap: Record<TTaxInvoiceType, string> = {
  product: 'Produto',
  service: 'Serviço',
};

function ProductEditDialog() {
  const classes = useStyles();

  const {
    formState,
    product,
    productState,
    taxInvoiceConfig,
    handleChangeForm,
    handleDialogClose,
    handleImageChange,
    handleMetadataChange,
    handleSubmit,
    hasError,
    setFormState,
  } = useProductEditDialog();

  return (
    <React.Fragment>
      <Mui.AppBar className={classes.appBar}>
        <Mui.Toolbar>
          <Mui.IconButton
            aria-label="Fechar"
            color="inherit"
            edge="start"
            onClick={handleDialogClose}
          >
            <MuiIcons.Close />
          </Mui.IconButton>
          <Mui.Typography className={classes.title} variant="h5">
            {productState === 'create'
              ? 'Criar novo produto'
              : 'Editar produto'}
          </Mui.Typography>
          <Mui.Button
            color="inherit"
            disabled={!formState.isValid || formState.disabled}
            onClick={handleSubmit}
          >
            {productState === 'create' ? 'Criar' : 'Salvar'}
          </Mui.Button>
        </Mui.Toolbar>
      </Mui.AppBar>
      <Mui.Grid className={classes.container} container spacing={2}>
        <Mui.Grid item xs={12}>
          <Mui.Typography variant="h5">Dados do produto</Mui.Typography>
        </Mui.Grid>
        <Mui.Grid item md={4} sm={6} xs={12}>
          <Mui.TextField
            error={hasError('name')}
            fullWidth
            helperText={hasError('name') && formState.errors.name}
            id="name"
            label="Nome do produto"
            name="name"
            onChange={handleChangeForm}
            value={formState.values.name || ''}
            variant="outlined"
          />
        </Mui.Grid>
        <Mui.Grid item md={4} sm={6} xs={12}>
          <Mui.TextField
            SelectProps={{
              MenuProps: {
                classes: {
                  paper: classes.selectPaper,
                },
              },
              classes: {
                icon: classes.selectIcon,
              },
            }}
            error={hasError('status')}
            fullWidth
            helperText={hasError('status') && formState.errors.status}
            id="status"
            label="Status"
            name="status"
            select
            value={formState.values.status}
            variant="outlined"
          >
            <Mui.MenuItem value="active">Ativado</Mui.MenuItem>
            <Mui.MenuItem disabled value="disabled">
              Desativado
            </Mui.MenuItem>
          </Mui.TextField>
        </Mui.Grid>
        <Mui.Grid item md={4} sm={6} xs={12}>
          <Mui.TextField
            fullWidth
            id="description"
            label="Descrição"
            multiline
            name="description"
            onChange={handleChangeForm}
            value={formState.values.description || ''}
            variant="outlined"
          />
        </Mui.Grid>

        <Mui.Grid item xs={12}>
          <Mui.Divider />
        </Mui.Grid>
        <Mui.Grid item xs={12}>
          <Mui.Typography variant="h4">Imagem do produto</Mui.Typography>
        </Mui.Grid>

        <Mui.Grid item xs={12}>
          <Mui.Typography variant="overline">
            Tamanho recomendado (64x64)
          </Mui.Typography>
        </Mui.Grid>

        <div className={classes.uploadContainer}>
          <input
            accept="image/*"
            className={classes.uploadInput}
            id="upload-input"
            onChange={handleImageChange}
            type="file"
          />
          {formState.values.image !== '' ? (
            <label className={classes.imageContainer} htmlFor="upload-input">
              <div className={classes.imageOverlay}>
                <Mui.IconButton component="span">
                  <MuiIcons.CameraAlt />
                </Mui.IconButton>
              </div>
              <img alt="Imagem do produto" src={formState.values.image} />
            </label>
          ) : (
            <span className={classes.imageMock}>
              <MuiIcons.Panorama />
            </span>
          )}

          <div className={classes.uploadActionsContainer}>
            <label htmlFor="upload-input">
              <Mui.Button color="primary" component="span" variant="contained">
                Fazer upload
              </Mui.Button>
            </label>
            <Mui.Tooltip title="Em breve">
              <div>
                <Mui.Button disabled variant="contained">
                  Pré-visualização
                </Mui.Button>
              </div>
            </Mui.Tooltip>
          </div>
          {hasError('imageFile') && (
            <Mui.FormHelperText error>
              {formState.errors.imageFile}
            </Mui.FormHelperText>
          )}
        </div>

        <Mui.Grid item xs={12}>
          <Mui.Divider />
        </Mui.Grid>
        <Mui.Grid item xs={12}>
          <div className={classes.taxInvoiceHeading}>
            <Mui.Typography variant="h4">Nota Fiscal do Produto</Mui.Typography>

            <Mui.Switch
              checked={formState.values.taxInvoiceActive}
              color="primary"
              id="taxInvoiceActive"
              name="taxInvoiceActive"
              onChange={(_, checked) =>
                setFormState(prevState => ({
                  ...prevState,
                  values: { ...prevState.values, taxInvoiceActive: checked },
                }))
              }
            />
          </div>
        </Mui.Grid>
        {formState.values.taxInvoiceActive && (
          <>
            <Mui.Grid item sm={4} xs={12}>
              <Mui.TextField
                SelectProps={{
                  MenuProps: {
                    classes: {
                      paper: classes.selectPaper,
                    },
                  },
                  classes: {
                    icon: classes.selectIcon,
                  },
                }}
                error={hasError('taxInvoiceProductType')}
                fullWidth
                helperText={
                  hasError('taxInvoiceProductType') &&
                  formState.errors.taxInvoiceProductType
                }
                id="taxInvoiceProductType"
                label="Tipo da nota fiscal"
                name="taxInvoiceProductType"
                onChange={handleChangeForm}
                select
                value={formState.values.taxInvoiceProductType}
                variant="outlined"
              >
                <Mui.MenuItem value={ETaxInvoiceType.PRODUCT}>
                  {taxInvoiceProductTypeMap['product']}
                </Mui.MenuItem>
                <Mui.MenuItem value={ETaxInvoiceType.SERVICE}>
                  {taxInvoiceProductTypeMap['service']}
                </Mui.MenuItem>
              </Mui.TextField>
            </Mui.Grid>

            <Mui.Grid item sm={4} xs={12}>
              <Mui.TextField
                error={hasError('taxInvoiceDescription')}
                fullWidth
                helperText={
                  hasError('taxInvoiceDescription') &&
                  formState.errors.taxInvoiceDescription
                }
                id="taxInvoiceDescription"
                label={`Descrição do ${
                  formState.values.taxInvoiceProductType === 'product'
                    ? 'produto'
                    : 'serviço'
                }`}
                multiline
                name="taxInvoiceDescription"
                onChange={handleChangeForm}
                value={formState.values.taxInvoiceDescription || ''}
                variant="outlined"
              />
            </Mui.Grid>

            {taxInvoiceConfig?.provider === 'notazz' &&
              formState.values.taxInvoiceProductType === 'product' && (
                <Mui.Grid item sm={4} xs={12}>
                  <Mui.TextField
                    error={hasError('taxInvoiceProductId')}
                    fullWidth
                    helperText={
                      hasError('taxInvoiceProductId') &&
                      formState.errors.taxInvoiceProductId
                    }
                    id="taxInvoiceProductId"
                    label="Identificador do produto"
                    name="taxInvoiceProductId"
                    onChange={handleChangeForm}
                    value={formState.values.taxInvoiceProductId || ''}
                    variant="outlined"
                  />
                </Mui.Grid>
              )}
          </>
        )}

        <Mui.Grid item xs={12}>
          <Mui.Divider />
        </Mui.Grid>
        <Mui.Grid item xs={12}>
          <Mui.Typography variant="h4">Metadados</Mui.Typography>
        </Mui.Grid>
        <Mui.Grid item lg={8} md={10} xs={12}>
          <Metadata
            metadata={product?.metadata}
            onChange={handleMetadataChange}
          />
        </Mui.Grid>
      </Mui.Grid>
    </React.Fragment>
  );
}

export default ProductEditDialog;
