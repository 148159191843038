import React, { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import axios from 'axios';

import moment from 'moment-timezone';

import validate from 'validate.js';

import { getSession } from 'auth';

import { documentMask, phoneMask, postalCodeMask, dateMask } from 'helpers';

import {
  setCustomerState,
  setCustomer,
  setDialogClose,
  setDialogType,
  setSnackbarOpen,
  setSnackbarMessage,
  setSnackbarType,
} from 'store/actions';

import {
  AppBar,
  Button,
  Dialog,
  DialogTitle,
  Divider,
  Grid,
  TextField,
  Toolbar,
  IconButton,
  Typography,
  CircularProgress,
} from '@material-ui/core';

import { InputMask, SelectState, SelectCity } from 'components';

import { makeStyles } from '@material-ui/styles';

import CloseIcon from '@material-ui/icons/Close';
import { api } from 'services/api';

const schema = {
  name: {
    presence: {
      allowEmpty: false,
      message: '^Nome é necessário',
    },
  },
  document_number: {
    presence: {
      allowEmpty: false,
      message: '^Documento é necessário',
    },
  },
};

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    color: '#fff',
  },
  container: {
    padding: theme.spacing(3),
    width: '100%',
    backgroundColor: theme.palette.background.default,
  },
  buttonCreate: {
    marginTop: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  formControl: {
    minWidth: 120,
    width: '100%',
  },
  containerLoader: {
    paddingTop: 0,
    padding: theme.spacing(2),
  },
  paper: {
    backgroundColor: theme.palette.background.dark,
  },
}));

const CustomersEditDialog = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const { state: stateCustomer, customer } = useSelector(
    state => state.customerState
  );

  const { open: dialogOpen } = useSelector(state => state.dialogState);

  const [disable_submit, setDisableSubmit] = useState(false);
  const [id_city, setIdCity] = useState(null);
  const [openLoader, setOpenLoader] = useState(false);
  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      state: '',
    },
    errors: {},
    touched: {},
  });

  useEffect(() => {
    if (dialogOpen) {
      if (customer) {
        const {
          name,
          document_number,
          email,
          email_secondary,
          phone_number,
          birthdate,
          obs,
          street,
          postal_code,
          house_number,
          neighborhood,
          id_state: state,
          id_city,
        } = customer;

        setIdCity(id_city);

        setFormState(formState => ({
          ...formState,
          values: {
            name,
            document_number,
            email,
            email_secondary,
            phone_number,
            birthdate: birthdate ? moment(birthdate).format('DD/MM/YYYY') : '',
            obs,
            street,
            postal_code,
            house_number,
            neighborhood,
            state,
          },
        }));
      }
    }
  }, [dialogOpen, customer]);

  useEffect(() => {
    if (id_city) {
      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          city: id_city,
        },
      }));

      setIdCity(null);
      handleCloseLoader();
    }
  }, [id_city]);

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: !errors,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleCloseDialog = () => {
    setFormState({
      isValid: false,
      values: {
        state: '',
      },
      errors: {},
      touched: {},
    });

    dispatch([setCustomer(null), setDialogClose(), setDialogType(null)]);
  };

  const handleOpenLoader = () => setOpenLoader(true);
  const handleCloseLoader = () => setOpenLoader(false);

  const handleChangeForm = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleFetchLocationData = async event => {
    const postal_code = event.target.value.replace(/\D/g, '');

    if (postal_code !== '') {
      const postal_codeRegex = new RegExp(/^[0-9]{8}$/);

      if (postal_codeRegex.test(postal_code)) {
        handleOpenLoader();

        setFormState(formState => ({
          ...formState,
          values: {
            ...formState.values,
            state: null,
          },
          errors: {
            postal_code: null,
          },
        }));

        try {
          const dataLocation = await axios.get(
            `https://viacep.com.br/ws/${postal_code}/json/`
          );

          const {
            bairro: neighborhood,
            logradouro: street,
            ibge: id_city,
          } = dataLocation.data;

          const id_state = id_city.slice(0, 2);

          setFormState(formState => ({
            ...formState,
            values: {
              ...formState.values,
              state: id_state,
              neighborhood,
              street,
            },
          }));

          setIdCity(id_city);
        } catch (error) {
          handleCloseLoader();
          setFormState(formState => ({
            ...formState,
            errors: {
              postal_code: 'Não é um CEP válido',
            },
          }));
        }
      } else {
        setFormState(formState => ({
          ...formState,
          errors: {
            postal_code: 'Não é um CEP válido',
          },
        }));
      }
    }
  };

  const onSubmitForm = async () => {
    const {
      name,
      document_number,
      email,
      email_secondary,
      phone_number,
      birthdate,
      obs,
      street,
      postal_code,
      house_number,
      neighborhood,
      state: id_state,
      city: id_city,
    } = formState.values;

    const document_number_only_numbers =
      document_number && document_number !== ''
        ? document_number.replace(/[^0-9]/g, '')
        : '';
    const phone_number_only_numbers =
      phone_number && phone_number !== ''
        ? phone_number.replace(/[^0-9]/g, '')
        : '';
    const postal_code_only_numbers =
      postal_code && postal_code !== ''
        ? postal_code.replace(/[^0-9]/g, '')
        : '';
    const birthdate_only_numbers =
      birthdate && birthdate !== '' ? birthdate.replace(/[^0-9]/g, '') : '';
    const emailRegex = new RegExp(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

    if (
      document_number_only_numbers.length !== 14 &&
      document_number_only_numbers.length !== 11
    ) {
      setFormState(formState => ({
        ...formState,
        errors: {
          ...formState.errors,
          document_number: ['O documento está inválido!'],
        },
      }));

      document.querySelector('#document_number').focus();
    } else if (email && email !== '' && !emailRegex.test(email)) {
      setFormState(formState => ({
        ...formState,
        errors: {
          ...formState.errors,
          email: 'O e-mail está inválido!',
        },
      }));

      document.querySelector('#email').focus();
    } else if (
      email_secondary &&
      email_secondary !== '' &&
      !emailRegex.test(email_secondary)
    ) {
      setFormState(formState => ({
        ...formState,
        errors: {
          ...formState.errors,
          email_secondary: 'O e-mail está inválido!',
        },
      }));

      document.querySelector('#email_secondary').focus();
    } else if (
      phone_number_only_numbers !== '' &&
      phone_number_only_numbers.length !== 11 &&
      phone_number_only_numbers.length !== 10
    ) {
      setFormState(formState => ({
        ...formState,
        errors: {
          ...formState.errors,
          phone_number: 'O número de telefone ou celular está inválido!',
        },
      }));

      document.querySelector('#phone_number').focus();
    } else if (
      birthdate_only_numbers !== '' &&
      birthdate_only_numbers.length !== 8
    ) {
      setFormState(formState => ({
        ...formState,
        errors: {
          ...formState.errors,
          birthdate: 'A data de nascimento está inválida!',
        },
      }));

      document.querySelector('#birthdate').focus();
    } else if (
      postal_code_only_numbers !== '' &&
      postal_code_only_numbers.length !== 8
    ) {
      setFormState(formState => ({
        ...formState,
        errors: {
          ...formState.errors,
          postal_code: 'O cep está inválido!',
        },
      }));

      document.querySelector('#postal_code').focus();
    } else {
      setDisableSubmit(true);
      const seller_id = getSession();

      let state = null;
      let city = null;

      if (id_state) {
        try {
          const response = await axios.get(
            `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${id_state}`
          );

          state = response.data;
        } catch (error) {
          console.log(error);
        }
      }

      if (id_city) {
        try {
          const response = await axios.get(
            `https://servicodados.ibge.gov.br/api/v1/localidades/municipios/${id_city}`
          );

          city = response.data;
        } catch (error) {
          console.log(error);
        }
      }

      const data = {
        seller_id,
        name,
        email: email || null,
        email_secondary: email_secondary || null,
        phone_number: phone_number_only_numbers,
        document_number: document_number_only_numbers,
        birthdate:
          birthdate && birthdate !== ''
            ? birthdate.split('/').reverse().join('-')
            : null,
        obs: obs || null,
        street: street || null,
        house_number: house_number || null,
        postal_code: postal_code_only_numbers,
        neighborhood: neighborhood || null,
        id_city: city ? city.id : null,
        city: city ? city.nome : null,
        id_state: state ? state.id : null,
        state_initials: state ? state.sigla : null,
        state: state ? state.nome : null,
        country_code: 'BR',
      };

      dispatch([
        setSnackbarType('info'),
        setSnackbarMessage('Processando...'),
        setSnackbarOpen(),
      ]);

      if (stateCustomer === 'create') {
        try {
          const token = localStorage.getItem('token');

          const response = await api.post('/buyer/', data, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          if (response.data) {
            dispatch([
              setCustomerState('get'),
              setCustomer(null),
              setSnackbarMessage('Cliente criado com sucesso !'),
              setSnackbarType('success'),
              setSnackbarOpen(),
            ]);
            handleCloseDialog();
          }
        } catch (error) {
          dispatch([
            setSnackbarMessage(
              'Não foi possível criar este cliente, tente novamente mais tarde !'
            ),
            setSnackbarType('error'),
            setSnackbarOpen(),
          ]);
          console.error('Erro ao criar o cliente', error);
        }
      } else if (stateCustomer === 'edit') {
        try {
          const token = localStorage.getItem('token');

          const response = await api.put(`/buyer/${customer.id}/`, data, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          if (response.data) {
            dispatch([
              setCustomerState('get'),
              setCustomer(null),
              setSnackbarMessage('Cliente editado com sucesso !'),
              setSnackbarType('success'),
              setSnackbarOpen(),
            ]);
            handleCloseDialog();
          }
        } catch (error) {
          dispatch([
            setSnackbarMessage(
              'Não foi possível criar este cliente, tente novamente mais tarde!'
            ),
            setSnackbarType('error'),
            setSnackbarOpen(),
          ]);
          console.error('Erro ao editar o cliente', error);
        }
      }

      setDisableSubmit(false);
    }

    // Nome: Edinei Rodrigues Filho,
    // Documento: 458.306.258-30,
    // Email: edineibk@gmail.com,
    // Email Secundario: edineibk@gmail.com,
    // Telefone ou Celular: (17) 99116-8727,
    // Nascimento: 15/03/1999,
    // Observação: Testando a observação,
    // Rua ou Avenida: Rua Alzira Cezário de Oliveira,
    // CEP: 15047-031,
    // Número: 184,
    // Complemento: Algum complemento de casa ai,
    // Bairro: Jardim das Oliveiras,
    // Estado: São Paulo,
    // Cidade: São José do Rio Preto
    // console.log(`
    //     Nome: ${name},
    //     Documento: ${document_number_only_numbers},
    //     Email: ${email || null},
    //     Email Secundario: ${email_secondary || null},
    //     Telefone ou Celular: ${phone_number_only_numbers},
    //     Nascimento: ${birthdate && birthdate !== '' ? birthdate.split('/').reverse().join('-') : null},
    //     Observação: ${obs || null},
    //     Rua ou Avenida: ${street || null},
    //     CEP: ${postal_code_only_numbers},
    //     Número: ${house_number || null},
    //     Bairro: ${neighborhood || null},
    //     Código da Cidade: ${city_index !== -1 ? cities[city_index].id : null},
    //     Cidade: ${city_index !== -1 ? cities[city_index].nome : null},
    //     Código do Estado: ${state_index !== -1 ? states[state_index].id : null},
    //     Sigla Estado: ${state_index !== -1 ? states[state_index].sigla : null},
    //     Estado: ${state_index !== -1 ? states[state_index].nome : null}
    // `);
  };

  const hasError = field =>
    !!(formState.touched[field] && formState.errors[field]);

  return (
    <React.Fragment>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            aria-label="Fechar"
            color="inherit"
            edge="start"
            onClick={handleCloseDialog}
          >
            <CloseIcon />
          </IconButton>
          <Typography className={classes.title} variant="h5">
            {stateCustomer === 'create'
              ? 'Criar novo cliente'
              : 'Editar cliente'}
          </Typography>
          <Button
            color="inherit"
            disabled={!formState.isValid || disable_submit}
            onClick={onSubmitForm}
          >
            {stateCustomer === 'create' ? 'Criar' : 'Salvar'}
          </Button>
        </Toolbar>
      </AppBar>
      <Grid className={classes.container} container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5">Dados pessoais</Typography>
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <TextField
            error={hasError('name')}
            fullWidth
            helperText={hasError('name') ? formState.errors.name[0] : ''}
            id="name"
            label="Nome Completo"
            name="name"
            onChange={handleChangeForm}
            required
            value={formState.values.name || ''}
            variant="outlined"
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <TextField
            InputProps={{
              inputComponent: InputMask,
            }}
            error={hasError('document_number')}
            fullWidth
            helperText={
              hasError('document_number')
                ? formState.errors.document_number[0]
                : 'Ex: 123.456.789-00 ou 12.345.678/0001-01'
            }
            id="document_number"
            inputProps={{
              mask: documentMask,
            }}
            label="CPF ou CNPJ"
            name="document_number"
            onChange={handleChangeForm}
            required
            type="tel" // eslint-disable-next-line
            value={formState.values.document_number || ''}
            variant="outlined"
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <TextField
            error={!!formState.errors.email}
            fullWidth
            helperText={formState.errors.email ? formState.errors.email : ''}
            id="email"
            label="E-mail para cobranças e alertas"
            name="email"
            onChange={handleChangeForm}
            value={formState.values.email || ''}
            variant="outlined"
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <TextField
            error={!!formState.errors.email_secondary}
            fullWidth
            helperText={
              formState.errors.email_secondary
                ? formState.errors.email_secondary
                : ''
            }
            id="email_secondary"
            label="E-mail secundário para cobranças e alertas"
            name="email_secondary"
            onChange={handleChangeForm}
            value={formState.values.email_secondary || ''}
            variant="outlined"
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <TextField
            InputProps={{
              inputComponent: InputMask,
            }}
            error={!!formState.errors.phone_number}
            fullWidth
            helperText={
              formState.errors.phone_number
                ? formState.errors.phone_number
                : 'Ex: (11) 3030-3030 ou (11) 99123-4567'
            }
            id="phone_number"
            inputProps={{
              mask: phoneMask,
            }}
            label="Telefone ou celular"
            name="phone_number"
            onChange={handleChangeForm}
            type="tel"
            value={formState.values.phone_number || ''} // eslint-disable-next-line
            variant="outlined"
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <TextField
            InputProps={{
              inputComponent: InputMask,
            }}
            error={!!formState.errors.birthdate}
            fullWidth
            helperText={
              formState.errors.birthdate ? formState.errors.birthdate : ''
            }
            id="birthdate"
            inputProps={{
              mask: dateMask,
            }}
            label="Data de Nascimento"
            name="birthdate"
            onChange={handleChangeForm}
            type="tel"
            value={formState.values.birthdate || ''} // eslint-disable-next-line
            variant="outlined"
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <TextField
            fullWidth
            id="obs"
            label="Observações"
            maxRows="4"
            multiline
            name="obs"
            onChange={handleChangeForm}
            value={formState.values.obs || ''}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5">Dados de endereço</Typography>
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <TextField
            InputProps={{
              inputComponent: InputMask,
            }}
            error={!!formState.errors.postal_code}
            fullWidth
            helperText={
              formState.errors.postal_code
                ? formState.errors.postal_code
                : 'Ex: 12345-000'
            }
            id="postal_code"
            inputProps={{
              mask: postalCodeMask,
            }}
            label="CEP"
            name="postal_code"
            onBlur={handleFetchLocationData}
            onChange={handleChangeForm}
            type="tel"
            value={formState.values.postal_code || ''} // eslint-disable-next-line
            variant="outlined"
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <TextField
            error={!!formState.errors.street}
            fullWidth
            helperText={
              formState.errors.street
                ? formState.errors.street
                : 'Ex: Rua José Antonio'
            }
            id="street"
            label="Endereço"
            name="street"
            onChange={handleChangeForm}
            value={formState.values.street || ''}
            variant="outlined"
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <TextField
            error={!!formState.errors.house_number}
            fullWidth
            helperText={
              formState.errors.house_number
                ? formState.errors.house_number
                : 'Ex: 123, apenas números'
            }
            id="house_number"
            label="Número"
            name="house_number"
            onChange={handleChangeForm}
            type="tel"
            value={formState.values.house_number || ''}
            variant="outlined"
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <TextField
            error={!!formState.errors.neighborhood}
            fullWidth
            helperText={
              formState.errors.neighborhood
                ? formState.errors.neighborhood
                : 'Ex: Jardim Exemplo'
            }
            id="neighborhood"
            label="Bairro"
            name="neighborhood"
            onChange={handleChangeForm}
            value={formState.values.neighborhood || ''}
            variant="outlined"
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <SelectState
            className={classes.formControl}
            condition={dialogOpen}
            error={!!formState.errors.state}
            inputProps={{
              id: 'state',
              name: 'state',
            }}
            messageError={formState.errors.state}
            onChange={handleChangeForm}
            value={
              formState.values.state || formState.values.state === 0
                ? formState.values.state
                : ''
            }
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <SelectCity
            className={classes.formControl}
            error={!!formState.errors.city}
            inputProps={{
              id: 'city',
              name: 'city',
            }}
            onChange={handleChangeForm}
            state={formState.values.state}
            value={
              formState.values.city || formState.values.city === 0
                ? formState.values.city
                : ''
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            className={classes.buttonCreate}
            color="primary"
            disabled={!formState.isValid || disable_submit}
            fullWidth
            onClick={onSubmitForm}
            variant="contained"
          >
            {stateCustomer === 'create' ? 'Criar' : 'Salvar'}
          </Button>
        </Grid>
      </Grid>
      <Dialog
        aria-labelledby="Carregando dados de localização"
        classes={{
          paper: classes.paper,
        }}
        onClose={handleCloseLoader}
        open={openLoader}
      >
        <DialogTitle>
          Buscando dados de localização
          <Typography variant="body2">
            Depois de colocar o cep clique <br /> fora do campo para fazer a
            busca!
          </Typography>
        </DialogTitle>
        <Grid
          alignItems="center"
          className={classes.containerLoader}
          container
          justify="center"
        >
          <CircularProgress color="secondary" />
        </Grid>
      </Dialog>
    </React.Fragment>
  );
};

export default CustomersEditDialog;
