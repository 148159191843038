const INITIAL_STATE = {
  open: false,
  full_screen: false,
  type: '',
  max_width: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'DIALOG_OPENED':
      return {
        ...state,
        open: true,
      };
    case 'DIALOG_CLOSED':
      return {
        ...state,
        open: false,
      };
    case 'DIALOG_TYPE_CHANGED':
      return {
        ...state,
        type: action.payload,
      };
    case 'DIALOG_FULL_SCREEN_CHANGED':
      return {
        ...state,
        full_screen: action.payload,
      };
    case 'DIALOG_MAX_WIDTH_CHANGED':
      return {
        ...state,
        max_width: action.payload,
      };
    default:
      return state;
  }
};
