export enum ETaxInvoiceProvider {
  ENOTAS = 'enotas',
  NFE_IO = 'nfe.io',
  NOTAZZ = 'notazz',
  RECEBAFACIL = 'recebafacil',
}

export enum ETaxInvoiceStatus {
  PENDING = 'pending',
  SUCCEEDED = 'succeeded',
  FAILED = 'failed',
}

export enum ETaxInvoiceType {
  PRODUCT = 'product',
  SERVICE = 'service',
}

type TStatus = `${ETaxInvoiceStatus}`;
type TProvider = `${ETaxInvoiceProvider}`;
export type TTaxInvoiceType = `${ETaxInvoiceType}`;
export interface ITaxInvoice<InternalMetadata = object, Metadata = object> {
  id: string;
  resource: 'tax-invoice';
  marketplaceId: string;
  sellerId: string;
  transactionId: string;
  customerId: string;
  providerId: string | null;
  provider: TProvider;
  type: TTaxInvoiceType;
  status: TStatus;
  amount: number;
  url: string | null;
  description: string;
  emittedAt: string | null;
  internalMetadata: InternalMetadata | null;
  metadata: Metadata | null;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
}
