import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import validate from 'validate.js';
import { getSession } from 'auth';
import {
  setSnackbarMessage,
  setSnackbarType,
  setSnackbarOpen,
} from 'store/actions';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button,
  TextField,
} from '@material-ui/core';
import { api } from 'services/api';

const schema = {
  currentPassword: {
    presence: {
      allowEmpty: false,
      message: '^Senha atual é necessária.',
    },
    length: {
      maximum: 32,
      minimum: 8,
      tooLong: '^Senha atual muito longa (máximo 32 caracteres)',
      tooShort: '^Senha atual muito curta (mínimo 8 caracteres)',
    },
  },
  password: {
    presence: {
      allowEmpty: false,
      message: '^Nova senha é necessária.',
    },
    length: {
      maximum: 32,
      minimum: 8,
      tooLong: '^Nova senha muito longa (máximo 32 caracteres)',
      tooShort: '^Nova senha muito curta (mínimo 8 caracteres)',
    },
  },
  confirmPassword: {
    presence: {
      allowEmpty: false,
      message: '^Confirmação de senha é necessária.',
    },
    length: {
      maximum: 32,
      minimum: 8,
      tooLong: '^Confirmação de senha muito longa (máximo 32 caracteres)',
      tooShort: '^Confirmação de senha muito curta (mínimo 8 caracteres)',
    },
  },
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark2,
  },
  textField: {
    marginTop: theme.spacing(2),
  },
}));

const Password = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  const dispatch = useDispatch();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    errors: {},
    touched: {},
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: !errors,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleSubmit = async event => {
    event.preventDefault();

    const { currentPassword, password, confirmPassword } = formState.values;

    if (password !== confirmPassword) {
      setFormState(formState => ({
        ...formState,
        errors: {
          ...formState.errors,
          password: ['Senhas não coincidem'],
          confirmPassword: ['Senhas não coincidem'],
        },
      }));

      document.querySelector('#password').focus();
    } else {
      const id_user = getSession();
      const token = localStorage.getItem('token');

      const data = {
        currentPassword,
        password,
      };

      dispatch(setSnackbarMessage('Processando...'));
      dispatch(setSnackbarType('info'));
      dispatch(setSnackbarOpen());

      try {
        const response = await api.post(
          `/seller/changePassword/${id_user}`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.data) {
          document.querySelector('#buttonSubmit').focus();
          setFormState({
            isValid: false,
            values: {},
            errors: {},
            touched: {},
          });
          dispatch(setSnackbarMessage('Senha alterada com sucesso !'));
          dispatch(setSnackbarType('success'));
          dispatch(setSnackbarOpen());
        }
      } catch (error) {
        dispatch(
          setSnackbarMessage(
            'Não foi possível alterar senha, tente novamente mais tarde !'
          )
        );
        if (error.response) {
          const { status, code } = error.response.data;
          if (status && status === 'error') {
            if (code === 0) {
              dispatch(setSnackbarMessage('Senha atual incorreta !'));
              setFormState(formState => ({
                ...formState,
                errors: {
                  ...formState.errors,
                  currentPassword: ['Senha atual incorreta'],
                },
              }));

              document.querySelector('#currentPassword').focus();
            } else if (code === 1) {
              dispatch(
                setSnackbarMessage(
                  'Não foi possível alterar senha, tente novamente mais tarde !'
                )
              );
            }
          }
        }
        dispatch(setSnackbarType('error'));
        dispatch(setSnackbarOpen());
      }
    }
  };

  const hasError = field =>
    !!(formState.touched[field] && formState.errors[field]);

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <form onSubmit={handleSubmit}>
        <CardHeader subheader="Alterar senha" title="Senha" />
        <Divider />
        <CardContent>
          <TextField
            error={hasError('currentPassword')}
            fullWidth
            helperText={
              hasError('currentPassword')
                ? formState.errors.currentPassword[0]
                : null
            }
            id="currentPassword"
            label="Senha atual"
            name="currentPassword"
            onChange={handleChange}
            type="password"
            value={formState.values.currentPassword || ''}
            variant="outlined"
          />
          <TextField
            className={classes.textField}
            error={hasError('password')}
            fullWidth
            helperText={
              hasError('password') ? formState.errors.password[0] : null
            }
            id="password"
            label="Nova senha"
            name="password"
            onChange={handleChange}
            type="password"
            value={formState.values.password || ''}
            variant="outlined"
          />
          <TextField
            className={classes.textField}
            error={hasError('confirmPassword')}
            fullWidth
            helperText={
              hasError('confirmPassword')
                ? formState.errors.confirmPassword[0]
                : null
            }
            id="confirmPassword"
            label="Confirmar senha"
            name="confirmPassword"
            onChange={handleChange}
            type="password"
            value={formState.values.confirmPassword || ''}
            variant="outlined"
          />
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            color="primary"
            disabled={!formState.isValid}
            id="buttonSubmit"
            type="submit"
            variant="contained"
          >
            Alterar Senha
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

Password.propTypes = {
  className: PropTypes.string,
};

export default Password;
