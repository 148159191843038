import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(3),
    backgroundColor: theme.palette.background.dark2,
    padding: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1),
      margin: theme.spacing(1),
    },
  },
  cardContent: {
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1),
    },
  },
}));

export default useStyles;
