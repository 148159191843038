import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Grid, IconButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%',
  },
  grid: {
    height: '100%',
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: '700px',
    margin: 'auto',
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
  },
}));

const SignUpStep05 = props => {
  const { history } = props;

  const classes = useStyles();

  // const handleChange = event => {
  //   event.persist();
  // };

  const handleBack = () => {
    history.goBack();
  };

  // const handleSignUp = event => {
  //   event.preventDefault();
  //   history.push('/');
  // };

  return (
    <div className={classes.root}>
      step5
      <Grid className={classes.grid} container>
        <Grid className={classes.content} item lg={12} xs={12}>
          <div className={classes.content}>
            <div className={classes.contentHeader}>
              <IconButton onClick={handleBack}>
                <ArrowBackIcon />
              </IconButton>
            </div>
            <div className={classes.contentBody} />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

SignUpStep05.propTypes = {
  history: PropTypes.object,
};

export default withRouter(SignUpStep05);
