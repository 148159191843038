import type { ICheckoutProduct } from 'types/entities/checkout';
import { getDisplayMoney } from './money';

declare namespace ICheckoutUtils {
  interface IDiscountToLabelParams {
    type?: 'percentage' | 'fixed';
    value?: number;
  }
}
export class CheckoutUtils {
  public static checkoutProductTypeLabelMap: Record<
    ICheckoutProduct['type'],
    string
  > = {
    product: 'Produto',
    plan: 'Assinatura',
  };

  public static getCheckoutProductTypeLabel(
    type: ICheckoutProduct['type']
  ): string {
    return this.checkoutProductTypeLabelMap[type] || '';
  }

  public static discountToLabel(
    params: ICheckoutUtils.IDiscountToLabelParams
  ): string {
    const { type = 'percentage', value = 0 } = params;

    return type === 'percentage'
      ? `% ${value * 100}`
      : getDisplayMoney(value / 100);
  }
}
