import React from 'react';
import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { useAuthContext } from 'hooks/useAuthContext.hook';

import { Profile, SidebarNav } from './components';

import { EMarketplaceUserRole } from 'types/entities/marketplace-user';

const useStyles = Mui.makeStyles(theme => ({
  drawer: {
    width: 250,
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)',
    },
    '&::-webkit-scrollbar': {
      width: 8,
    },
    '&::-webkit-scrollbar-thumb': {
      height: 56,
      background: 'hsla(0, 0%, 53.3%, 0.4)',
    },
  },
  paperAnchorDockedLeft: {
    borderColor: theme.palette.complementary.grey,
  },
  root: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(2, 0),
    backgroundColor: theme.palette.complementary.grey,
  },
  nav: {
    marginBottom: theme.spacing(2),
  },
  buttonEmitPayment: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  adornmentLeft: {
    marginLeft: 'auto',
    fontSize: 10,
    fontWeight: 600,
    padding: `${theme.spacing(1.5)}px ${theme.spacing(0.5)}px`,
  },
}));

function ComingSoonFeatureChip(props) {
  return (
    <Mui.Chip
      clickable
      color="secondary"
      label="EM BREVE"
      size="small"
      variant="default"
      {...props}
    />
  );
}

function NewFeatureChip(props) {
  return (
    <Mui.Chip
      clickable
      color="primary"
      label={props.label || 'ATUALIZADO'}
      size="small"
      variant="default"
      {...props}
    />
  );
}

const Sidebar = props => {
  const { open, variant, onClose, onOpen, className, history, ...rest } = props;
  const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

  const classes = useStyles();

  const { user, marketplaceUser } = useAuthContext();

  const pages = React.useMemo(
    () => [
      {
        title: 'Painel',
        href: '/dashboard',
        icon: <MuiIcons.Dashboard />,
        roles: [
          EMarketplaceUserRole.ATTENDANTS,
          EMarketplaceUserRole.DEVELOPER,
          EMarketplaceUserRole.FINANCIAL,
          EMarketplaceUserRole.MARKETING,
          EMarketplaceUserRole.OWNER,
          EMarketplaceUserRole.SELLER,
        ],
      },
      {
        title: 'Lançamentos Futuros',
        href: '/future-transfers',
        icon: <MuiIcons.TrendingUp />,
        roles: [EMarketplaceUserRole.FINANCIAL, EMarketplaceUserRole.OWNER],
      },
      {
        title: 'Clientes',
        href: '/customers',
        icon: <MuiIcons.People />,
        roles: [
          EMarketplaceUserRole.ATTENDANTS,
          EMarketplaceUserRole.DEVELOPER,
          EMarketplaceUserRole.FINANCIAL,
          EMarketplaceUserRole.MARKETING,
          EMarketplaceUserRole.OWNER,
          EMarketplaceUserRole.SELLER,
        ],
      },
      {
        title: 'Vendas',
        href: '/sales',
        icon: <MuiIcons.Store />,
        roles: [
          EMarketplaceUserRole.ATTENDANTS,
          EMarketplaceUserRole.DEVELOPER,
          EMarketplaceUserRole.FINANCIAL,
          EMarketplaceUserRole.MARKETING,
          EMarketplaceUserRole.OWNER,
          EMarketplaceUserRole.SELLER,
        ],
      },
      {
        title: 'Assinaturas',
        href: '/subscriptions',
        icon: <MuiIcons.Cached />,
        roles: [
          EMarketplaceUserRole.ATTENDANTS,
          EMarketplaceUserRole.DEVELOPER,
          EMarketplaceUserRole.FINANCIAL,
          EMarketplaceUserRole.MARKETING,
          EMarketplaceUserRole.OWNER,
          EMarketplaceUserRole.SELLER,
        ],
      },
      {
        title: 'Planos',
        href: '/plans',
        icon: <MuiIcons.LibraryBooks />,
        roles: [
          EMarketplaceUserRole.ATTENDANTS,
          EMarketplaceUserRole.DEVELOPER,
          EMarketplaceUserRole.FINANCIAL,
          EMarketplaceUserRole.MARKETING,
          EMarketplaceUserRole.OWNER,
          EMarketplaceUserRole.SELLER,
        ],
      },
      {
        title: 'Produtos',
        href: '/products',
        icon: <MuiIcons.ShoppingBasket />,
        roles: [
          EMarketplaceUserRole.ATTENDANTS,
          EMarketplaceUserRole.DEVELOPER,
          EMarketplaceUserRole.FINANCIAL,
          EMarketplaceUserRole.MARKETING,
          EMarketplaceUserRole.OWNER,
          EMarketplaceUserRole.SELLER,
        ],
      },
      {
        title: 'Checkouts',
        href: '/checkouts',
        icon: <MuiIcons.ShoppingCart />,
        roles: [
          EMarketplaceUserRole.ATTENDANTS,
          EMarketplaceUserRole.DEVELOPER,
          EMarketplaceUserRole.FINANCIAL,
          EMarketplaceUserRole.MARKETING,
          EMarketplaceUserRole.OWNER,
          EMarketplaceUserRole.SELLER,
        ],
      },
      {
        title: 'Ofertas',
        href: '/offers',
        icon: <MuiIcons.LocalOffer />,
        roles: [
          EMarketplaceUserRole.ATTENDANTS,
          EMarketplaceUserRole.DEVELOPER,
          EMarketplaceUserRole.FINANCIAL,
          EMarketplaceUserRole.MARKETING,
          EMarketplaceUserRole.OWNER,
          EMarketplaceUserRole.SELLER,
        ],
      },
      {
        title: 'Cupons',
        href: '/coupons',
        icon: <MuiIcons.ConfirmationNumber />,
        roles: [
          EMarketplaceUserRole.ATTENDANTS,
          EMarketplaceUserRole.DEVELOPER,
          EMarketplaceUserRole.FINANCIAL,
          EMarketplaceUserRole.MARKETING,
          EMarketplaceUserRole.OWNER,
          EMarketplaceUserRole.SELLER,
        ],
      },
      {
        title: 'Notas Fiscais',
        href: '/tax-invoice',
        icon: <MuiIcons.Receipt />,
        roles: [
          EMarketplaceUserRole.ATTENDANTS,
          EMarketplaceUserRole.DEVELOPER,
          EMarketplaceUserRole.FINANCIAL,
          EMarketplaceUserRole.MARKETING,
          EMarketplaceUserRole.OWNER,
          EMarketplaceUserRole.SELLER,
        ],
        adornmentLeft: (
          <NewFeatureChip className={classes.adornmentLeft} label="NOVO" />
        ),
      },
      {
        title: 'Recibos',
        href: '/sales-receipt',
        icon: <MuiIcons.ReceiptOutlined />,
        roles: [
          EMarketplaceUserRole.ATTENDANTS,
          EMarketplaceUserRole.DEVELOPER,
          EMarketplaceUserRole.FINANCIAL,
          EMarketplaceUserRole.MARKETING,
          EMarketplaceUserRole.OWNER,
          EMarketplaceUserRole.SELLER,
        ],
        disabled: true,
      },
      {
        title: 'Cobranças',
        href: '/charges',
        icon: <MuiIcons.AttachMoney />,
        roles: [
          EMarketplaceUserRole.ATTENDANTS,
          EMarketplaceUserRole.DEVELOPER,
          EMarketplaceUserRole.FINANCIAL,
          EMarketplaceUserRole.MARKETING,
          EMarketplaceUserRole.OWNER,
          EMarketplaceUserRole.SELLER,
        ],
        adornmentLeft: (
          <NewFeatureChip className={classes.adornmentLeft} label="NOVO" />
        ),
      },
      {
        title: 'Webhooks',
        href: '/webhooks',
        icon: <MuiIcons.Http />,
        roles: [EMarketplaceUserRole.DEVELOPER, EMarketplaceUserRole.OWNER],
        disabled: true,
      },
      {
        title: 'Conta Bancária',
        href: '/bank-account',
        icon: <MuiIcons.AccountBalance />,
        roles: [EMarketplaceUserRole.OWNER, EMarketplaceUserRole.FINANCIAL],
      },
      {
        title: 'Usuários',
        href: '/users',
        icon: <MuiIcons.SupervisorAccountRounded />,
        roles: [EMarketplaceUserRole.OWNER],
        adornmentLeft: (
          <NewFeatureChip className={classes.adornmentLeft} label="NOVO" />
        ),
      },
      {
        title: 'Minha conta',
        href: '/account',
        icon: <MuiIcons.AccountBox />,
        roles: [
          EMarketplaceUserRole.OWNER,
          EMarketplaceUserRole.DEVELOPER,
          EMarketplaceUserRole.MARKETING,
          EMarketplaceUserRole.FINANCIAL,
          EMarketplaceUserRole.SELLER,
          EMarketplaceUserRole.ATTENDANTS,
        ],
        disabled: !!marketplaceUser,
        adornmentLeft: !!marketplaceUser && (
          <ComingSoonFeatureChip className={classes.adornmentLeft} />
        ),
      },
      {
        title: 'Configurações',
        href: '/settings',
        icon: <MuiIcons.Settings />,
        roles: [
          EMarketplaceUserRole.OWNER,
          EMarketplaceUserRole.DEVELOPER,
          EMarketplaceUserRole.MARKETING,
          EMarketplaceUserRole.FINANCIAL,
          EMarketplaceUserRole.SELLER,
          EMarketplaceUserRole.ATTENDANTS,
        ],
        disabled: !!marketplaceUser,
      },
    ],
    [classes.adornmentLeft, marketplaceUser]
  );

  const filteredPages = React.useMemo(
    () =>
      marketplaceUser
        ? pages.filter(page =>
            page.roles.some(role => marketplaceUser.roles.includes(role))
          )
        : pages,
    [marketplaceUser, pages]
  );

  const handleChargeOpen = event => {
    onClose(event);
    history.push('/charge');
  };

  return (
    <Mui.SwipeableDrawer
      anchor="left"
      classes={{
        paper: classes.drawer,
        paperAnchorDockedLeft: classes.paperAnchorDockedLeft,
      }}
      disableBackdropTransition={!iOS}
      disableDiscovery={iOS}
      hysteresis={0.4}
      onClose={onClose}
      onOpen={onOpen}
      open={open}
      transitionDuration={800}
      variant={variant}
    >
      <div {...rest} className={clsx(classes.root, className)}>
        <Profile user={user} />
        <Mui.Divider className={classes.divider} />
        <Mui.Tooltip title="Em manutenção">
          <div>
            <Mui.Button
              className={classes.buttonEmitPayment}
              color="inherit"
              disabled
              fullWidth
              onClick={handleChargeOpen}
              variant="contained"
            >
              Emitir cobrança
            </Mui.Button>
          </div>
        </Mui.Tooltip>
        <Mui.Divider className={classes.divider} />
        <SidebarNav
          className={classes.nav}
          history={history}
          onClose={onClose}
          pages={filteredPages}
        />
      </div>
    </Mui.SwipeableDrawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
};

export default Sidebar;
