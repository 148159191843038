import React, { useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import {
  setBankAccountState,
  setBankAccount,
  setDialogClose,
  setSnackbarOpen,
  setSnackbarMessage,
  setSnackbarType,
} from 'store/actions';

import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
  Grid,
  DialogActions,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/styles';

import { Close as CloseIcon } from '@material-ui/icons';
import { api } from 'services/api';

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
    paddingRight: '0px !important',
    backgroundColor: theme.palette.error.light,
  },
  title: {
    flex: 1,
    color: '#fff',
  },
  container: {
    padding: theme.spacing(2),
    width: 450,
    maxWidth: '100%',
  },
}));

const BankAccountDeleteDialog = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const { bank_account_id, routing_number, account_number, bank_name } =
    useSelector(state => state.bankAccountState.bank_account);

  const [buttonDisable, setButtonDisable] = useState(true);

  useEffect(() => {
    if (bank_account_id) {
      setButtonDisable(false);
    }
  }, [bank_account_id]);

  const handleDialogClose = () => dispatch(setDialogClose());

  const handleBankAccountDelete = async bank_account_id => {
    dispatch(setSnackbarType('info'));
    dispatch(setSnackbarMessage('Processando...'));
    dispatch(setSnackbarOpen());

    async function deleteBankAccount(bank_account_id) {
      try {
        const token = localStorage.getItem('token');

        const response = await api.delete(`/bank_account/${bank_account_id}/`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data) {
          dispatch(setBankAccountState('get'));
          dispatch(setBankAccount({}));
          dispatch(setDialogClose());
          dispatch(setSnackbarType('success'));
          dispatch(setSnackbarMessage('Conta bancária deletada com sucesso !'));
          dispatch(setSnackbarOpen());
        }
      } catch (error) {
        dispatch(setDialogClose());
        dispatch(setSnackbarType('error'));
        dispatch(
          setSnackbarMessage(
            'Não foi possível deletar a conta bancária, tente novamente mais tarde !'
          )
        );
        dispatch(setSnackbarOpen());
        console.error('Não foi possível deletar está conta bancária', error);
      }
    }

    deleteBankAccount(bank_account_id);
  };

  return (
    <React.Fragment>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Typography className={classes.title} variant="h5">
            Confirmar ação
          </Typography>
          <IconButton
            aria-label="Fechar"
            color="inherit"
            edge="start"
            onClick={handleDialogClose}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Grid className={classes.container} container>
        <Typography variant="body1">
          Você deseja realmente excluir está conta bancária?
        </Typography>
        <Typography variant="body1">
          Agência: {routing_number}, Conta: {account_number}, Banco: {bank_name}
        </Typography>
        <Typography variant="body2">
          Lembrando que essas ações não podem ser revertidas!
        </Typography>
      </Grid>
      <DialogActions>
        <Button color="secondary" onClick={handleDialogClose}>
          Cancelar
        </Button>
        <Button
          color="secondary"
          disabled={buttonDisable}
          onClick={() => handleBankAccountDelete(bank_account_id)}
        >
          OK
        </Button>
      </DialogActions>
    </React.Fragment>
  );
};

export default BankAccountDeleteDialog;
