import React from 'react';
import * as ReactRedux from 'react-redux';
import { validate } from 'validate.js';

import * as PlanHooks from 'hooks/usePlan';
import type { IPlan } from 'types/entities';
import { NumberUtils } from 'helpers';
import { setDialogClose, setDialogType, setPlan } from 'store/actions';

interface IFormState {
  disabled: boolean;
  isValid: boolean;
  values: {
    name: string;
    frequency: IPlan['frequency'];
    interval: string;
    amount: number;
    description: string;
    duration: string;
    metadata: Record<string, string | number> | null;
  };
  errors: {
    name?: string;
    frequency?: string;
    interval?: string;
    amount?: string;
    description?: string;
    duration?: string;
    metadata?: string;
  };
  touched: {
    name?: boolean;
    frequency?: boolean;
    interval?: boolean;
    amount?: boolean;
    description?: boolean;
    duration?: boolean;
    metadata?: boolean;
  };
}

const schema = {
  name: {
    presence: {
      allowEmpty: false,
      message: '^Nome é obrigatório',
    },
  },
};

export function usePlanEditDialog() {
  const dispatch = ReactRedux.useDispatch();

  const { state, service } = PlanHooks.usePlan();

  const { plan, state: planState } = state;

  const formStateInitialValue = React.useMemo(
    () => ({
      disabled: false,
      isValid: false,
      values: {
        name: plan?.name || '',
        frequency: plan?.frequency || 'monthly',
        interval: plan?.interval.toString() || '1',
        amount: (plan?.amount || 0) / 100,
        description: plan?.description || '',
        duration: plan?.duration.toString() || '1',
        metadata: null,
      },
      errors: {},
      touched: {
        amount: true,
      },
    }),
    [plan]
  );

  const [formState, setFormState] = React.useState<IFormState>(
    formStateInitialValue
  );

  const handleFormChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.persist();

      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          [event.target.name]: event.target.value,
        },
        touched: {
          ...formState.touched,
          [event.target.name]: true,
        },
      }));
    },
    []
  );

  const handleMetadataChange = React.useCallback(
    (metadata: Record<string, string | number> | null) => {
      setFormState(prevState => ({
        ...prevState,
        values: {
          ...prevState.values,
          metadata,
        },
      }));
    },
    []
  );

  const hasError = React.useCallback(
    (field: keyof IFormState['values']) => {
      return !!(formState.touched[field] && formState.errors[field]);
    },
    [formState.errors, formState.touched]
  );

  function handleInputFocus(inputName: keyof IFormState['values']) {
    const inputElement = document.querySelector(
      `#${inputName}`
    ) as HTMLInputElement;
    inputElement.focus();
  }

  const handleTransformData = React.useCallback(() => {
    return {
      ...formState.values,
      name: formState.values.name.trim(),
      description: formState.values.description.trim() || undefined,
      duration: Number(formState.values.duration),
      interval: Number(formState.values.interval),
      amount: NumberUtils.toPrecision(formState.values.amount * 100, 0),
    };
  }, [formState.values]);

  const handleSubmit = React.useCallback(async () => {
    setFormState(prevState => ({
      ...prevState,
      errors: {},
    }));

    const {
      amount,
      duration,
      frequency,
      interval,
      name,
      description,
      metadata,
    } = handleTransformData();

    if (name === '') {
      setFormState(formState => ({
        ...formState,
        errors: {
          ...formState.errors,
          name: 'Nome é obrigatório',
        },
      }));

      handleInputFocus('name');
      return;
    }

    if (amount <= 0) {
      setFormState(formState => ({
        ...formState,
        errors: {
          ...formState.errors,
          amount: 'Valor deve ser maior que R$ 0,00',
        },
      }));

      handleInputFocus('amount');
      return;
    }

    if (Number(duration) <= 0) {
      setFormState(formState => ({
        ...formState,
        errors: {
          ...formState.errors,
          duration: 'Duração deve ser maior 0',
        },
      }));

      handleInputFocus('duration');
      return;
    }

    if (Number(interval) <= 0) {
      setFormState(formState => ({
        ...formState,
        errors: {
          ...formState.errors,
          interval: 'Intervalo deve ser maior 0',
        },
      }));

      handleInputFocus('interval');
      return;
    }

    if (planState === 'create') {
      await service.create({
        name,
        description,
        amount,
        duration,
        frequency: frequency as IPlan['frequency'],
        interval,
        metadata,
      });
    }

    if (planState === 'edit') {
      await service.edit({
        id: plan!.id,
        name,
        description,
        amount,
        duration,
        frequency: frequency as IPlan['frequency'],
        interval,
        metadata,
      });
    }
  }, [handleTransformData, plan, planState, service]);

  const handleDialogClose = React.useCallback(() => {
    dispatch([setPlan(null), setDialogClose(), setDialogType(null)]);
  }, [dispatch]);

  React.useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: !errors,
      errors: errors || {},
    }));
  }, [formState.values]);

  return React.useMemo(
    () => ({
      formState,
      plan,
      planState,
      handleDialogClose,
      handleFormChange,
      handleMetadataChange,
      handleSubmit,
      hasError,
    }),
    [
      formState,
      plan,
      planState,
      handleDialogClose,
      handleFormChange,
      handleMetadataChange,
      handleSubmit,
      hasError,
    ]
  );
}
