import React from 'react';
import * as ReactRedux from 'react-redux';

import type { RootState } from 'types/redux/redux.types';

import useProductService from './useProduct.service';
import useProductUI from './useProduct.ui';

function useProduct() {
  const service = useProductService();
  const ui = useProductUI();
  const state = ReactRedux.useSelector(
    (state: RootState) => state.productState
  );

  return React.useMemo(
    () => ({
      service,
      ui,
      state,
    }),
    [service, state, ui]
  );
}

export default useProduct;
