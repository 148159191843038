import React from 'react';

import clsx from 'clsx';

import moment from 'moment';

import PropTypes from 'prop-types';

import { getDisplayMoney } from 'helpers';

import { makeStyles } from '@material-ui/styles';

import { Card, CardContent, Grid, Typography } from '@material-ui/core';

import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.primary.light,
  },
  cardContent: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  colorWhite: {
    color: '#ffffff',
  },
}));

const NextPayment = props => {
  const {
    // eslint-disable-next-line react/prop-types
    amount,
    date,
    stateRequest,
  } = props;

  const classes = useStyles();

  return (
    <Card className={clsx(classes.root)}>
      <CardContent className={classes.cardContent}>
        <Grid alignItems="center" container direction="column" justify="center">
          {stateRequest === 'get' ? (
            <Skeleton
              animation="pulse"
              height={32}
              variant="text"
              width="50%"
            />
          ) : (
            <Typography
              align="center"
              className={clsx(classes.colorWhite)}
              color="textPrimary"
              gutterBottom={!!date}
              variant="h6"
            >
              {date
                ? `Próximo pagamento em ${moment(date).format('DD/MM/YYYY')}`
                : 'Não há data para um próximo pagamento'}
            </Typography>
          )}
          {stateRequest === 'get' ? (
            <Skeleton
              animation="pulse"
              height={32}
              variant="text"
              width="40%"
            />
          ) : (
            date && (
              <Typography
                className={clsx(classes.colorWhite)}
                color="textPrimary"
                variant="h5"
              >
                {date && getDisplayMoney(amount / 100)}
              </Typography>
            )
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

NextPayment.propTypes = {
  className: PropTypes.string,
  date: PropTypes.string,
  stateRequest: PropTypes.string.isRequired,
};

export default NextPayment;
