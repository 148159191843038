import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import {
  setCurrentBalance,
  setCustomerDifferenceValue,
  setDashboardState,
  setExpectedBilling,
  setSales,
  setSalesDifferenceValue,
  setSalesFailed,
  setSalesPending,
  setSalesRefunded,
  setSalesSucceeded,
  setTotalCustomers,
  setTotalSales,
} from 'store/actions';

import { Grid } from '@material-ui/core';

import {
  CurrentBilling,
  ExpectedBilling,
  LatestSales,
  Sales,
  SalesByStatus,
  Toolbar,
  TotalCustomers,
} from './components';

import { makeStyles } from '@material-ui/styles';
import { useAuthContext } from 'hooks/useAuthContext.hook';
import { api } from 'services/api';
import { usePrevious } from '../../hooks/usePrevious';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
  },
}));

const filterDisplay = {
  year: 'Anual',
  month: 'Mensal',
  week: 'Semanal',
  sinceStart: 'Desde o início',
};

const filterLegend = {
  year: 'ano',
  month: 'mês',
  week: 'semana',
  sinceStart: 'Desde o início',
};

const Dashboard = ({ history }) => {
  const classes = useStyles();
  const { marketplaceUser } = useAuthContext();

  const dispatch = useDispatch();

  const {
    state: dashboardState,
    total_sales,
    total_customers,
    customer_difference_value,
    expected_billing,
    current_balance,
    sales,
    sales_difference_value,
    sales_succeeded,
    sales_pending,
    sales_failed,
    sales_refunded,
    filter,
  } = useSelector(state => state.dashboardState);

  const previousDashboardState = usePrevious(dashboardState);

  const getDashboardData = React.useCallback(
    async withoutCache => {
      try {
        dispatch(setDashboardState('get'));
        const token = localStorage.getItem('token');
        const response = await api.get('/seller/dashboard/', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            filter,
            cache: withoutCache && 'no-cache',
          },
        });

        if (response.data) {
          dispatch(setDashboardState('success'));
          dispatch(setCurrentBalance(response.data.current_balance));
          dispatch(
            setCustomerDifferenceValue(response.data.customer_difference_value)
          );
          dispatch(setExpectedBilling(response.data.expected_billing));
          dispatch(setSales(response.data.sales));
          dispatch(
            setSalesDifferenceValue(response.data.sales_difference_value)
          );
          dispatch(setSalesFailed(response.data.sales_failed));
          dispatch(setSalesPending(response.data.sales_pending));
          dispatch(setSalesRefunded(response.data.sales_refunded));
          dispatch(setSalesSucceeded(response.data.sales_succeeded));
          dispatch(setTotalCustomers(response.data.total_customers));
          dispatch(setTotalSales(response.data.total_sales));
        }
      } catch (error) {
        console.log(error);
      }
    },
    [dispatch, filter]
  );

  useEffect(() => {
    if (
      dashboardState === 'get' &&
      sales.length === 0 &&
      previousDashboardState !== 'success'
    ) {
      getDashboardData();
    }
  }, [dashboardState, getDashboardData, previousDashboardState, sales.length]);

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Toolbar
          filter={filter}
          filterDisplay={filterDisplay}
          getDashboardData={getDashboardData}
        />
        <Grid item lg={3} sm={6} xl={3} xs={12}>
          <Sales
            dashboardState={dashboardState}
            difference_value={sales_difference_value}
            filterLegend={filterLegend[filter]}
            total_sales={total_sales}
          />
        </Grid>
        <Grid item lg={3} sm={6} xl={3} xs={12}>
          <TotalCustomers
            dashboardState={dashboardState}
            difference_value={customer_difference_value}
            filterLegend={filterLegend[filter]}
            total_customers={total_customers}
          />
        </Grid>
        {(!marketplaceUser ||
          marketplaceUser.roles.includes('owner') ||
          marketplaceUser.roles.includes('financial')) && (
          <>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <ExpectedBilling
                dashboardState={dashboardState}
                expected_billing={expected_billing}
                filterLegend={filterLegend[filter]}
              />
            </Grid>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <CurrentBilling
                current_balance={current_balance}
                dashboardState={dashboardState}
                filterLegend={filterLegend[filter]}
              />
            </Grid>
          </>
        )}

        <Grid item lg={8} md={12} xl={9} xs={12}>
          <LatestSales
            dashboardState={dashboardState}
            filter={filter}
            history={history}
            sales={sales}
          />
        </Grid>
        <Grid item lg={4} md={6} xl={3} xs={12}>
          <SalesByStatus
            dashboardState={dashboardState}
            filter={filter}
            sales_failed={sales_failed}
            sales_pending={sales_pending}
            sales_refunded={sales_refunded}
            sales_succeeded={sales_succeeded}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default Dashboard;
