import React, { useState, useEffect, useCallback } from 'react';

import { useParams } from 'react-router-dom';

import { useDispatch } from 'react-redux';

import validate from 'validate.js';

import { api } from 'services/api';

import { useQueryParams } from 'hooks/useQueryParams';
import history from 'hooks/useHistory';

import {
  creditCardExpiryMask,
  creditCardNumberMask,
  creditCardSecurityCodeMask,
} from 'helpers';

import {
  setSnackbarMessage,
  setSnackbarOpen,
  setSnackbarType,
} from 'store/actions';

import {
  Typography,
  Grid,
  Button,
  TextField,
  Link,
  useTheme,
  useMediaQuery,
  IconButton,
} from '@material-ui/core';

import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';

import Cards from 'react-credit-cards';

import { InputMask } from 'components';

import PaymentFpass from 'assets/images/change_credit_card.svg';

import 'react-credit-cards/es/styles-compiled.css';

import { makeStyles } from '@material-ui/styles';

const schema = {
  holder_name: {
    presence: {
      allowEmpty: false,
      message: '^Preencha o nome do titular',
    },
    format: {
      pattern: /^[A-ZÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ]+( [A-ZÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ]+)+$/,
      message: '^Preencha o nome do titular corretamente',
    },
  },
  card_number: {
    presence: {
      allowEmpty: false,
      message: '^Preencha o número do cartão',
    },
    length: value => {
      if (value) {
        value = value.replace(/_/g, '').replace(/ /g, '');

        if (value.length !== 16) {
          return {
            is: 16,
            message: '^Preencha corretamente os números do cartão',
          };
        }

        return null;
      }

      return null;
    },
  },
  expiry_date: {
    presence: {
      allowEmpty: false,
      message: '^Preencha a validade do cartão',
    },
    length: value => {
      if (value) {
        value = value.replace(/_/g, '').replace(/\//g, '');

        if (value.length !== 4) {
          return {
            is: 4,
            message: '^Deve conter mês e o ano da expiração',
          };
        }

        return null;
      }

      return null;
    },
  },
  security_code: {
    presence: {
      allowEmpty: false,
      message: '^Preencha o código de segurança',
    },
    length: value => {
      if (value) {
        value = value.replace(/_/g, '');

        if (value.length !== 3 && value.length !== 4) {
          return {
            is: 4,
            message: '^Preencha corretamente o código de segurança',
          };
        }

        return null;
      }

      return null;
    },
  },
};

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 0,
    },
  },
  containerLeft: {
    width: '100%',
    backgroundColor: props => props.backgroundColor,
    padding: theme.spacing(2),
    justifyContent: 'center',
    alignItems: 'center',
  },
  containerRight: {
    width: '100%',
    backgroundColor: '#fdfdfd',
    padding: theme.spacing(2),
    justifyContent: 'center',
    alignItems: 'center',
  },
  contentItemFpass: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '180px',
    },
    [theme.breakpoints.up('sm')]: {
      maxWidth: '220px',
    },
  },
  contentItemPayment: {
    marginBottom: theme.spacing(3),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(2),
      maxWidth: '530px',
    },
  },
  imgFpass: {
    width: '100%',
    height: 'auto',
    display: 'block',
  },
  containerLogo: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  logo: {
    marginRight: '1rem',
    maxWidth: 50,
    [theme.breakpoints.up('sm')]: {
      maxWidth: 60,
    },
  },
  textLogo: {
    color: '#fff',
    fontSize: 30,
    [theme.breakpoints.up('sm')]: {
      fontSize: 36,
    },
  },
  welcomeText: {
    color: '#fff',
    fontSize: '22px',
    fontWeight: 'bold',
    lineHeight: '1.3',
    textAlign: 'center',
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      fontSize: '36px',
      maxWidth: '500px',
    },
  },
  imgPayment: {
    width: '100%',
    height: 'auto',
    display: 'block',
  },
  textTitle: {
    color: '#fff',
    fontSize: '22px',
    fontWeight: 'bold',
    lineHeight: '1.3',
    textAlign: 'center',
    marginTop: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      fontSize: '36px',
      maxWidth: '500px',
    },
  },
  iconButtonExpandMoreIcon: {
    marginTop: theme.spacing(2),
    color: '#fff',
  },
  expandMoreIcon: {
    color: '#fff',
  },
  containerCreditCard: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  contentCreditCard: {
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '0px !important',
      paddingRight: '0px !important',
    },
    marginBottom: theme.spacing(3),
  },
  textField: {
    color: props => `${props.primaryColor} !important`,
  },
  inputLabelRoot: {
    color: theme.palette.divider,
  },
  inputLabelFocused: {
    color: props => `${props.primaryColor} !important`,
    fontWeight: 'bold',
  },
  input: {
    color: theme.palette.divider,
    borderColor: props => `${props.primaryColor}`,
    '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: props => `${props.primaryColor} !important`,
    },
    '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: props => props.primaryColor,
    },
    '&.MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(0, 0, 0, 0.38)',
    },
  },
  link: {
    cursor: 'pointer',
    color: props => `${props.primaryColor}`,
  },
  button: {
    backgroundColor: props => `${props.primaryColor}`,
    color: '#fff',
    '&:hover': {
      backgroundColor: props => props.primaryColor,
    },
  },
  containerError: {
    padding: theme.spacing(4),
  },
  contentError: {
    paddingTop: 150,
    textAlign: 'center',
  },
}));

const CustomerWhitelabel = () => {
  const queryParams = useQueryParams();
  const dataQueryParam = queryParams.get('data');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true,
  });

  let metadata;

  if (dataQueryParam) {
    try {
      metadata = JSON.parse(atob(dataQueryParam));
    } catch (error) {
      throw new Error(error);
    }
  }

  const returnUrl = metadata && metadata.theme && metadata.theme.returnUrl;

  const classes = useStyles({
    backgroundColor:
      metadata && metadata.theme && metadata.theme.backgroundColor
        ? metadata.theme.backgroundColor
        : '#282c34',
    primaryColor:
      metadata && metadata.theme && metadata.theme.primaryColor
        ? metadata.theme.primaryColor
        : theme.palette.secondary.light,
  });
  const dispatch = useDispatch();
  const { marketplace_id, id: buyer_id } = useParams();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    errors: {},
    touched: {},
    focus: null,
  });
  const [state, setState] = useState('get');

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: !errors,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleChangeForm = event => {
    event.persist();

    if (event.target.name === 'holder_name') {
      event.target.value = event.target.value.toUpperCase();
    }

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleInputFocus = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      focus: event.target.name,
    }));
  };

  const handleConfirmPayment = useCallback(async () => {
    const { card_number, holder_name, expiry_date, security_code } =
      formState.values;

    const data = {
      card: {
        card_number: card_number.replace(/[^0-9]/g, ''),
        holder_name,
        expiration_month: expiry_date.split('/')[0],
        expiration_year: expiry_date.split('/')[1],
        security_code,
      },
    };

    setState('pending');

    try {
      await api.post(
        `/marketplaces/${marketplace_id}/buyers/${buyer_id}`,
        data
      );
      setState('success');

      dispatch(setSnackbarType('success'));
      dispatch(
        setSnackbarMessage(
          'Novo cartão cadastrado com sucesso! Você está sendo redirecionado em breve...'
        )
      );
      dispatch(setSnackbarOpen());

      setTimeout(() => {
        window.location.href = returnUrl;
      }, 4000);
    } catch (error) {
      setState('failed');
      dispatch(setSnackbarType('error'));
      dispatch(
        setSnackbarMessage(
          'Não foi possível adicionar um novo cartão, tente novamente mais tarde!'
        )
      );
      dispatch(setSnackbarOpen());
    }
  }, [dispatch, formState.values, marketplace_id, buyer_id, returnUrl]);

  const hasError = field =>
    !!(formState.touched[field] && formState.errors[field]);

  if (!dataQueryParam || !metadata || !metadata.customerName) {
    return (
      <div className={classes.containerError}>
        <Grid container justify="center" spacing={4}>
          <Grid item lg={6} xs={12}>
            <div className={classes.contentError}>
              <Typography variant="h1">
                Houve um problema na geração desta página
              </Typography>
              <Typography
                style={{
                  marginTop: 16,
                }}
                variant="subtitle2"
              >
                Tente realizar o procedimento novamente ou envia um e-mail para{' '}
                <Link
                  className={classes.link}
                  href="mailto:contato.recebafacil@gmail.com"
                  underline="always"
                >
                  contato.recebafacil@gmail.com
                </Link>
              </Typography>
              <Button
                color="primary"
                onClick={() => history.goBack()}
                size="large"
                style={{
                  marginTop: 32,
                }}
                variant="contained"
              >
                Voltar
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }

  return (
    <Grid className={classes.root} container>
      <Grid
        className={classes.containerLeft}
        container
        direction="column"
        item
        md={6}
        sm={12}
        wrap="nowrap"
      >
        {metadata.theme ? (
          <Grid className={classes.contentItemFpass} item>
            <img
              alt="Logo"
              className={classes.imgFpass}
              src={metadata.theme.logoUrl}
            />
          </Grid>
        ) : (
          <Grid
            alignItems="center"
            className={classes.containerLogo}
            container
            justify="center"
          >
            <img
              alt="Logo Receba Fácil"
              className={classes.logo}
              src="/images/logos/logo-white.svg"
            />
            <Typography className={classes.textLogo} variant="h3">
              Receba Fácil
            </Typography>
          </Grid>
        )}

        <Grid item>
          <Typography className={classes.welcomeText} variant="h3">
            Olá, {metadata.customerName}
          </Typography>
        </Grid>
        <Grid className={classes.contentItemPayment} item>
          <img
            alt="Payment"
            className={classes.imgPayment}
            src={PaymentFpass}
          />
        </Grid>
        <Typography className={classes.textTitle} variant="h5">
          Altere seus dados de pagamento
        </Typography>
        {isMobile && (
          <Link
            className={classes.iconButtonExpandMoreIcon}
            component={IconButton}
          >
            <ExpandMoreIcon className={classes.expandMoreIcon} />
          </Link>
        )}
      </Grid>
      <Grid
        className={classes.containerRight}
        container
        id="main"
        item
        md={6}
        sm={12}
      >
        <Grid item>
          <Grid
            alignItems="center"
            className={classes.containerCreditCard}
            container
            direction="column"
            spacing={2}
          >
            <Grid className={classes.contentCreditCard} item>
              <Cards
                cvc={formState.values.security_code || ''}
                expiry={formState.values.expiry_date || ''}
                focused={formState.focus || ''}
                name={formState.values.holder_name || ''}
                number={formState.values.card_number || ''}
                placeholders={{
                  name: 'SEU NOME AQUI',
                }}
              />
            </Grid>
            <Grid item md={10} sm={8} xl={8}>
              <form>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      InputLabelProps={{
                        classes: {
                          root: classes.inputLabelRoot,
                          focused: classes.inputLabelFocused,
                        },
                      }}
                      InputProps={{
                        inputComponent: InputMask,
                        classes: {
                          root: classes.input,
                        },
                      }}
                      classes={{
                        root: classes.textField,
                      }}
                      disabled={state === 'pending' || state === 'success'}
                      error={hasError('card_number')}
                      fullWidth
                      helperText={
                        hasError('card_number')
                          ? formState.errors.card_number[0]
                          : ''
                      }
                      id="card_number"
                      inputProps={{
                        mask: creditCardNumberMask,
                      }}
                      label="Número do cartão"
                      name="card_number"
                      onChange={handleChangeForm}
                      onFocus={handleInputFocus}
                      required // eslint-disable-next-line
                      type="tel"
                      value={formState.values.card_number || ''}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      InputLabelProps={{
                        classes: {
                          root: classes.inputLabelRoot,
                          focused: classes.inputLabelFocused,
                        },
                      }}
                      InputProps={{
                        classes: {
                          root: classes.input,
                        },
                      }}
                      classes={{
                        root: classes.textField,
                      }}
                      disabled={state === 'pending' || state === 'success'}
                      error={hasError('holder_name')}
                      fullWidth
                      helperText={
                        hasError('holder_name')
                          ? formState.errors.holder_name[0]
                          : ''
                      }
                      id="holder_name"
                      label="Nome do Titular"
                      name="holder_name"
                      onChange={handleChangeForm}
                      onFocus={handleInputFocus}
                      required
                      value={formState.values.holder_name || ''}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      InputLabelProps={{
                        classes: {
                          root: classes.inputLabelRoot,
                          focused: classes.inputLabelFocused,
                        },
                      }}
                      InputProps={{
                        inputComponent: InputMask,
                        classes: {
                          root: classes.input,
                        },
                      }}
                      classes={{
                        root: classes.textField,
                      }}
                      disabled={state === 'pending' || state === 'success'}
                      error={hasError('expiry_date')}
                      fullWidth
                      helperText={
                        hasError('expiry_date')
                          ? formState.errors.expiry_date[0]
                          : ''
                      }
                      id="expiry_date"
                      inputProps={{
                        mask: creditCardExpiryMask,
                      }}
                      label="Data de expiração"
                      name="expiry_date"
                      onChange={handleChangeForm}
                      onFocus={handleInputFocus}
                      required // eslint-disable-next-line
                      type="tel"
                      value={formState.values.expiry_date || ''}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      InputLabelProps={{
                        classes: {
                          root: classes.inputLabelRoot,
                          focused: classes.inputLabelFocused,
                        },
                      }}
                      InputProps={{
                        inputComponent: InputMask,
                        classes: {
                          root: classes.input,
                        },
                      }}
                      classes={{
                        root: classes.textField,
                      }}
                      disabled={state === 'pending' || state === 'success'}
                      error={hasError('security_code')}
                      fullWidth
                      helperText={
                        hasError('security_code')
                          ? formState.errors.security_code[0]
                          : ''
                      }
                      id="security_code"
                      inputProps={{
                        mask: creditCardSecurityCodeMask,
                      }}
                      label="CVC"
                      name="security_code"
                      onChange={handleChangeForm}
                      onFocus={handleInputFocus}
                      required // eslint-disable-next-line
                      type="tel"
                      value={formState.values.security_code || ''}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      className={classes.button}
                      color="primary"
                      disabled={
                        !formState.isValid ||
                        state === 'pending' ||
                        state === 'success'
                      }
                      fullWidth
                      onClick={handleConfirmPayment}
                      variant="contained"
                    >
                      Adiconar Cartão de crédito
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CustomerWhitelabel;
