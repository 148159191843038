import React from 'react';
import * as ReactRedux from 'react-redux';

import type { IMarketplaceUser } from 'types/entities';
import {
  setDialogFullScreen,
  setDialogMaxWidth,
  setDialogOpen,
  setDialogType,
} from 'store/actions';
import {
  setMarketplaceUser,
  setMarketplaceUserState,
} from 'store/actions/marketplace-user-actions';

function useMarketplaceUserUI() {
  const dispatch = ReactRedux.useDispatch();

  const openToCreate = React.useCallback(() => {
    dispatch([
      setMarketplaceUser(null),
      setMarketplaceUserState('create'),
      setDialogType('createMarketplaceUser'),
      setDialogFullScreen(true),
      setDialogOpen(),
    ]);
  }, [dispatch]);

  const openToEdit = React.useCallback(
    (marketplaceUser: IMarketplaceUser) => {
      dispatch([
        setMarketplaceUser(marketplaceUser),
        setMarketplaceUserState('edit'),
        setDialogType('editMarketplaceUser'),
        setDialogFullScreen(true),
        setDialogOpen(),
      ]);
    },
    [dispatch]
  );

  const openToView = React.useCallback(
    (marketplaceUser: IMarketplaceUser) => {
      dispatch([
        setMarketplaceUser(marketplaceUser),
        setMarketplaceUserState('view'),
        setDialogType('viewMarketplaceUser'),
        setDialogFullScreen(true),
        setDialogOpen(),
      ]);
    },
    [dispatch]
  );

  const openToDelete = React.useCallback(
    (marketplaceUser: IMarketplaceUser) => {
      dispatch([
        setMarketplaceUser(marketplaceUser),
        setMarketplaceUserState('delete'),
        setDialogType('deleteMarketplaceUser'),
        setDialogMaxWidth('sm'),
        setDialogFullScreen(false),
        setDialogOpen(),
      ]);
    },
    [dispatch]
  );

  const refresh = React.useCallback(() => {
    dispatch([setMarketplaceUser(null), setMarketplaceUserState('get')]);
  }, [dispatch]);

  return React.useMemo(
    () => ({
      openToCreate,
      openToDelete,
      openToEdit,
      openToView,
      refresh,
    }),
    [openToCreate, openToDelete, openToEdit, openToView, refresh]
  );
}

export default useMarketplaceUserUI;
