import type { IPaymentTypesConfig } from 'types/entities/checkout';

export class PixUtils {
  public static discountTypeLabelMap: Record<
    IPaymentTypesConfig['pix']['discountType'],
    string
  > = {
    fixed: 'Fixo',
    percentage: 'Porcentagem',
  };

  public static getDiscountTypeLabel(
    discountType: IPaymentTypesConfig['pix']['discountType']
  ): string {
    return this.discountTypeLabelMap[discountType] || '';
  }
}
