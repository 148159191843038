import * as Mui from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

import { SearchInput } from 'components';
import * as MarketplaceUserHooks from 'hooks/use-marketplace-user';

import useStyles from './marketplace-user-toolbar.styles';

type MarketplaceUserToolbarProps = React.HTMLAttributes<HTMLDivElement>;

function MarketplaceUserToolbar(props: MarketplaceUserToolbarProps) {
  const { className, ...rest } = props;

  const classes = useStyles();
  const marketplaceUser = MarketplaceUserHooks.useMarketplaceUser();

  const [query, setQuery] = React.useState('');

  function handleSearch(event: React.ChangeEvent<HTMLInputElement>) {
    const query = event.target.value;

    setQuery(query);
  }

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div className={classes.row}>
        <div className={classes.leftActionsContainer} />
        <span className={classes.spacer} />
        <div className={classes.rightActionsContainer}>
          <Mui.Tooltip title="Em breve">
            <div>
              <Mui.Button
                className={classes.exportButton}
                disabled
                onClick={marketplaceUser.service.report}
              >
                Exportar
              </Mui.Button>
            </div>
          </Mui.Tooltip>
          <Mui.Button
            className={classes.buttonCreate}
            color="primary"
            onClick={marketplaceUser.ui.openToCreate}
            variant="contained"
          >
            Adicionar usuário
          </Mui.Button>
        </div>
      </div>
      <Mui.Tooltip title="Em breve">
        <div className={classes.row}>
          <SearchInput
            className={classes.searchInput}
            disabled
            onChange={handleSearch}
            placeholder="Pesquise um usuário"
            value={query}
          />
        </div>
      </Mui.Tooltip>
    </div>
  );
}

export default MarketplaceUserToolbar;
