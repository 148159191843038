import React, { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import {
  setSalesPlan,
  setSalesPlanState,
  setSnackbarType,
  setSnackbarMessage,
  setSnackbarOpen,
} from 'store/actions';

import { getDisplayMoney } from 'helpers';

import { Link } from 'react-router-dom';

import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
  Typography,
  CircularProgress,
  IconButton,
  Hidden,
  useMediaQuery,
  useTheme,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core';

import { Alert } from '@material-ui/lab';

import { makeStyles } from '@material-ui/styles';

import {
  Refresh as RefreshIcon,
  ExpandMore as ExpandMoreIcon,
  Refresh,
} from '@material-ui/icons';

import EloIcon from 'assets/images/elo.png';
import { api } from 'services/api';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark2,
  },
  containerProgress: {
    marginTop: theme.spacing(2),
    minHeight: 200,
  },
  accordion: {
    backgroundColor: theme.palette.background.dark2,
  },
  marginAuto: {
    margin: 'auto',
  },
  padding: {
    padding: theme.spacing(3),
  },
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
  flexWrap: {
    flexWrap: 'wrap',
  },
  marginLeft: {
    marginLeft: theme.spacing(1),
  },
  marginTop: {
    marginTop: theme.spacing(1),
  },
  paddingVertical: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  containerElo: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
}));

const AccountFeeDetails = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  });
  const dispatch = useDispatch();
  const { state: sales_plan_state, plan } = useSelector(
    state => state.salesPlanState
  );
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (sales_plan_state === 'get') {
      async function getPlan() {
        try {
          const token = localStorage.getItem('token');

          const response = await api.get('/seller/plans/', {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          if (response.data) {
            const plan = response.data;

            plan.sort((a, b) => {
              if (a.number_installments < b.number_installments) {
                return -1;
              }

              if (a.payment_type === 'debit') {
                return -1;
              }

              return 0;
            });

            dispatch([setSalesPlan(plan), setSalesPlanState('success')]);
          }
        } catch (error) {
          console.error('Não foi possível resgatar os planos');
          dispatch([
            setSnackbarType('error'),
            setSnackbarMessage('Não foi possível resgatar os planos.'),
            setSnackbarOpen(),
            setSalesPlanState('failed'),
          ]);
        }
      }

      getPlan();
    }
  }, [sales_plan_state, dispatch]);

  const handleRefresh = () => {
    dispatch([setSalesPlan([]), setSalesPlanState('get')]);
  };

  const handleChangeExpansion = panel => (event, isExpanded) =>
    setExpanded(isExpanded ? panel : false);

  const renderBilletFeeDetails = () => {
    const billet_amount = plan
      .filter(fee_details => fee_details.payment_type === 'boleto')
      .reduce((amount, fee_details) => {
        amount = amount + fee_details.dollar_amount;

        return amount;
      }, 0);

    return (
      <Typography color="primary" variant="h3">
        {getDisplayMoney(billet_amount / 100)}
      </Typography>
    );
  };

  const renderCreditFeeDetails = () => {
    const plan_elo = plan.filter(
      fee_details =>
        fee_details.payment_type !== 'boleto' &&
        fee_details.card_brand === 'Elo' &&
        !fee_details.capture_mode
    );
    const plan_default = plan.filter(
      fee_details =>
        fee_details.payment_type !== 'boleto' &&
        !fee_details.capture_mode &&
        (!fee_details.card_brand
          ? true
          : fee_details.card_brand === 'MasterCard' ||
            fee_details.card_brand === 'Maestro')
    );

    return plan_elo.map((fee_details, index) => {
      return (
        <Accordion
          classes={{
            root: classes.accordion,
          }}
          elevation={2}
          expanded={expanded === `${fee_details.payment_type}${index}`}
          key={fee_details.type}
          onChange={handleChangeExpansion(
            `${fee_details.payment_type}${index}`
          )}
        >
          <AccordionSummary
            aria-controls={`${fee_details.payment_type}${index}-content`}
            expandIcon={<ExpandMoreIcon />}
            id={`${fee_details.payment_type}${index}-header`}
          >
            <Typography variant="body1">
              {`${getDisplayPaymentType(
                fee_details.payment_type,
                fee_details.number_installments
              )}`}
            </Typography>
          </AccordionSummary>
          <Divider />
          <AccordionDetails className={classes.flexWrap}>
            <Grid container justify="flex-end">
              <Typography variant="h6">
                <strong>Taxa</strong>
              </Typography>
            </Grid>
            <Grid container>
              <Grid className={classes.containerElo} item xs={6}>
                <img
                  alt="Elo"
                  src={EloIcon}
                  style={{
                    width: 32,
                  }}
                />
                <Typography
                  className={classes.marginLeft}
                  component="div"
                  variant="body1"
                >
                  {fee_details.card_brand}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Grid container justify="flex-end" wrap="nowrap">
                  <Typography component="div" variant="body1">
                    {`${(fee_details.percent_amount / 100).toFixed(2)}%`}
                  </Typography>
                  <Typography
                    className={classes.marginLeft}
                    component="div"
                    variant="body1"
                  >
                    {getDisplayMoney(fee_details.dollar_amount / 100)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Hidden smUp>
              <Grid className={classes.paddingVertical} container>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </Grid>
            </Hidden>
            <Grid container>
              <Grid item xs={6}>
                <Typography variant="body1">
                  Bandeiras de contrato padrão
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Grid container justify="flex-end" wrap="nowrap">
                  <Typography component="div" variant="body1">
                    {`${(plan_default[index].percent_amount / 100).toFixed(
                      2
                    )}%`}
                  </Typography>
                  <Typography
                    className={classes.marginLeft}
                    component="div"
                    variant="body1"
                  >
                    {getDisplayMoney(plan_default[index].dollar_amount / 100)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      );
    });
  };

  const getDisplayPaymentType = (payment_type, number_installments) => {
    if (payment_type === 'boleto') {
      return 'Boleto Bancário';
    } else if (payment_type === 'credit') {
      if (number_installments === 1) {
        return 'Crédito à vista';
      } else {
        return `Crédito parcelado ${number_installments}x`;
      }
    } else if (payment_type === 'debit') {
      return 'Débito';
    } else {
      return '';
    }
  };

  const renderData = () => {
    if (sales_plan_state === 'get') {
      return (
        <Grid
          alignItems="center"
          className={classes.containerProgress}
          container
          justify="center"
          spacing={2}
        >
          <CircularProgress />
        </Grid>
      );
    } else if (plan.length > 0) {
      return (
        <Grid container>
          <Grid className={classes.padding} item md={5} xs={12}>
            <Typography variant="h4">Taxa de Transferência</Typography>
            <Typography color="primary" variant="h3">
              R$ 0,00
            </Typography>
            <Typography color="textSecondary" variant="h6">
              Por transferência bancária.
            </Typography>
            <Alert
              className={classes.marginTop}
              severity="info"
              variant="standard"
            >
              <Typography variant="inherit">
                Hoje a transferência para sua conta bancária acontece
                automaticamente todos os dias desde que haja uma cadastrada{' '}
                <Link to="/bank-account">(cadastre aqui)</Link>. Estamos
                trabalhando para que você possa personalizar!
              </Typography>
            </Alert>
          </Grid>
          <Grid item md={1} xs={12}>
            <Divider
              className={classes.marginAuto}
              orientation={isDesktop ? 'vertical' : 'horizontal'}
            />
          </Grid>
          <Grid className={classes.padding} item md={5} xs={12}>
            <Typography variant="h4">Taxa de Boletos</Typography>
            {renderBilletFeeDetails()}
            <Typography color="textSecondary" variant="h6">
              Por boleto pago.
            </Typography>
            <Typography className={classes.marginTop} variant="body2">
              Recebimento em até 1 dia útil
            </Typography>
            <Typography variant="body2">Não cobramos por:</Typography>
            <Typography variant="body2">• Emissão de boletos</Typography>
            <Typography variant="body2">• Emissão de segunda via</Typography>
            <Typography variant="body2">• Cancelamentos</Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider className={classes.marginAuto} orientation="horizontal" />
          </Grid>
          <Grid className={classes.padding} item xs={12}>
            <Typography className={classes.marginBottom} variant="h4">
              Taxas de Cartão
            </Typography>
            {renderCreditFeeDetails()}
          </Grid>
        </Grid>
      );
    } else if (sales_plan_state === 'failed') {
      return (
        <Grid
          alignItems="center"
          className={classes.containerProgress}
          container
          direction="column"
          justify="center"
          spacing={2}
        >
          <Typography variant="body2">
            Não foi possível se conectar ao servidor. Tente novamente...
          </Typography>
          <IconButton onClick={handleRefresh}>
            <RefreshIcon />
          </IconButton>
        </Grid>
      );
    } else {
      return (
        <Grid
          alignItems="center"
          className={classes.containerProgress}
          container
          direction="column"
          justify="center"
          spacing={2}
        >
          <Typography variant="body2">
            Não foi possível se conectar ao servidor. Tente novamente...
          </Typography>
        </Grid>
      );
    }
  };

  return (
    <Card className={classes.root}>
      <CardHeader
        action={
          <IconButton onClick={handleRefresh}>
            <Refresh />
          </IconButton>
        }
        subheader="As taxas são cobradas apenas em vendas aprovadas"
        title="Taxas"
      />
      <Divider />
      <CardContent>
        <Grid container spacing={2}>
          {renderData()}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default AccountFeeDetails;
