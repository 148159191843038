import * as Mui from '@material-ui/core';

const useStyles = Mui.makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(3),
    gap: theme.spacing(2),
    flexWrap: 'wrap',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
}));

export default useStyles;
