export function setFutureTransfersState(state) {
  return {
    type: 'FUTURE_TRANSFERS_STATE_CHANGED',
    payload: state,
  };
}

export function setFutureTransfersList(future_transfers) {
  return {
    type: 'FUTURE_TRANSFERS_LIST_CHANGED',
    payload: future_transfers,
  };
}
