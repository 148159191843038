const INITIAL_STATE = {
  state: 'get',
  bank_account: {},
  bank_accounts_list: [],
  bank_accounts_list_filtered: [],
  bank_accounts_list_selected: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'BANK_ACCOUNT_STATE_CHANGED':
      return {
        ...state,
        state: action.payload,
      };
    case 'BANK_ACCOUNT_CHANGED':
      return {
        ...state,
        bank_account: action.payload,
      };
    case 'BANK_ACCOUNTS_LIST_CHANGED':
      return {
        ...state,
        bank_accounts_list: action.payload,
      };
    case 'BANK_ACCOUNTS_LIST_FILTERED_CHANGED':
      return {
        ...state,
        bank_accounts_list_filtered: action.payload,
      };
    case 'BANK_ACCOUNTS_LIST_SELECTED_CHANGED':
      return {
        ...state,
        bank_accounts_list_selected: action.payload,
      };
    default:
      return state;
  }
};
