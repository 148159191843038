import palette from 'theme/palette';

export default {
  responsive: true,
  backgroundColor: palette.primary.main,
  maintainAspectRatio: false,
  animation: {
    duration: 2500,
  },
  legend: {
    display: false,
  },
  cornerRadius: 20,
  datasets: {
    line: {
      borderColor: palette.complementary.grey,
    },
  },
  tooltips: {
    enabled: true,
    mode: 'index',
    intersect: false,
    borderWidth: 2,
    borderColor: palette.primary.main,
    backgroundColor: palette.background.dark,
    titleFontColor: palette.text.primary,
    bodyFontColor: palette.text.primary,
    footerFontColor: palette.text.primary,
  },
  layout: {
    padding: 0,
  },
  scales: {
    xAxes: [
      {
        barThickness: 10,
        maxBarThickness: 10,
        barPercentage: 0.7,
        categoryPercentage: 0.7,
        ticks: {
          fontColor: palette.text.primary,
        },
        gridLines: {
          display: false,
          drawBorder: false,
        },
      },
    ],
    yAxes: [
      {
        ticks: {
          fontColor: palette.text.primary,
          beginAtZero: true,
          min: 0,
        },
        gridLines: {
          borderDash: [2],
          borderDashOffset: [2],
          color: palette.divider,
          drawBorder: false,
          zeroLineBorderDash: [2],
          zeroLineBorderDashOffset: [2],
          zeroLineColor: palette.divider,
        },
      },
    ],
  },
};
