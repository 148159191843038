/* eslint-disable react/no-multi-comp */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setSnackbarClose } from 'store/actions/SnackbarActions';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { IconButton, Snackbar, SnackbarContent } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import ErrorIcon from '@material-ui/icons/Error';
import { green, amber } from '@material-ui/core/colors';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
  inherit: () => <span />,
};

const useStyles = makeStyles(theme => ({
  success: {
    backgroundColor: green[600],
  },
  warning: {
    backgroundColor: amber[700],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  content: {
    margin: theme.spacing(1),
    flexWrap: 'nowrap',
  },
  message: {
    display: 'flex',
    color: theme.palette.text.primary,
    alignItems: 'center',
  },
  icon: {
    fontSize: 20,
    opacity: 0.9,
    marginRight: theme.spacing(1),
    color: theme.palette.text.primary,
  },
  iconClose: {
    fontSize: 20,
    color: theme.palette.text.primary,
  },
}));

function FlexSnackbar() {
  const classes = useStyles();

  const { open, type, anchorOrigin, autoHideDuration, message } = useSelector(
    state => state.snackbarState
  );
  const dispatch = useDispatch();

  const Icon = variantIcon[type];

  return (
    <Snackbar
      anchorOrigin={anchorOrigin}
      autoHideDuration={autoHideDuration}
      onClose={() => dispatch(setSnackbarClose())}
      open={open}
    >
      <SnackbarContent
        action={[
          <IconButton
            aria-label="fechar"
            color="inherit"
            key="close"
            onClick={() => dispatch(setSnackbarClose())}
          >
            <CloseIcon className={classes.iconClose} />
          </IconButton>,
        ]}
        aria-describedby={message}
        className={clsx(classes[type], classes.content)}
        message={
          <span className={classes.message} id="snackbar-content">
            <Icon className={classes.icon} />
            {message}
          </span>
        }
      />
    </Snackbar>
  );
}

export default FlexSnackbar;
