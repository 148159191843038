import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none',
    backgroundColor: theme.palette.background.dark,
  },
  containerLogo: {
    display: 'flex',
    alignItems: 'center',
    width: '200px',
  },
  logo: {
    marginRight: '1rem',
    maxWidth: '100px',
  },
  textLogo: {
    color: '#fff',
  },
}));

const Topbar = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
      position="fixed"
    >
      <Toolbar>
        <RouterLink className={classes.containerLogo} to="/">
          <img
            alt="Logo"
            className={classes.logo}
            src="/images/logos/logo.svg"
          />
        </RouterLink>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
};

export default Topbar;
