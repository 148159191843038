import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';
import React from 'react';

import { Metadata } from 'components/utils/Metadata';

import { useTaxInvoiceConfigDialog } from './tax-invoice-config-dialog.hook';
import useStyles from './tax-invoice-config-dialog.styles';

import { ETaxInvoiceConfigProvider } from 'types/entities/tax-invoice-config';
import InputMask from 'components/InputMask';
import { postalCodeMask } from 'helpers/inputMask';

function TaxInvoiceConfigDialog() {
  const classes = useStyles();

  const {
    taxInvoiceConfig,
    formState,
    handleDialogClose,
    handleFormChange,
    handleMetadataChange,
    handleSubmit,
    hasError,
  } = useTaxInvoiceConfigDialog();

  return (
    <React.Fragment>
      <Mui.AppBar className={classes.appBar}>
        <Mui.Toolbar>
          <Mui.IconButton
            aria-label="Fechar"
            color="inherit"
            edge="start"
            onClick={handleDialogClose}
          >
            <MuiIcons.Close />
          </Mui.IconButton>
          <Mui.Typography className={classes.appBarTitle} variant="h5">
            Configurar nota fiscal
          </Mui.Typography>
          <Mui.Button
            color="inherit"
            disabled={!formState.isValid || formState.disabled}
            onClick={handleSubmit}
          >
            Salvar
          </Mui.Button>
        </Mui.Toolbar>
      </Mui.AppBar>
      <Mui.Grid className={classes.container} container spacing={2}>
        <Mui.Grid item xs={12}>
          <Mui.Typography variant="h4">
            Dados da configuração da nota fiscal
          </Mui.Typography>
        </Mui.Grid>

        <Mui.Grid item md={4} sm={6} xs={12}>
          <Mui.TextField
            InputLabelProps={{
              classes: {
                disabled: classes.selectDisabled,
              },
            }}
            SelectProps={{
              classes: {
                icon: classes.selectIcon,
                disabled: classes.selectDisabled,
              },
              MenuProps: {
                classes: {
                  paper: classes.selectMenuList,
                },
              },
            }}
            disabled={formState.disabled}
            error={hasError('provider')}
            fullWidth
            helperText={hasError('provider') && formState.errors.provider}
            id="provider"
            label="Provedor"
            name="provider"
            onChange={handleFormChange}
            select
            value={formState.values.provider || ''}
            variant="outlined"
          >
            <Mui.MenuItem
              button
              disabled
              value={ETaxInvoiceConfigProvider.ENOTAS}
            >
              ENOTAS
            </Mui.MenuItem>
            <Mui.MenuItem
              button
              disabled
              value={ETaxInvoiceConfigProvider.NFE_IO}
            >
              NFE.IO
            </Mui.MenuItem>
            <Mui.MenuItem button value={ETaxInvoiceConfigProvider.NOTAZZ}>
              NOTAZZ
            </Mui.MenuItem>
            <Mui.MenuItem
              button
              disabled
              value={ETaxInvoiceConfigProvider.RECEBAFACIL}
            >
              RECEBA FÁCIL
            </Mui.MenuItem>
          </Mui.TextField>
        </Mui.Grid>
        {formState.values.provider && (
          <>
            <Mui.Grid item md={4} sm={6} xs={12}>
              <Mui.TextField
                InputLabelProps={{
                  classes: {
                    disabled: classes.inputDisabled,
                  },
                }}
                InputProps={{
                  classes: {
                    disabled: classes.inputDisabled,
                  },
                }}
                disabled={formState.disabled}
                error={hasError('apiKey')}
                fullWidth
                helperText={hasError('apiKey') && formState.errors.apiKey}
                id="apiKey"
                label="Chave da API"
                name="apiKey"
                onChange={handleFormChange}
                type="text"
                value={formState.values.apiKey}
                variant="outlined"
              />
            </Mui.Grid>

            <Mui.Grid item md={4} sm={6} xs={12}>
              <Mui.TextField
                InputLabelProps={{
                  classes: {
                    disabled: classes.selectDisabled,
                  },
                }}
                SelectProps={{
                  classes: {
                    icon: classes.selectIcon,
                    disabled: classes.selectDisabled,
                  },
                  MenuProps: {
                    classes: {
                      paper: classes.selectMenuList,
                    },
                  },
                }}
                disabled={formState.disabled}
                error={hasError('active')}
                fullWidth
                helperText={hasError('active') && formState.errors.active}
                label="Ativo"
                name="active"
                onChange={handleFormChange}
                select
                value={formState.values.active}
                variant="outlined"
              >
                <Mui.MenuItem button value="true">
                  SIM
                </Mui.MenuItem>
                <Mui.MenuItem button value="false">
                  NÃO
                </Mui.MenuItem>
              </Mui.TextField>
            </Mui.Grid>
          </>
        )}
        <Mui.Grid item xs={12}>
          <Mui.Divider />
        </Mui.Grid>

        <Mui.Grid item xs={12}>
          <Mui.Typography className={classes.addressTitle} variant="h4">
            Dados de endereço
          </Mui.Typography>
          <Mui.Typography variant="caption">
            O CADASTRO NÃO É OBRIGATÓRIO E SERÁ UTILIZADO SOMENTE QUANDO O
            CLIENTE NÃO TEM NENHUM ENDEREÇO CADASTRADO, A NOTA FISCAL SERÁ
            EMITIDA NO ENDEREÇO INFORMADO ABAIXO.
          </Mui.Typography>
        </Mui.Grid>

        <Mui.Grid item md={4} sm={6} xs={12}>
          <Mui.TextField
            InputLabelProps={{
              classes: {
                disabled: classes.inputDisabled,
              },
            }}
            InputProps={{
              classes: {
                disabled: classes.inputDisabled,
              },
            }}
            disabled={formState.disabled}
            error={hasError('street')}
            fullWidth
            helperText={
              hasError('street')
                ? formState.errors.street
                : 'Ex: Avenida Paulista'
            }
            id="street"
            label="Logradouro"
            name="street"
            onChange={handleFormChange}
            type="text"
            value={formState.values.street}
            variant="outlined"
          />
        </Mui.Grid>
        <Mui.Grid item md={4} sm={6} xs={12}>
          <Mui.TextField
            InputLabelProps={{
              classes: {
                disabled: classes.inputDisabled,
              },
            }}
            InputProps={{
              classes: {
                disabled: classes.inputDisabled,
              },
            }}
            disabled={formState.disabled}
            error={hasError('streetNumber')}
            fullWidth
            helperText={
              hasError('streetNumber')
                ? formState.errors.streetNumber
                : 'Ex: 4177'
            }
            id="streetNumber"
            label="Número"
            name="streetNumber"
            onChange={handleFormChange}
            type="text"
            value={formState.values.streetNumber}
            variant="outlined"
          />
        </Mui.Grid>
        <Mui.Grid item md={4} sm={6} xs={12}>
          <Mui.TextField
            InputLabelProps={{
              classes: {
                disabled: classes.inputDisabled,
              },
            }}
            InputProps={{
              classes: {
                disabled: classes.inputDisabled,
              },
            }}
            disabled={formState.disabled}
            error={hasError('streetComplement')}
            fullWidth
            helperText={
              hasError('streetComplement')
                ? formState.errors.streetComplement
                : 'Ex: Bloco 1, Apto 101'
            }
            id="streetComplement"
            label="Complemento"
            name="streetComplement"
            onChange={handleFormChange}
            type="text"
            value={formState.values.streetComplement}
            variant="outlined"
          />
        </Mui.Grid>
        <Mui.Grid item md={4} sm={6} xs={12}>
          <Mui.TextField
            InputLabelProps={{
              classes: {
                disabled: classes.inputDisabled,
              },
            }}
            InputProps={{
              classes: {
                disabled: classes.inputDisabled,
              },
            }}
            disabled={formState.disabled}
            error={hasError('neighborhood')}
            fullWidth
            helperText={
              hasError('neighborhood')
                ? formState.errors.neighborhood
                : 'Ex: Bela Vista'
            }
            id="neighborhood"
            label="Bairro"
            name="neighborhood"
            onChange={handleFormChange}
            type="text"
            value={formState.values.neighborhood}
            variant="outlined"
          />
        </Mui.Grid>
        <Mui.Grid item md={4} sm={6} xs={12}>
          <Mui.TextField
            InputLabelProps={{
              classes: {
                disabled: classes.inputDisabled,
              },
            }}
            InputProps={{
              classes: {
                disabled: classes.inputDisabled,
              },
            }}
            disabled={formState.disabled}
            error={hasError('city')}
            fullWidth
            helperText={
              hasError('city') ? formState.errors.city : 'Ex: São Paulo'
            }
            id="city"
            label="Cidade"
            name="city"
            onChange={handleFormChange}
            type="text"
            value={formState.values.city}
            variant="outlined"
          />
        </Mui.Grid>
        <Mui.Grid item md={4} sm={6} xs={12}>
          <Mui.TextField
            InputLabelProps={{
              classes: {
                disabled: classes.inputDisabled,
              },
            }}
            InputProps={{
              inputComponent: InputMask,
              classes: {
                disabled: classes.inputDisabled,
              },
            }}
            disabled={formState.disabled}
            error={hasError('postalCode')}
            fullWidth
            helperText={
              hasError('postalCode')
                ? formState.errors.postalCode
                : 'Ex: 01311-200'
            }
            id="postalCode"
            inputProps={{
              mask: postalCodeMask,
            }}
            label="CEP"
            name="postalCode"
            onChange={handleFormChange}
            type="text"
            value={formState.values.postalCode}
            variant="outlined"
          />
        </Mui.Grid>
        <Mui.Grid item md={4} sm={6} xs={12}>
          <Mui.TextField
            InputLabelProps={{
              classes: {
                disabled: classes.inputDisabled,
              },
            }}
            InputProps={{
              classes: {
                disabled: classes.inputDisabled,
              },
            }}
            disabled={formState.disabled}
            error={hasError('state')}
            fullWidth
            helperText={hasError('state') ? formState.errors.state : 'Ex: SP'}
            id="state"
            label="Estado"
            name="state"
            onChange={handleFormChange}
            type="text"
            value={formState.values.state}
            variant="outlined"
          />
        </Mui.Grid>
        <Mui.Grid item md={4} sm={6} xs={12}>
          <Mui.TextField
            InputLabelProps={{
              classes: {
                disabled: classes.inputDisabled,
              },
            }}
            InputProps={{
              classes: {
                disabled: classes.inputDisabled,
              },
            }}
            disabled={formState.disabled}
            error={hasError('country')}
            fullWidth
            helperText={
              hasError('country') ? formState.errors.country : 'Ex: BR'
            }
            id="country"
            label="País"
            name="country"
            onChange={handleFormChange}
            type="text"
            value={formState.values.country}
            variant="outlined"
          />
        </Mui.Grid>
        <Mui.Grid item xs={12}>
          <Mui.Divider />
        </Mui.Grid>
        <Mui.Grid item xs={12}>
          <Mui.Typography variant="h4">Metadados</Mui.Typography>
        </Mui.Grid>
        <Mui.Grid item lg={8} md={10} xs={12}>
          <Metadata
            metadata={taxInvoiceConfig?.metadata}
            onChange={handleMetadataChange}
          />
        </Mui.Grid>
      </Mui.Grid>
    </React.Fragment>
  );
}

export default TaxInvoiceConfigDialog;
