import React from 'react';
import * as ReactRedux from 'react-redux';

import type { RootState } from 'types/redux/redux.types';

import useMarketplaceUserService from './use-marketplace-user.service';
import useMarketplaceUserUI from './use-marketplace-user.ui';

function useMarketplaceUser() {
  const service = useMarketplaceUserService();
  const ui = useMarketplaceUserUI();
  const state = ReactRedux.useSelector(
    (state: RootState) => state.marketplaceUser
  );

  return React.useMemo(
    () => ({
      service,
      ui,
      state,
    }),
    [service, state, ui]
  );
}

export default useMarketplaceUser;
