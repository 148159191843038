import React from 'react';

import clsx from 'clsx';

import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';

import { Card, CardContent, Grid, Typography, Avatar } from '@material-ui/core';

import { Skeleton } from '@material-ui/lab';

import { Money as MoneyIcon } from '@material-ui/icons';

import { getDisplayMoney } from 'helpers';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    backgroundColor: theme.palette.background.dark2,
  },
  content: {
    alignItems: 'center',
    display: 'flex',
  },
  title: {
    fontWeight: 700,
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    height: 56,
    width: 56,
  },
  icon: {
    height: 32,
    width: 32,
  },
}));

const ExpectedBilling = props => {
  const { expected_billing, dashboardState } = props;

  const classes = useStyles();

  return (
    <Card className={clsx(classes.root)}>
      <CardContent>
        <Grid container justify="space-between">
          <Grid item>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
              variant="body2"
            >
              FATURAMENTO ESPERADO
            </Typography>
            {dashboardState === 'get' && expected_billing === null ? (
              <Skeleton
                animation="pulse"
                height={32}
                variant="text"
                width={100}
              />
            ) : (
              <Typography variant="h3">
                {getDisplayMoney(expected_billing || 0)}
              </Typography>
            )}
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <MoneyIcon className={classes.icon} />
            </Avatar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

ExpectedBilling.propTypes = {
  className: PropTypes.string,
};

export default ExpectedBilling;
