import React from 'react';
import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';

import * as TaxInvoiceHooks from 'hooks/use-tax-invoice';

import useStyles from './tax-invoices-failed-state.styles';

function TaxInvoicesFailedState() {
  const classes = useStyles();

  const useTaxInvoice = TaxInvoiceHooks.useTaxInvoice();

  return (
    <Mui.Grid
      alignItems="center"
      className={classes.container}
      container
      direction="column"
      justifyContent="center"
      spacing={2}
    >
      <Mui.Typography variant="body2">
        Não foi possível se conectar ao servidor. Tente novamente...
      </Mui.Typography>
      <Mui.IconButton onClick={useTaxInvoice.ui.refresh}>
        <MuiIcons.Refresh />
      </Mui.IconButton>
    </Mui.Grid>
  );
}

export default TaxInvoicesFailedState;
