import type {
  IProductReducerAction,
  IProductReducerActionPayload,
  IProductReducerState,
} from 'types/redux';

export function setProductState(state: IProductReducerState['state']) {
  return {
    type: 'PRODUCT_STATE_CHANGED',
    payload: state,
  };
}

export function setProduct(product: IProductReducerState['product']) {
  return {
    type: 'PRODUCT_CHANGED',
    payload: product,
  };
}

export function setListProducts(
  products: IProductReducerState['listProducts']
) {
  return {
    type: 'LIST_PRODUCTS_CHANGED',
    payload: products,
  };
}

export function setProductPagination(
  pagination: IProductReducerState['pagination']
) {
  return {
    type: 'PRODUCT_PAGINATION_CHANGED',
    payload: pagination,
  } as IProductReducerAction<
    IProductReducerActionPayload['PRODUCT_PAGINATION_CHANGED']
  >;
}
