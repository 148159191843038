export class JSONUtils {
  public static isJson(json: unknown) {
    try {
      if (typeof json !== 'string') {
        throw new Error();
      }

      JSON.parse(json);

      return true;
    } catch {
      return false;
    }
  }
}
