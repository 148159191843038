import React from 'react';
import * as ReactRedux from 'react-redux';

import type { RootState } from 'types/redux/redux.types';

import useSubscriptionService from './useSubscription.service';
import useSubscriptionUI from './useSubscription.ui';

function useSubscription() {
  const service = useSubscriptionService();
  const ui = useSubscriptionUI();
  const state = ReactRedux.useSelector(
    (state: RootState) => state.subscriptionState
  );

  return React.useMemo(
    () => ({
      service,
      ui,
      state,
    }),
    [service, state, ui]
  );
}

export default useSubscription;
