import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';

import { SearchInput } from 'components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    flexWrap: 'wrap',
  },
  spacer: {
    flexGrow: 1,
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  searchInput: {
    flex: 1,
    backgroundColor: theme.palette.background.default,
  },
  buttonCreate: {
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(1),
      width: '100%',
    },
  },
}));

const ChargeToolbar = props => {
  const { className, history, ...rest } = props;

  const classes = useStyles();

  const handleCreateCharge = () => {
    history.push('charge');
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div className={classes.row}>
        <span className={classes.spacer} />
        {/* <Button className={classes.importButton}>Import</Button>
        <Button className={classes.exportButton}>Export</Button> */}
        {/* <Button
          color="primary"
          variant="contained"
        >
          Add product
        </Button> */}
        <Button
          className={classes.buttonCreate}
          color="primary"
          onClick={handleCreateCharge}
          variant="contained"
        >
          Criar cobrança
        </Button>
      </div>
      <div className={classes.row}>
        <SearchInput
          className={classes.searchInput}
          placeholder="Procure uma cobrança"
        />
      </div>
    </div>
  );
};

ChargeToolbar.propTypes = {
  className: PropTypes.string,
};

export default ChargeToolbar;
