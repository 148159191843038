import type {
  IMarketplaceUserReducerAction,
  IMarketplaceUserReducerActionPayload,
  IMarketplaceUserReducerState,
} from 'types/redux/reducers/marketplace-user-reducer.types';

export function setMarketplaceUserState(
  state: IMarketplaceUserReducerState['state']
): IMarketplaceUserReducerAction<
  IMarketplaceUserReducerActionPayload['MARKETPLACE_USER_STATE_CHANGED']
> {
  return {
    type: 'MARKETPLACE_USER_STATE_CHANGED',
    payload: state,
  };
}

export function setMarketplaceUser(
  marketplaceUser: IMarketplaceUserReducerState['marketplaceUser']
): IMarketplaceUserReducerAction<
  IMarketplaceUserReducerActionPayload['MARKETPLACE_USER_CHANGED']
> {
  return {
    type: 'MARKETPLACE_USER_CHANGED',
    payload: marketplaceUser,
  };
}

export function setListMarketplaceUsers(
  marketplaceUsers: IMarketplaceUserReducerState['listMarketplaceUsers']
): IMarketplaceUserReducerAction<
  IMarketplaceUserReducerActionPayload['LIST_MARKETPLACE_USERS_CHANGED']
> {
  return {
    type: 'LIST_MARKETPLACE_USERS_CHANGED',
    payload: marketplaceUsers,
  };
}

export function setMarketplaceUserPagination(
  pagination: IMarketplaceUserReducerState['pagination']
) {
  return {
    type: 'MARKETPLACE_USER_PAGINATION_CHANGED',
    payload: pagination,
  } as IMarketplaceUserReducerAction<
    IMarketplaceUserReducerActionPayload['MARKETPLACE_USER_PAGINATION_CHANGED']
  >;
}
