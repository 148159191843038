import React from 'react';
import * as ReactRedux from 'react-redux';
import moment from 'moment';
import type { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import type { Moment } from 'moment';
import validate from 'validate.js';

import * as CouponHooks from 'hooks/useCoupon';
import type { ICoupon } from 'types/entities';
import { CouponUtils, NumberUtils } from 'helpers';
import { usePrevious } from 'hooks/usePrevious';
import {
  setDialogClose,
  setDialogType,
  setPlan,
  setSnackbarMessage,
  setSnackbarOpen,
  setSnackbarType,
} from 'store/actions';

interface IFormState {
  disabled: boolean;
  isValid: boolean;
  values: {
    type: ICoupon['type'];
    value: number | string;
    valueType: ICoupon['valueType'];
    redeemCode: string;
    maxUse: string;
    dateStart: Moment | null;
    dateEnd: Moment | null;
    frequency: ICoupon['frequency'];
    duration: string;
    metadata: Record<string, string | number> | null;
  };
  errors: {
    type?: string;
    value?: string;
    valueType?: string;
    redeemCode?: string;
    maxUse?: string;
    dateStart?: string;
    dateEnd?: string;
    frequency?: string;
    duration?: string;
    metadata?: string;
  };
  touched: {
    type?: boolean;
    value?: boolean;
    valueType?: boolean;
    redeemCode?: boolean;
    maxUse?: boolean;
    dateStart?: boolean;
    dateEnd?: boolean;
    frequency?: boolean;
    duration?: boolean;
    metadata?: boolean;
  };
}

const schema = {
  redeemCode: {
    length: {
      minimum: 6,
      maximum: 14,
      tooShort: '^O código precisa ter no mínimo %{count} caracteres',
      tooLong: '^O código precisa ter no máximo %{count} caracteres',
    },
  },
};

export function useCouponEditDialog() {
  const dispatch = ReactRedux.useDispatch();

  const { state, service } = CouponHooks.useCoupon();

  const { coupon, state: couponState } = state;

  const formStateInitialValue = React.useMemo<IFormState>(
    () => ({
      disabled: false,
      isValid: false,
      values: {
        type: coupon?.type || 'product',
        value: coupon?.value
          ? coupon.valueType === 'percentage'
            ? `% ${NumberUtils.toPrecision(coupon.value * 100, 0)}`
            : NumberUtils.toPrecision(coupon.value / 100, 2)
          : 0,
        valueType: coupon?.valueType || 'fixed',
        redeemCode: coupon?.redeemCode || '',
        maxUse: coupon?.maxUse.toString() || '1',
        dateStart: coupon?.dateStart ? moment(coupon.dateStart) : null,
        dateEnd: coupon?.dateEnd ? moment(coupon.dateEnd) : null,
        frequency: coupon?.frequency || 'monthly',
        duration: coupon?.duration?.toString() || '1',
        metadata: null,
      },
      errors: {},
      touched: {
        value: true,
      },
    }),
    [coupon]
  );

  const [formState, setFormState] = React.useState<IFormState>(
    formStateInitialValue
  );

  const previousValueType = usePrevious(formState.values.valueType);

  const handleCouponValueChange = React.useCallback(
    (value: string | number) => {
      if (formState.values.valueType === 'percentage') {
        value = value.toString().replace(/[^0-9]/g, '');

        if (Number(value) > 100) {
          value = '100';
        }

        value = `% ${Number(value)}`;
      }

      if (formState.values.valueType === 'fixed') {
        const valueNumber = Number(value);

        if (valueNumber > Number.MAX_SAFE_INTEGER) {
          value = Number.MAX_SAFE_INTEGER;
        }
      }

      return value;
    },
    [formState.values.valueType]
  );

  const handleDecoratorValue = React.useCallback(
    (fieldName: keyof IFormState['values'], value: string | number) => {
      switch (fieldName) {
        case 'value':
          return handleCouponValueChange(value);
        case 'duration':
          return handleCouponDurationChange(value as string);
        case 'maxUse':
          return handleMaxUseChange(value as string);
        case 'redeemCode':
          return handleRedeemCodeChange(value as string);
        default:
          return value;
      }
    },
    [handleCouponValueChange]
  );

  const handleFormChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.persist();

      const fieldName = event.target.name;
      let value = event.target.value as string | number;

      value = handleDecoratorValue(
        fieldName as keyof IFormState['values'],
        value
      );

      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          [fieldName]: value,
        },
        touched: {
          ...formState.touched,
          [fieldName]: true,
        },
      }));
    },
    [handleDecoratorValue]
  );

  const handleDateChange = React.useCallback(
    (field: keyof Pick<IFormState['values'], 'dateStart' | 'dateEnd'>) =>
      (date: MaterialUiPickersDate) => {
        setFormState(prevState => ({
          ...prevState,
          values: {
            ...prevState.values,
            [field]: date,
          },
        }));
      },
    []
  );

  const handleMetadataChange = React.useCallback(
    (metadata: Record<string, string | number> | null) => {
      setFormState(prevState => ({
        ...prevState,
        values: {
          ...prevState.values,
          metadata,
        },
      }));
    },
    []
  );

  const handleDisableDateEnd = React.useCallback(
    (momentDate: MaterialUiPickersDate) => {
      const dateStart = moment(formState.values.dateStart);

      return !!momentDate?.isBefore(dateStart.add(-1, 'days'));
    },
    [formState.values.dateStart]
  );

  const handleGenerateRedeemCode = React.useCallback(() => {
    const redeemCode = CouponUtils.generateRedeemCode();

    setFormState(prevState => ({
      ...prevState,
      values: {
        ...prevState.values,
        redeemCode,
      },
      touched: {
        ...prevState.touched,
        redeemCode: true,
      },
    }));
  }, []);

  const hasError = React.useCallback(
    (field: keyof IFormState['values']) => {
      return !!(formState.touched[field] && formState.errors[field]);
    },
    [formState.errors, formState.touched]
  );

  const handleTransformData = React.useCallback(() => {
    let frequency = null;
    let duration = null;
    let value: number;

    if (formState.values.type === 'subscription') {
      frequency = formState.values.frequency;
      duration = Number(formState.values.duration);
    }

    if (formState.values.valueType === 'percentage') {
      value =
        Number((formState.values.value as string).replace(/[^0-9]/g, '')) / 100;
    }

    if (formState.values.valueType === 'fixed') {
      value = NumberUtils.toPrecision(
        (formState.values.value as number) * 100,
        0
      );
    }

    return {
      ...formState.values,
      frequency,
      duration,
      maxUse: Number(formState.values.maxUse),
      value: value!,
      dateStart: formState.values.dateStart
        ? formState.values.dateStart.startOf('day').utc(true).toISOString()
        : null,
      dateEnd: formState.values.dateEnd
        ? formState.values.dateEnd.endOf('day').utc(true).toISOString()
        : null,
    };
  }, [formState.values]);

  const handleSubmit = React.useCallback(async () => {
    try {
      setFormState(prevState => ({
        ...prevState,
        disabled: true,
        error: {},
      }));

      const {
        dateEnd,
        dateStart,
        frequency,
        redeemCode,
        type,
        valueType,
        duration,
        maxUse,
        value,
        metadata,
      } = handleTransformData();

      if (value === 0) {
        const displayValue = valueType === 'fixed' ? 'R$ 0,00' : '% 0';

        setFormState(formState => ({
          ...formState,
          errors: {
            ...formState.errors,
            value: `Valor deve ser maior que ${displayValue}`,
          },
        }));

        handleInputFocus('value');
        return;
      }

      if (maxUse < 1) {
        setFormState(prevState => ({
          ...prevState,
          errors: {
            ...prevState.errors,
            maxUse: 'O máximo de uso de ve ser no mínimo 1',
          },
        }));

        handleInputFocus('maxUse');
        return;
      }

      if (couponState === 'create') {
        await service.create({
          dateEnd,
          dateStart,
          duration: duration || 0,
          frequency: frequency || 'monthly',
          maxUse,
          metadata,
          redeemCode,
          type,
          value,
          valueType,
        });
      }

      if (couponState === 'edit') {
        await service.edit({
          dateEnd,
          dateStart,
          duration: duration || 0,
          frequency: frequency || 'monthly',
          id: coupon!.id,
          maxUse,
          metadata,
          redeemCode:
            coupon!.redeemCode !== redeemCode ? redeemCode : undefined,
          value,
          valueType,
        });
      }

      dispatch([setDialogClose(), setDialogType(null)]);
    } catch (error) {
      dispatch([
        setSnackbarMessage(
          'Não foi possível criar este cupom, tente novamente mais tarde!'
        ),
        setSnackbarType('error'),
        setSnackbarOpen(),
      ]);
    } finally {
      setFormState(prevState => ({
        ...prevState,
        disabled: false,
      }));
    }
  }, [coupon, couponState, dispatch, handleTransformData, service]);

  const handleDialogClose = React.useCallback(() => {
    dispatch([setPlan(null), setDialogClose(), setDialogType(null)]);
  }, [dispatch]);

  function handleCouponDurationChange(value: string) {
    value = value.replace(/[^0-9]/g, '');

    if (Number(value) <= 1) {
      value = '1';
    }

    return value;
  }

  function handleMaxUseChange(value: string) {
    if (Number(value) > Number.MAX_SAFE_INTEGER) {
      value = Number.MAX_SAFE_INTEGER.toString();
    }

    return value.replace(/[^0-9]/g, '');
  }

  function handleRedeemCodeChange(value: string) {
    return value.substring(0, 14);
  }

  function handleInputFocus(inputName: keyof IFormState['values']) {
    const inputElement = document.querySelector(
      `#${inputName}`
    ) as HTMLInputElement;

    inputElement.focus();
  }

  React.useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: !errors,
      errors: errors || {},
    }));
  }, [formState.values]);

  React.useEffect(() => {
    let value: string | number | undefined;

    if (
      previousValueType === 'fixed' &&
      formState.values.valueType === 'percentage'
    ) {
      value = '% 0';
    }

    if (
      previousValueType === 'percentage' &&
      formState.values.valueType === 'fixed'
    ) {
      value = 0;
    }

    if (value !== undefined) {
      setFormState(prevState => ({
        ...prevState,
        values: {
          ...prevState.values,
          value: value!,
        },
        touched: {
          ...prevState.touched,
          value: true,
        },
      }));
    }
  }, [formState.values.valueType, previousValueType]);

  return React.useMemo(
    () => ({
      coupon,
      couponState,
      formState,
      handleDateChange,
      handleDialogClose,
      handleDisableDateEnd,
      handleFormChange,
      handleGenerateRedeemCode,
      handleMetadataChange,
      handleSubmit,
      hasError,
    }),
    [
      coupon,
      couponState,
      formState,
      handleDateChange,
      handleDialogClose,
      handleDisableDateEnd,
      handleFormChange,
      handleGenerateRedeemCode,
      handleMetadataChange,
      handleSubmit,
      hasError,
    ]
  );
}
