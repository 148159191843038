import React from 'react';
import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';
import * as ReactRedux from 'react-redux';
import moment from 'moment';

import type { RootState } from 'types/redux';
import { CouponUtils, getDisplayMoney, NumberUtils } from 'helpers';
import { setCoupon, setDialogClose, setDialogType } from 'store/actions';

import useStyles from './CouponViewDialog.styles';

function CouponViewDialog() {
  const classes = useStyles();
  const dispatch = ReactRedux.useDispatch();

  const { coupon } = ReactRedux.useSelector(
    (state: RootState) => state.couponState
  );

  if (!coupon) {
    return null;
  }

  const metadata = Object.entries(coupon.metadata || {});

  function handleDialogClose() {
    dispatch([setCoupon(null), setDialogClose(), setDialogType(null)]);
  }

  function getDisplayValueType() {
    if (coupon?.valueType === 'percentage') {
      return `${NumberUtils.toPrecision(coupon.value * 100, 2)}%`;
    }

    return getDisplayMoney(Number(coupon?.value) / 100);
  }

  return (
    <React.Fragment>
      <Mui.AppBar className={classes.appBar}>
        <Mui.Toolbar>
          <Mui.Typography className={classes.title} variant="h5">
            {`Cupom (${coupon.redeemCode})`}
          </Mui.Typography>
          <Mui.IconButton
            aria-label="Fechar"
            color="inherit"
            edge="end"
            onClick={handleDialogClose}
          >
            <MuiIcons.Close />
          </Mui.IconButton>
        </Mui.Toolbar>
      </Mui.AppBar>
      <Mui.Grid className={classes.container} container spacing={2}>
        <Mui.Grid item xs={12}>
          <Mui.Typography variant="h4">Ações</Mui.Typography>
        </Mui.Grid>
        <div className={classes.actionsContainer}>
          <Mui.Tooltip title="Em breve">
            <div className={classes.actionButton}>
              <Mui.Button
                color="secondary"
                disabled
                fullWidth
                variant="contained"
              >
                DELETAR
              </Mui.Button>
            </div>
          </Mui.Tooltip>
        </div>

        <Mui.Grid item xs={12}>
          <Mui.Divider />
        </Mui.Grid>
        <Mui.Grid item xs={12}>
          <Mui.Typography variant="h4">Dados do cupom</Mui.Typography>
        </Mui.Grid>

        <Mui.Grid item md={3} sm={4} xs={12}>
          <Mui.Typography variant="body1">
            <strong>Código: </strong>
            {coupon.redeemCode}
          </Mui.Typography>
        </Mui.Grid>
        <Mui.Grid item md={3} sm={4} xs={12}>
          <Mui.Typography variant="body1">
            <strong>Tipo: </strong>
            {CouponUtils.getTypeLabel(coupon.type)}
          </Mui.Typography>
        </Mui.Grid>
        <Mui.Grid item md={3} sm={4} xs={12}>
          <Mui.Typography variant="body1">
            <strong>Tipo do valor: </strong>
            {CouponUtils.getValueTypeLabel(coupon.valueType)}
          </Mui.Typography>
        </Mui.Grid>
        <Mui.Grid item md={3} sm={4} xs={12}>
          <Mui.Typography variant="body1">
            <strong>Valor: </strong>
            {getDisplayValueType()}
          </Mui.Typography>
        </Mui.Grid>
        <Mui.Grid item md={3} sm={4} xs={12}>
          <Mui.Typography variant="body1">
            <strong>Máximo de uso: </strong>
            {coupon.maxUse}
          </Mui.Typography>
        </Mui.Grid>
        <Mui.Grid item md={3} sm={4} xs={12}>
          <Mui.Typography variant="body1">
            <strong>Uso: </strong>
            {coupon.used}
          </Mui.Typography>
        </Mui.Grid>
        <Mui.Grid item md={3} sm={4} xs={12}>
          <Mui.Typography variant="body1">
            <strong>Duração do cupom: </strong>
            {coupon.duration || 'Não definida'}
          </Mui.Typography>
        </Mui.Grid>
        <Mui.Grid item md={3} sm={4} xs={12}>
          <Mui.Typography variant="body1">
            <strong>Frequência: </strong>
            {CouponUtils.getFrequencyLabel(
              coupon.frequency,
              coupon.duration > 1 ? 'plural_of_noun' : 'noun'
            ) || 'Não definida'}
          </Mui.Typography>
        </Mui.Grid>
        <Mui.Grid item md={3} sm={4} xs={12}>
          <Mui.Typography variant="body1">
            <strong>Data de início: </strong>
            {coupon.dateStart
              ? moment(coupon.dateStart).format('DD/MM/YYYY')
              : 'Não definida'}
          </Mui.Typography>
        </Mui.Grid>
        <Mui.Grid item md={3} sm={4} xs={12}>
          <Mui.Typography variant="body1">
            <strong>Data de término: </strong>
            {coupon.dateEnd
              ? moment(coupon.dateEnd).format('DD/MM/YYYY')
              : 'Não definida'}
          </Mui.Typography>
        </Mui.Grid>
        <Mui.Grid item md={3} sm={4} xs={12}>
          <Mui.Typography variant="body1">
            <strong>Data de criação: </strong>
            {moment(coupon.createdAt).format('DD/MM/YYYY HH:mm:ss')}
          </Mui.Typography>
        </Mui.Grid>
        <Mui.Grid item md={3} sm={4} xs={12}>
          <Mui.Typography variant="body1">
            <strong>Última atualização: </strong>
            {moment(coupon.updatedAt).format('DD/MM/YYYY HH:mm:ss')}
          </Mui.Typography>
        </Mui.Grid>

        {metadata.length > 0 && (
          <>
            <Mui.Grid item xs={12}>
              <Mui.Divider />
            </Mui.Grid>
            <Mui.Grid item xs={12}>
              <Mui.Typography variant="h4">Metadados</Mui.Typography>
            </Mui.Grid>
            <Mui.Grid container direction="column" item spacing={1}>
              {metadata.map(([key, value]) => (
                <Mui.Grid item key={key} xs={4}>
                  <Mui.Typography variant="body1">
                    <strong>{key}: </strong>
                    {value}
                  </Mui.Typography>
                </Mui.Grid>
              ))}
            </Mui.Grid>
          </>
        )}
      </Mui.Grid>
    </React.Fragment>
  );
}

export default CouponViewDialog;
