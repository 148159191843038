import React from 'react';
import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';

import * as CheckoutHooks from 'hooks/useCheckout';

import useStyles from './CheckoutFailedState.styles';

function CheckoutFailedState() {
  const classes = useStyles();

  const checkout = CheckoutHooks.useCheckout();

  return (
    <Mui.Grid
      alignItems="center"
      className={classes.container}
      container
      direction="column"
      justifyContent="center"
      spacing={2}
    >
      <Mui.Typography variant="body2">
        Não foi possível se conectar ao servidor. Tente novamente...
      </Mui.Typography>
      <Mui.IconButton onClick={checkout.ui.refresh}>
        <MuiIcons.Refresh />
      </Mui.IconButton>
    </Mui.Grid>
  );
}

export default CheckoutFailedState;
