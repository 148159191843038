import type { IProductReducerAction, IProductReducerState } from 'types/redux';

const INITIAL_STATE: IProductReducerState = {
  state: 'get',
  product: null,
  listProducts: [],
  pagination: null,
};

export default (
  state = INITIAL_STATE,
  action: IProductReducerAction<unknown>
) => {
  switch (action.type) {
    case 'PRODUCT_STATE_CHANGED':
      return {
        ...state,
        state: action.payload,
      };
    case 'PRODUCT_CHANGED':
      return {
        ...state,
        product: action.payload,
      };
    case 'LIST_PRODUCTS_CHANGED':
      return {
        ...state,
        listProducts: action.payload,
      };
    case 'PRODUCT_PAGINATION_CHANGED':
      return {
        ...state,
        pagination: action.payload,
      };
    default:
      return state;
  }
};
