import React from 'react';
import * as ReactRedux from 'react-redux';
import moment from 'moment';

import type { IResourceListInterface } from 'types/services';
import type { ISaleReducerState } from 'types/redux';
import type { ITransaction, ITransactionMetric } from 'types/entities';
import { api } from 'services/api';
import {
  setSaleListType,
  setSaleMetricsState,
  setSaleMetricsValues,
  setSalePagination,
  setSaleState,
  setSalesList,
  setSnackbarMessage,
  setSnackbarOpen,
  setSnackbarType,
} from 'store/actions';
import { useAuthContext } from '../useAuthContext.hook';

declare namespace ITransactionService {
  interface IGetByIdParams {
    transactionId: string;
  }

  interface IPaginationParams {
    limit?: number;
    offset?: number;
  }

  interface ISearchParams extends IPaginationParams {
    amountFrom?: string;
    amountTo?: string;
    dateEnd?: string;
    dateStart?: string;
    paymentTypes?: string;
    search?: string;
    status?: string;
  }

  interface IReportParams extends IPaginationParams {
    amountFrom?: string;
    amountTo?: string;
    dateEnd?: string;
    dateStart?: string;
    paymentTypes?: string;
    search?: string;
    status?: string;
  }

  interface IMetricsParams {
    amountFrom?: string;
    amountTo?: string;
    dateEnd?: string;
    dateStart?: string;
    paymentTypes?: string;
    search?: string;
  }

  interface IRefundParams {
    transactionId: string;
  }
}

function useSaleService() {
  const dispatch = ReactRedux.useDispatch();

  const { user } = useAuthContext();
  const { marketplace_id } = user;

  const getById = React.useCallback(
    async (params: ITransactionService.IGetByIdParams) => {
      try {
        const { transactionId } = params;

        const response = await api.get<ITransaction>(
          `/marketplaces/${marketplace_id}/transactions/${transactionId}`,
          {
            headers: {
              auth: {
                username: process.env.REACT_APP_BYPASS_API_KEY,
                password: '',
              },
            },
          }
        );

        return response.data;
      } catch (error) {
        dispatch([
          setSnackbarMessage(
            'Não foi possível buscar a venda, tente novamente mais tarde!'
          ),
          setSnackbarType('error'),
          setSnackbarOpen(),
          setSaleMetricsState('failed'),
        ]);
        console.error('Não foi possível buscar a venda');
        throw error;
      }
    },
    [dispatch, marketplace_id]
  );

  const search = React.useCallback(
    async (params: ITransactionService.ISearchParams) => {
      try {
        const {
          amountFrom: amountFromParam,
          amountTo: amountToParam,
          dateEnd: dateEndParam,
          dateStart: dateStartParam,
          limit: limitParam,
          offset: offsetParam,
          paymentTypes: paymentTypesParam,
          search,
          status: statusParam,
        } = params;

        const dateStart = dateStartParam
          ? moment(dateStartParam).startOf('day').toISOString()
          : undefined;
        const dateEnd = dateEndParam
          ? moment(dateEndParam).endOf('day').toISOString()
          : undefined;

        const token = localStorage.getItem('token');

        const response = await api.get<IResourceListInterface<ITransaction>>(
          '/transaction/search',
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              amountFrom: amountFromParam,
              amountTo: amountToParam,
              dateEnd,
              dateStart,
              limit: limitParam,
              offset: offsetParam,
              payment_types: paymentTypesParam,
              populate: 'customer',
              query: search,
              status: statusParam,
            },
          }
        );

        const {
          page,
          offset,
          limit,
          // TODO: Fix hasMore property
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          hasMore,
          total,
          items: transactions,
        } = response.data;

        const pagination: ISaleReducerState['pagination'] = {
          hasMore,
          limit,
          offset,
          page,
          total,
        };

        dispatch([
          setSalesList(transactions),
          setSaleListType('search'),
          setSaleState('success'),
          setSalePagination(pagination),
        ]);
      } catch (error) {
        dispatch([
          setSnackbarMessage(
            'Não foi possível fazer a listagem de vendas, tente novamente mais tarde!'
          ),
          setSnackbarType('error'),
          setSnackbarOpen(),
          setSaleListType('search'),
          setSaleState('failed'),
        ]);
        console.error('Não foi possível buscar a lista de vendas');
        throw error;
      }
    },
    [dispatch]
  );

  const report = React.useCallback(
    async (params: ITransactionService.IReportParams) => {
      try {
        const {
          amountFrom: amountFromParam,
          amountTo: amountToParam,
          dateEnd: dateEndParam,
          dateStart: dateStartParam,
          limit: limitParam,
          offset: offsetParam,
          paymentTypes: paymentTypesParam,
          search,
          status: statusParam,
        } = params;

        const dateStart = dateStartParam
          ? moment(dateStartParam).startOf('day').toISOString()
          : undefined;
        const dateEnd = dateEndParam
          ? moment(dateEndParam).endOf('day').toISOString()
          : undefined;

        const response = await api.get(
          `/v2/marketplaces/${marketplace_id}/transactions/report`,
          {
            auth: {
              username: process.env.REACT_APP_BYPASS_API_KEY!,
              password: '',
            },
            responseType: 'blob',
            params: {
              amountFrom: amountFromParam,
              amountTo: amountToParam,
              dateEnd,
              dateStart,
              limit: limitParam,
              offset: offsetParam,
              payment_types: paymentTypesParam,
              populate: 'customer',
              query: search,
              status: statusParam,
            },
          }
        );

        const blob = response.data;
        const linkElement = document.createElement('a');
        linkElement.href = URL.createObjectURL(blob);
        linkElement.download = 'transactions.csv';
        linkElement.click();
        setTimeout(() => URL.revokeObjectURL(linkElement.href), 0);
      } catch (error) {
        dispatch([
          setSnackbarMessage(
            'Não foi possível fazer o relatório de vendas, tente novamente mais tarde!'
          ),
          setSnackbarType('error'),
          setSnackbarOpen(),
          setSaleListType('search'),
          setSaleState('failed'),
        ]);
        console.error('Não foi possível fazer o relatório de vendas');
        throw error;
      }
    },
    [dispatch, marketplace_id]
  );

  const metrics = React.useCallback(
    async (params: ITransactionService.IMetricsParams) => {
      try {
        const {
          amountFrom: amountFromParam,
          amountTo: amountToParam,
          dateEnd: dateEndParam,
          dateStart: dateStartParam,
          paymentTypes: paymentTypesParam,
          search,
        } = params;

        const dateStart = dateStartParam
          ? moment(dateStartParam).startOf('day').toISOString()
          : undefined;
        const dateEnd = dateEndParam
          ? moment(dateEndParam).endOf('day').toISOString()
          : undefined;

        const token = localStorage.getItem('token');

        const response = await api.get<
          IResourceListInterface<ITransactionMetric>
        >('/transaction/metrics', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            amountFrom: amountFromParam,
            amountTo: amountToParam,
            dateEnd,
            dateStart,
            payment_types: paymentTypesParam,
            query: search,
          },
        });

        const transactionMetrics = response.data.items;

        const metricsValues: ISaleReducerState['metrics']['values'] =
          {} as ISaleReducerState['metrics']['values'];

        transactionMetrics.forEach(
          transactionMetric =>
            (metricsValues[transactionMetric.name] = transactionMetric.value)
        );

        dispatch([
          setSaleMetricsState('done'),
          setSaleMetricsValues(metricsValues),
        ]);
      } catch (error) {
        dispatch([
          setSnackbarMessage(
            'Não foi possível fazer a listagem de métricas, tente novamente mais tarde!'
          ),
          setSnackbarType('error'),
          setSnackbarOpen(),
          setSaleMetricsState('failed'),
        ]);
        console.error('Não foi possível buscar a lista de métricas');
        throw error;
      }
    },
    [dispatch]
  );

  const refund = React.useCallback(
    async (params: ITransactionService.IRefundParams) => {
      try {
        const { transactionId } = params;

        const response = await api.post<ITransaction>(
          `/marketplaces/${marketplace_id}/transactions/${transactionId}/void`,
          null,
          {
            auth: {
              username: process.env.REACT_APP_BYPASS_API_KEY!,
              password: '',
            },
          }
        );

        return response.data;
      } catch (error) {
        dispatch([
          setSnackbarMessage(
            'Não foi possível fazer o estorno, tente novamente mais tarde!'
          ),
          setSnackbarType('error'),
          setSnackbarOpen(),
          setSaleMetricsState('failed'),
        ]);
        console.error('Não foi possível fazer o estorno');
        throw error;
      }
    },
    [dispatch, marketplace_id]
  );

  return React.useMemo(
    () => ({
      getById,
      metrics,
      refund,
      report,
      search,
    }),
    [getById, metrics, refund, report, search]
  );
}

export default useSaleService;
