export function setBankAccountState(state) {
  return {
    type: 'BANK_ACCOUNT_STATE_CHANGED',
    payload: state,
  };
}

export function setBankAccount(bank_account) {
  return {
    type: 'BANK_ACCOUNT_CHANGED',
    payload: bank_account,
  };
}

export function setBankAccountsList(bank_accounts) {
  return {
    type: 'BANK_ACCOUNTS_LIST_CHANGED',
    payload: bank_accounts,
  };
}

export function setBankAccountsListFiltered(bank_accounts) {
  return {
    type: 'BANK_ACCOUNTS_LIST_FILTERED_CHANGED',
    payload: bank_accounts,
  };
}

export function setBankAccountsListSelected(bank_accounts) {
  return {
    type: 'BANK_ACCOUNTS_LIST_SELECTED_CHANGED',
    payload: bank_accounts,
  };
}
