/* eslint-disable react/prop-types */
import React, { useCallback } from 'react';

import moment from 'moment';

import { getDisplayMoney } from 'helpers';

import {
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  makeStyles,
  Typography,
} from '@material-ui/core';

import { Refresh as RefreshIcon } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(2),
    backgroundColor: theme.palette.background.dark2,
    padding: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
  },
  containerProgress: {
    marginTop: theme.spacing(2),
    minHeight: 200,
  },
}));

const FutureTransfersDetails = props => {
  const classes = useStyles();

  const { data, stateRequest, handleRefresh } = props;

  const renderContent = useCallback(() => {
    if (stateRequest === 'get') {
      return (
        <Grid
          alignItems="center"
          className={classes.containerProgress}
          container
          justify="center"
          spacing={2}
        >
          <CircularProgress />
        </Grid>
      );
    } else if (stateRequest === 'failed') {
      return (
        <Grid
          alignItems="center"
          className={classes.containerProgress}
          container
          direction="column"
          justify="center"
          spacing={2}
        >
          <Typography variant="body2">
            Não foi possível se conectar ao servidor. Tente novamente...
          </Typography>
          <IconButton onClick={handleRefresh}>
            <RefreshIcon />
          </IconButton>
        </Grid>
      );
    } else if (data && Object.values(data.items).length > 0) {
      return (
        <Grid className={classes.containerTable} component={List} container>
          <ListItem disableGutters>
            <Grid className={classes.tableCell} item xs={6}>
              <Typography noWrap variant="h5">
                Data prevista
              </Typography>
            </Grid>
            <Grid className={classes.tableCell} item xs={6}>
              <Typography noWrap variant="h5">
                Valor Total
              </Typography>
            </Grid>
          </ListItem>

          <Grid component={Divider} item xs={12} />

          {data &&
            Object.entries(data.items).map(future_transfer => (
              <React.Fragment key={future_transfer[0]}>
                <ListItem className={classes.listItem} disableGutters>
                  <Grid className={classes.tableCell} item xs={6}>
                    <Typography noWrap variant="body1">
                      {moment(future_transfer[0]).format('DD/MM/YYYY')}
                    </Typography>
                  </Grid>
                  <Grid className={classes.tableCell} item xs={6}>
                    <Typography noWrap variant="body1">
                      {getDisplayMoney(future_transfer[1].amount / 100)}
                    </Typography>
                  </Grid>
                </ListItem>

                <Grid component={Divider} item xs={12} />
              </React.Fragment>
            ))}
        </Grid>
      );
    } else {
      return (
        <Grid
          alignItems="center"
          className={classes.containerProgress}
          container
          justify="center"
          spacing={2}
        >
          <Typography variant="h5">Não há lançamentos futuros</Typography>
          <Typography variant="h3">
            <span aria-label="Emoji nerd" role="img">
              &#129299;
            </span>
          </Typography>
        </Grid>
      );
    }
  }, [
    data,
    stateRequest,
    classes.containerProgress,
    classes.containerTable,
    classes.listItem,
    classes.tableCell,
    handleRefresh,
  ]);

  return (
    <Card className={classes.root}>
      <CardHeader
        action={
          <IconButton onClick={handleRefresh}>
            <RefreshIcon />
          </IconButton>
        }
        subheader={
          <Typography color="textSecondary" variant="h5">
            Datas previstas para pagamento
          </Typography>
        }
        title={<Typography variant="h3">Detalhes</Typography>}
      />
      <Divider />
      <CardContent>{renderContent()}</CardContent>
    </Card>
  );
};

export default FutureTransfersDetails;
