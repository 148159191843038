import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setCustomerState, setCustomer } from 'store/actions/CustomerActions';
import { setDialogClose } from 'store/actions/DialogActions';
import {
  setSnackbarOpen,
  setSnackbarMessage,
  setSnackbarType,
} from 'store/actions/SnackbarActions';

import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
  Grid,
  DialogActions,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Close as CloseIcon } from '@material-ui/icons';
import { api } from 'services/api';

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
    paddingRight: '0px !important',
    backgroundColor: theme.palette.error.light,
  },
  title: {
    flex: 1,
    color: '#fff',
  },
  container: {
    padding: theme.spacing(2),
    width: 450,
    maxWidth: '100%',
  },
}));

const CustomerDeleteDialog = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const { id: buyer_id } = useSelector(state => state.customerState.customer);
  const [buttonDisable, setButtonDisable] = useState(true);

  useEffect(() => {
    if (buyer_id) {
      setButtonDisable(false);
    }
  }, [buyer_id]);

  const handleDialogClose = () => dispatch(setDialogClose());

  async function handleCustomerDelete(buyer_id) {
    dispatch(setSnackbarType('info'));
    dispatch(setSnackbarMessage('Processando...'));
    dispatch(setSnackbarOpen());

    async function deleteCustomer(buyer_id) {
      try {
        const token = localStorage.getItem('token');

        const response = await api.delete(`/buyer/${buyer_id}/`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data) {
          dispatch(setCustomerState('get'));
          dispatch(setCustomer(null));
          dispatch(setDialogClose());
          dispatch(setSnackbarType('success'));
          dispatch(setSnackbarMessage('Cliente deletado com sucesso !'));
          dispatch(setSnackbarOpen());
        }
      } catch (error) {
        dispatch(setDialogClose());
        dispatch(setSnackbarType('error'));
        dispatch(
          setSnackbarMessage(
            'Não foi possível deletar o cliente, tente novamente mais tarde !'
          )
        );
        dispatch(setSnackbarOpen());
        console.error('Não foi possível deletar este cliente', error);
      }
    }

    deleteCustomer(buyer_id);
  }

  return (
    <React.Fragment>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Typography className={classes.title} variant="h5">
            Confirmar ação
          </Typography>
          <IconButton
            aria-label="Fechar"
            color="inherit"
            edge="start"
            onClick={handleDialogClose}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Grid className={classes.container} container>
        <Typography variant="body1">
          Você deseja realmente realizar está ação?
        </Typography>
        <Typography variant="body2">
          Lembrando que essas ações não podem ser revertidas!
        </Typography>
      </Grid>
      <DialogActions>
        <Button color="secondary" onClick={() => handleDialogClose()}>
          Cancelar
        </Button>
        <Button
          color="secondary"
          disabled={buttonDisable}
          onClick={() => handleCustomerDelete(buyer_id)}
        >
          OK
        </Button>
      </DialogActions>
    </React.Fragment>
  );
};

export default CustomerDeleteDialog;
