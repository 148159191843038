import React from 'react';
import * as ReactRedux from 'react-redux';

import type { RootState } from 'types/redux/redux.types';

import useChargeService from './use-charge.service';
import useChargeUI from './use-charge.ui';

function useCharge() {
  const service = useChargeService();
  const ui = useChargeUI();
  const state = ReactRedux.useSelector((state: RootState) => state.charge);

  return React.useMemo(
    () => ({
      service,
      ui,
      state,
    }),
    [service, state, ui]
  );
}

export default useCharge;
