export function setDialogOpen() {
  return {
    type: 'DIALOG_OPENED',
  };
}

export function setDialogClose() {
  return {
    type: 'DIALOG_CLOSED',
  };
}

export function setDialogType(type) {
  return {
    type: 'DIALOG_TYPE_CHANGED',
    payload: type,
  };
}

export function setDialogFullScreen(option) {
  return {
    type: 'DIALOG_FULL_SCREEN_CHANGED',
    payload: option,
  };
}

export function setDialogMaxWidth(option) {
  return {
    type: 'DIALOG_MAX_WIDTH_CHANGED',
    payload: option,
  };
}
