import React from 'react';
import * as ReactRedux from 'react-redux';

import type { RootState } from 'types/redux/redux.types';

import usePlanService from './usePlan.service';
import usePlanUI from './usePlan.ui';

function usePlan() {
  const service = usePlanService();
  const ui = usePlanUI();
  const state = ReactRedux.useSelector((state: RootState) => state.planState);

  return React.useMemo(
    () => ({
      service,
      ui,
      state,
    }),
    [service, state, ui]
  );
}

export default usePlan;
