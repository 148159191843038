import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import {
  setDialogClose,
  setChargeState,
  setChargeSelected,
  setSnackbarOpen,
  setSnackbarType,
  setSnackbarMessage,
} from 'store/actions';

import { getDisplayMoney } from 'helpers';

import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
  Grid,
  DialogActions,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/styles';
import { Close as CloseIcon } from '@material-ui/icons';
import { api } from 'services/api';

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
    paddingRight: '0px !important',
    backgroundColor: theme.palette.error.light,
  },
  title: {
    flex: 1,
    color: '#fff',
  },
  subtitle: {
    marginBottom: theme.spacing(2),
  },
  container: {
    padding: theme.spacing(2),
    width: 700,
    maxWidth: '100%',
  },
  containerActions: {
    padding: theme.spacing(2),
  },
}));

const ChargeDeleteDialog = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { chargeSelected } = useSelector(state => state.chargeState);
  const [buttonDisabled, setButtonDisabled] = useState(true);

  useEffect(() => {
    if (chargeSelected) {
      setButtonDisabled(false);
    }
  }, [chargeSelected]);

  const handleDialogClose = () => dispatch(setDialogClose());

  const handleDeleteThisCharge = async () => {
    dispatch(setSnackbarType('info'));
    dispatch(setSnackbarMessage('Processando...'));
    dispatch(setSnackbarOpen());
    setButtonDisabled(true);

    async function deleteThisCharge() {
      try {
        const token = localStorage.getItem('token');

        const response = await api.delete(`/charge/${chargeSelected.id}/`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data) {
          dispatch(setChargeState('get'));
          dispatch(setChargeSelected({}));
          dispatch(setDialogClose());
          dispatch(setSnackbarType('success'));
          dispatch(setSnackbarMessage('Cobrança deletada com sucesso !'));
          dispatch(setSnackbarOpen());
        }
      } catch (error) {
        dispatch(setDialogClose());
        dispatch(setSnackbarType('error'));
        dispatch(
          setSnackbarMessage(
            'Não foi possível deletar está cobrança, tente novamente mais tarde !'
          )
        );
        dispatch(setSnackbarOpen());
        console.error('Não foi possível deletar este cliente', error);
      }
    }

    deleteThisCharge();
  };

  const handleDeleteThisAndNextCharges = () => {
    dispatch(setSnackbarType('info'));
    dispatch(setSnackbarMessage('Processando...'));
    dispatch(setSnackbarOpen());
    setButtonDisabled(true);

    async function deleteThisAndNextCharges() {
      try {
        const token = localStorage.getItem('token');

        const response = await api.delete(
          `/charge/${chargeSelected.id}/next/`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.data) {
          dispatch(setChargeState('get'));
          dispatch(setChargeSelected({}));
          dispatch(setDialogClose());
          dispatch(setSnackbarType('success'));
          dispatch(setSnackbarMessage('Cobranças deletadas com sucesso !'));
          dispatch(setSnackbarOpen());
        }
      } catch (error) {
        dispatch(setDialogClose());
        dispatch(setSnackbarType('error'));
        dispatch(
          setSnackbarMessage(
            'Não foi possível deletar as cobranças, tente novamente mais tarde !'
          )
        );
        dispatch(setSnackbarOpen());
        console.error('Não foi possível deletar este cliente', error);
      }
    }

    deleteThisAndNextCharges();
  };

  const getDisplayInstallmentOrCash = () => {
    if (chargeSelected.installments !== 1) {
      return `${chargeSelected.installments}x de ${getDisplayMoney(
        chargeSelected.amount / 100
      )}`;
    } else {
      return `${getDisplayMoney(chargeSelected.amount / 100)} à vista`;
    }
  };

  return (
    <React.Fragment>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Typography className={classes.title} variant="h5">
            Confirmar ação
          </Typography>
          <IconButton
            aria-label="Fechar"
            color="inherit"
            edge="start"
            onClick={handleDialogClose}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Grid className={classes.container} container spacing={2}>
        <Grid item xs={12}>
          <Typography className={classes.subtitle} variant="h4">
            Dados da cobrança
          </Typography>
        </Grid>
        <Grid item md={6} xs={12}>
          <Typography variant="body1">
            <strong>De: </strong> {chargeSelected.seller_name}
          </Typography>
        </Grid>
        <Grid item md={6} xs={12}>
          <Typography variant="body1">
            <strong>Para: </strong> {chargeSelected.buyer_name}
          </Typography>
        </Grid>
        <Grid item md={6} xs={12}>
          <Typography variant="body1">
            <strong>Valor: </strong> {getDisplayInstallmentOrCash()}
          </Typography>
        </Grid>
        {chargeSelected.installments !== 1 && (
          <React.Fragment>
            <Grid item md={6} xs={12}>
              <Typography variant="body1">
                <strong>Parcela: </strong> {chargeSelected.installment}
              </Typography>
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography variant="body1">
                <strong>Total de parcelas: </strong>{' '}
                {chargeSelected.installments}
              </Typography>
            </Grid>
          </React.Fragment>
        )}
        <Grid item md={6} xs={12}>
          <Typography variant="body1">
            <strong>Data de vencimento: </strong>{' '}
            {moment(chargeSelected.expiration_date).format('DD/MM/YYYY')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2">
            Lembrando que essas ações não podem ser revertidas!
          </Typography>
        </Grid>
      </Grid>
      <DialogActions className={classes.containerActions}>
        <Grid container justify="space-around" spacing={2}>
          <Grid item md={6} xs={12}>
            <Button
              color="primary"
              disabled={buttonDisabled}
              fullWidth
              onClick={handleDeleteThisCharge}
              size="small"
              variant="contained"
            >
              Apagar e não cobrar apenas está
            </Button>
          </Grid>
          <Grid item md={6} xs={12}>
            <Button
              color="primary"
              disabled={buttonDisabled}
              fullWidth
              onClick={handleDeleteThisAndNextCharges}
              size="small"
              variant="contained"
            >
              Apagar e não cobrar todas em diante
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </React.Fragment>
  );
};

export default ChargeDeleteDialog;
