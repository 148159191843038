import React from 'react';
import * as ReactRedux from 'react-redux';

import type { ISubscription } from 'types/entities';
import {
  setSubscription,
  setSubscriptionState,
  setDialogFullScreen,
  setDialogOpen,
  setDialogType,
} from 'store/actions';

function useSubscriptionUI() {
  const dispatch = ReactRedux.useDispatch();

  const openToView = React.useCallback(
    (subscription: ISubscription) => {
      dispatch([
        setSubscription(subscription),
        setSubscriptionState('view'),
        setDialogType('viewSubscription'),
        setDialogFullScreen(true),
        setDialogOpen(),
      ]);
    },
    [dispatch]
  );

  const openToEdit = React.useCallback(
    (subscription: ISubscription) => {
      dispatch([
        setSubscription(subscription),
        setSubscriptionState('edit'),
        setDialogType('editSubscription'),
        setDialogFullScreen(true),
        setDialogOpen(),
      ]);
    },
    [dispatch]
  );

  const refresh = React.useCallback(() => {
    dispatch([setSubscription(null), setSubscriptionState('get')]);
  }, [dispatch]);

  return React.useMemo(
    () => ({
      openToView,
      openToEdit,
      refresh,
    }),
    [openToView, openToEdit, refresh]
  );
}

export default useSubscriptionUI;
