import React from 'react';
import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';
import * as ReactRedux from 'react-redux';
import moment from 'moment';

import type { IBuyer, IPlan } from 'types/entities';
import type { RootState } from 'types/redux';
import { setDialogClose, setDialogType, setSubscription } from 'store/actions';
import {
  NumberUtils,
  PlanUtils,
  StringUtils,
  SubscriptionUtils,
  getDisplayDocumentNumber,
  getDisplayMoney,
  getDisplayPhoneNumber,
} from 'helpers';

import useStyles from './SubscriptionViewDialog.styles';

function SubscriptionViewDialog() {
  const classes = useStyles();
  const dispatch = ReactRedux.useDispatch();

  const { subscription } = ReactRedux.useSelector(
    (state: RootState) => state.subscriptionState
  );

  if (!subscription) {
    return null;
  }

  const customer = subscription.customer as unknown as IBuyer;
  const plan = subscription.plan as unknown as IPlan;
  const metadata = Object.entries(subscription.metadata || {});

  const handleDialogClose = () => {
    dispatch([setSubscription(null), setDialogClose(), setDialogType(null)]);
  };

  return (
    <React.Fragment>
      <Mui.AppBar className={classes.appBar}>
        <Mui.Toolbar>
          <Mui.Typography className={classes.title} variant="h5">
            {`Assinatura (${SubscriptionUtils.getStatusLabel(
              subscription.status
            ).toUpperCase()})`}
          </Mui.Typography>
          <Mui.IconButton
            aria-label="Fechar"
            color="inherit"
            edge="end"
            onClick={handleDialogClose}
          >
            <MuiIcons.Close />
          </Mui.IconButton>
        </Mui.Toolbar>
      </Mui.AppBar>
      <Mui.Grid className={classes.container} container spacing={2}>
        <Mui.Grid item xs={12}>
          <Mui.Typography variant="h4">Ações</Mui.Typography>
        </Mui.Grid>
        <div className={classes.actionsContainer}>
          <Mui.Tooltip title="Em breve">
            <div className={classes.actionButton}>
              <Mui.Button
                color="secondary"
                disabled
                fullWidth
                variant="contained"
              >
                Suspender
              </Mui.Button>
            </div>
          </Mui.Tooltip>
          <Mui.Tooltip title="Em breve">
            <div className={classes.actionButton}>
              <Mui.Button
                color="secondary"
                disabled
                fullWidth
                variant="contained"
              >
                Reativar
              </Mui.Button>
            </div>
          </Mui.Tooltip>
          <Mui.Tooltip title="Em breve">
            <div className={classes.actionButton}>
              <Mui.Button
                color="secondary"
                disabled
                fullWidth
                variant="contained"
              >
                Cancelar
              </Mui.Button>
            </div>
          </Mui.Tooltip>
        </div>

        <Mui.Grid item xs={12}>
          <Mui.Divider />
        </Mui.Grid>

        <Mui.Grid item xs={12}>
          <Mui.Typography variant="h4">Dados da assinatura</Mui.Typography>
        </Mui.Grid>

        <Mui.Grid item md={3} sm={4} xs={12}>
          <Mui.Typography variant="body1">
            <strong>Valor: </strong>
            {subscription.amount
              ? getDisplayMoney(
                  NumberUtils.toPrecision(subscription.amount / 100, 0)
                )
              : getDisplayMoney(NumberUtils.toPrecision(plan.amount / 100, 0))}
          </Mui.Typography>
        </Mui.Grid>

        {/*<Mui.Grid item md={3} sm={4} xs={12}>*/}
        {/*  <Mui.Typography variant="body1">*/}
        {/*    <strong>Card: </strong>*/}
        {/*    {subscription.card || 'não definido'}*/}
        {/*  </Mui.Typography>*/}
        {/*</Mui.Grid>*/}

        <Mui.Grid item md={3} sm={4} xs={12}>
          <Mui.Typography variant="body1">
            <strong>Description: </strong>
            {subscription.description || 'Sem descrição'}
          </Mui.Typography>
        </Mui.Grid>

        <Mui.Grid item md={3} sm={4} xs={12}>
          <Mui.Typography variant="body1">
            <strong>
              Data da{' '}
              {moment(subscription.due_date).isBefore(new Date())
                ? 'última'
                : 'próxima'}{' '}
              cobrança:{' '}
            </strong>
            {moment(subscription.due_date).format('DD/MM/YYYY HH:mm:ss')}
          </Mui.Typography>
        </Mui.Grid>

        <Mui.Grid item md={3} sm={4} xs={12}>
          <Mui.Typography variant="body1">
            <strong>Vencimento da última cobrança: </strong>
            {subscription.due_since_date
              ? moment(subscription.due_since_date).format(
                  'DD/MM/YYYY HH:mm:ss'
                )
              : 'Sem data definida'}
          </Mui.Typography>
        </Mui.Grid>

        <Mui.Grid item md={3} sm={4} xs={12}>
          <Mui.Typography variant="body1">
            <strong>Data de expiração : </strong>
            {subscription.expiration_date
              ? moment(subscription.expiration_date).format(
                  'DD/MM/YYYY HH:mm:ss'
                )
              : 'Sem data definida'}
          </Mui.Typography>
        </Mui.Grid>

        <Mui.Grid item md={3} sm={4} xs={12}>
          <Mui.Typography variant="body1">
            <strong>Status: </strong>
            {StringUtils.capitalize(
              SubscriptionUtils.getStatusLabel(subscription.status)
            )}
          </Mui.Typography>
        </Mui.Grid>

        <Mui.Grid item md={3} sm={4} xs={12}>
          <Mui.Typography variant="body1">
            <strong>Data da suspensão: </strong>
            {subscription.suspended_at
              ? moment(subscription.suspended_at).format('DD/MM/YYYY HH:mm:ss')
              : 'Sem data definida'}
          </Mui.Typography>
        </Mui.Grid>

        <Mui.Grid item md={3} sm={4} xs={12}>
          <Mui.Typography variant="body1">
            <strong>Data de criação: </strong>
            {moment(subscription.created_at).format('DD/MM/YYYY HH:mm:ss')}
          </Mui.Typography>
        </Mui.Grid>

        <Mui.Grid item md={3} sm={4} xs={12}>
          <Mui.Typography variant="body1">
            <strong>Último atualização: </strong>
            {moment(subscription.updated_at).format('DD/MM/YYYY HH:mm:ss')}
          </Mui.Typography>
        </Mui.Grid>

        <Mui.Grid item xs={12}>
          <Mui.Divider />
        </Mui.Grid>
        <Mui.Grid item xs={12}>
          <Mui.Typography variant="h4">Dados do plano</Mui.Typography>
        </Mui.Grid>

        <Mui.Grid item md={3} sm={4} xs={12}>
          <Mui.Typography variant="body1">
            <strong>Nome: </strong>
            {plan.name}
          </Mui.Typography>
        </Mui.Grid>

        <Mui.Grid item md={3} sm={4} xs={12}>
          <Mui.Typography variant="body1">
            <strong>Valor: </strong>
            {getDisplayMoney(NumberUtils.toPrecision(plan.amount / 100, 0))}
          </Mui.Typography>
        </Mui.Grid>

        <Mui.Grid item md={3} sm={4} xs={12}>
          <Mui.Typography variant="body1">
            <strong>Frequência: </strong>
            {StringUtils.capitalize(
              PlanUtils.getFrequencyLabel(plan.frequency)
            )}
          </Mui.Typography>
        </Mui.Grid>

        <Mui.Grid item md={3} sm={4} xs={12}>
          <Mui.Typography variant="body1">
            <strong>Intervalo: </strong>
            {`${plan.interval} ${StringUtils.capitalize(
              PlanUtils.getFrequencyLabel(
                plan.frequency,
                plan.interval > 1 ? 'plural_of_noun' : 'noun'
              )
            )}`}
          </Mui.Typography>
        </Mui.Grid>

        <Mui.Grid item md={3} sm={4} xs={12}>
          <Mui.Typography variant="body1">
            <strong>Duração: </strong>
            {`${plan.duration} ${StringUtils.capitalize(
              PlanUtils.getFrequencyLabel(
                plan.frequency,
                plan.duration > 1 ? 'plural_of_noun' : 'noun'
              )
            )}`}
          </Mui.Typography>
        </Mui.Grid>

        <Mui.Grid item md={3} sm={4} xs={12}>
          <Mui.Typography variant="body1">
            <strong>Descrição: </strong>
            {plan.description || 'Sem descrição'}
          </Mui.Typography>
        </Mui.Grid>

        <Mui.Grid item md={3} sm={4} xs={12}>
          <Mui.Typography variant="body1">
            <strong>Data de criação: </strong>
            {moment(plan.createdAt).format('DD/MM/YYYY HH:mm:ss')}
          </Mui.Typography>
        </Mui.Grid>

        <Mui.Grid item md={3} sm={4} xs={12}>
          <Mui.Typography variant="body1">
            <strong>Último atualização: </strong>
            {moment(plan.updatedAt).format('DD/MM/YYYY HH:mm:ss')}
          </Mui.Typography>
        </Mui.Grid>

        <Mui.Grid item xs={12}>
          <Mui.Divider />
        </Mui.Grid>
        <Mui.Grid item xs={12}>
          <Mui.Typography variant="h4">Dados do assinante</Mui.Typography>
        </Mui.Grid>

        <Mui.Grid item md={3} sm={4} xs={12}>
          <Mui.Typography variant="body1">
            <strong>Nome: </strong>
            {customer.name}
          </Mui.Typography>
        </Mui.Grid>

        <Mui.Grid item md={3} sm={4} xs={12}>
          <Mui.Typography variant="body1">
            <strong>CPF: </strong>
            {getDisplayDocumentNumber(customer.document_number)}
          </Mui.Typography>
        </Mui.Grid>

        <Mui.Grid item md={3} sm={4} xs={12}>
          <Mui.Typography variant="body1">
            <strong>Celular / Telefone: </strong>
            {getDisplayPhoneNumber(customer.phone_number)}
          </Mui.Typography>
        </Mui.Grid>

        <Mui.Grid item md={3} sm={4} xs={12}>
          <Mui.Typography variant="body1">
            <strong>Data de nascimento: </strong>
            {customer.birthdate
              ? moment(customer.birthdate).format('DD/MM/YYYY')
              : 'Não definido'}
          </Mui.Typography>
        </Mui.Grid>

        <Mui.Grid item md={3} sm={4} xs={12}>
          <Mui.Typography variant="body1">
            <strong>Rua: </strong>
            {customer.street || 'Não definido'}
          </Mui.Typography>
        </Mui.Grid>

        <Mui.Grid item md={3} sm={4} xs={12}>
          <Mui.Typography variant="body1">
            <strong>Número: </strong>
            {customer.house_number || 'Não definido'}
          </Mui.Typography>
        </Mui.Grid>

        <Mui.Grid item md={3} sm={4} xs={12}>
          <Mui.Typography variant="body1">
            <strong>CEP: </strong>
            {customer.postal_code || 'Não definido'}
          </Mui.Typography>
        </Mui.Grid>

        <Mui.Grid item md={3} sm={4} xs={12}>
          <Mui.Typography variant="body1">
            <strong>Bairro: </strong>
            {customer.neighborhood || 'Não definido'}
          </Mui.Typography>
        </Mui.Grid>

        <Mui.Grid item md={3} sm={4} xs={12}>
          <Mui.Typography variant="body1">
            <strong>Cidade: </strong>
            {customer.city || 'Não definido'}
          </Mui.Typography>
        </Mui.Grid>

        <Mui.Grid item md={3} sm={4} xs={12}>
          <Mui.Typography variant="body1">
            <strong>Estado: </strong>
            {customer.state || 'Não definido'}
          </Mui.Typography>
        </Mui.Grid>

        <Mui.Grid item md={3} sm={4} xs={12}>
          <Mui.Typography variant="body1">
            <strong>País: </strong>
            {customer.country_code || 'Não definido'}
          </Mui.Typography>
        </Mui.Grid>

        <Mui.Grid item md={3} sm={4} xs={12}>
          <Mui.Typography variant="body1">
            <strong>Data de criação: </strong>
            {moment(customer.created_at).format('DD/MM/YYYY HH:mm:ss')}
          </Mui.Typography>
        </Mui.Grid>

        <Mui.Grid item md={3} sm={4} xs={12}>
          <Mui.Typography variant="body1">
            <strong>Último atualização: </strong>
            {moment(customer.updated_at).format('DD/MM/YYYY HH:mm:ss')}
          </Mui.Typography>
        </Mui.Grid>

        {metadata.length > 0 && (
          <>
            <Mui.Grid item xs={12}>
              <Mui.Divider />
            </Mui.Grid>
            <Mui.Grid item xs={12}>
              <Mui.Typography variant="h4">Metadados</Mui.Typography>
            </Mui.Grid>
            <Mui.Grid container direction="column" item spacing={1}>
              {metadata.map(([key, value]) => (
                <Mui.Grid item key={key} xs={4}>
                  <Mui.Typography variant="body1">
                    <strong>{key}: </strong>
                    {value}
                  </Mui.Typography>
                </Mui.Grid>
              ))}
            </Mui.Grid>
          </>
        )}
      </Mui.Grid>
    </React.Fragment>
  );
}

export default SubscriptionViewDialog;
