import React from 'react';
import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';
import * as MuiLab from '@material-ui/lab';
import * as MuiPickers from '@material-ui/pickers';

import { InputCurrency } from 'components';
import { Metadata } from 'components/utils/Metadata';
import { OfferUtils } from 'helpers';

import useStyles from './OfferEditDialog.styles';
import { useOfferEditDialog } from './OfferEditDialog.hook';
import moment from 'moment-timezone';
import MomentUtils from '@date-io/moment';

const OfferEditDialog = () => {
  const classes = useStyles();
  const {
    formState,
    offer,
    offerState,
    products,
    handleDateChange,
    handleDialogClose,
    handleDisableDateEnd,
    handleFormChange,
    handleMetadataChange,
    handleProductChange,
    handleSubmit,
    hasError,
  } = useOfferEditDialog();

  return (
    <React.Fragment>
      <Mui.AppBar className={classes.appBar}>
        <Mui.Toolbar>
          <Mui.IconButton
            aria-label="Fechar"
            color="inherit"
            edge="start"
            onClick={handleDialogClose}
          >
            <MuiIcons.Close />
          </Mui.IconButton>
          <Mui.Typography className={classes.title} variant="h5">
            {offerState === 'create' ? 'Criar nova oferta' : 'Editar oferta'}
          </Mui.Typography>
          <Mui.Button
            color="inherit"
            disabled={!formState.isValid || formState.disabled}
            onClick={handleSubmit}
          >
            {offerState === 'create' ? 'Criar' : 'Salvar'}
          </Mui.Button>
        </Mui.Toolbar>
      </Mui.AppBar>
      <Mui.Grid className={classes.container} container spacing={2}>
        <Mui.Grid item xs={12}>
          <Mui.Typography variant="h4">Ações</Mui.Typography>
        </Mui.Grid>
        <div className={classes.actionsContainer}>
          <Mui.Tooltip title="Em breve">
            <div className={classes.actionButton}>
              <Mui.Button
                color="secondary"
                disabled
                fullWidth
                variant="contained"
              >
                Desativar
              </Mui.Button>
            </div>
          </Mui.Tooltip>
        </div>
        <Mui.Grid item xs={12}>
          <Mui.Divider />
        </Mui.Grid>
        <Mui.Grid item xs={12}>
          <Mui.Typography variant="h4">Dados da oferta</Mui.Typography>
        </Mui.Grid>
        <Mui.Grid item md={4} sm={6} xs={12}>
          <Mui.TextField
            error={hasError('name')}
            fullWidth
            helperText={hasError('name') && formState.errors.name}
            id="name"
            label="Nome da oferta"
            name="name"
            onChange={handleFormChange}
            value={formState.values.name || ''}
            variant="outlined"
          />
        </Mui.Grid>
        <Mui.Grid item md={4} sm={6} xs={12}>
          <Mui.TextField
            InputLabelProps={{
              classes: {
                disabled: classes.selectDisabled,
              },
            }}
            SelectProps={{
              classes: {
                icon: classes.selectIcon,
              },
              MenuProps: {
                classes: {
                  paper: classes.selectMenuList,
                },
              },
            }}
            error={hasError('status')}
            fullWidth
            helperText={hasError('status') ? formState.errors.status : null}
            label="Status da oferta"
            name="status"
            onChange={handleFormChange}
            select
            value={formState.values.status}
            variant="outlined"
          >
            {Object.entries(OfferUtils.statusLabelMap).map(([value, label]) => {
              return (
                <Mui.MenuItem
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  ListItemClasses={{
                    root: classes.selectMenuItem,
                    selected: classes.selectItemSelected,
                  }}
                  button
                  key={value}
                  selected={value === formState.values.status}
                  value={value}
                >
                  {label}
                </Mui.MenuItem>
              );
            })}
          </Mui.TextField>
        </Mui.Grid>
        <Mui.Grid item md={4} sm={6} xs={12}>
          <InputCurrency
            error={hasError('amount')}
            fullWidth
            helperText={hasError('amount') ? formState.errors.amount : ''}
            id="amount"
            label="Valor da oferta"
            name="amount"
            onChange={handleFormChange}
            type="tel"
            value={formState.values.amount}
            variant="outlined"
          />
        </Mui.Grid>
        <Mui.Grid item md={4} sm={6} xs={12}>
          <Mui.TextField
            error={hasError('description')}
            fullWidth
            helperText={hasError('description') && formState.errors.description}
            id="description"
            label="Descrição"
            maxRows="4"
            multiline
            name="description"
            onChange={handleFormChange}
            value={formState.values.description}
            variant="outlined"
          />
        </Mui.Grid>

        <Mui.Grid item md={4} sm={6} xs={12}>
          <MuiLab.Autocomplete
            autoHighlight
            autoSelect
            classes={{
              paper: classes.paperAutocomplete,
            }}
            clearOnEscape
            clearText="Limpar"
            closeText="Fechar"
            disabled={offerState === 'edit'}
            forcePopupIcon
            getOptionLabel={product => product.name}
            getOptionSelected={(option, value) => option.id === value.id}
            id="product"
            noOptionsText="Não há opções"
            onChange={handleProductChange}
            openText="Abrir"
            options={products}
            renderInput={params => (
              <Mui.TextField
                {...params}
                InputLabelProps={{
                  classes: {
                    disabled: classes.inputDisabled,
                  },
                }}
                InputProps={{
                  ...params.InputProps,
                  classes: {
                    disabled: classes.inputDisabled,
                  },
                }}
                disabled={offerState === 'edit'}
                error={hasError('product')}
                fullWidth
                helperText={hasError('product') ? formState.errors.product : ''}
                id="product-input"
                label="Produto"
                name="product"
                type="text"
                variant="outlined"
              />
            )}
            value={formState.values.product}
          />
        </Mui.Grid>

        <Mui.Grid item md={4} sm={6} xs={12}>
          <MuiPickers.MuiPickersUtilsProvider
            libInstance={moment}
            locale="pt-br"
            utils={MomentUtils}
          >
            <MuiPickers.DatePicker
              DialogProps={{
                PaperProps: {
                  classes: {
                    root: classes.rootDialog,
                  },
                },
              }}
              cancelLabel="CANCELAR"
              clearLabel="LIMPAR"
              clearable
              format="DD/MM/YYYY"
              fullWidth
              id="dateStart"
              inputVariant="outlined"
              label="Data de início"
              leftArrowButtonProps={{
                classes: {
                  root: classes.buttonPicker,
                },
              }}
              name="dateStart"
              onChange={handleDateChange('dateStart')}
              rightArrowButtonProps={{
                classes: {
                  root: classes.buttonPicker,
                },
              }}
              value={formState.values.dateStart}
            />
          </MuiPickers.MuiPickersUtilsProvider>
        </Mui.Grid>
        <Mui.Grid item md={4} sm={6} xs={12}>
          <MuiPickers.MuiPickersUtilsProvider
            libInstance={moment}
            locale="pt-br"
            utils={MomentUtils}
          >
            <MuiPickers.DatePicker
              DialogProps={{
                PaperProps: {
                  classes: {
                    root: classes.rootDialog,
                  },
                },
              }}
              cancelLabel="CANCELAR"
              clearLabel="LIMPAR"
              clearable
              format="DD/MM/YYYY"
              fullWidth
              id="dateEnd"
              inputVariant="outlined"
              label="Data de término"
              leftArrowButtonProps={{
                classes: {
                  root: classes.buttonPicker,
                },
              }}
              minDate={formState.values.dateStart || undefined}
              minDateMessage={`A data mínima é ${formState.values.dateStart?.format(
                'DD/MM/YYYY'
              )}`}
              name="dateEnd"
              onChange={handleDateChange('dateEnd')}
              rightArrowButtonProps={{
                classes: {
                  root: classes.buttonPicker,
                },
              }}
              shouldDisableDate={handleDisableDateEnd}
              value={formState.values.dateEnd}
            />
          </MuiPickers.MuiPickersUtilsProvider>
        </Mui.Grid>

        <Mui.Grid item xs={12}>
          <Mui.Divider />
        </Mui.Grid>
        <Mui.Grid item xs={12}>
          <Mui.Typography variant="h4">Metadados</Mui.Typography>
        </Mui.Grid>
        <Mui.Grid item lg={8} md={10} xs={12}>
          <Metadata
            metadata={offer?.metadata}
            onChange={handleMetadataChange}
          />
        </Mui.Grid>
      </Mui.Grid>
    </React.Fragment>
  );
};

export default OfferEditDialog;
