import React from 'react';
import * as Mui from '@material-ui/core';
import * as MuiPickers from '@material-ui/pickers';
import * as ReactRedux from 'react-redux';
import MomentUtils from '@date-io/moment';
import clsx from 'clsx';
import moment from 'moment';
import type { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

import * as BuyerHooks from 'hooks/useBuyer';
import type { ICustomerReducerState } from 'types/redux';
import { SearchInput } from 'components';
import { setCustomerFilter } from 'store/actions';

import useStyles from './CustomerToolbar.styles';
import { useAuthContext } from 'hooks/useAuthContext.hook';

type CustomersToolbarProps = React.HTMLAttributes<HTMLDivElement>;

interface CustomersToolbarMakeSearchParams {
  search?: string;
  pagination: ICustomerReducerState['pagination'];
  dateStart?: string;
  dateEnd?: string;
}

const CustomersToolbar = (props: CustomersToolbarProps) => {
  const { className, ...rest } = props;

  const { marketplaceUser } = useAuthContext();
  const dispatch = ReactRedux.useDispatch();
  const classes = useStyles();

  const buyer = BuyerHooks.useBuyer();
  const { state, pagination, filter } = buyer.state;

  const lastFilterRef = React.useRef<string>(filter.search);

  function handleDateStartChange(momentDate: MaterialUiPickersDate) {
    const dateStart = momentDate?.toISOString() || '';
    buyer.ui.search();
    dispatch(setCustomerFilter({ dateStart }));
  }

  function handleDateEndChange(momentDate: MaterialUiPickersDate) {
    const dateEnd = momentDate?.toISOString() || '';
    buyer.ui.search();
    dispatch(setCustomerFilter({ dateEnd }));
  }

  const makeSearch = React.useMemo(() => {
    return Mui.debounce((params: CustomersToolbarMakeSearchParams) => {
      const { search, pagination, dateEnd, dateStart } = params;

      buyer.service.search({
        search,
        offset: pagination?.offset,
        limit: pagination?.limit,
        dateEnd,
        dateStart,
      });
    }, 1000);
  }, [buyer.service]);

  function handleSearch(event: React.ChangeEvent<HTMLInputElement>) {
    const search = event.target.value;

    buyer.ui.search();
    dispatch(setCustomerFilter({ search }));
  }

  function handleDisableDateEnd(momentDate: MaterialUiPickersDate) {
    return !!momentDate?.isBefore(filter.dateStart);
  }

  function handleButtonExportClick() {
    buyer.service.report({
      search: filter.search,
      offset: pagination?.offset,
      limit: pagination?.limit,
      dateEnd: filter.dateEnd,
      dateStart: filter.dateStart,
    });
  }

  React.useEffect(() => {
    if (state === 'search') {
      if (filter.search || filter.dateStart || filter.dateEnd) {
        makeSearch({
          search: filter.search,
          pagination: pagination && {
            ...pagination,
            offset:
              lastFilterRef.current === filter.search ? pagination.offset : 0,
          },
          dateEnd: filter.dateEnd ? filter.dateEnd : undefined,
          dateStart: filter.dateStart ? filter.dateStart : undefined,
        });
      } else if (lastFilterRef.current) {
        buyer.service.getAll();
      }
    }

    lastFilterRef.current = filter.search || filter.dateStart || filter.dateEnd;
  }, [buyer.service, filter, pagination, state, makeSearch]);

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div className={classes.row}>
        <div className={classes.leftActionsContainer}>
          <MuiPickers.MuiPickersUtilsProvider
            libInstance={moment}
            locale="pt-br"
            utils={MomentUtils}
          >
            <MuiPickers.DatePicker
              DialogProps={{
                PaperProps: {
                  classes: {
                    root: classes.rootDialog,
                  },
                },
              }}
              cancelLabel="CANCELAR"
              className={classes.dateStartPicker}
              clearLabel="LIMPAR"
              clearable
              disableFuture
              format="DD/MM/YYYY"
              id="dateStart"
              inputVariant="outlined"
              label="De"
              leftArrowButtonProps={{
                classes: {
                  root: classes.buttonPicker,
                },
              }}
              margin="dense"
              name="dateStart"
              onChange={handleDateStartChange}
              rightArrowButtonProps={{
                classes: {
                  root: classes.buttonPicker,
                },
              }}
              value={filter.dateStart || null}
            />
            <MuiPickers.DatePicker
              DialogProps={{
                PaperProps: {
                  classes: {
                    root: classes.rootDialog,
                  },
                },
              }}
              cancelLabel="CANCELAR"
              className={classes.dateEndPicker}
              clearLabel="LIMPAR"
              clearable
              disableFuture
              format="DD/MM/YYYY"
              id="dateEnd"
              inputVariant="outlined"
              label="Até"
              leftArrowButtonProps={{
                classes: {
                  root: classes.buttonPicker,
                },
              }}
              margin="dense"
              name="dateEnd"
              onChange={handleDateEndChange}
              rightArrowButtonProps={{
                classes: {
                  root: classes.buttonPicker,
                },
              }}
              shouldDisableDate={handleDisableDateEnd}
              value={filter.dateEnd || null}
            />
          </MuiPickers.MuiPickersUtilsProvider>
          <Mui.Button
            color="primary"
            onClick={buyer.ui.refresh}
            variant="outlined"
          >
            Limpar Filtro
          </Mui.Button>
        </div>
        <span className={classes.spacer} />
        {(!marketplaceUser ||
          marketplaceUser?.roles.includes('owner') ||
          marketplaceUser?.roles.includes('marketing') ||
          marketplaceUser?.roles.includes('seller')) && (
          <div className={classes.rightActionsContainer}>
            {/*<Mui.Button className={classes.importButton}>Importar</Mui.Button>*/}
            <Mui.Button
              className={classes.exportButton}
              onClick={handleButtonExportClick}
            >
              Exportar
            </Mui.Button>
            <Mui.Button
              className={classes.buttonCreate}
              color="primary"
              onClick={buyer.ui.openToCreate}
              variant="contained"
            >
              Adicionar cliente
            </Mui.Button>
          </div>
        )}
      </div>
      <div className={classes.row}>
        <SearchInput
          className={classes.searchInput}
          onChange={handleSearch}
          placeholder="Pesquise um cliente"
          value={filter.search}
        />
      </div>
    </div>
  );
};

export default CustomersToolbar;
